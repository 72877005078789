import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';

const ButtonRole = BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'ButtonRole'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
            type: PropTypes.oneOf(['button', 'submit', 'reset']),
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            type: 'button',
        };

        render() {
            return (
                <BaseComponent {...this.props} />
            );
        }
    }

    return ComposedComponent;
};

export default ButtonRole;
