import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import MobileDetect from 'mobile-detect';
import routePaths from '../../routePaths';
import {mediaRouteResolver} from '../../services';
import resolveRoute from '../../navigation/resolveRoute';
import {
    withMediaRouteHelpers,
    withMediaRouteHelpersPropTypes,
    withMediaRouteHelpersDefaultProps,
} from '../../media/withMediaRouteHelpers';
import {
    withMediaSearch,
    withMediaSearchPropTypes,
    withMediaSearchDefaultProps,
} from '../../media/withMediaSearch';
import {
    withOutsideClick,
    withOutsideClickPropTypes,
    withOutsideClickDefaultProps,
} from '../../ui-elements/outside-click/withOutsideClick';
import './NavigationSearch.scss';
import {iconNavSearch, iconNavClear, iconInputSearch} from '../../ui-elements/icons';
import NavigationIconButton from './NavigationIconButton';
import NavigationIcon from './NavigationIcon';
import NavigationSearchDropDown from './navigation-search/NavigationSearchDropDown';

const minimumSearchQueryLength = 3;

@withRouter
@withOutsideClick
@withMediaSearch
@withMediaRouteHelpers({mediaRouteResolver})
class NavigationSearch extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaSearchPropTypes,
        ...withOutsideClickPropTypes,
        history: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaSearchDefaultProps,
        ...withOutsideClickDefaultProps,
        history: null,
    };

    state = {
        isSearchOpened: this.props.isSearchRouteActive,
    };

    componentDidMount() {
        this.props.subscribeToClickOutside(this.searchContainer, this.onClickOutside);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromClickOutside(this.searchContainer, this.onClickOutside);
    }

    searchInput = React.createRef();

    searchContainer = React.createRef();

    onClickOutside = () => {
        const {isSearchRouteActive} = this.props;
        const md = new MobileDetect(window.navigator.userAgent);
        const isMobile = md.phone();
        if (!isSearchRouteActive && !isMobile) {
            this.setState({
                isSearchOpened: false,
            });
        }
    };

    toggleSearch = () => {
        const {isSearchRouteActive} = this.props;
        const md = new MobileDetect(window.navigator.userAgent);
        const isMobile = md.phone();
        if (isSearchRouteActive && !isMobile) return;
        this.setState(state => ({
            isSearchOpened: !state.isSearchOpened,
        }), () => {
            if (this.state.isSearchOpened) {
                const searchInputNode = this.searchInput.current;
                searchInputNode.focus();
            }
        });
    };

    onSearchInputChange = event => this.props.setSearchQueryString(event.target.value);

    clearSearchInput = () => this.props.setSearchQueryString('');

    goToSearchPage = event => {
        if (event) event.preventDefault();

        const {isSearchRouteActive, searchQueryString, history} = this.props;
        if (isSearchRouteActive
            || searchQueryString.length < minimumSearchQueryLength) return;

        history.push(resolveRoute(routePaths.MEDIA_SEARCH, {searchQueryString}));
    };

    render() {
        const {mediaBucket, resolveMediaItemRoute} = this.props;
        const {isSearchRouteActive, searchQueryString, areSearchResultsValid} = this.props;
        const {isSearchOpened} = this.state;

        return (
            <div className={`vub-c-navigation-search ${isSearchOpened ? 'cr-is-switched-on' : ''}`}>
                <NavigationIconButton
                    icon={iconNavSearch}
                    className={`vub-c-navigation-icon-button--large ${isSearchOpened ? 'cr-is-switched-on' : ''}`}
                    onClick={this.toggleSearch}
                />
                <div className="vub-c-navigation-search__input-container" ref={this.searchContainer}>
                    <form
                        className="vub-c-navigation-search__form"
                        onSubmit={this.goToSearchPage}
                    >
                        <input
                            type="text"
                            className="vub-c-navigation-search__search-input"
                            placeholder="Search"
                            value={searchQueryString}
                            onChange={this.onSearchInputChange}
                            ref={this.searchInput}
                        />
                        {isSearchOpened && (
                            <Fragment>
                                {searchQueryString && (
                                    <NavigationIconButton
                                        icon={iconNavClear}
                                        className="vub-c-navigation-icon-button--small"
                                        onClick={this.clearSearchInput}
                                    />
                                )}
                                <NavigationIcon
                                    icon={iconInputSearch}
                                    className="vub-c-navigation-icon-button--medium"
                                />
                            </Fragment>
                        )}
                    </form>

                    {searchQueryString.length >= minimumSearchQueryLength
                    && areSearchResultsValid
                    && !isSearchRouteActive
                    && (
                        <div className="vub-c-navigation-search__drop-down-wrapper">
                            <NavigationSearchDropDown
                                mediaBucket={mediaBucket}
                                resolveMediaItemRoute={resolveMediaItemRoute}
                                goToSearchPage={this.goToSearchPage}
                                searchQueryString={searchQueryString}
                            />
                        </div>
                    )}
                </div>
                <div className="vub-c-navigation-search__cancel" onClick={this.toggleSearch}>Cancel</div>
            </div>
        );
    }
}

export default NavigationSearch;
