import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import routePaths from '../routePaths';
import resolveRoute from '../navigation/resolveRoute';
import * as mediaEntitlementActions from '../media-entitlement/mediaEntitlementActions';
import * as authActions from '../auth/authActions';
import './SubscriptionCheckoutView.scss';
import withPopUp from '../ui-elements/pop-up/withPopUp';
import {
    withOverlay,
    withOverlayPropTypes,
    withOverlayDefaultProps,
} from '../ui-elements/overlay/withOverlay';
import StyledButton from '../ui-elements/styled-button/StyledButton';
import Payment from './elements/Payment';
import PriceInfo from './elements/PriceInfo';
import ProductInfo from './elements/ProductInfo';
import LayoutBox from '../ui-elements/layout-box/LayoutBox';
import LayoutBoxRow from '../ui-elements/layout-box/LayoutBoxRow';

@withPopUp
@withOverlay
class SubscriptionCheckoutView extends Component {
    static propTypes = {
        ...withOverlayPropTypes,
        providers: PropTypes.object,
        isSubscriptionCheckoutInProgress: PropTypes.bool,
        mediaItemOffer: PropTypes.object,
        userAccount: PropTypes.object,
        cancelSubscriptionCheckout: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        setPopUp: PropTypes.func,
        closePopUp: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        ...withOverlayDefaultProps,
        providers: null,
        isSubscriptionCheckoutInProgress: false,
        mediaItemOffer: null,
        userAccount: null,
        setPopUp: null,
        closePopUp: null,
        title: 'Subscription confirmation',
    };

    state = {
        paymentMethod: null,
    };

    componentDidUpdate(prevProps) {
        const {
            isSubscriptionCheckoutInProgress,
            mediaItemOffer,
            closePopUp,
            setPopUp,
        } = this.props;
        if (isSubscriptionCheckoutInProgress
            && mediaItemOffer !== prevProps.mediaItemOffer) {
            setPopUp(this.renderSubscriptionCheckoutView());
        }

        if (!isSubscriptionCheckoutInProgress && prevProps.isSubscriptionCheckoutInProgress) {
            closePopUp();
        }
    }

    cancelSubscriptionCheckout = () => {
        const {cancelSubscriptionCheckout, closePopUp} = this.props;

        cancelSubscriptionCheckout();
        closePopUp();
    };

    onPaymentMethodSelect = paymentMethod => {
        this.setState({
            paymentMethod,
        }, () => this.props.setPopUp(this.renderSubscriptionCheckoutView()));
    };

    onOverlayClick = () => {
        this.cancelSubscriptionCheckout();
        this.props.unsubscribeFromOverlayClick(this.onOverlayClick);
    };

    renderSubscriptionCheckoutView = () => {
        const {userAccount, updateUser, title} = this.props;
        const {providers, mediaItemOffer, isSubscriptionCheckoutInProgress} = this.props;
        const {subscribeToOverlayClick} = this.props;

        const provider = providers.get(mediaItemOffer.provider);
        const price = mediaItemOffer.price.toLocaleString('en', {
            style: 'currency',
            currency: mediaItemOffer.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + '/mo';

        subscribeToOverlayClick(this.onOverlayClick);

        return (
            <LayoutBox>
                <div className="vub-c-subscription-checkout-view">
                    <LayoutBoxRow
                        title={title}
                        border={['bottom']}
                    />
                    <LayoutBoxRow border={['bottom']}>
                        <div className="vub-c-subscription-checkout-view__poster">
                            <img
                                className="vub-c-subscription-checkout-view__image"
                                src={`${provider.serviceLogo}`}
                                alt="tile"
                            />
                        </div>
                        <ProductInfo
                            title={provider.service}
                            description={provider.description}
                        />
                    </LayoutBoxRow>
                    <LayoutBoxRow
                        border={['bottom']}
                        direction="column"
                    >
                        <Payment
                            userAccount={userAccount}
                            paymentMethod={this.state.paymentMethod}
                            onPaymentMethodSelect={this.onPaymentMethodSelect}
                            updateUser={updateUser}
                        />
                        <PriceInfo price={price} />
                        <span>
                            By pressing &quot;Subscribe&quot; you agree that you have read and accept&nbsp;
                            <Link
                                className="vub-c-subscription-checkout-view__link"
                                to={resolveRoute(routePaths.PAGE, {pageId: 'terms-of-use'})}
                                target="_blank"
                            >
                                Terms & Conditions
                            </Link>
                            &nbsp;and wish to complete your subscription.
                        </span>
                    </LayoutBoxRow>
                    <LayoutBoxRow>
                        <div className="vub-c-subscription-checkout-view__buttons">
                            <StyledButton
                                className="vub-c-styled-button--secondary"
                                onClick={this.cancelSubscriptionCheckout}
                            >
                                <span className="vub-u-font-weight-bold">Cancel</span>
                            </StyledButton>
                            <StyledButton
                                onClick={null}
                                isDisabled={isSubscriptionCheckoutInProgress}
                            >
                                <span className="vub-u-font-weight-bold">Subscribe</span>
                            </StyledButton>
                        </div>
                    </LayoutBoxRow>
                </div>
            </LayoutBox>
        );
    };

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    isSubscriptionCheckoutInProgress: state.mediaEntitlement.isSubscriptionCheckoutInProgress,
    providers: state.storeConfiguration.providers,
    mediaItemOffer: state.mediaEntitlement.subscriptionBasket,
    userAccount: state.auth.userAccount,
});

const mapDispatchToProps = dispatch => ({
    cancelSubscriptionCheckout: payload => dispatch(
        mediaEntitlementActions.cancelSubscriptionCheckout(payload),
    ),
    updateUser: payload => dispatch(authActions.updateUser(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubscriptionCheckoutView);
