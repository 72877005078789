import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {groupBy} from 'lodash'; //eslint-disable-line
import {findSearchQueryStringInMediaItem, markSearchQueryString, searchResultsGroupingOrder} from './mediaSearchUtils';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortraitExtendable from '../ui-elements/media-gallery-tile/MediaGalleryTilePortraitExtendable';
import MediaGalleryListEntry from '../ui-elements/media-gallery-list-entry/MediaGalleryListEntry';
import SearchLayoutPicker from '../ui-elements/search-layout-picker/SearchLayoutPicker';

const MediaSearchGroupedGallery = props => {
    const searchResults = [];
    let groupedSearchResults = {};
    const {mediaBucket, providers, searchQueryString, resolveMediaItemRoute} = props;
    const {searchViewLayout, updateLayoutSelectButtons} = props;

    if (mediaBucket.media.size > 0) {
        mediaBucket.media
            .toArray()
            .forEach(mediaItem => {
                const searchEntry = findSearchQueryStringInMediaItem(mediaItem, searchQueryString, 90);
                const {key, value} = searchEntry || {key: null, value: null};
                if (key && value) {
                    searchResults.push([key, mediaItem]);
                }
            });

        groupedSearchResults = groupBy(searchResults, x => x[0]);
    }
    return (
        <Fragment>
            {Object.keys(groupedSearchResults)
                .sort((a, b) => searchResultsGroupingOrder[a] - searchResultsGroupingOrder[b])
                .map(criteria => {
                    return (
                        <MediaGallery
                            key={criteria}
                            title={(
                                <div className="vub-c-media-search-view__header">
                                    <div>
                                        {groupedSearchResults[criteria].length}
                                        &nbsp;Search Results for &quot;{criteria}&quot;:
                                        <span className="vub-c-media-search-view__query-string"> &#32;{searchQueryString}</span>
                                    </div>
                                </div>
                            )}
                            subtitle={(
                                <SearchLayoutPicker
                                    searchViewLayout={searchViewLayout}
                                    updateLayoutSelectButtons={updateLayoutSelectButtons}
                                    isInTitle={true}
                                />
                            )}
                        >
                            {groupedSearchResults[criteria].map(entry => {
                                const mediaItem = entry[1];
                                const {meta, node} = markSearchQueryString(mediaItem, searchQueryString, 90);

                                if (searchViewLayout === 'grid') {
                                    return (
                                        <MediaGalleryTilePortraitExtendable
                                            key={mediaItem.id}
                                            mediaItem={mediaItem}
                                            linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                            additionalContent={(
                                                <Fragment>
                                                    <div className="vub-c-media-gallery-tile-portrait-extendable__title">
                                                        {meta && meta.toLowerCase() !== 'title' && `(${meta})`}
                                                    </div>
                                                    <div className="vub-c-media-gallery-tile-portrait-extendable__description">
                                                        {node}
                                                    </div>
                                                </Fragment>
                                            )}
                                        />
                                    );
                                }
                                return (
                                    <MediaGalleryListEntry
                                        key={mediaItem.id}
                                        mediaItem={mediaItem}
                                        linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                        meta={meta}
                                        node={node}
                                        providers={providers}
                                    />
                                );
                            })}
                        </MediaGallery>
                    );
                })}
        </Fragment>
    );
};

MediaSearchGroupedGallery.propTypes = {
    mediaBucket: PropTypes.object.isRequired,
    searchQueryString: PropTypes.string.isRequired,
    providers: PropTypes.object.isRequired,
    resolveMediaItemRoute: PropTypes.func.isRequired,
    searchViewLayout: PropTypes.string.isRequired,
    updateLayoutSelectButtons: PropTypes.func.isRequired,
};

export default MediaSearchGroupedGallery;
