import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {combineClassNames} from '../../../ui-kit/utils';
import NavigationSideItem from './NavigationSideItem';
import NavigationIcon from '../NavigationIcon';
import {iconArrowRight, iconArrowLeft} from '../../../ui-elements/icons';
import './NavigationSideSubmenu.scss';

const NavigationSideSubmenu = ({
    menuItem,
    toggleSecondaryMenu,
    toggleMenu,
    isSecondaryMenuOpened,
    section,
    location,
}) => (
    <li className={combineClassNames(
        'vub-c-navigation-side-submenu',
        isSecondaryMenuOpened && section === menuItem.id && 'cr-is-opened',
    )}
    >
        <label
            className="vub-c-navigation-side-submenu__label"
            onClick={() => toggleSecondaryMenu(menuItem.id)}
        >
            {menuItem.title}
            <NavigationIcon icon={iconArrowRight} />
        </label>
        <ul className="vub-c-navigation-side-submenu__menu">
            <NavigationSideItem
                key="back-arrow"
                isSelected={true}
                onClick={() => toggleSecondaryMenu(menuItem.id)}
            >
                <NavigationIcon
                    icon={iconArrowLeft}
                    className="vub-u-margin-right-small"
                />
                &nbsp;
            </NavigationSideItem>

            {(menuItem.children.map(group => group.display && (
                <Fragment key={group.id}>
                    <hr key="hr" className="vub-c-navigation-side-submenu__horizontal-rule" />
                    {(group.children.map(childMenuItem => childMenuItem.display && (
                        <NavigationSideItem
                            key={`sidebar-second-menu-item-${childMenuItem.id}`}
                            linkTo={childMenuItem.url}
                            isSelected={encodeURI(location.pathname).toString() === childMenuItem.url.pathname}
                            onClick={toggleMenu}
                        >
                            {childMenuItem.title}
                        </NavigationSideItem>
                    )))}
                </Fragment>
            )))}
        </ul>
    </li>
);

NavigationSideSubmenu.propTypes = {
    menuItem: PropTypes.object,
    toggleSecondaryMenu: PropTypes.func,
    toggleMenu: PropTypes.func,
    isSecondaryMenuOpened: PropTypes.bool,
    section: PropTypes.number,
    location: PropTypes.object,
};

NavigationSideSubmenu.defaultProps = {
    menuItem: null,
    toggleSecondaryMenu: null,
    toggleMenu: null,
    isSecondaryMenuOpened: null,
    section: null,
    location: null,
};

export default NavigationSideSubmenu;
