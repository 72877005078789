import React from 'react';
import PropTypes from 'prop-types';
import './SpinnerStandard.scss';

const SpinnerStandard = ({className = ''}) => (
    <div className={`vub-c-standard-spinner ${className}`} />
);

SpinnerStandard.propTypes = {
    className: PropTypes.string,
};

SpinnerStandard.defaultProps = {
    className: null,
};

export default SpinnerStandard;
