import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import routePaths from '../routePaths';
import resolveRoute from '../navigation/resolveRoute';
import * as authActions from '../auth/authActions';
import './AccountSignInView.scss';
import CustomCheckbox from '../ui-elements/custom-checkbox/CustomCheckbox';
import Input from '../ui-elements/input/Input';
import FormContainer from '../ui-elements/form/FormContainer';
import {required, isEmailValid, isPasswordValid} from '../ui-elements/form-validation/validations';
import FormButton from '../ui-elements/form/FormButton';
import ServerValidationErrors from '../ui-elements/form-validation/ServerValidationErrors';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';

const AccountSignInView = ({signUpUser, authenticateUser, isSessionChangeInProgress, serverErrors}) => {
    const getFormValues = ({email, password}) => {
        authenticateUser({email, password});
    };

    return (
        <div className="vub-c-account-sign-in-view">
            <div className="vub-c-account-sign-in-view__title">
                sign in
            </div>
            <div className="vub-c-account-sign-in-view__form">
                <FormContainer
                    initialState={{
                        email: '',
                        password: '',
                        isSignIn: false,
                        errors: {},
                    }}
                    getFormValues={getFormValues}
                    render={
                        (
                            {email, password, isSignIn, errors},
                            handleValues,
                        ) => (
                            <Fragment>
                                <Input
                                    type="email"
                                    name="email"
                                    className="vub-c-input-container--auth"
                                    placeholder="Email"
                                    value={email}
                                    isDisabled={isSessionChangeInProgress}
                                    handleChange={handleValues}
                                    validators={[required, isEmailValid]}
                                    error={errors['email']}
                                />
                                <Input
                                    type="password"
                                    name="password"
                                    className="vub-c-input-container--auth"
                                    placeholder="Password"
                                    value={password}
                                    isDisabled={isSessionChangeInProgress}
                                    handleChange={handleValues}
                                    validators={[required, isPasswordValid]}
                                    error={errors['password']}
                                />
                                <CustomCheckbox
                                    name="isSignIn"
                                    className="vub-c-custom-checkbox--auth"
                                    label="Keep me signed in"
                                    isChecked={isSignIn}
                                    isDisabled={isSessionChangeInProgress}
                                    handleChange={handleValues}
                                />
                                {serverErrors
                                    && serverErrors.length > 0
                                    && <ServerValidationErrors errors={serverErrors} />
                                }
                                <div className="vub-c-account-sign-in-view__buttons">
                                    <FormButton
                                        type="submit"
                                        isDisabled={
                                            isSessionChangeInProgress
                                            || !email
                                            || !password
                                            || Object.values(errors).some(el => el.isInvalid)
                                        }
                                    >
                                        {isSessionChangeInProgress ? <SpinnerStandard /> : 'Submit'}
                                    </FormButton>
                                </div>
                            </Fragment>
                        )
                    }
                />
            </div>
            <div className="vub-c-account-sign-in-view__link-container">
                <Link className="vub-c-account-sign-in-view__link" to={resolveRoute(routePaths.PASSWORD_RECOVERY)}>
                    Forgot your password?
                </Link>
                <label
                    className="vub-c-account-request-password-reset-view__link"
                    onClick={signUpUser}
                >
                    {`Don't have an account yet? Sign up now!`}
                </label>
            </div>
        </div>
    );
};

AccountSignInView.propTypes = {
    signUpUser: PropTypes.func.isRequired,
    authenticateUser: PropTypes.func.isRequired,
    isSessionChangeInProgress: PropTypes.bool,
    serverErrors: PropTypes.array,
};

AccountSignInView.defaultProps = {
    isSessionChangeInProgress: false,
    serverErrors: [],
};

export {AccountSignInView};

const mapStateToProps = state => ({
    isSessionChangeInProgress: state.auth.isSessionChangeInProgress,
    serverErrors: state.auth.serverErrors,
});

const mapDispatchToProps = dispatch => ({
    signUpUser: payload => dispatch(authActions.signUpUser(payload)),
    authenticateUser: payload => dispatch(authActions.authenticateUser(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountSignInView);
