import * as actionTypes from './authActionTypes';
import UserAccount from './records/UserAccount';

const initialState = {
    isSessionChangeInProgress: false,
    userAccount: null,
    isUserSignedIn: null,
    serverErrors: [],
};

/**
 * Auth reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_SESSION_CHANGE_STATUS:
            state = {
                ...state,
                isSessionChangeInProgress: action.payload.isSessionChangeInProgress,
                serverErrors: [],
            };
            break;

        case actionTypes.STORE_USER_ACCOUNT:
            if (action.payload.userAccountDTO) {
                let userAccount = state.userAccount;
                if (!userAccount) userAccount = new UserAccount({});
                userAccount = userAccount.fromDTO(action.payload.userAccountDTO);

                state = {
                    ...state,
                    userAccount: userAccount,
                    isUserSignedIn: true,
                };
            }
            break;

        case actionTypes.STORE_SERVER_ERRORS:
            if (action.payload.serverErrors) {
                state = {
                    ...state,
                    serverErrors: action.payload.serverErrors,
                };
            }
            break;

        case actionTypes.USER_SESSION_ENDED:
            state = {
                ...state,
                userAccount: null,
                isUserSignedIn: false,
            };
            break;

        default:
        // no-op
    }
    return state;
};

export default authReducer;
