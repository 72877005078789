import React, {Component} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import videojs from 'video.js';
import './VideoJSPlayer.scss';

const MEDIA_PLAYER_CONTROLS_AUTO_HIDE = 2500;

class VideoJSPlayer extends Component {
    static propTypes = {
        playbackSource: PropTypes.object,
    };

    static defaultProps = {
        playbackSource: null,
    };

    constructor(props) {
        super(props);
        this.timeControlsToggle = throttle(this.timeControlsToggle, MEDIA_PLAYER_CONTROLS_AUTO_HIDE - 500);
    }

    state = {
        isFullScreenActive: null,
    };

    componentDidMount() {
        this.player = videojs(this.playerElement.current, {
            controls: false,
            autoplay: true,
            preload: 'auto',
        }, this.onPlayerReady);

        this.controlsToggleTimeoutId = setTimeout(this.hideControls, MEDIA_PLAYER_CONTROLS_AUTO_HIDE);
        this.videoJSPlayerElement.current.addEventListener('mousemove', this.timeControlsToggle);
    }

    componentWillUnmount() {
        if (!this.player) return;

        this.controlsToggleTimeoutId = clearTimeout(this.controlsToggleTimeoutId);
        this.player.dispose();
    }

    // player element ref
    playerElement = React.createRef();
    videoJSPlayerElement = React.createRef();

    // player instance
    player = null;

    // timeOut id
    controlsToggleTimeoutId = 0;

    onPlayerReady = () => {
        const {playbackSource} = this.props;

        this.player.src(playbackSource.src);
    };

    timeControlsToggle = () => {
        if (this.player && this.videoJSPlayerElement.current) {
            clearTimeout(this.controlsToggleTimeoutId);
            this.setState({
                areControlsHidden: false,
            }, () => {
                this.controlsToggleTimeoutId = setTimeout(this.hideControls, MEDIA_PLAYER_CONTROLS_AUTO_HIDE);
            });
        }
    };

    hideControls = () => {
        if (!this.player) return;

        this.setState({
            areControlsHidden: true,
        });
    };

    render() {
        const {children} = this.props;
        const {isFullScreenActive, areControlsHidden} = this.state;

        return (
            <div
                className={`vub-c-video-js-player ${isFullScreenActive
                    ? 'vub-c-video-js-player--full-screen' : ''} ${areControlsHidden
                    ? 'vub-c-video-js-player--are-controls-hidden' : ''}`}
                ref={this.videoJSPlayerElement}
            >
                <video className="vub-c-video-js-player__video" ref={this.playerElement} />
                {children}
            </div>
        );
    }
}

export default VideoJSPlayer;
