import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import routePaths from '../routePaths';
import resolveRoute from '../navigation/resolveRoute';
import * as mediaEntitlementActions from '../media-entitlement/mediaEntitlementActions';
import * as authActions from '../auth/authActions';
import * as selectors from './fastCheckoutViewSelectors';
import './FastCheckoutView.scss';
import withPopUp from '../ui-elements/pop-up/withPopUp';
import {
    withOverlay,
    withOverlayPropTypes,
    withOverlayDefaultProps,
} from '../ui-elements/overlay/withOverlay';
import StyledButton from '../ui-elements/styled-button/StyledButton';
import Payment from './elements/Payment';
import PriceInfo from './elements/PriceInfo';
import MediaItemInfo from './elements/MediaItemInfo';
import LayoutBox from '../ui-elements/layout-box/LayoutBox';
import LayoutBoxRow from '../ui-elements/layout-box/LayoutBoxRow';

@withPopUp
@withOverlay
class FastCheckoutView extends Component {
    static propTypes = {
        ...withOverlayPropTypes,
        isFastCheckoutInProgress: PropTypes.bool,
        basketItem: PropTypes.object,
        isBasketChargingInProgress: PropTypes.bool,
        userAccount: PropTypes.object,
        mediaItem: PropTypes.object,
        cancelMediaItemOfferFastCheckout: PropTypes.func.isRequired,
        chargeBasket: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        setPopUp: PropTypes.func,
        closePopUp: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        ...withOverlayDefaultProps,
        isFastCheckoutInProgress: false,
        basketItem: null,
        isBasketChargingInProgress: false,
        userAccount: null,
        mediaItem: null,
        setPopUp: null,
        closePopUp: null,
        title: 'Purchase confirmation',
    };

    state = {
        paymentMethod: null,
    };

    componentDidUpdate(prevProps) {
        const {isFastCheckoutInProgress, basketItem, isBasketChargingInProgress, closePopUp, setPopUp} = this.props;
        if (isFastCheckoutInProgress
            && (basketItem !== prevProps.basketItem
                || isBasketChargingInProgress !== prevProps.isBasketChargingInProgress)) {
            setPopUp(this.renderFastCheckoutView());
        }

        if (!isFastCheckoutInProgress && prevProps.isFastCheckoutInProgress) {
            closePopUp();
        }
    }

    cancelMediaItemOfferFastCheckout = () => {
        const {cancelMediaItemOfferFastCheckout, closePopUp} = this.props;

        cancelMediaItemOfferFastCheckout();
        closePopUp();
    };

    onPaymentMethodSelect = paymentMethod => {
        this.setState({
            paymentMethod,
        }, () => this.props.setPopUp(this.renderFastCheckoutView()));
    };

    onOverlayClick = () => {
        this.cancelMediaItemOfferFastCheckout();
        this.props.unsubscribeFromOverlayClick(this.onOverlayClick);
    };

    renderFastCheckoutView = () => {
        const {userAccount, updateUser, title} = this.props;
        const {mediaItem} = this.props;
        const {basketItem, chargeBasket, isBasketChargingInProgress} = this.props;
        const {subscribeToOverlayClick} = this.props;
        if (!basketItem) return null;

        const offer = mediaItem.offers.find(offer => offer.id === basketItem.offerId);

        subscribeToOverlayClick(this.onOverlayClick);

        let offerPrice = null;
        if (offer.price) {
            offerPrice = offer.price.toLocaleString('en', {
                style: 'currency',
                currency: offer.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }

        return (
            <LayoutBox>
                <div className="vub-c-fast-checkout-view">
                    <LayoutBoxRow title={title} border={['bottom']} />
                    <LayoutBoxRow border={['bottom']}>
                        <div className="vub-c-fast-checkout-view__poster">
                            <img
                                className="vub-c-fast-checkout-view__image"
                                src={`${mediaItem.imageBoxCover}&q=70&w=200`}
                                alt="tile"
                            />
                        </div>
                        <MediaItemInfo
                            mediaItem={mediaItem}
                            basketItem={basketItem}
                            offer={offer}
                        >
                            <div className="vub-c-media-item-info__owned-notice">
                                Rental includes 30 days to start watching and 48h to finish.
                            </div>
                        </MediaItemInfo>
                    </LayoutBoxRow>
                    <LayoutBoxRow border={['bottom']} direction="column">
                        <Payment
                            userAccount={userAccount}
                            paymentMethod={this.state.paymentMethod}
                            onPaymentMethodSelect={this.onPaymentMethodSelect}
                            updateUser={updateUser}
                        />
                        {offer && (
                            <PriceInfo price={offerPrice} />
                        )}
                        <span>
                            By pressing &quot;Purchase&quot; you agree that you have read and accept&nbsp;
                            <Link
                                className="vub-c-fast-checkout-view__link"
                                to={resolveRoute(routePaths.PAGE, {pageId: 'terms-of-use'})}
                                target="_blank"
                            >
                                Terms & Conditions
                            </Link>
                            &nbsp;and wish to complete your purchase.
                        </span>
                    </LayoutBoxRow>
                    <LayoutBoxRow>
                        <div className="vub-c-fast-checkout-view__buttons">
                            <StyledButton
                                className="vub-c-cta-button--secondary"
                                onClick={this.cancelMediaItemOfferFastCheckout}
                                isDisabled={isBasketChargingInProgress}
                            >
                                Cancel
                            </StyledButton>
                            <StyledButton
                                onClick={chargeBasket}
                                isDisabled={isBasketChargingInProgress}
                            >
                                <span className="vub-u-font-weight-bold">Confirm &amp; Rent</span>
                            </StyledButton>
                        </div>
                    </LayoutBoxRow>
                </div>
            </LayoutBox>
        );
    };

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    isFastCheckoutInProgress: state.mediaEntitlement.isFastCheckoutInProgress,
    basketItem: selectors.getBasketItem(state),
    isBasketChargingInProgress: state.mediaEntitlement.isBasketChargingInProgress,
    userAccount: state.auth.userAccount,
    mediaItem: selectors.getMediaItem(state),
});

const mapDispatchToProps = dispatch => ({
    cancelMediaItemOfferFastCheckout: payload => dispatch(
        mediaEntitlementActions.cancelMediaItemOfferFastCheckout(payload),
    ),
    chargeBasket: payload => dispatch(
        mediaEntitlementActions.chargeBasket(payload),
    ),
    updateUser: payload => dispatch(authActions.updateUser(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FastCheckoutView);
