import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../withStyles';
import styles from './Carousel.module.scss';

const CarouselPagination = ({currentPage, carouselPages, goToPage, cx}) => (
    <div className={cx('cr-c-carousel__pagination')}>
        {carouselPages.map(page => {
            const goToSelectedPage = () => goToPage(page);
            return (
                <button
                    key={page.id}
                    type="button"
                    className={cx('cr-c-carousel__button-page', {
                        'global!cr-is-disabled': currentPage === page.id,
                    })}
                    disabled={currentPage === page.id}
                    onClick={goToSelectedPage}
                >
                    {page.id}
                </button>
            );
        })}
    </div>
);

CarouselPagination.propTypes = {
    ...withStylesPropTypes,
    currentPage: PropTypes.number,
    carouselPages: PropTypes.array,
    goToPage: PropTypes.func,
};

CarouselPagination.defaultProps = {
    ...withStylesDefaultProps,
    currentPage: 0,
    carouselPages: [],
    goToPage: null,
};

export default withStyles(styles)(CarouselPagination);
