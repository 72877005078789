import React, {PureComponent} from 'react';
import Icon from '../../ui-kit/Icon';
import './NavigationIcon.scss';

export default class NavigationIcon extends PureComponent {
    static propTypes = {
        ...Icon.propTypes,
    };

    static defaultProps = {
        ...Icon.defaultProps,
    };

    render() {
        return (
            <Icon {...this.props} blockName="vub-c-navigation-icon" />
        );
    }
}
