import Immutable from 'immutable';
import * as actionTypes from './storeConfigurationActionTypes';
import Provider from './records/Provider';

const initialState = {
    providers: Immutable.Map(),
};

/**
 * Store configuration reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const storeConfigurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_PROVIDERS:
            if (action.payload.providerDTOs) {
                const providerDTOs = action.payload.providerDTOs;
                let providers = state.providers || Immutable.Map();

                providerDTOs.forEach(providerDTO => {
                    const provider = new Provider({}).fromDTO(providerDTO);
                    if (provider.id) {
                        providers = providers.set(provider.id, provider);
                    }
                });

                state = {
                    ...state,
                    providers,
                };
            }
            break;

        default:
        // no-op
    }
    return state;
};

export default storeConfigurationReducer;
