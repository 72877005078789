import React, {PureComponent} from 'react';
import './ShowMoreButton.scss';
import Button from '../../ui-kit/Button';

class ShowMoreButton extends PureComponent {
    static propTypes = {
        ...Button.propTypes,
    };

    static defaultProps = {
        ...Button.defaultProps,
    };

    render() {
        return (
            <Button {...this.props} blockName="vub-c-navigation-show-more-button" />
        );
    }
}

export default ShowMoreButton;
