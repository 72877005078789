import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../../media-entitlement/withMediaActions';
import CTAButton from '../../ui-elements/cta-button/CTAButton';
import CTADropDown from '../../ui-elements/cta-drop-down/CTADropDown';
import CTADropDownOption from '../../ui-elements/cta-drop-down/CTADropDownOption';

@withMediaActions
class PurchaseCTAButtonsSVOD extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {mediaItem, providers} = this.props;
        const {isFastCheckoutInProgress, checkoutSubscription} = this.props;
        const offers = mediaItem.offers.filter(offer => offer.offerType === mediaItemOfferType.SVOD);

        if (offers.length === 1) {
            const offer = offers[0];
            const fastCheckoutSubscription = () => checkoutSubscription(offer);
            const provider = providers.get(offer.provider);

            return (
                <CTAButton
                    key={offer.id}
                    onClick={fastCheckoutSubscription}
                    isDisabled={isFastCheckoutInProgress}
                >
                    <span>
                        Subscribe
                    </span>
                    {provider && (
                        <img
                            className="vub-c-cta-button__icon vub-u-margin-left-small"
                            src={provider.serviceCTALogo}
                            alt="cta-icon"
                        />
                    )}
                </CTAButton>
            );
        }

        if (offers.length > 1) {
            const fastCheckoutSubscription = () => checkoutSubscription(offer);
            const offer = offers[0];

            return (
                <CTADropDown
                    label={(
                        <span className="vub-u-font-weight-bold">Subscribe</span>
                    )}
                    isDisabled={false}
                    onChange={fastCheckoutSubscription}
                >
                    {offers.map(offer => {
                        const provider = providers.get(offer.provider);

                        return (
                            <CTADropDownOption
                                key={offer.id}
                                value={offer}
                            >
                                <span>Subscribe</span>
                                {provider && (
                                    <img
                                        className="vub-c-cta-drop-down-option__icon"
                                        src={provider.serviceCTALogo}
                                        alt="drop-down-option-icon"
                                    />
                                )}
                            </CTADropDownOption>
                        );
                    })}
                </CTADropDown>
            );
        }

        // return null if no render condition is matched
        return null;
    }
}

export default PurchaseCTAButtonsSVOD;
