import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './CustomNestedDropDownTouchDevice.scss';

class CustomNestedDropDownTouchDevice extends Component {
    static propTypes = {
        children: PropTypes.node,
        openOverlay: PropTypes.func,
        closeOverlay: PropTypes.func,
        closeDropDown: PropTypes.func,
        closeCurrentDropDown: PropTypes.func,
        label: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        openOverlay: null,
        closeOverlay: null,
        closeDropDown: null,
        closeCurrentDropDown: null,
        label: null,
    };

    componentDidMount() {
        this.props.openOverlay();
    }

    handleClick = () => {
        const {closeDropDown, closeOverlay} = this.props;
        closeDropDown();
        closeOverlay();
    }

    render() {
        const {children, label, closeCurrentDropDown} = this.props;
        return (
            <div className="vub-c-custom-nested-drop-down-touch-device">
                <div className="vub-c-custom-nested-drop-down-touch-device__header" onClick={this.handleClick} />
                <div className="vub-c-custom-nested-drop-down-touch-device__content">
                    <ul className="vub-c-custom-nested-drop-down-touch-device__list">
                        {label ? (
                            <li className="vub-c-custom-select-item vub-c-custom-select-item--is-disabled">{label}</li>
                        ) : (
                            <li className="vub-c-custom-select-item" onClick={closeCurrentDropDown}>Back</li>
                        )}
                        {children}
                    </ul>
                </div>
            </div>
        );
    }
}

export default CustomNestedDropDownTouchDevice;
