import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import './Input.scss';
import InputValidationError from '../form-validation/InputValidationError';

const Input = ({
    type,
    name,
    value,
    className,
    placeholder,
    isDisabled,
    handleChange,
    handlePaste,
    maxLength,
    minLength,
    validators,
    error,
}) => {
    const handleInputChange = event => {
        const {value} = event.target;
        handleChange(name, value, validators);
    };

    const handleBlur = () => {
        handleChange(name, value, validators, true);
    };

    const handleInputPaste = event => {
        if (typeof handlePaste === 'function') {
            handlePaste(event);
        }
    };

    const {isInvalid, isTouched} = error;

    return (
        <div className={`vub-c-input-container ${className}`}>
            <input
                type={type}
                className="vub-c-input-container__field"
                name={name}
                value={value}
                placeholder={placeholder}
                disabled={isDisabled}
                onChange={handleInputChange}
                onBlur={validators && handleBlur}
                onPaste={handleInputPaste}
                maxLength={maxLength}
                minLength={minLength}
            />
            {isDisabled && <div className="vub-c-input-container__icon vub-c-input-container__icon--locked" />}
            {isInvalid && isTouched && (
                <Fragment>
                    <div className="vub-c-input-container__icon vub-c-input-container__icon--warning" />
                    <InputValidationError error={error} />
                </Fragment>
            )}
        </div>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    handleChange: PropTypes.func,
    handlePaste: PropTypes.func,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    validators: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    error: PropTypes.object,
};

Input.defaultProps = {
    type: 'text',
    name: null,
    value: null,
    className: '',
    placeholder: null,
    isDisabled: false,
    handleChange: null,
    handlePaste: null,
    maxLength: null,
    minLength: null,
    validators: null,
    error: {},
};

export default Input;
