import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetailsBoxCover.scss';

const MediaItemDetailsBoxCover = ({mediaItem, children}) => (
    <div className="vub-c-media-item-details-box-cover">
        <div className="vub-c-media-item-details-box-cover__image-placeholder">
            <img
                className="vub-c-media-item-details-box-cover__image"
                src={`${mediaItem.imageBoxCover}&q=70&w=200`}
                alt="tile"
            />
            {children}
        </div>
    </div>
);

MediaItemDetailsBoxCover.propTypes = {
    mediaItem: PropTypes.object.isRequired,
};

export default MediaItemDetailsBoxCover;
