import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './NavigationTop.scss';
import * as menuTypes from '../../navigation/menuTypes';
import menuItemTypes from '../../navigation/menuItemTypes';

const NavigationTop = ({headerMenu}) => (headerMenu ? (
    <div className="vub-c-navigation-top">
        <ul className="vub-c-navigation-top__menu">
            {headerMenu.map(menuItem => (
                <li key={`header-menu-item-${menuItem.id}`} className="vub-c-navigation-top__menu-item">
                    {(menuItem.type === menuItemTypes.CONTAINER && menuItem.children.length !== 0)
                    && (
                        (menuItem.display) && (
                            <Fragment>
                                <label className="vub-c-navigation-top__menu-label">
                                    {menuItem.title}
                                </label>
                                <div className="vub-c-navigation-top__drop-down">
                                    {(menuItem.children.map((group, index) => (
                                        (group.display) && (
                                            <ul
                                                key={`header-dropdown-menu-${index}`}
                                                className={
                                                    `vub-c-navigation-top__drop-down-menu
                                                        ${index > 0
                                                        ? `${group.children.length > 12
                                                            ? 'vub-c-navigation-top__drop-down-menu--wrapped'
                                                            : ''} vub-u-border-left-small`
                                                        : 'vub-u-transform-text-uppercase'
                                                        }`
                                                }
                                            >
                                                {(group.children.map(childMenuItem => (
                                                    (childMenuItem.display) && (
                                                        <li
                                                            key={`header-dropdown-item-${childMenuItem.id}`}
                                                            className="vub-c-navigation-top__drop-down-item"
                                                        >
                                                            {(childMenuItem.url) ? (
                                                                <Link
                                                                    className="vub-c-navigation-top__drop-down-link"
                                                                    to={childMenuItem.url}
                                                                >
                                                                    {childMenuItem.title}
                                                                </Link>
                                                            ) : (
                                                                <span className="vub-c-navigation-top__drop-down-link">
                                                                    {childMenuItem.title}
                                                                </span>
                                                            )}
                                                        </li>
                                                    )
                                                )))}
                                            </ul>
                                        )
                                    )))}
                                </div>
                            </Fragment>
                        )
                    )}
                    {(menuItem.type === menuItemTypes.GENRE
                        || menuItem.type === menuItemTypes.CATEGORY
                        || menuItem.type === menuItemTypes.PAGE
                        || menuItem.type === menuItemTypes.ROUTE)
                    && (
                        <Link
                            className="vub-c-navigation-top__menu-link"
                            to={menuItem.url}
                        >
                            {menuItem.title}
                        </Link>
                    )}
                </li>
            ))}
        </ul>
    </div>
) : null);

NavigationTop.propTypes = {
    headerMenu: PropTypes.array,
};

NavigationTop.defaultProps = {
    headerMenu: null,
};

export {NavigationTop};

// connect component to redux store and actions
const mapStateToProps = state => ({
    headerMenu: state.navigation.menus[menuTypes.HEADER_MENU],
});

export default connect(
    mapStateToProps,
)(NavigationTop);
