import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
// import memoize from 'lodash.memoize';
import BaseElement from './BaseElement';

const BEMClassNameResolver = defaultBlockName => BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'BEMClassNameResolver'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
            className: PropTypes.string,
            blockName: PropTypes.string,
            resolveClassName: PropTypes.func,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            className: '',
            blockName: null,
            resolveClassName: null,
        };

        resolveClassName = (first = [], ...restArgs) => {
            const params = (Array.isArray(first) ? first.concat(...restArgs) : [first, ...restArgs])
                .filter(param => typeof param === 'string');

            // determine main block/element class
            const blockName = this.props.blockName || defaultBlockName;
            const elementName = params.find(param => param.match(/^(__).*/) !== null);
            const mainClassName = elementName ? `${blockName}${elementName}` : blockName;

            // parse all modifiers
            const modifiers = params.filter(param => param.match(/^(--).*/) !== null);

            // parse other classes
            const otherClassNames = params.filter(param => !modifiers.includes(param) && param !== elementName);

            const classNames = [
                mainClassName,
                // map modifiers on to block/element class
                ...modifiers.map(modifier => `${mainClassName}${modifier}`),
                // add other classes
                ...otherClassNames,
                // add classes from className prop if main class is block
                ...(this.props.className && !elementName ? this.props.className.split(' ') : []),
            ];

            // return de-duped classNames as one string
            return [...new Set(classNames)].filter(className => !!className).join(' ');
        };

        render() {
            const props = {
                ...this.props,
                blockName: this.props.blockName,
                resolveClassName: this.resolveClassName,
                // resolveClassName: memoize(this.resolveClassName), // TODO test memoization
            };

            return (
                <BaseComponent {...props} />
            );
        }
    }

    return ComposedComponent;
};

const {BEMClassNameResolverPropTypes, BEMClassNameResolverDefaultProps} = BEMClassNameResolver(BaseElement);

export {
    BEMClassNameResolver as default,
    BEMClassNameResolver,
    BEMClassNameResolverPropTypes,
    BEMClassNameResolverDefaultProps,
};
