import React from 'react';
import PropTypes from 'prop-types';
import './LayoutBoxRow.scss';

const LayoutBoxRow = ({title, children, border, text, direction}) => {
    const borderStyle = border.map(el => `vub-c-layout-box-row--border-${el}`);
    return (
        <div className={`vub-c-layout-box-row ${borderStyle} ${direction ? `vub-c-layout-box-row--${direction}` : ''}`}>
            {title && <div className="vub-c-layout-box-row__title">{title}</div>}
            {children}
            {text}
        </div>
    );
};

LayoutBoxRow.propTypes = {
    title: PropTypes.string,
    border: PropTypes.array,
    text: PropTypes.string,
    direction: PropTypes.string,
};

LayoutBoxRow.defaultProps = {
    title: null,
    border: [],
    text: null,
    direction: null,
};

export default LayoutBoxRow;
