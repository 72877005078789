import React from 'react';
import './GeoFenceInfoPage.scss';

const GeoFenceInfoPage = () => (
    <div className="vub-c-geo-fence-info">
        <div className="vub-c-geo-fence-info__icon" />
        <div className="vub-c-geo-fence-info__message">
            <h1 className="vub-c-geo-fence-info__title">We&#39;re Sorry</h1>
            <div className="vub-c-geo-fence-info__subtitle">
                This service is not available in your country.
            </div>
            <div className="vub-c-geo-fence-info__text">
                If you&#39;re in the UK and still have problems,
                please contact support@playvu.com
            </div>
        </div>
    </div>
);

export default GeoFenceInfoPage;
