import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import routePaths from '../routePaths';
import resolveRoute from '../navigation/resolveRoute';
import * as authActions from '../auth/authActions';
import './AccountSettingsView.scss';
import CustomCheckbox from '../ui-elements/custom-checkbox/CustomCheckbox';
import Input from '../ui-elements/input/Input';
import FormContainer from '../ui-elements/form/FormContainer';
import {
    required,
    isPasswordValid,
    maxLength,
    isMatchWith,
} from '../ui-elements/form-validation/validations';
import FormButton from '../ui-elements/form/FormButton';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';

const AccountSettingsView = ({userAccount, updateUserAccount, isSessionChangeInProgress}) => {
    const getFormValues = formValues => {
        // filter for values - exclude empty fields, but includes bool fields with false value
        const filteredFormValues = Object.keys(formValues).reduce((filteredFormValues, key) => {
            if (formValues[key] !== '') filteredFormValues[key] = formValues[key];

            return filteredFormValues;
        }, {});
        updateUserAccount(filteredFormValues);
    };

    return userAccount ? (
        <div className="vub-c-account-settings-view">
            <div className="vub-c-account-settings-view__title">
                settings
            </div>
            <div className="vub-c-account-settings-view__title vub-c-account-settings-view__title--second vub-u-transform-text-uppercase">
                account
            </div>
            <div className="vub-c-account-settings-view__form">
                <FormContainer
                    initialState={{
                        name: userAccount.name,
                        email: userAccount.email,
                        oldPassword: '',
                        newPassword: '',
                        confirmedPassword: '',
                        hasOptedInToReceiveUpdates: userAccount.hasOptedInToReceiveUpdates,
                        errors: {},
                    }}
                    getFormValues={getFormValues}
                    render={(formValues, handleValues) => {
                        const {
                            name,
                            email,
                            oldPassword,
                            newPassword,
                            confirmedPassword,
                            hasOptedInToReceiveUpdates,
                            errors,
                        } = formValues;

                        return (
                            <Fragment>
                                <Input
                                    name="name"
                                    className="vub-c-input-container--auth"
                                    placeholder="Display name"
                                    value={name}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    maxLength={40}
                                    validators={[
                                        required,
                                        maxLength(40),
                                    ]}
                                    error={errors['name']}
                                />
                                <Input
                                    type="email"
                                    name="email"
                                    className="vub-c-input-container--auth"
                                    placeholder="Email"
                                    isDisabled={true}
                                    value={email}
                                    handleChange={handleValues}
                                />
                                <Input
                                    type="password"
                                    name="oldPassword"
                                    className="vub-c-input-container--auth"
                                    placeholder="Old Password"
                                    value={oldPassword}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={isPasswordValid}
                                    error={errors['oldPassword']}
                                />
                                <Input
                                    type="password"
                                    name="newPassword"
                                    className="vub-c-input-container--auth"
                                    placeholder="New Password"
                                    value={newPassword}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={isPasswordValid}
                                    error={errors['newPassword']}
                                />
                                <Input
                                    type="password"
                                    name="confirmedPassword"
                                    className="vub-c-input-container--auth"
                                    placeholder="Confirm New Password"
                                    value={confirmedPassword}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={[
                                        isPasswordValid,
                                        isMatchWith('newPassword'),
                                    ]}
                                    error={errors['confirmedPassword']}
                                />
                                <div className="vub-c-account-settings-view__description">
                                    <p className="vub-c-account-settings-view__paragraph">
                                        Your contact preferences were last updated on {userAccount.preferenceUpdateDate && userAccount.preferenceUpdateDate.format('DD.MM.YYYY')}.
                                    </p>
                                    <p className="vub-c-account-settings-view__paragraph">
                                        If the box is ticked below, it means you agree to share your contact details so
                                        that PlayVU Store can send you updates about brilliant offers,
                                        rewards and product news.
                                    </p>
                                </div>
                                <CustomCheckbox
                                    name="hasOptedInToReceiveUpdates"
                                    label="Receive updates by email"
                                    isChecked={hasOptedInToReceiveUpdates}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                />
                                <div className="vub-c-account-settings-view__description">
                                    <p className="vub-c-account-settings-view__paragraph">
                                        Obviously we want to stay in your good books,
                                        so we keep your personal details private and treat them
                                        with the utmost care (find out more&nbsp;
                                        <Link
                                            className="vub-c-account-settings-view__link"
                                            to={resolveRoute(routePaths.PAGE, {pageId: 'privacy'})}
                                            target="_blank"
                                        >
                                            here
                                        </Link>),
                                        and fully comply with your data subject right under the&nbsp;
                                        <Link
                                            className="vub-c-account-settings-view__link"
                                            to={resolveRoute(routePaths.PAGE, {pageId: 'gdpr-info'})}
                                            target="_blank"
                                        >
                                            General Data Protection Regulations
                                        </Link>)
                                    </p>
                                </div>
                                <div className="vub-c-account-settings-view__buttons">
                                    <FormButton
                                        type="submit"
                                        isDisabled={
                                            isSessionChangeInProgress
                                            || !name
                                            || !!(
                                                (!oldPassword || !newPassword || !confirmedPassword)
                                                && (oldPassword || newPassword || confirmedPassword)
                                            )
                                            || Object.values(errors).some(el => el.isInvalid)
                                        }
                                    >
                                        {isSessionChangeInProgress ? <SpinnerStandard /> : 'Update account details'}
                                    </FormButton>
                                </div>
                            </Fragment>
                        );
                    }}
                />
            </div>
        </div>
    ) : null;
};

AccountSettingsView.propTypes = {
    userAccount: PropTypes.object,
    updateUserAccount: PropTypes.func.isRequired,
    isSessionChangeInProgress: PropTypes.bool,
};

AccountSettingsView.defaultProps = {
    userAccount: null,
    isSessionChangeInProgress: false,
};

export {AccountSettingsView};

const mapStateToProps = state => ({
    userAccount: state.auth.userAccount,
    isSessionChangeInProgress: state.auth.isSessionChangeInProgress,
});

const mapDispatchToProps = dispatch => ({
    updateUserAccount: payload => dispatch(authActions.updateUserAccount(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountSettingsView);
