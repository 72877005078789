import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './Overlay.scss';

class Overlay extends PureComponent {
    static propTypes= {
        children: PropTypes.node,
        className: PropTypes.string,
        onOverlayClick: PropTypes.func,
    };

    static defaultProps = {
        children: null,
        className: null,
        onOverlayClick: null,
    };

    componentDidMount() {
        window.document.body.classList.add('scroll-disabled');
    }

    componentWillUnmount() {
        window.document.body.classList.remove('scroll-disabled');
    }

    render() {
        const {children, className, onOverlayClick} = this.props;
        return (
            <div
                className={`vub-c-overlay ${className ? `vub-c-overlay--${className}` : ''}`}
                onClick={onOverlayClick}
            >
                {children}
            </div>
        );
    }
}

export default Overlay;

