import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import './ProgressBar.scss';

const ProgressBar = ({setCurrentTime, currentTime, duration, marks}) => (
    <div className="vub-c-progress-bar">
        <Slider
            max={duration}
            value={currentTime}
            onChange={setCurrentTime}
            marks={marks.reduce((markNodes, mark) => {
                markNodes[mark] = '|';
                return markNodes;
            }, {})}
        />
    </div>
);

ProgressBar.propTypes = {
    setCurrentTime: PropTypes.func,
    currentTime: PropTypes.number,
    duration: PropTypes.number,
    marks: PropTypes.array,
};

ProgressBar.defaultProps = {
    setCurrentTime: null,
    currentTime: 0,
    duration: 0,
    marks: [],
};

export default ProgressBar;

