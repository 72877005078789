import {all, takeEvery, fork} from 'redux-saga/effects';
import * as actionTypes from './authActionTypes';
import signInUserFlow from './sagas/signInUserFlow';
import signUpUserFlow from './sagas/signUpUserFlow';
import updateUserFlow from './sagas/updateUserFlow';
import userAuthenticationFlow from './sagas/userAuthenticationFlow';
import userRegistrationFlow from './sagas/userRegistrationFlow';
import setUserContactPreferencesFlow from './sagas/setUserContactPreferencesFlow';
import updateUserAccountFlow from './sagas/updateUserAccountFlow';
import requestPasswordReset from './sagas/requestPasswordResetFlow';
import resetPassword from './sagas/resetPasswordFlow';

/**
 * Storefront watcher saga
 */
export const authWatcher = function* authWatcher() {
    yield all([
        fork(signInUserFlow),
        fork(signUpUserFlow),
        fork(updateUserFlow),
        fork(userAuthenticationFlow),
        fork(userRegistrationFlow),
        fork(setUserContactPreferencesFlow),
        fork(updateUserAccountFlow),
        takeEvery(actionTypes.REQUEST_PASSWORD_RESET, requestPasswordReset),
        takeEvery(actionTypes.RESET_PASSWORD, resetPassword),
    ]);
};
