import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MediaItemHeroImageForTransparentHeader.scss';
import {
    withLayoutControls,
    withLayoutControlsPropTypes,
    withLayoutControlsDefaultProps,
} from '../../layout/withLayoutControls';

@withLayoutControls
class MediaItemHeroImageForTransparentHeader extends Component {
    static propTypes = {
        ...withLayoutControlsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withLayoutControlsDefaultProps,
        mediaItem: null,
    };

    componentDidMount() {
        this.props.setHeaderTransparency(true);
    }

    componentWillUnmount() {
        this.props.setHeaderTransparency(false);
    }

    render() {
        const {mediaItem, children} = this.props;

        return (
            <div className="vub-c-media-item-hero-image-for-transparent-header">
                <div
                    className="vub-c-media-item-hero-image-for-transparent-header__background-image"
                    style={{backgroundImage: `url('${mediaItem.imageThumbnail}&q=70')`}}
                />
                {children}
            </div>
        );
    }
}

export default MediaItemHeroImageForTransparentHeader;
