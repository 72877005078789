import React from 'react';
import PropTypes from 'prop-types';
import './DownloadMobileAppPopUp.scss';
import LinkButton from './LinkButton';
import withPopUp from '../withPopUp';
import LayoutBox from '../../layout-box/LayoutBox';
import LayoutBoxRow from '../../layout-box/LayoutBoxRow';

// TODO - Move this to translation folder
const title = 'play media item';
const description1 = 'In order to play this media item you need to download our mobile app on your device.';
const description2 = 'Please select below:';

const MobilePopUpPlayContent = ({closePopUp, appURI, content}) => (
    <LayoutBox>
        <div className="vub-c-download-mobile-app-pop-up">
            <LayoutBoxRow title={title} border={['bottom']}>
                <div className="vub-c-download-mobile-app-pop-up__close" onClick={closePopUp} />
            </LayoutBoxRow>
            <LayoutBoxRow>
                <div className="vub-c-download-mobile-app-pop-up__description">
                    <div>
                        {description1}
                    </div>
                    <div>
                        {description2}
                    </div>
                </div>
            </LayoutBoxRow>
            <LayoutBoxRow>
                <LinkButton
                    target="_blank"
                    uri={appURI}
                >
                    {content}
                </LinkButton>
            </LayoutBoxRow>
        </div>
    </LayoutBox>
);

MobilePopUpPlayContent.propTypes = {
    closePopUp: PropTypes.func,
    appURI: PropTypes.string,
    content: PropTypes.string,
};

MobilePopUpPlayContent.defaultProps = {
    closePopUp: null,
    appURI: null,
    content: null,
};

export default withPopUp(MobilePopUpPlayContent);
