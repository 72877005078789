import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../ui-kit/withStyles';
import styles from './MediaCarouselSlideCTADetailed.module.scss';
import {CarouselSlide} from '../../ui-kit';
import CTAIconButton from '../cta-button/CTAIconButton';
import {
    iconPlaySolid,
    iconMore,
} from '../icons';

@withStyles(styles)
class MediaCarouselSlideCTADetailed extends Component {
    static propTypes = {
        ...withStylesPropTypes,
        mediaItem: PropTypes.object,
        linkTo: PropTypes.object,
        history: PropTypes.object.isRequired,
        onPlayIconClick: PropTypes.func,
        isUserSignedIn: PropTypes.bool,
    };

    static defaultProps = {
        ...withStylesDefaultProps,
        mediaItem: null,
        linkTo: {pathname: ''},
        onPlayIconClick: null,
        isUserSignedIn: null,
    };

    handleMoreClick = linkTo => {
        this.props.history.push(linkTo);
    };

    render() {
        const {className, cx, createClassNameResolver, ...props} = this.props;
        const {mediaItem, linkTo, onPlayIconClick, isUserSignedIn, children, ...restProps} = props;
        const imageUrl = mediaItem.imageThumbnail ? `${mediaItem.imageThumbnail}&q=70&w=400` : null;

        let mediaItemTitle = mediaItem ? mediaItem.title : null;
        if (mediaItem && mediaItem.classification === mediaItemClassification.EPISODE) {
            mediaItemTitle = `${mediaItem.siblingIndex}. ${mediaItem.title}`;
        }

        return (
            <CarouselSlide
                {...restProps}
                className={cx(className)}
                classNameResolver={createClassNameResolver('vub-c-media-carousel-slide-cta-detailed')}
            >
                {mediaItem ? (
                    <div className={cx('vub-c-media-carousel-slide-cta-detailed__content')}>
                        <div className={cx('vub-c-media-carousel-slide-cta-detailed__image-placeholder')}>
                            <Link
                                className={cx('vub-c-media-gallery-tile-portrait__link')}
                                to={linkTo}
                            >
                                <img
                                    className={cx('vub-c-media-carousel-slide-cta-detailed__image')}
                                    src={imageUrl}
                                    alt="box cover"
                                />
                                {children}
                            </Link>
                        </div>
                        <div className={cx('vub-c-media-carousel-slide-cta-detailed__description')}>
                            <h3 className={cx('vub-c-media-carousel-slide-cta-detailed__description-title')}>
                                {mediaItemTitle}
                            </h3>
                            <h3 className={cx('vub-c-media-carousel-slide-cta-detailed__description-duration')}>
                                {mediaItem.durationMinutes}
                            </h3>
                            <p className={cx('vub-c-media-carousel-slide-cta-detailed__description-text')}>
                                {mediaItem.synopsis}
                            </p>
                        </div>
                        <div className={cx('vub-c-media-carousel-slide-cta-detailed__buttons')}>
                            {isUserSignedIn && (
                                <CTAIconButton
                                    className="vub-c-cta-icon-button--primary"
                                    icon={iconPlaySolid}
                                    label="play"
                                    type="large"
                                    onClick={onPlayIconClick}
                                />
                            )}
                            <CTAIconButton
                                className="vub-c-cta-icon-button--secondary"
                                icon={iconMore}
                                label="More"
                                type="large"
                                onClick={() => this.handleMoreClick(linkTo)}
                            />
                        </div>
                    </div>
                ) : null}
            </CarouselSlide>
        );
    }
}

export default MediaCarouselSlideCTADetailed;
