import React from 'react';
import './ContentRowInner.scss';

const ContentRowInner = ({children}) => {
    return (
        <div className="vub-c-content-row-inner">
            {children}
        </div>
    );
};

export default ContentRowInner;
