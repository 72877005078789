import React, {useContext} from 'react';
import moment from 'moment';
import './EPGGrid.scss';
import EPGContext from './EPGContext';

const EPGGrid = () => {
    const epgContext = useContext(EPGContext);
    const {epgChannels, startLiveStreamPlayback} = epgContext;
    const {epgChannelShows, epgShowPositions, selectedTime, currentTime} = epgContext;
    const {slideTrayWidth, slideTrayOffset} = epgContext;

    const slideTrayStyle = {
        width: `${slideTrayWidth}%`,
        transform: `translateX(${slideTrayOffset}%)`,
    };

    return (
        <div className="vub-c-epg-grid">
            <div className="vub-c-epg-grid__channel-logos">
                {epgChannels.toArray().map(epgChannel => {
                    const startChannelLiveStreamPlayback = () => startLiveStreamPlayback({epgChannel});

                    return (
                        <div className="vub-c-epg-grid__image-container" key={epgChannel.id}>
                            <img
                                src={epgChannel.logo}
                                alt={epgChannel.name}
                                className="vub-c-epg-grid__channel-logo"
                                onClick={epgChannel.name === '502 Bloomberg' ? startChannelLiveStreamPlayback : null}
                            />
                            {epgChannel.name === '502 Bloomberg'
                            && (
                                <div className="vub-c-epg-grid__channel-live">
                                    <p className="vub-c-epg-grid__channel-live-heading">Live</p>
                                    <span className="vub-c-epg-grid__channel-live-rec" />
                                </div>
                            )
                            }
                        </div>
                    );
                })}
            </div>
            <div className="vub-c-epg-grid__viewing-frame">
                <div className="vub-c-epg-grid__slide-tray" style={slideTrayStyle}>
                    {epgChannels.toArray().map(epgChannel => {
                        const startChannelLiveStreamPlayback = () => startLiveStreamPlayback({epgChannel});

                        return (
                            <div className="vub-c-epg-grid__channel-shows" key={epgChannel.id}>
                                {epgChannelShows[epgChannel.id] && epgChannelShows[epgChannel.id].map(epgShow => {
                                    const {showWidth, showOffset} = epgShowPositions[epgShow.id];
                                    const showStyle = {
                                        width: `${showWidth}%`,
                                        ...(showOffset && {marginLeft: `${showOffset}%`}),
                                    };

                                    const showOffsetPeriod = moment.duration(selectedTime.diff(epgShow.startDate));
                                    const showIsActive = epgShow.startDate < currentTime
                                        && epgShow.endDate > currentTime;
                                    const showIsLive = epgChannel.name === '502 Bloomberg';
                                    let showContentStyle = null;
                                    if (showOffsetPeriod.asMinutes() > 0) {
                                        const showContentOffset = showOffsetPeriod.asMinutes() * 100
                                            / moment.duration(epgShow.endDate.diff(epgShow.startDate)).asMinutes();
                                        showContentStyle = {
                                            paddingLeft: `calc(${showContentOffset}% + 10px)`,
                                        };
                                    }

                                    return (
                                        <div
                                            className={`vub-c-epg-grid__show ${showIsActive
                                                ? 'vub-c-epg-grid__show--is-active' : ''} ${showIsLive
                                                ? 'vub-c-epg-grid__show--is-live' : ''}`}
                                            style={showStyle}
                                            key={epgShow.id}
                                        >
                                            <div
                                                className="vub-c-epg-grid__show-content"
                                                style={showContentStyle}
                                            >
                                                <div
                                                    className="vub-c-epg-grid__show-airtime"
                                                    title={`${epgShow.startDate.format('dddd, Do MMM - HH:mm')}`}
                                                >
                                                    {`${epgShow.startDate.format('HH:mm')} - ${epgShow.endDate.format('HH:mm')}`}
                                                </div>
                                                <div
                                                    className="vub-c-epg-grid__show-title"
                                                >
                                                    {epgShow.title}
                                                    <p className="vub-c-epg-grid__show-description">
                                                        {epgShow.description}
                                                    </p>
                                                </div>
                                                {showIsActive && showIsLive
                                                    && (
                                                        <div className="vub-c-epg-grid__content-live">
                                                            <span className="vub-c-epg-grid__watch-now">Watch Now!</span>
                                                            <button
                                                                type="button"
                                                                className="vub-c-epg-grid__live-button"
                                                                onClick={startChannelLiveStreamPlayback}
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EPGGrid;
