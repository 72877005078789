import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../../media-entitlement/withMediaActions';
import CTAButton from '../../ui-elements/cta-button/CTAButton';

@withMediaActions
class PurchaseCTAButtonsTVODPopover extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object.isRequired,
        showAllPurchaseOptions: PropTypes.func.isRequired,
        hidePopover: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
    };

    render() {
        const {mediaItem, hidePopover, showAllPurchaseOptions} = this.props;
        const {isFastCheckoutInProgress, fastCheckoutMediaItemOffer} = this.props;
        const offers = mediaItem.offers.filter(offer => offer.offerType === mediaItemOfferType.TVOD);

        if (offers.length === 1) {
            const offer = offers[0];
            const fastCheckoutOffer = () => {
                hidePopover();
                fastCheckoutMediaItemOffer(offer);
            };
            const price = offer.price.toLocaleString('en', {
                style: 'currency',
                currency: offer.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            return (
                <CTAButton
                    onClick={fastCheckoutOffer}
                    isDisabled={isFastCheckoutInProgress}
                >
                    <span>
                        Rent {offer.resolution}
                        &nbsp;<span className="vub-c-cta-button__price">{price}</span>
                    </span>
                </CTAButton>
            );
        }

        if (offers.length > 1) {
            const offer = offers[0];
            const price = offer.price.toLocaleString('en', {
                style: 'currency',
                currency: offer.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            return (
                <CTAButton
                    onClick={showAllPurchaseOptions}
                    isDisabled={isFastCheckoutInProgress}
                >
                    <span>
                        Rent from <span className="vub-c-cta-button__price">{price}</span>
                    </span>
                </CTAButton>
            );
        }

        // return null if no render condition is matched
        return null;
    }
}

export default PurchaseCTAButtonsTVODPopover;
