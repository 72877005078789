import React, {Fragment, Children} from 'react';
import PropTypes from 'prop-types';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../withStyles';
import styles from './Carousel.module.scss';

const CarouselControls = props => {
    const {children, cx} = props;
    const {currentPage, carouselPages, rotateCarouselLeft, rotateCarouselRight, goToPage} = props;

    const parsedChildren = Children.map(children, child => (typeof child === 'string'
        ? child // TODO check if safety needed here
        : React.cloneElement(child, {
            currentPage: currentPage,
            carouselPages: carouselPages,
            goToPage: goToPage,
        })));

    return (
        <div className={cx('cr-c-carousel__controls')}>
            {carouselPages.length > 0 && (
                <Fragment>
                    <button
                        type="button"
                        className={cx('cr-c-carousel__button-left', {
                            'global!cr-is-disabled': currentPage === 0,
                        })}
                        disabled={currentPage === 0}
                        onClick={rotateCarouselLeft}
                    >
                        left
                    </button>
                    {parsedChildren}
                    <button
                        type="button"
                        className={cx('cr-c-carousel__button-right', {
                            'global!cr-is-disabled': currentPage === carouselPages.length - 1
                                || carouselPages.length === 0,
                        })}
                        disabled={currentPage === carouselPages.length - 1}
                        onClick={rotateCarouselRight}
                    >
                        right
                    </button>
                </Fragment>
            )}
        </div>
    );
};

CarouselControls.propTypes = {
    ...withStylesPropTypes,
    currentPage: PropTypes.number,
    carouselPages: PropTypes.array,
    rotateCarouselLeft: PropTypes.func,
    rotateCarouselRight: PropTypes.func,
    goToPage: PropTypes.func,
};

CarouselControls.defaultProps = {
    ...withStylesDefaultProps,
    currentPage: 0,
    carouselPages: [],
    rotateCarouselLeft: null,
    rotateCarouselRight: null,
    goToPage: null,
};

export default withStyles(styles)(CarouselControls);
