import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import {first, groupBy} from 'lodash'; //eslint-disable-line
import {
    withMediaItemPopover,
    withMediaItemPopoverDefaultProps,
    withMediaItemPopoverPropTypes,
} from '../media-item-popover/withMediaItemPopover';
import {groupOffers} from './helpers';
import MediaGalleryListEntryImage from './MediaGalleryListEntryImage';
import MediaGalleryListEntryInfo from './MediaGalleryListEntryInfo';
import MediaGalleryListEntryOffers from './MediaGalleryListEntryOffers';
import './MediaGalleryListEntry.scss';

@withMediaItemPopover
class MediaGalleryListEntry extends Component {
    static propTypes = {
        ...withMediaItemPopoverPropTypes,
        mediaItem: PropTypes.object,
        linkTo: PropTypes.object,
        meta: PropTypes.string,
        node: PropTypes.node,
        providers: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaItemPopoverDefaultProps,
        mediaItem: null,
        linkTo: {pathname: ''},
        meta: '',
        node: null,
        providers: null,
    };

    componentWillUnmount() {
        this.props.toggleMediaItemPopover(false);
    }

    onMouseEnter = () => this.props.toggleMediaItemPopover(true, this.props.mediaItem);

    onMouseOut = () => this.props.toggleMediaItemPopover(false);

    render() {
        const {mediaItem, providers, popoverCallerRef, linkTo} = this.props;
        const {meta, node} = this.props;
        const groupedOffers = groupOffers(mediaItem);

        return (
            <div className="vub-c-media-gallery-list-entry">
                <MediaGalleryListEntryImage
                    mediaItem={mediaItem}
                    onMouseEnter={this.onMouseEnter}
                    onMouseOut={this.onMouseOut}
                    popoverCallerRef={popoverCallerRef}
                    linkTo={linkTo}
                />
                <div className="vub-c-media-gallery-list-entry__info-content">
                    <MediaGalleryListEntryInfo
                        mediaItem={mediaItem}
                        meta={meta}
                        node={node}
                    />
                    <MediaGalleryListEntryOffers
                        groupedOffers={groupedOffers}
                        providers={providers}
                    />
                </div>
            </div>
        );
    }
}

export default MediaGalleryListEntry;
