import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import 'moment-duration-format';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import {mediaRouteResolver} from '../services';
import {
    withMediaRouteHelpers,
    withMediaRouteHelpersPropTypes,
    withMediaRouteHelpersDefaultProps,
} from '../media/withMediaRouteHelpers';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../media-entitlement/withMediaActions';
import './MediaItemPopoverView.scss';
import CTAIconButton from '../ui-elements/cta-button/CTAIconButton';
import {iconPlaySolid, iconMore} from '../ui-elements/icons';
import CTAButtonBarPopoverView from './CTAButtonBarPopoverView';

@withRouter
@withMediaActions
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemPopoverView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
        history: PropTypes.object,
        hidePopover: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaActionsDefaultProps,
        mediaItem: null,
        history: null,
    };

    goToMediaItem = () => {
        const {mediaItem, hidePopover, history, resolveMediaItemRoute} = this.props;
        hidePopover();
        history.push(resolveMediaItemRoute({mediaItemId: mediaItem.id}));
    };

    render() {
        const {mediaItem, hidePopover, startMediaItemPreviewPlayback} = this.props;
        const startPreviewPlayback = () => startMediaItemPreviewPlayback(mediaItem.preview);

        return (
            <div className="vub-c-media-item-popover-view">
                <div className="vub-c-media-item-popover-view__header">{mediaItem.title}</div>
                <div className="vub-c-media-item-popover-view__content">
                    <div className="vub-c-media-item-popover-view__item vub-c-media-item-popover-view__basic-info">
                        {mediaItem.contentAdvisoryRating && (
                            <span className="vub-c-media-item-popover-view__info">
                            Certificate: {mediaItem.contentAdvisoryRating}
                            </span>
                        )}
                        {mediaItem.year && (
                            <span className="vub-c-media-item-popover-view__info">
                                {mediaItem.year}
                            </span>
                        )}
                        {mediaItem.durationMinutes && (
                            <span className="vub-c-media-item-popover-view__info">
                                {mediaItem.durationMinutes}
                            </span>
                        )}
                    </div>
                    <div className="vub-c-media-item-popover-view__item">
                        {mediaItem.synopsis}
                    </div>
                    <div className="vub-c-media-item-popover-view__item">
                        <div className="vub-c-media-item-popover-view__buttons">
                            {mediaItem.preview
                            && mediaItem.classification !== mediaItemClassification.TV_SHOW
                            && (
                                <CTAIconButton
                                    icon={iconPlaySolid}
                                    label="trailer"
                                    onClick={startPreviewPlayback}
                                />
                            )}
                            <CTAIconButton
                                icon={iconMore}
                                label="details"
                                onClick={this.goToMediaItem}
                            />
                        </div>
                    </div>
                    <div className="vub-c-media-item-popover-view__item">
                        <CTAButtonBarPopoverView
                            mediaItem={mediaItem}
                            hidePopover={hidePopover}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default MediaItemPopoverView;
