import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './MediaBanner.scss';

const MediaBanner = ({title, subtitle, linkTo, media, image, logo}) => (
    <div className="vub-c-media-banner-row">
        <div className="vub-c-media-banner__header">
            <div className="vub-c-media-banner__title">
                <Link
                    className="vub-c-media-banner__title-link"
                    to={linkTo}
                >
                    {title}
                </Link>
            </div>
            <div className="vub-c-media-banner__subtitle">
                <Link
                    className="vub-c-media-banner__subtitle-link"
                    to={linkTo}
                >
                    {subtitle}
                </Link>
            </div>
        </div>
        <div className="vub-c-media-banner">
            <Link to={linkTo}>
                <div className="vub-c-media-banner__content">
                    <div
                        className="vub-c-media-banner__image"
                        style={{backgroundImage: `url('${image}')`}}
                    >
                        <img
                            src={logo}
                            className="vub-c-media-banner__logo"
                            alt="collection background"
                        />
                    </div>
                    <div className="vub-c-media-banner__media-items">
                        {media.slice(0, 3).toArray().map((item, index) => (
                            <div key={item.id} className="vub-c-media-banner__media-item">
                                <div key={item.id} className="vub-c-media-banner__media-item-content">
                                    {item.imageBoxCover
                                        ? <img className="vub-c-media-banner__media-item-image" src={item.imageBoxCover} alt="media item" />
                                        : null
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Link>
        </div>
    </div>
);

MediaBanner.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.node,
    linkTo: PropTypes.object,
    media: PropTypes.object,
    image: PropTypes.string,
    logo: PropTypes.string,
};

MediaBanner.defaultProps = {
    title: null,
    subtitle: null,
    linkTo: null,
    media: null,
    image: null,
    logo: null,
};

export default MediaBanner;
