import React from 'react';
import PropTypes from 'prop-types';
import HeroBannerBackgroundPlayer from '../../ui-elements/hero-banner-background-player/HeroBannerBackgroundPlayer';
import {
    withLayoutControls,
    withLayoutControlsPropTypes,
    withLayoutControlsDefaultProps,
} from '../../layout/withLayoutControls';

const HeroBannerBackgroundPlayerRow = ({storefrontRow, setHeaderTransparency, isPlaybackEnabled}) => {
    if (!storefrontRow) return null;

    const playbackSources = storefrontRow.toJS().data;
    const {playbackDelay} = storefrontRow.templateConfiguration;
    return (
        <HeroBannerBackgroundPlayer
            playbackSources={playbackSources}
            playbackDelay={playbackDelay}
            setHeaderTransparency={setHeaderTransparency}
            isWithImage={true}
            isPlaybackEnabled={isPlaybackEnabled}
        />
    );
};

HeroBannerBackgroundPlayerRow.propTypes = {
    ...withLayoutControlsPropTypes,
    storefrontRow: PropTypes.object,
    isPlaybackEnabled: PropTypes.bool,
};

HeroBannerBackgroundPlayerRow.defaultProps = {
    ...withLayoutControlsDefaultProps,
    storefrontRow: null,
    isPlaybackEnabled: true,
};

export default withLayoutControls(HeroBannerBackgroundPlayerRow);
