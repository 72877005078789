import {Record} from 'immutable';

/**
 * EPGChannel definition
 *
 * @typedef {Immutable.Record} EPGChannel
 * @property {?string} id
 * @property {?string} logo
 * @property {?string} name
 * @property {?number} orderNumber
 */

/**
 * EPGChannel record
 *
 * @type {EPGChannel}
 */
class EPGChannel extends Record({
    id: null,
    logo: null,
    name: null,
    orderNumber: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {EPGChannel}
     */
    fromDTO(dataTransferObject) {
        return new EPGChannel({
            ...this.toObject(),
            ...dataTransferObject,
        });
    }
}

export default EPGChannel;
