import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './CTAIconButton.scss';
import StandardIcon from '../standard-icon/StandardIcon';

class CTAIconButton extends Component {
    static propTypes = {
        ...StandardIcon.propTypes,
        label: PropTypes.string,
        onClick: PropTypes.func,
        type: PropTypes.string,
    };

    static defaultProps = {
        ...StandardIcon.defaultProps,
        label: null,
        onClick: null,
        className: '',
        type: 'medium',
    };

    onClick = () => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    };

    render() {
        const {label, icon, className, type} = this.props;
        return (
            <div className={`vub-c-cta-icon-button ${className}`} onClick={this.onClick}>
                <StandardIcon
                    className={`vub-c-icon--${type}`}
                    icon={icon}
                />
                <label className="vub-c-cta-icon-button__label">{label}</label>
            </div>
        );
    }
}

export default CTAIconButton;
