import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetailsSynopsis.scss';

const MediaItemDetailsSynopsis = ({mediaItem}) => (
    <div className="vub-c-media-item-details-synopsis">
        <h3 className="vub-c-media-item-details-synopsis__title">
            Overview
        </h3>
        <p className="vub-c-media-item-details-synopsis__text">
            {mediaItem.synopsisLong}
        </p>
    </div>
);

MediaItemDetailsSynopsis.propTypes = {
    mediaItem: PropTypes.object.isRequired,
};

export default MediaItemDetailsSynopsis;
