import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../authActionTypes';
import {authService} from '../../services';

/**
 * Request password reset flow
 * TODO refactor into flow
 */
const requestPasswordResetFlow = function* requestPasswordResetFlow({payload}) {
    try {
        yield put({type: actionTypes.REQUEST_PASSWORD_RESET_REQUEST, payload});

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: true},
        });

        const {email} = payload;
        const response = yield call(authService.requestPasswordReset, {email});
        yield put({
            type: actionTypes.REQUEST_PASSWORD_RESET_SUCCESS,
            payload: {response},
        });

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });
    } catch (error) {
        yield put({type: actionTypes.REQUEST_PASSWORD_RESET_ERROR, error: true, payload: error});

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });
    }
};

export default requestPasswordResetFlow;
