import Immutable from 'immutable';
import moment from 'moment';

/**
 * Entitlement definition
 *
 * @typedef {Immutable.Record} Entitlement
 * @property {?number} id
 * @property {?number} purchaseOrderId
 * @property {?number} offerId
 * @property {?string} mediaItemId
 * @property {?object} rentalExpiryTime
 */

/**
 * Entitlement record
 *
 * @type {Entitlement}
 */
class Entitlement extends Immutable.Record({
    id: null,
    purchaseOrderId: null,
    offerId: null,
    mediaItemId: null,
    rentalExpiryTime: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {Entitlement}
     */
    fromDTO(dataTransferObject) {
        return new Entitlement({
            ...this.toObject(),
            ...dataTransferObject,
            rentalExpiryTime: dataTransferObject.rentalExpiryTime
                ? moment(dataTransferObject.rentalExpiryTime) : this.rentalExpiryTime,
        });
    }
}

export default Entitlement;
