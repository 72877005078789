import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import storefrontRowTypes from '../../navigation/storefrontRowTypes';
import MediaBanner from '../../ui-elements/media-banner/MediaBanner';
import CollectionImage from '../../assets/images/collection-hp-image.jpg'; // TODO should come from config api
import CollectionLogo from '../../assets/images/collection-hp-logo.png'; // TODO should come from config api

const getLinkTo = storefrontRow => {
    const {value} = storefrontRow;
    let linkTo = {pathname: ''};

    if (storefrontRow.type === storefrontRowTypes.MEDIA_GENRE) {
        linkTo = resolveRoute(routePaths.MEDIA_GENRE, {mediaGenreId: value});
    }

    if (storefrontRow.type === storefrontRowTypes.MEDIA_COLLECTION) {
        linkTo = resolveRoute(routePaths.MEDIA_COLLECTION, {mediaCollectionId: value});
    }

    return linkTo;
};

const MediaStripBannerStandardRow = ({storefrontRow}) => {
    if (!storefrontRow) return null;

    const {data: media} = storefrontRow;
    const {title, mediaLimitForSubtitle} = storefrontRow.templateConfiguration;
    const linkTo = getLinkTo(storefrontRow);

    return media && media.size > 0 ? (
        <MediaBanner
            title={title}
            subtitle={media.size > mediaLimitForSubtitle ? `(${media.size} ${pluralize('title', media.size)})` : null}
            media={media}
            linkTo={linkTo}
            image={CollectionImage}
            logo={CollectionLogo}
        />
    ) : null;
};

MediaStripBannerStandardRow.propTypes = {
    storefrontRow: PropTypes.object,
};

MediaStripBannerStandardRow.defaultProps = {
    storefrontRow: null,
};

export default MediaStripBannerStandardRow;
