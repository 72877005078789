import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import './MoreDetailsFilter.scss';
import NestedCustomSelect from '../nested-custom-select/NestedCustomSelect';
import Option from '../custom-select/Option';
import MoreDetailsTag from './MoreDetailsTag';

class MoreDetailsFilter extends Component {
    static propTypes = {
        filterByMoreDetails: PropTypes.array,
        onMoreDetailsFilterSelect: PropTypes.func,
        providers: PropTypes.object,
        facetProviders: PropTypes.array,
    };

    static defaultProps = {
        filterByMoreDetails: [],
        onMoreDetailsFilterSelect: null,
        providers: [],
        facetProviders: [],
    };

    deleteOption = optionValue => {
        const filteredOptions = this.props.filterByMoreDetails.filter(el => el.value !== optionValue);
        this.props.onMoreDetailsFilterSelect(filteredOptions);
    };

    handleChange = (value, key) => {
        const element = {
            key,
            value,
        };
        const filteredItems = this.props.filterByMoreDetails || [];
        if (filteredItems && filteredItems.every(el => el.value !== value)) {
            const updatedFilterByMoreDetails = [...filteredItems, element];
            this.props.onMoreDetailsFilterSelect(updatedFilterByMoreDetails);
        }
    };


    render() {
        const {filterByMoreDetails, providers, facetProviders} = this.props;
        const moreDetailsOptions = [
            {
                id: 'offers/provider',
                value: 'offers/provider',
                name: 'Provider',
                options: providers ? providers.reduce((prev, {service, provider}) => {
                    if (prev.every(el => el.value !== provider) && facetProviders.includes(provider)) {
                        prev = [...prev, {
                            id: provider,
                            value: provider,
                            name: service,
                        }];
                    }
                    return prev;
                }, []) : [],
            },
        ];

        const getTagName = ({key, value, moreDetailsOptions}) => {
            const moreDetailsOption = moreDetailsOptions ? moreDetailsOptions.find(el => el.value === key) : null;
            const subOptions = moreDetailsOption ? moreDetailsOption.options : null;
            const tag = subOptions ? subOptions.find(el => el.value === value) : null;
            if (tag && tag.name) {
                return tag.name;
            }
            return value;
        };

        return (
            <Fragment>
                <span className="vub-c-more-details-filter__vertical-divider" />
                <NestedCustomSelect
                    label="More details"
                    onChange={this.handleChange}
                    value="Select"
                >
                    {moreDetailsOptions.map((option, index) => (
                        <NestedCustomSelect
                            value={option.name}
                            className="vub-c-nested-custom-select--inner"
                            key={option.id}
                            isChild={true}
                            index={index}
                        >
                            {option.options.map(secondLevelOption => (
                                <Option
                                    key={secondLevelOption.id}
                                    value={secondLevelOption.value}
                                    parentValue={option.value}
                                    isSelected={
                                        filterByMoreDetails
                                            .map(el => el.value)
                                            .includes(secondLevelOption.value)
                                    }
                                >
                                    {secondLevelOption.name}
                                </Option>
                            ))}
                        </NestedCustomSelect>
                    ))}
                </NestedCustomSelect>
                {filterByMoreDetails && filterByMoreDetails.map(({key, value}) => {
                    const tagOption = moreDetailsOptions.find(moreDetailsOption => moreDetailsOption.id === key);
                    const label = tagOption ? tagOption.name : key;
                    return (
                        <MoreDetailsTag
                            key={value}
                            label={label}
                            value={value}
                            name={getTagName({key, value, moreDetailsOptions})}
                            removeTag={this.deleteOption}
                        />
                    );
                })}
            </Fragment>
        );
    }
}

export default MoreDetailsFilter;

