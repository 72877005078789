import {createSelector} from 'reselect';

const getBasket = state => state.mediaEntitlement.basket;
const getMedia = state => state.media.media;

export const getBasketItem = createSelector(
    getBasket,
    basket => basket.first(),
);

export const getMediaItem = createSelector(
    getBasket,
    getMedia,
    (basket, media) => {
        const basketItem = basket.first();
        return basketItem ? media.get(basketItem.mediaItemId) : null;
    }
);
