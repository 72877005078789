import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification, mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../media-entitlement/withMediaActions';
import './CTAButtonBarPopoverView.scss';
import PurchaseCTAButtonsTVODPopover from './elements/PurchaseCTAButtonsTVODPopover';
import PurchaseCTAButtonsESTPopover from './elements/PurchaseCTAButtonsESTPopover';
import PurchaseCTAButtonsSVODPopover from './elements/PurchaseCTAButtonsSVODPopover';
import PurchaseCTAButtonsAVODPopover from './elements/PurchaseCTAButtonsAVODPopover';
import WatchCTAButtonsPopover from './elements/WatchCTAButtonsPopover';
import EpisodicCTAButtonsPopover from './elements/EpisodicCTAButtonsPopover';

@withMediaActions
class CTAButtonBarPopoverView extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
        hidePopover: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {isUserSignedIn, userAccount} = this.props;
        const {mediaItem, currentEntitlements} = this.props;
        const {hidePopover} = this.props;

        // display episodic CTA buttons for tv-shows and seasons
        if (mediaItem.classification === mediaItemClassification.TV_SHOW
            || mediaItem.classification === mediaItemClassification.SEASON) {
            return (
                <EpisodicCTAButtonsPopover mediaItem={mediaItem} hidePopover={hidePopover} />
            );
        }

        const showAllPurchaseOptions = () => {
            hidePopover();
            // TODO more purchase options pop-up
        };

        const isAVODOfferAvailable = !isUserSignedIn
            && !!mediaItem.offers.find(offer => offer.offerType === mediaItemOfferType.AVOD);

        const entitlementOfferIds = currentEntitlements
            ? currentEntitlements.map(entitlement => entitlement.offerId) : [];

        const signedInUserHasEntitlements = isUserSignedIn
            && !!mediaItem.offers.find(offer => {
                if (offer.offerType === mediaItemOfferType.AVOD) {
                    return true;
                }

                if (offer.offerType === mediaItemOfferType.SVOD) {
                    return !!userAccount.subscriptions.find(subscription => subscription.provider === offer.provider);
                }

                if (offer.offerType === mediaItemOfferType.TVOD || offer.offerType === mediaItemOfferType.EST) {
                    return entitlementOfferIds.includes(offer.id);
                }

                return false;
            });

        return (
            <div className="vub-c-cta-button-bar-popover-view">
                {isAVODOfferAvailable && (
                    <PurchaseCTAButtonsAVODPopover
                        mediaItem={mediaItem}
                        hidePopover={hidePopover}
                    />
                )}
                {signedInUserHasEntitlements && (
                    <WatchCTAButtonsPopover
                        mediaItem={mediaItem}
                        hidePopover={hidePopover}
                    />
                )}
                {!isAVODOfferAvailable && !signedInUserHasEntitlements && (
                    <Fragment>
                        <PurchaseCTAButtonsTVODPopover
                            mediaItem={mediaItem}
                            showAllPurchaseOptions={showAllPurchaseOptions}
                            hidePopover={hidePopover}
                        />
                        <PurchaseCTAButtonsESTPopover
                            mediaItem={mediaItem}
                            showAllPurchaseOptions={showAllPurchaseOptions}
                            hidePopover={hidePopover}
                        />
                        <PurchaseCTAButtonsSVODPopover
                            mediaItem={mediaItem}
                            showAllPurchaseOptions={showAllPurchaseOptions}
                            hidePopover={hidePopover}
                        />
                    </Fragment>
                )}
            </div>
        );
    }
}

export default CTAButtonBarPopoverView;
