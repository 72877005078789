import {Record} from 'immutable';

/**
 * CreditCard definition
 *
 * @typedef {Object} CreditCard
 * @property {?string} account
 * @property {?string} expirationDateTime
 * @property {?string} name
 */

/**
 * PaymentMethod definition
 *
 * @typedef {Immutable.Record} PaymentMethod
 * @property {?string} id
 * @property {?string} externalId
 * @property {?boolean} active
 * @property {?CreditCard} creditCard
 * @property {?string} type
 */

/**
 * PaymentMethod record
 *
 * @type {PaymentMethod}
 */
class PaymentMethod extends Record({
    id: null,
    externalId: null,
    active: null,
    creditCard: null,
    type: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {PaymentMethod}
     */
    fromDTO(dataTransferObject) {
        const creditCard = dataTransferObject.creditCard || null;
        if (creditCard) {
            const accountFirstDigit = creditCard.account ? creditCard.account[0] : 0;
            let creditCardName;
            switch (parseInt(accountFirstDigit, 10)) {
                case 3:
                    creditCardName = 'Amex';
                    break;

                case 4:
                    creditCardName = 'Visa';
                    break;

                case 5:
                    creditCardName = 'MasterCard';
                    break;

                case 6:
                    creditCardName = 'Discover';
                    break;

                default:
                    creditCardName = 'Unknown';
            }

            creditCard.name = creditCardName;
        }

        return new PaymentMethod({
            ...this.toObject(),
            ...dataTransferObject,
            creditCard,
        });
    }
}

export default PaymentMethod;
