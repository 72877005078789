import React from 'react';
import PropTypes from 'prop-types';
import './HoverComponent.scss';
import CTAButton from '../../ui-elements/cta-button/CTAButton';
import CTAIconButton from '../../ui-elements/cta-button/CTAIconButton';
import {
    iconPlaySolid,
    iconWishlist,
    iconMore,
} from '../../ui-elements/icons';

const HoverComponent = props => {
    const {
        ctaButtons,
        mediaItem: {title, rating, imdbRating, duration, year, certificate, description, translations},
    } = props;
    return (
        <div className="vub-c-hover-component">
            <div className="vub-c-hover-component__header">{title}</div>
            <div className="vub-c-hover-component__content">
                <div className="vub-c-hover-component__item vub-c-hover-component__basic-info">
                    <span className="vub-c-hover-component__info">{year}</span>
                    <span className="vub-c-hover-component__info">{duration && `${duration}m`}</span>
                    <span className="vub-c-hover-component__info">{certificate}</span>
                    <span className="vub-c-hover-component__info vub-u-transform-text-uppercase">{translations.join(' / ')}</span>
                </div>
                <div className="vub-c-hover-component__item vub-c-hover-component__rating">
                    <span className="vub-c-hover-component__info">{rating}</span>
                    <span className="vub-c-hover-component__info">{imdbRating}</span>
                </div>
                <div className="vub-c-hover-component__item">{description}</div>
                <div className="vub-c-hover-component__item">
                    <div className="vub-c-hover-component__buttons">
                        <CTAIconButton
                            icon={iconPlaySolid}
                            label="trailer"
                        />
                        <CTAIconButton
                            icon={iconWishlist}
                            label="favourites"
                        />
                        <CTAIconButton
                            icon={iconMore}
                            label="details"
                        />
                    </div>
                </div>
                <div className="vub-c-hover-component__item">
                    <div className="vub-c-hover-component__cta-buttons">
                        {ctaButtons.map(button => (
                            <CTAButton key={button.type}>{button.type} <span className="vub-c-cta-button__connecting-word"> from</span>
                                <span className="vub-c-cta-button__price"> £{button.price}</span>
                            </CTAButton>
                        ))}
                    </div>
                </div>
                <div className="vub-c-hover-component__triangle-wrapper vub-c-hover-component__triangle-wrapper--down">
                    <div className="vub-c-hover-component__triangle vub-c-hover-component__triangle--down" />
                </div>
            </div>
        </div>
    );
};

HoverComponent.propTypes = {
    mediaItem: PropTypes.object,
    ctaButtons: PropTypes.array,
};

HoverComponent.defaultProps = {
    mediaItem: null,
    ctaButtons: null,
};

export default HoverComponent;
