import {put, take, call} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {providerAuthenticationType, providerAuthenticationProtocol} from '@comrock/vub-wls-auth-service';
import * as actionTypes from '../authActionTypes';
import routePaths from '../../routePaths';
import {authService} from '../../services';
import config from '../../config';

/**
 * Sign-up user flow
 */
const signUpUserFlow = function* signUpUserFlow() {
    while (true) {
        yield take(actionTypes.SIGN_UP_USER);

        if (authService.authenticationType === providerAuthenticationType.BUILT_IN) {
            yield put(push(routePaths.SIGN_UP));
        }

        if (authService.authenticationType === providerAuthenticationType.SINGLE_SIGN_ON) {
            switch (authService.authenticationProtocol) {
                case providerAuthenticationProtocol.OAUTH2:
                    if (config.BASE_URL) {
                        const oauth2AuthorizationUri = authService.getOAuth2AuthorizationUri({
                            responseType: 'token',
                            redirectUri: `${config.BASE_URL}${routePaths.OAUTH2_AUTHENTICATION}`,
                        });
                        yield call(() => window.location.assign(oauth2AuthorizationUri));
                    }
                    break;

                case providerAuthenticationProtocol.OPEN_ID:
                    if (config.BASE_URL) {
                        const oauth2AuthorizationUri = authService.getOAuth2AuthorizationUri({
                            responseType: 'token',
                            redirectUri: `${config.BASE_URL}${routePaths.OIDC_AUTHENTICATION}`,
                        });
                        yield call(() => window.location.assign(oauth2AuthorizationUri));
                    }
                    break;

                default:
                // no-op
            }
        }
    }
};

export default signUpUserFlow;
