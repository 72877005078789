import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {combineClassNames} from '../../ui-kit/utils';
import './MediaItemPopover.scss';

const DIRECTION_UP = 'DIRECTION_UP';
const DIRECTION_RIGHT = 'DIRECTION_RIGHT';
const DIRECTION_DOWN = 'DIRECTION_DOWN';
const DIRECTION_LEFT = 'DIRECTION_LEFT';
const ELEMENT_OVERLAP = 5;
const ARROW_SIZE = 15;
const DEFAULT_POPOVER_POSITION = {
    direction: DIRECTION_UP,
    top: -1000,
    left: -1000,
    arrowTop: 0,
    arrowLeft: 0,
};
const POPOVER_TIMEOUT_PERIOD = 650;

// TODO re-check state usage and timeout behaviour
// TODO check eslint fix for false positive: react/no-unused-state
/* eslint-disable react/no-unused-state */
class MediaItemPopover extends Component {
    static propTypes = {
        isCallerHovered: PropTypes.bool,
        mediaItem: PropTypes.object,
        elementNode: PropTypes.object,
        screenPadding: PropTypes.shape({
            top: PropTypes.number,
            right: PropTypes.number,
            bottom: PropTypes.number,
            left: PropTypes.number,
        }).isRequired,
        renderPopoverContent: PropTypes.func.isRequired,
        isDebugPopoverEnabled: PropTypes.bool, // used for debugging popover hover and is read from Config file
    };

    static defaultProps = {
        isCallerHovered: false,
        mediaItem: null,
        elementNode: null,
        isDebugPopoverEnabled: false,
    };

    state = {
        isDisplayed: false,
        isVisible: false,
        isHovered: false,
        mediaItem: this.props.mediaItem,
        elementNode: this.props.elementNode,
        prevPropMediaItem: null,
        callerHoverTimeoutId: 0,
    };

    static getDerivedStateFromProps(props, state) {
        if (state.isHovered) {
            return {
                isDisplayed: true,
                isVisible: true,
            };
        }

        if ((!props.mediaItem || !props.isCallerHovered) && !state.isHovered) {
            clearTimeout(state.callerHoverTimeoutId);

            return {
                isDisplayed: false,
                isVisible: false,
                mediaItem: null,
                elementNode: null,
                prevPropMediaItem: null,
                callerHoverTimeoutId: 0,
            };
        }

        if (props.mediaItem && props.isCallerHovered && props.mediaItem !== state.prevPropMediaItem) {
            clearTimeout(state.callerHoverTimeoutId);

            return {
                isDisplayed: true,
                isVisible: false,
                mediaItem: props.mediaItem,
                elementNode: props.elementNode,
                prevPropMediaItem: props.mediaItem,
                callerHoverTimeoutId: 0,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isDisplayed && !this.state.isVisible && prevState.mediaItem !== this.state.mediaItem) {
            // Here we are intentionally updating state. Props change caused component to be displayed, once
            // displayed we can get it's rect and use values for calculations. We can also apply timeout in order
            // to delay making it visible
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                callerHoverTimeoutId: setTimeout(this.setPopoverVisible, POPOVER_TIMEOUT_PERIOD),
            });
        }
    }

    popoverRef = React.createRef();

    onMouseEnter = () => {
        this.setState({
            isHovered: true,
        });
    };

    onMouseLeave = () => {
        if (this.props.isDebugPopoverEnabled) return;

        this.setState({
            isHovered: false,
        });
    };

    setPopoverVisible = () => {
        clearTimeout(this.state.callerHoverTimeoutId);

        this.setState({
            isVisible: true,
            callerHoverTimeoutId: 0,
        });
    };

    // TODO find a way to eliminate the need for hidePopover method
    hidePopover = () => {
        if (this.props.isDebugPopoverEnabled) return;

        this.setState({
            isDisplayed: false,
            isVisible: false,
            isHovered: false,
        });
    };

    calculateDirection = (elementRect, popoverRect) => {
        const {screenPadding} = this.props;

        if (elementRect.top - screenPadding.top > popoverRect.height) {
            return DIRECTION_UP;
        }

        if (window.innerHeight - elementRect.bottom - screenPadding.bottom > popoverRect.height) {
            return DIRECTION_DOWN;
        }

        if (window.innerWidth - elementRect.right - screenPadding.right > popoverRect.width
            && screenPadding.top < elementRect.top - (popoverRect.height - elementRect.height)) {
            return DIRECTION_RIGHT;
        }

        if (elementRect.left - screenPadding.left > popoverRect.width
            && screenPadding.top < elementRect.top - (popoverRect.height - elementRect.height)) {
            return DIRECTION_LEFT;
        }

        return undefined;
    };

    calculatePositionTop = (direction = DIRECTION_UP, elementRect, popoverRect) => {
        const {screenPadding} = this.props;
        const scrollTop = window.pageYOffset;

        if (direction === DIRECTION_UP) {
            return scrollTop + elementRect.top - popoverRect.height + ELEMENT_OVERLAP;
        }

        if (direction === DIRECTION_RIGHT || direction === DIRECTION_LEFT) {
            let positionTop = scrollTop + elementRect.top - (popoverRect.height / 2) + (elementRect.height / 2);
            positionTop = positionTop < scrollTop + screenPadding.top
                ? scrollTop + elementRect.top - ARROW_SIZE : positionTop;
            positionTop = positionTop - scrollTop + popoverRect.height > window.innerHeight
                ? scrollTop + elementRect.bottom - popoverRect.height + ARROW_SIZE : positionTop;

            return positionTop;
        }

        if (direction === DIRECTION_DOWN) {
            return scrollTop + elementRect.bottom - ELEMENT_OVERLAP;
        }
    };

    calculatePositionLeft = (direction = DIRECTION_UP, elementRect, popoverRect) => {
        if (direction === DIRECTION_UP || direction === DIRECTION_DOWN) {
            let positionLeft = elementRect.left - (popoverRect.width / 2) + (elementRect.width / 2);
            positionLeft = positionLeft < 0 ? elementRect.left - ARROW_SIZE : positionLeft;
            positionLeft = positionLeft + popoverRect.width > window.innerWidth
                ? elementRect.right - popoverRect.width + ARROW_SIZE : positionLeft;

            return positionLeft;
        }

        if (direction === DIRECTION_RIGHT) {
            return elementRect.right - ELEMENT_OVERLAP;
        }

        if (direction === DIRECTION_LEFT) {
            return elementRect.left - popoverRect.width + ELEMENT_OVERLAP;
        }
    };

    calculateArrowPositionTop = (direction = DIRECTION_UP, elementRect, popoverRect, top) => {
        if (direction === DIRECTION_UP || direction === DIRECTION_DOWN) return 0;

        const scrollTop = window.pageYOffset;
        if (scrollTop + elementRect.top - ARROW_SIZE === top) return elementRect.height / 2;
        if (scrollTop + elementRect.bottom - popoverRect.height + ARROW_SIZE === top) {
            return popoverRect.height - (elementRect.height / 2) - 2 * ARROW_SIZE;
        }

        return (popoverRect.height / 2) - ARROW_SIZE;
    };

    calculateArrowPositionLeft = (direction = DIRECTION_UP, elementRect, popoverRect, left) => {
        if (direction === DIRECTION_RIGHT || direction === DIRECTION_LEFT) return 0;

        if (elementRect.left - ARROW_SIZE === left) return elementRect.width / 2;
        if (elementRect.right - popoverRect.width + ARROW_SIZE === left) {
            return popoverRect.width - (elementRect.width / 2) - 2 * ARROW_SIZE;
        }

        return (popoverRect.width / 2) - ARROW_SIZE;
    };

    getPopoverPosition = () => {
        const {elementNode} = this.state;
        const popoverNode = this.popoverRef.current;
        if (!elementNode || !popoverNode) return DEFAULT_POPOVER_POSITION;

        const elementRect = elementNode.getBoundingClientRect();
        const popoverRect = popoverNode.getBoundingClientRect(); // only rect.width & rect.height are usable!

        const direction = this.calculateDirection(elementRect, popoverRect);
        if (!direction) return DEFAULT_POPOVER_POSITION;

        const top = this.calculatePositionTop(direction, elementRect, popoverRect);
        const left = this.calculatePositionLeft(direction, elementRect, popoverRect);

        const arrowTop = this.calculateArrowPositionTop(direction, elementRect, popoverRect, top);
        const arrowLeft = this.calculateArrowPositionLeft(direction, elementRect, popoverRect, left);

        return {direction, top, left, arrowTop, arrowLeft};
    };

    render() {
        const {renderPopoverContent} = this.props;
        const {isDisplayed, isVisible, mediaItem} = this.state;
        const {direction, top, left, arrowTop, arrowLeft} = !isVisible
            ? DEFAULT_POPOVER_POSITION : this.getPopoverPosition();

        const popoverStyle = {
            display: isDisplayed ? 'block' : 'none',
            visibility: isVisible ? 'visible' : 'hidden',
            top: top,
            left: left,
        };

        const popoverArrowStyle = direction === DIRECTION_UP || direction === DIRECTION_DOWN
            ? {left: arrowLeft}
            : {top: arrowTop};

        return (
            <div
                className={combineClassNames(
                    'vub-c-media-item-popover',
                    direction === DIRECTION_UP && 'vub-c-media-item-popover--direction-up',
                    direction === DIRECTION_RIGHT && 'vub-c-media-item-popover--direction-right',
                    direction === DIRECTION_LEFT && 'vub-c-media-item-popover--direction-left',
                    direction === DIRECTION_DOWN && 'vub-c-media-item-popover--direction-down',
                )}
                style={popoverStyle}
                ref={this.popoverRef}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <div className="vub-c-media-item-popover__content">
                    {mediaItem && renderPopoverContent(mediaItem, this.hidePopover)}
                    <div className="vub-c-media-item-popover__arrow" style={popoverArrowStyle} />
                </div>
            </div>
        );
    }
}

export default MediaItemPopover;
