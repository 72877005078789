import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../ui-kit/withStyles';
import styles from './MediaCarouselSlidePortraitSeeMore.module.scss';
import {CarouselSlide} from '../../ui-kit';

const MediaCarouselSlidePortraitSeeMore = ({linkTo, className, cx, createClassNameResolver, ...restProps}) => (
    <CarouselSlide
        {...restProps}
        className={cx(className)}
        classNameResolver={createClassNameResolver('vub-c-media-carousel-slide-portrait-see-more')}
    >

        <div className={cx('vub-c-media-carousel-slide-portrait-see-more__content')}>
            <div className={cx('vub-c-media-carousel-slide-portrait-see-more__text-placeholder')}>
                <div className={cx('vub-c-media-carousel-slide-portrait-see-more__text')}>
                    <Link
                        className={cx('vub-c-media-carousel-slide-portrait-see-more__text-link')}
                        to={linkTo}
                    >
                        see more
                    </Link>
                </div>
            </div>
        </div>

    </CarouselSlide>
);

MediaCarouselSlidePortraitSeeMore.propTypes = {
    ...withStylesPropTypes,
    linkTo: PropTypes.object.isRequired,
};

MediaCarouselSlidePortraitSeeMore.defaultProps = {
    ...withStylesDefaultProps,
};

export default withStyles(styles)(MediaCarouselSlidePortraitSeeMore);
