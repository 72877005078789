import React, {Component} from 'react';
import MobileDetect from 'mobile-detect';
import OutsideClickContext from './OutsideClickContext';

export default class OutsideClickProvider extends Component {
    componentDidMount() {
        const md = new MobileDetect(window.navigator.userAgent);
        const isTouchDevice = md.os() === 'AndroidOS' || md.os() === 'iOS';

        if (isTouchDevice) {
            document.addEventListener('touchstart', this.handleClick);
            return;
        }
        document.addEventListener('keydown', this.handleClick);
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        const md = new MobileDetect(window.navigator.userAgent);
        const isTouchDevice = md.os() === 'AndroidOS' || md.os() === 'iOS';

        if (isTouchDevice) {
            document.removeEventListener('touchstart', this.handleClick);
            return;
        }
        document.removeEventListener('keydown', this.handleClick);
        document.removeEventListener('mousedown', this.handleClick);
    }

    observers = [];

    subscribeToClickOutside = (ref, handler) => {
        this.observers = this.observers.every(observer => observer.ref !== ref)
            ? [...this.observers, {
                ref,
                handler,
            }]
            : this.observers;
    };

    unsubscribeFromClickOutside = ref => {
        this.observers = ref ? this.observers.filter(observer => observer.ref !== ref) : this.observers;
    };

    onClickOutside = (observers = []) => observers.forEach(({handler}) => handler());

    handleClick = ({target, type, key}) => {
        // handle keydown event
        if (type === 'keydown') {
            const eventKeys = ['Escape'];
            if (eventKeys.includes(key)) {
                this.onClickOutside(this.observers);
            }
            return;
        }
        // hanlde click event
        const filteredObservers = this.observers.filter(({ref}) => {
            return !(!ref.current || ref.current.contains(target));
        });
        this.onClickOutside(filteredObservers);
    };

    render() {
        const {children} = this.props;
        const context = {
            subscribeToClickOutside: this.subscribeToClickOutside,
            unsubscribeFromClickOutside: this.unsubscribeFromClickOutside,
        };
        return (
            <OutsideClickContext.Provider value={context}>
                {children}
            </OutsideClickContext.Provider>
        );
    }
}
