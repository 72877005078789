import React from 'react';
import PropTypes from 'prop-types';
import './MoreDetailsTag.scss';

const MoreDetailsTag = ({label, value, name, removeTag}) => (
    <div className="vub-c-more-details-tag">
        <div className="vub-c-more-details-tag__label">{label}:</div>
        <div className="vub-c-more-details-tag__value">
            {name}
            <span className="vub-c-more-details-tag__close" onClick={() => removeTag(value)} />
        </div>
    </div>
);

MoreDetailsTag.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    removeTag: PropTypes.func,
};

MoreDetailsTag.defaultProps = {
    value: null,
    name: null,
    label: null,
    removeTag: null,
};

export default MoreDetailsTag;
