import React from 'react';
import PropTypes from 'prop-types';
import './MediaGallery.scss';

const MediaGallery = React.forwardRef(({title, subtitle, children}, ref) => (
    <div className="vub-c-media-gallery">
        <div className="vub-c-media-gallery__header" ref={ref}>
            <div className="vub-c-media-gallery__title">
                {title}
            </div>
            <div className="vub-c-media-gallery__subtitle">
                {subtitle}
            </div>
        </div>
        <div className="vub-c-media-gallery__viewing-frame">
            {children}
        </div>
    </div>
));

MediaGallery.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
};

MediaGallery.defaultProps = {
    title: null,
    subtitle: null,
};

export default MediaGallery;
