import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import OverlayContext from './OverlayContext';

export const withOverlay = BaseComponent => (
    class ComposeComponent extends PureComponent {
        static propTypes = {
            ...BaseComponent.propTypes,
            subscribeToOverlayClick: PropTypes.func,
            unsubscribeFromOverlayClick: PropTypes.func,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            subscribeToOverlayClick: null,
            unsubscribeFromOverlayClick: null,
        };

        render() {
            return (
                <OverlayContext.Consumer>
                    {context => {
                        const props = {
                            ...this.props,
                            ...context,
                        };
                        return <BaseComponent {...props} />;
                    }}
                </OverlayContext.Consumer>
            );
        }
    }
);

export const withOverlayPropTypes = {
    subscribeToOverlayClick: PropTypes.func.isRequired,
    unsubscribeFromOverlayClick: PropTypes.func.isRequired,
};

export const withOverlayDefaultProps = {
    subscribeToOverlayClick: null,
    unsubscribeFromOverlayClick: null,
};
