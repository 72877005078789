import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as authActions from '../auth/authActions';
import './AccountRequestPasswordResetView.scss';
import Input from '../ui-elements/input/Input';
import FormContainer from '../ui-elements/form/FormContainer';
import {required, isEmailValid} from '../ui-elements/form-validation/validations';
import FormButton from '../ui-elements/form/FormButton';
import ServerValidationErrors from '../ui-elements/form-validation/ServerValidationErrors';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';

const AccountRequestPasswordResetView = props => {
    const {signInUser, signUpUser} = props;
    const {requestPasswordReset, isSessionChangeInProgress} = props;

    const serverErrors = [];
    const getFormValues = ({email}) => {
        requestPasswordReset({email});
    };

    return (
        <div className="vub-c-account-password-reset-view">
            <div className="vub-c-account-password-reset-view__title">
                password recovery
            </div>
            <div className="vub-c-account-password-reset-view__form">
                <FormContainer
                    initialState={{
                        email: '',
                        errors: {},
                    }}
                    getFormValues={getFormValues}
                    render={
                        (
                            {email, errors},
                            handleValues,
                        ) => (
                            <Fragment>
                                <Input
                                    type="email"
                                    name="email"
                                    className="vub-c-input-container--auth"
                                    placeholder="Email"
                                    value={email}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={[required, isEmailValid]}
                                    error={errors['email']}
                                />
                                {serverErrors
                                && serverErrors.length > 0
                                && <ServerValidationErrors errors={serverErrors} />
                                }
                                <div className="vub-c-account-password-reset-view__buttons">
                                    <FormButton
                                        type="submit"
                                        isDisabled={
                                            isSessionChangeInProgress
                                            || !email
                                            || Object.values(errors).some(el => el.isInvalid)
                                        }
                                    >
                                        {isSessionChangeInProgress ? <SpinnerStandard /> : 'Submit'}
                                    </FormButton>
                                </div>
                            </Fragment>
                        )
                    }
                />
            </div>
            <div className="vub-c-account-password-reset-view__link-container">
                <label
                    className="vub-c-account-password-reset-view__link"
                    onClick={signInUser}
                >
                    Go back to Sign In.
                </label>
                <label
                    className="vub-c-account-password-reset-view__link"
                    onClick={signUpUser}
                >
                    {`Don't have an account yet? Sign up now!`}
                </label>
            </div>
        </div>
    );
};

AccountRequestPasswordResetView.propTypes = {
    signInUser: PropTypes.func.isRequired,
    signUpUser: PropTypes.func.isRequired,
    requestPasswordReset: PropTypes.func.isRequired,
    isSessionChangeInProgress: PropTypes.bool,
};

AccountRequestPasswordResetView.defaultProps = {
    isSessionChangeInProgress: false,
};

export {AccountRequestPasswordResetView};

const mapStateToProps = state => ({
    isSessionChangeInProgress: state.auth.isSessionChangeInProgress,
});

const mapDispatchToProps = dispatch => ({
    signInUser: payload => dispatch(authActions.signInUser(payload)),
    signUpUser: payload => dispatch(authActions.signUpUser(payload)),
    requestPasswordReset: payload => dispatch(authActions.requestPasswordReset(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountRequestPasswordResetView);
