import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetails.scss';

const MediaItemDetails = ({mediaItem}) => (
    <div className="vub-c-media-item-details">
        <div className="vub-c-media-item-details__title">
            {mediaItem.title}
        </div>
        <div className="vub-c-media-item-details__synopsis">
            {mediaItem.synopsis}
        </div>
    </div>
);

MediaItemDetails.propTypes = {
    mediaItem: PropTypes.object.isRequired,
};

export default MediaItemDetails;
