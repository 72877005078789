import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import storefrontRowTypes from '../../navigation/storefrontRowTypes';
import ContentRow from '../../layout/ContentRow';
import ContentRowHeader from '../../layout/ContentRowHeader';
import MediaCarousel from '../../ui-elements/media-carousel/MediaCarousel';
import MediaCarouselSlidePortrait from '../../ui-elements/media-carousel-slide/MediaCarouselSlidePortrait';
import MediaCarouselSlidePortraitSeeMore
    from '../../ui-elements/media-carousel-slide/MediaCarouselSlidePortraitSeeMore';
import MediaCarouselSlideLandscape from '../../ui-elements/media-carousel-slide/MediaCarouselSlideLandscape';
import MediaCarouselSlideDouble from '../../ui-elements/media-carousel-slide/MediaCarouselSlideDouble';
import ProviderCornerRibbon from '../../ui-elements/provider-corner-ribbon/ProviderCornerRibbon';

const getLinkTo = storefrontRow => {
    const {value} = storefrontRow;
    let linkTo = {pathname: ''};

    if (storefrontRow.type === storefrontRowTypes.MEDIA_GENRE) {
        linkTo = resolveRoute(routePaths.MEDIA_GENRE, {mediaGenreId: value});
    }

    if (storefrontRow.type === storefrontRowTypes.MEDIA_COLLECTION) {
        linkTo = resolveRoute(routePaths.MEDIA_COLLECTION, {mediaCollectionId: value});
    }

    return linkTo;
};

const getMediaCarouselSlide = slideTemplate => {
    let MediaCarouselSlide;
    switch (slideTemplate) {
        case 'MediaCarouselSlidePortrait':
            MediaCarouselSlide = MediaCarouselSlidePortrait;
            break;

        case 'MediaCarouselSlideLandscape':
            MediaCarouselSlide = MediaCarouselSlideLandscape;
            break;

        case 'MediaCarouselSlideDouble':
            MediaCarouselSlide = MediaCarouselSlideDouble;
            break;

        default:
            MediaCarouselSlide = MediaCarouselSlidePortrait;
    }

    return MediaCarouselSlide;
};

// TODO needs refactoring
const getMediaSlides = (storefrontRow, resolveMediaItemRoute, providers) => {
    const {data: media} = storefrontRow;
    const {displaySeeMoreSlide} = storefrontRow.templateConfiguration;
    let {
        mediaLimit,
        slideTemplate,
        featuredSlideTemplate,
    } = storefrontRow.templateConfiguration;
    const linkTo = getLinkTo(storefrontRow);

    if (!mediaLimit) mediaLimit = 20;
    if (!slideTemplate) slideTemplate = 'MediaCarouselSlidePortrait';

    const MediaCarouselSlide = getMediaCarouselSlide(slideTemplate);
    const FeatureMediaCarouselSlideTemplate = getMediaCarouselSlide(featuredSlideTemplate);

    if (slideTemplate === 'MediaCarouselSlideDouble') {
        return media.size > 0
            ? media
                .toArray()
                .reduce((mediaItemPairList, mediaItem) => {
                    let mediaItemPair = mediaItemPairList.pop();

                    if (!!mediaItemPair.mediaItemA && !!mediaItemPair.mediaItemB) {
                        mediaItemPairList.push(mediaItemPair);
                        mediaItemPair = {mediaItemA: null, mediaItemB: null};
                    }

                    if (!mediaItemPair.mediaItemA) {
                        mediaItemPair.mediaItemA = mediaItem;
                    } else if (!mediaItemPair.mediaItemB) {
                        mediaItemPair.mediaItemB = mediaItem;
                    }

                    mediaItemPairList.push(mediaItemPair);
                    return mediaItemPairList;
                }, [{mediaItemA: null, mediaItemB: null}])
                .map(mediaItemPair => {
                    const mediaItemProviderA = mediaItemPair.mediaItemA
                        && typeof mediaItemPair.mediaItemA.offers[0] !== 'undefined'
                        && providers
                        ? providers.get(mediaItemPair.mediaItemA.offers[0].provider) : null;

                    const mediaItemProviderB = mediaItemPair.mediaItemB
                        && typeof mediaItemPair.mediaItemB.offers[0] !== 'undefined'
                        && providers
                        ? providers.get(mediaItemPair.mediaItemB.offers[0].provider) : null;

                    return (
                        <MediaCarouselSlide
                            key={mediaItemPair.mediaItemA.id}
                            mediaItemA={mediaItemPair.mediaItemA}
                            mediaItemProviderA={mediaItemProviderA}
                            linkMediaItemATo={resolveMediaItemRoute({
                                mediaItemId: mediaItemPair.mediaItemA ? mediaItemPair.mediaItemA.id : null,
                            })}
                            mediaItemB={mediaItemPair.mediaItemB}
                            mediaItemProviderB={mediaItemProviderB}
                            linkMediaItemBTo={resolveMediaItemRoute({
                                mediaItemId: mediaItemPair.mediaItemB ? mediaItemPair.mediaItemB.id : null,
                            })}
                        />
                    );
                })
            : null;
    }

    return media
        .slice(0, mediaLimit)
        .toArray()
        .map((mediaItem, index) => {
            const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
                ? providers.get(mediaItem.offers[0].provider) : null;

            if (featuredSlideTemplate && index === 0) {
                return (
                    <FeatureMediaCarouselSlideTemplate
                        key={mediaItem.id}
                        mediaItem={mediaItem}
                        className="global!vub-is-featured"
                        linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                    >
                        <ProviderCornerRibbon
                            provider={provider}
                            className={featuredSlideTemplate === 'MediaCarouselSlideLandscape'
                                ? 'vub-c-catalogue-product-corner-ribbon--large' : ''}
                        />
                    </FeatureMediaCarouselSlideTemplate>
                );
            }

            if (displaySeeMoreSlide && index === mediaLimit - 1) {
                return (
                    <MediaCarouselSlidePortraitSeeMore key="see more" linkTo={linkTo} />
                );
            }

            return (
                <MediaCarouselSlide
                    key={mediaItem.id}
                    mediaItem={mediaItem}
                    linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                >
                    <ProviderCornerRibbon
                        provider={provider}
                        className={['MediaCarouselSlideDouble', 'MediaCarouselSlideLandscape']
                            .includes(slideTemplate) ? 'vub-c-catalogue-product-corner-ribbon--large' : ''}
                    />
                </MediaCarouselSlide>
            );
        });
};

const MediaCarouselRow = ({storefrontRow, resolveMediaItemRoute, providers}) => {
    if (!storefrontRow) return null;

    const {data: media} = storefrontRow;
    const {title, mediaLimitForSubtitle} = storefrontRow.templateConfiguration;
    const linkTo = getLinkTo(storefrontRow);

    const mediaSlides = getMediaSlides(storefrontRow, resolveMediaItemRoute, providers);
    return media && media.size > 0 ? (
        <ContentRow>
            <ContentRowHeader
                title={title}
                subtitle={media.size > mediaLimitForSubtitle ? `(${media.size} ${pluralize('title', media.size)})` : null}
                linkTo={linkTo}
            />
            <MediaCarousel>
                {mediaSlides}
            </MediaCarousel>
        </ContentRow>
    ) : null;
};

MediaCarouselRow.propTypes = {
    storefrontRow: PropTypes.object,
    resolveMediaItemRoute: PropTypes.func.isRequired,
    providers: PropTypes.object,
};

MediaCarouselRow.defaultProps = {
    storefrontRow: null,
    providers: null,
};

export default MediaCarouselRow;
