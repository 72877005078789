import {Record} from 'immutable';

/**
 * StorefrontRowDTO definition
 *
 * @typedef {Object} StorefrontRowDTO
 * @property {?string} id
 * @property {?boolean} display
 * @property {?number} position
 * @property {?string} type
 * @property {?string|number|Array<string|number>} value
 * @property {?string} template
 * @property {?Object} templateConfiguration
 */

/**
 * StorefrontRow definition
 *
 * @typedef {Immutable.Record} StorefrontRow
 * @property {?string} id
 * @property {?boolean} display
 * @property {?number} position
 * @property {?string} type
 * @property {?string|number|Array<string|number>} value
 * @property {?string} template
 * @property {?Object} templateConfiguration
 * @property {*} data
 */

/**
 * StorefrontRow record
 *
 * @type {StorefrontRow}
 */
class StorefrontRow extends Record({
    id: null,
    display: false,
    position: 0,
    type: null,
    value: null,
    template: null,
    templateConfiguration: {},
    data: null,
}) {}

export default StorefrontRow;
