import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import './VolumeSlider.scss';

const VolumeSlider = ({setVolume, volume}) => (
    <div className="vub-c-volume-slider">
        <Slider
            max={100}
            value={volume}
            onChange={setVolume}
        />
    </div>
);

VolumeSlider.propTypes = {
    setVolume: PropTypes.func,
    volume: PropTypes.node,
};

VolumeSlider.defaultProps = {
    setVolume: null,
    volume: '',
};

export default VolumeSlider;

