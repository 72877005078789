import React from 'react';
import PropTypes from 'prop-types';
import './HoverDialogueContainer.scss';
import HoverComponent from './HoverComponent';

const mediaItem = {
    title: 'jurasic world: fallen kingdom',
    year: 2018,
    rating: 3.5,
    imdbRating: 6.9,
    duration: 128,
    translations: ['de', 'en', 'fr'],
    certificate: 'Certificate 12',
    description: 'Dolor culpa quos perspiciatis cupiditate nemo. Labore animi blanditiis amet soluta rerum? Asperiores vitae libero praesentium repellendus labore porro esse illo iure?',
};

const HoverDialogueContainer = ({title}) => (
    <div className="vub-c-hover-dialogue-container">
        <div className="vub-c-hover-dialogue-container__title">
            {title}
        </div>
        <div className="vub-c-hover-dialogue-container__content">
            <div className="vub-c-hover-dialogue-container__container">
                <div className="vub-c-hover-dialogue-container__container-name"> two buttons
                </div>
                <HoverComponent
                    mediaItem={mediaItem}
                    ctaButtons={[
                        {
                            type: 'Rent',
                            price: 2.60,
                        },
                        {
                            type: 'Buy',
                            price: 12.80,
                        },
                    ]}
                />
            </div>
            <div className="vub-c-hover-dialogue-container__container">
                <div className="vub-c-hover-dialogue-container__container-name"> single button
                </div>
                <HoverComponent
                    mediaItem={mediaItem}
                    ctaButtons={[{
                        type: 'Rent',
                        price: 12.80,
                    }]}
                />
            </div>
        </div>
    </div>
);

HoverDialogueContainer.propTypes = {
    title: PropTypes.string,
};

HoverDialogueContainer.defaultProps = {
    title: 'hover dialogue',
};

export default HoverDialogueContainer;
