import React from 'react';
import PropTypes from 'prop-types';
import './PriceInfo.scss';

const PriceInfo = ({price}) => (
    <div className="vub-c-price-info">
        <span>Total: </span>
        <span className="vub-c-price-info__price">{price}</span>
    </div>
);

PriceInfo.propTypes = {
    price: PropTypes.string,
};

PriceInfo.defaultProps = {
    price: null,
};

export default PriceInfo;
