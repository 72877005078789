import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import './MediaGalleryListEntryImage.scss';

const MediaGalleryListEntryImage = (
    {mediaItem, onMouseEnter, onMouseOut, popoverCallerRef, linkTo}
) => {
    const imageUrl = mediaItem && mediaItem.imageThumbnail ? `${mediaItem.imageThumbnail}&q=70&w=300` : null;

    return (
        mediaItem ? (
            <div
                className="vub-c-media-gallery-list-entry__link-container"
                onMouseEnter={onMouseEnter}
                onMouseOut={onMouseOut}
                ref={popoverCallerRef}
            >
                <Link
                    className="vub-c-media-gallery-list-entry__link"
                    to={linkTo}
                >
                    <img
                        className="vub-c-media-gallery-list-entry__image"
                        src={imageUrl}
                        alt="box cover"
                    />
                </Link>
            </div>
        ) : null
    );
};

MediaGalleryListEntryImage.propTypes = {
    mediaItem: PropTypes.object.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseOut: PropTypes.func,
    popoverCallerRef: PropTypes.object,
    linkTo: PropTypes.object,
};

MediaGalleryListEntryImage.defaultProps = {
    onMouseEnter: () => {},
    onMouseOut: () => {},
    popoverCallerRef: null,
    linkTo: null,
};

export default MediaGalleryListEntryImage;
