import React from 'react';
import PropTypes from 'prop-types';
import FaqPage from './content/FaqPage';
import ContactUsPage from './content/ContactUsPage';
import CookiesPage from './content/CookiesPage';
import PrivacyPage from './content/PrivacyPage';
import TermsOfUsePage from './content/TermsOfUsePage';
import AboutUsPage from './content/AboutUsPage';
import NotFoundPage from './content/NotFoundPage';
import GeoFenceInfoPage from './content/GeoFenceInfoPage';
import GDPRPage from './content/GDPRPage';
import './StaticPageView.scss';

const StaticPageView = props => {
    const {match} = props;
    const pages = {
        'faq': (
            <FaqPage />
        ),
        'contact-us': (
            <ContactUsPage />
        ),
        'cookies': (
            <CookiesPage />
        ),
        'privacy': (
            <PrivacyPage />
        ),
        'terms-of-use': (
            <TermsOfUsePage />
        ),
        'about-us': (
            <AboutUsPage />
        ),
        'not-found': (
            <NotFoundPage />
        ),
        'geo-fence-info': (
            <GeoFenceInfoPage />
        ),
        'gdpr-info': (
            <GDPRPage />
        ),
    };
    return (
        <div className="vub-c-static-page">
            {pages[match.params.pageId] || <NotFoundPage />}
        </div>
    );
};


StaticPageView.propTypes = {
    match: PropTypes.object,
};

StaticPageView.defaultProps = {
    match: {},
};

export default StaticPageView;
