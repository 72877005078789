import React from 'react';
import PropTypes from 'prop-types';
import DefaultBannerSlider from '../../ui-elements/promotion-banner/types/DefaultBannerSlider';
import PromotionBannerItem from '../../ui-elements/promotion-banner/PromotionBannerItem';
import PromotionBannerPagination from '../../ui-elements/promotion-banner/PromotionBannerPagination';

const MediaHeroBannerStandardRow = ({storefrontRow, resolveMediaItemRoute}) => {
    if (!storefrontRow) return null;

    const {data: media} = storefrontRow;
    const {sliderSpeed} = storefrontRow.templateConfiguration;

    return media && media.size > 0 ? (
        <DefaultBannerSlider
            sliderSpeed={sliderSpeed}
        >
            {media
                .toArray()
                .map(mediaItem => (
                    <PromotionBannerItem
                        key={mediaItem.id}
                        mediaItem={mediaItem}
                        resolveMediaItemRoute={resolveMediaItemRoute}
                    />
                ))}
            <PromotionBannerPagination key="pagination" />
        </DefaultBannerSlider>
    ) : null;
};

MediaHeroBannerStandardRow.propTypes = {
    storefrontRow: PropTypes.object,
    resolveMediaItemRoute: PropTypes.func.isRequired,
};

MediaHeroBannerStandardRow.defaultProps = {
    storefrontRow: null,
};

export default MediaHeroBannerStandardRow;
