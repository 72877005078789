import {call, put, all, select} from 'redux-saga/effects';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import * as navigationSelectors from '../navigation/navigationSelectors';
import * as navigationSagas from '../navigation/navigationSagas';
import storefrontRowTypes from '../navigation/storefrontRowTypes';
import * as mediaActionTypes from '../media/mediaActionTypes';
import * as mediaSelectors from '../media/mediaSelectors';

/**
 * Loads media for mediaGenre row
 *
 * @param {StorefrontRow} storefrontRow
 */
const loadMediaForMediaGenreRow = function* loadMediaForMediaGenreRow(storefrontRow) {
    const {id: rowId, value: mediaGenreId} = storefrontRow;
    const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_GENRE['${rowId}']['${mediaGenreId}']`;

    const mediaBuckets = yield select(mediaSelectors.getMediaBuckets);
    if (mediaBuckets.get(mediaBucketKey)) {
        yield put({type: mediaActionTypes.MEDIA_BUCKET_IN_STORE, payload: {mediaBucketKey}});
        return;
    }

    yield put({
        type: mediaActionTypes.FETCH_MEDIA_GENRE_MEDIA,
        payload: {
            requestParams: {
                mediaGenreId,
                where: [
                    `classification:in:${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`,
                ],
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};

/**
 * Load media for mediaCollection row
 *
 * @param {StorefrontRow} storefrontRow
 */
const loadMediaForMediaCollectionRow = function* loadMediaForMediaCollectionRow(storefrontRow) {
    const {id: rowId, value: mediaCollectionId} = storefrontRow;
    const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_COLLECTION['${rowId}']['${mediaCollectionId}']`;

    const mediaBuckets = yield select(mediaSelectors.getMediaBuckets);
    if (mediaBuckets.get(mediaBucketKey)) {
        yield put({type: mediaActionTypes.MEDIA_BUCKET_IN_STORE, payload: {mediaBucketKey}});
        return;
    }

    yield put({
        type: mediaActionTypes.FETCH_MEDIA_COLLECTION_MEDIA,
        payload: {
            requestParams: {
                mediaCollectionId: mediaCollectionId !== 'all' ? mediaCollectionId : null,
                where: [
                    `classification:in:${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`,
                ],
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};

/**
 * Load media for mediaSet row
 *
 * @param {StorefrontRow} storefrontRow
 */
const loadMediaForMediaSetRow = function* loadMediaForMediaSetRow(storefrontRow) {
    const {id: rowId, value: mediaIds} = storefrontRow;
    const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_SET['${rowId}']`;

    const mediaBuckets = yield select(mediaSelectors.getMediaBuckets);
    if (mediaBuckets.get(mediaBucketKey)) {
        yield put({type: mediaActionTypes.MEDIA_BUCKET_IN_STORE, payload: {mediaBucketKey}});
        return;
    }

    yield put({
        type: mediaActionTypes.FETCH_MEDIA,
        payload: {
            requestParams: {
                mediaIds: mediaIds,
                where: [
                    `classification:in:${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`,
                ],
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};

/**
 * Load storefront view content
 */
export const loadStorefrontViewContent = function* loadStorefrontViewContent() {
    let storefrontRows = yield select(navigationSelectors.getStorefrontRows);
    if (storefrontRows.size === 0) {
        yield navigationSagas.fetchStorefrontRows();
        storefrontRows = yield select(navigationSelectors.getStorefrontRows);
    }

    yield all(storefrontRows.toArray().map(storefrontRow => {
        switch (storefrontRow.type) {
            case storefrontRowTypes.MEDIA_GENRE:
                return call(loadMediaForMediaGenreRow, storefrontRow);

            case storefrontRowTypes.MEDIA_COLLECTION:
                return call(loadMediaForMediaCollectionRow, storefrontRow);

            case storefrontRowTypes.MEDIA_SET:
                return call(loadMediaForMediaSetRow, storefrontRow);

            case storefrontRowTypes.CUSTOM_DATA:
                return storefrontRow;

            default:
                return () => null;
        }
    }));
};
