import {put, select} from 'redux-saga/effects';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import * as actionTypes from '../media/mediaActionTypes';
import * as selectors from '../media/mediaSelectors';

/**
 * Load media for media item view
 *
 * @param {FluxStandardAction} action
 */
export const loadMediaForMediaItemView = function* loadMediaForMediaItemView({payload}) {
    const {match} = payload;
    const {mediaItemId} = match.params;
    const mediaBucketKey = `MEDIA_ITEM_VIEW['${mediaItemId}']`;

    const mediaBuckets = yield select(selectors.getMediaBuckets);
    if (mediaBuckets.get(mediaBucketKey)) {
        yield put({type: actionTypes.MEDIA_BUCKET_IN_STORE, payload: {mediaBucketKey}});
        return;
    }

    yield put({
        type: actionTypes.FETCH_MEDIA_ITEM,
        payload: {
            requestParams: {mediaItemId},
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};

/**
 * Load recommendation media for media item view
 *
 * @param {FluxStandardAction} action
 */
export const loadMediaForMediaItemRecommendationsView = function* loadMediaForMediaItemRecommendationsView({payload}) {
    const {match} = payload;
    const {mediaItemId} = match.params;
    const mediaBucketKey = `MEDIA_ITEM_RECOMMENDATIONS_VIEW['${mediaItemId}']`;

    const mediaBuckets = yield select(selectors.getMediaBuckets);
    if (mediaBuckets.get(mediaBucketKey)) {
        yield put({type: actionTypes.MEDIA_BUCKET_IN_STORE, payload: {mediaBucketKey}});
        return;
    }

    yield put({
        type: actionTypes.FETCH_RECOMMENDATIONS_MEDIA,
        payload: {
            requestParams: {
                mediaItemId,
                where: [`classification:in:${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`],
                limit: 6,
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};
