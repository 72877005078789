/**
 * Progressive web app service worker
 */

/* eslint-disable no-console, func-style */

const isLocalhost = Boolean(
    window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export default function register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
        if (publicUrl.origin !== window.location.origin) return;

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                checkValidServiceWorker(swUrl);
                navigator.serviceWorker.ready.then(() => {
                    console.log('[PWA] web app served cache-first by a service worker');
                });
            } else {
                registerValidSW(swUrl);
            }
        });
    }
}

function registerValidSW(swUrl) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            console.log('[PWA] new content is available; please refresh');
                        } else {
                            console.log('[PWA] content is cached for offline use');
                        }
                    }
                };
            };
        })
        .catch(error => {
            console.error('[PWA] error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl) {
    fetch(swUrl)
        .then(response => {
            if (response.status === 404 || response.headers.get('content-type').indexOf('javascript') === -1) {
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => { window.location.reload(); });
                });
            } else {
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            console.log('[PWA] no internet connection found; app is running in offline mode');
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}
