import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import {combineClassNames} from '../utils';

const SwitchRole = BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'SwitchRole'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
            onClick: PropTypes.func,
            isToggleSwitch: PropTypes.bool,
            isSwitchedOn: PropTypes.bool,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            onClick: null,
            isToggleSwitch: false,
            isSwitchedOn: false,
        };

        state = {
            isSwitchedOn: this.props.isSwitchedOn,
            prevPropIsSwitchedOn: null, // eslint-disable-line react/no-unused-state
        };

        static getDerivedStateFromProps(props, state) {
            if (props.isSwitchedOn !== state.prevPropIsSwitchedOn) {
                return {
                    isSwitchedOn: props.isSwitchedOn,
                    prevPropIsSwitchedOn: props.isSwitchedOn,
                };
            }

            return null;
        }

        /**
         * On click
         *
         * @private
         */
        onClick = () => {
            if (this.props.isDisabled) return;

            if (!this.props.isToggleSwitch) {
                if (typeof this.props.onClick === 'function') {
                    this.props.onClick();
                }
                return;
            }

            this.setState(state => ({
                isSwitchedOn: !state.isSwitchedOn,
            }), () => {
                if (typeof this.props.onClick === 'function') {
                    this.props.onClick(this.state.isSwitchedOn);
                }
            });
        };

        render() {
            const props = {
                ...this.props,
                onClick: this.onClick,
                isSwitchedOn: this.state.isSwitchedOn,
                className: combineClassNames(
                    this.props.className,
                    this.state.isSwitchedOn && 'cr-is-switched-on',
                ),
            };

            return (
                <BaseComponent {...props} />
            );
        }
    }

    return ComposedComponent;
};

export default SwitchRole;
