import React from 'react';
import {
    withLayoutControls,
    withLayoutControlsPropTypes,
    withLayoutControlsDefaultProps,
} from '../../layout/withLayoutControls';
import './ContentTopStripe.scss';

const ContentTopStripe = props => {
    const {children, isHeaderReduced} = props;
    return (
        <div className={`vub-c-content-top-stripe ${isHeaderReduced ? 'vub-c-content-top-stripe--reduced' : ''}`}>
            <div className="vub-c-content-top-stripe__content">
                {children}
            </div>
        </div>
    );
};

ContentTopStripe.propTypes = {
    ...withLayoutControlsPropTypes,
};

ContentTopStripe.defaultProps = {
    ...withLayoutControlsDefaultProps,
};

export default withLayoutControls(ContentTopStripe);
