import {put} from 'redux-saga/effects';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import * as actionTypes from '../media/mediaActionTypes';

export const loadMediaForMediaClassificationView = function* loadMediaForMediaClassificationView({payload}) {
    const {classification} = payload;
    const mediaBucketKey = `MEDIA_CLASSIFICATION_VIEW['${classification}']`;

    const classificationQuery = [
        !classification && `${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`,
        classification === 'movies' && mediaItemClassification.MOVIE,
        classification === 'tv-shows' && mediaItemClassification.TV_SHOW,
    ].filter(Boolean);

    yield put({
        type: actionTypes.FETCH_MEDIA_COLLECTION_MEDIA,
        payload: {
            requestParams: {
                mediaCollectionId: null,
                where: classificationQuery.length > 0
                    ? [`classification:in:${classificationQuery.join(',')}`] : [],
                sort: [
                    'titleSort:ASC',
                ],
                limit: 24,
                facets: ['mediaGenreId', 'offers/provider'],
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};
