import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../authActionTypes';
import {authService} from '../../services';

/**
 * Authenticate user
 */
const authenticateUser = function* authenticateUser(params) {
    try {
        yield put({type: actionTypes.AUTHENTICATE_USER_REQUEST, payload: params});

        const response = yield call(authService.authenticateUser, params);
        yield put({
            type: actionTypes.AUTHENTICATE_USER_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.AUTHENTICATE_USER_ERROR, error: true, payload: error});
    }
};

export default authenticateUser;
