import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetailsPrimaryInfo.scss';

const MediaItemDetailsPrimaryInfo = ({mediaItem}) => (
    <div className="vub-c-media-item-details-primary-info">
        <p className="vub-c-media-item-details-primary-info__segment">
            <span className="vub-c-media-item-details-primary-info__title">Certificate:</span>
            {mediaItem.contentAdvisoryRating}
        </p>
        <p className="vub-c-media-item-details-primary-info__segment">
            {mediaItem.year}
        </p>
        <p className="vub-c-media-item-details-primary-info__segment">
            {mediaItem.durationMinutes}
        </p>
    </div>
);

MediaItemDetailsPrimaryInfo.propTypes = {
    mediaItem: PropTypes.object.isRequired,
};

export default MediaItemDetailsPrimaryInfo;
