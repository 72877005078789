import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../liveStreamingActionTypes';
import {liveStreamingService} from '../../services';

/**
 * Fetch EPG data saga
 */
const fetchEPGData = function* fetchEPGData(params, meta) {
    try {
        yield put({
            type: actionTypes.FETCH_EPG_DATA_REQUEST,
            payload: params,
            meta,
        });

        const response = yield call(liveStreamingService.getEPG, params);
        yield put({
            type: actionTypes.FETCH_EPG_DATA_SUCCESS,
            payload: {response},
            meta,
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_EPG_DATA_ERROR, error: true, payload: error, meta});
    }
};

export default fetchEPGData;
