import Immutable from 'immutable';

/**
 * BasketItem definition
 *
 * @typedef {Immutable.Record} BasketItem
 * @property {?number} id
 * @property {?number} offerId
 * @property {?string} mediaItemId
 * @property {?number} price
 * @property {?string} message
 * @property {boolean} updatePending
 */

/**
 * BasketItem record
 *
 * @type {BasketItem}
 */
class BasketItem extends Immutable.Record({
    id: null,
    offerId: null,
    mediaItemId: null,
    price: null,
    message: null,
    updatePending: false,
}) {}

export default BasketItem;
