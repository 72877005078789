import {createSelector} from 'reselect';
import Immutable from 'immutable';
import * as mediaSelectors from '../media/mediaSelectors';

export const getCurrentEntitlements = state => state.mediaEntitlement.currentEntitlements;

export const getCurrentEntitlementsUpdateTime = state => state.mediaEntitlement.currentEntitlementsUpdateTime;

export const getMediaBucketKey = state => {
    const userAccount = state.auth.userAccount;
    const currentEntitlementsUpdateTime = state.mediaEntitlement.currentEntitlementsUpdateTime;
    return `ACCOUNT_LIBRARY_VIEW['${userAccount
        ? userAccount.id : 'null'}_${currentEntitlementsUpdateTime
        ? currentEntitlementsUpdateTime.unix() : 'null'}']`;
};

export const createAccountLibraryViewBucketSelector = () => createSelector(
    [
        mediaSelectors.getMediaItems,
        mediaSelectors.getMediaBuckets,
        getMediaBucketKey,
    ],
    (mediaItems, mediaBuckets, mediaBucketKey) => {
        const mediaBucket = mediaBuckets.get(mediaBucketKey);
        if (!mediaBucket) return mediaBucket;

        let media = Immutable.OrderedMap();
        mediaBucket.mediaIds.forEach(mediaItemId => {
            media = media.set(mediaItemId, mediaItems.get(mediaItemId));
        });

        return mediaBucket.merge({media: media});
    },
);
