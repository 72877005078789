import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../mediaEntitlementActionTypes';
import {mediaEntitlementService} from '../../services';

/**
 * Fetch current entitlements
 */
const fetchCurrentEntitlements = function* fetchCurrentEntitlements() {
    try {
        yield put({
            type: actionTypes.FETCH_CURRENT_ENTITLEMENTS_REQUEST,
            payload: {},
        });

        const response = yield call(mediaEntitlementService.getCurrentEntitlements);
        yield put({
            type: actionTypes.FETCH_CURRENT_ENTITLEMENTS_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_CURRENT_ENTITLEMENTS_ERROR, error: true, payload: error});
    }
};

export default fetchCurrentEntitlements;
