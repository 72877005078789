import * as actionTypes from './mediaActionTypes';

/**
 * Fetch mediaGenre media
 */
export const fetchMediaGenreMedia = (payload = {}) => ({type: actionTypes.FETCH_MEDIA_GENRE_MEDIA, payload});

/**
 * Fetch mediaCollection media
 */
export const fetchMediaCollectionMedia = (payload = {}) => ({type: actionTypes.FETCH_MEDIA_COLLECTION_MEDIA, payload});

/**
 * Search media
 */
export const searchMedia = (payload = {}) => ({type: actionTypes.SEARCH_MEDIA, payload});
