import React from 'react';
import PropTypes from 'prop-types';
import './DisablePlaybackContent.scss';
import StyledButton from '../../styled-button/StyledButton';
import withPopUp from '../withPopUp';
import LayoutBox from '../../layout-box/LayoutBox';
import LayoutBoxRow from '../../layout-box/LayoutBoxRow';

const title = 'please note';
const description = 'Playback has been disabled in this service.';

const DisablePlaybackContent = ({closePopUp}) => (
    <LayoutBox>
        <div className="vub-c-disable-playback-content">
            <LayoutBoxRow title={title}>
                <div className="vub-c-disable-playback-content__close" onClick={closePopUp} />
            </LayoutBoxRow>
            <LayoutBoxRow>
                <div className="vub-c-disable-playback-content__description">
                    {description}
                </div>
            </LayoutBoxRow>
            <LayoutBoxRow>
                <StyledButton
                    onClick={closePopUp}
                >
                    Ok
                </StyledButton>
            </LayoutBoxRow>
        </div>
    </LayoutBox>
);

DisablePlaybackContent.propTypes = {
    closePopUp: PropTypes.func,
};

DisablePlaybackContent.defaultProps = {
    closePopUp: null,
};

export default withPopUp(DisablePlaybackContent);
