import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../storeConfigurationActionTypes';
import {wlsConfigurationService} from '../../services';

/**
 * Fetch provider
 */
const fetchProviders = function* fetchProviders() {
    try {
        yield put({
            type: actionTypes.FETCH_PROVIDERS_REQUEST,
            payload: {},
        });

        const response = yield call(wlsConfigurationService.getProviders);
        yield put({
            type: actionTypes.FETCH_PROVIDERS_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_PROVIDERS_ERROR, error: true, payload: error});
    }
};

export default fetchProviders;
