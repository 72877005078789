import React, {useContext} from 'react';
import './EPGMarker.scss';
import EPGContext from './EPGContext';

const EPGMarker = () => {
    const {markerOffset, isMarkerVisible, epgChannels, currentTime} = useContext(EPGContext);

    const markerStyle = {
        paddingLeft: `${markerOffset}%`,
        height: `calc(${epgChannels.toArray().length} * 85px)`,
    };

    return isMarkerVisible ? (
        <div className="vub-c-epg-marker">
            <div className="vub-c-epg-marker__flag" style={markerStyle}>
                <div className="vub-c-epg-marker__label">
                    {currentTime.format('HH:mm')}
                </div>
            </div>
        </div>
    ) : null;
};

export default EPGMarker;
