import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    withMediaItemPopover,
    withMediaItemPopoverPropTypes,
    withMediaItemPopoverDefaultProps,
} from '../media-item-popover/withMediaItemPopover';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../ui-kit/withStyles';
import styles from './MediaCarouselSlideDouble.module.scss';
import {CarouselSlide} from '../../ui-kit';
import ProviderCornerRibbon from '../provider-corner-ribbon/ProviderCornerRibbon';

const SingleMediaItemTile = withMediaItemPopover(withStyles(styles)(props => {
    const {mediaItem, linkTo, children, cx} = props;
    const {popoverCallerRef, toggleMediaItemPopover} = props;

    const imageUrl = mediaItem && mediaItem.imageThumbnail ? `${mediaItem.imageThumbnail}&q=70&w=300` : null;
    const onMouseEnter = () => toggleMediaItemPopover(true, mediaItem);
    const onMouseOut = () => toggleMediaItemPopover(false);

    return (
        <div
            className={cx('vub-c-media-carousel-slide-double__media-item')}
            onMouseEnter={onMouseEnter}
            onMouseOut={onMouseOut}
            ref={popoverCallerRef}
        >
            <div className={cx('vub-c-media-carousel-slide-double__image-placeholder')}>
                {mediaItem ? (
                    <Link
                        className="vub-c-media-gallery-tile-portrait__link"
                        to={linkTo}
                    >
                        <img
                            className={cx('vub-c-media-carousel-slide-double__image')}
                            src={imageUrl}
                            alt="thumbnail"
                        />
                        {children}
                    </Link>
                ) : null}
            </div>
        </div>
    );
}));

SingleMediaItemTile.propTypes = {
    ...withStylesPropTypes,
    ...withMediaItemPopoverPropTypes,
    mediaItem: PropTypes.object,
    linkTo: PropTypes.object,
};

SingleMediaItemTile.defaultProps = {
    ...withStylesDefaultProps,
    ...withMediaItemPopoverDefaultProps,
    mediaItem: null,
    linkTo: {pathname: ''},
};

const MediaCarouselSlideDouble = ({
    mediaItemA,
    linkMediaItemATo,
    mediaItemProviderA,
    mediaItemB,
    linkMediaItemBTo,
    mediaItemProviderB,
    className,
    cx,
    createClassNameResolver,
    ...restProps
}) => (
    <CarouselSlide
        {...restProps}
        className={cx(className)}
        classNameResolver={createClassNameResolver('vub-c-media-carousel-slide-double')}
    >
        <div className={cx('vub-c-media-carousel-slide-double__content')}>
            <SingleMediaItemTile
                mediaItem={mediaItemA}
                linkTo={linkMediaItemATo}
            >
                <ProviderCornerRibbon
                    provider={mediaItemProviderA}
                    className="vub-c-provider-corner-ribbon--large"
                />
            </SingleMediaItemTile>
            <SingleMediaItemTile
                mediaItem={mediaItemB}
                linkTo={linkMediaItemBTo}
            >
                <ProviderCornerRibbon
                    provider={mediaItemProviderB}
                    className="vub-c-provider-corner-ribbon--large"
                />
            </SingleMediaItemTile>
        </div>
    </CarouselSlide>
);

MediaCarouselSlideDouble.propTypes = {
    ...withStylesPropTypes,
    mediaItemA: PropTypes.object,
    linkMediaItemATo: PropTypes.object,
    mediaItemProviderA: PropTypes.object,
    mediaItemB: PropTypes.object,
    linkMediaItemBTo: PropTypes.object,
    mediaItemProviderB: PropTypes.object,
};

MediaCarouselSlideDouble.defaultProps = {
    ...withStylesDefaultProps,
    mediaItemA: null,
    linkMediaItemATo: {pathname: ''},
    mediaItemProviderA: null,
    mediaItemB: null,
    linkMediaItemBTo: {pathname: ''},
    mediaItemProviderB: null,
};

export default withStyles(styles)(MediaCarouselSlideDouble);
