import React from 'react';
import PropTypes from 'prop-types';
import './PromotionBannerPagination.scss';
import PromotionBannerPaginationBullet from './PromotionBannerPaginationBullet';

/**
 * PromotionBannerPagination Component
 */
const PromotionBannerPagination = ({
    mediaItems,
    onClick, onMouseOver,
    onMouseOut,
    activeSlideIndex,
    promotionItemsLength,
}) => (
    <div
        className="vub-c-promotion-banner-pagination"
        onMouseOver={() => onMouseOver(true)}
        onMouseOut={() => onMouseOut(false)}
    >
        {[...Array(promotionItemsLength)].map((item, index) => (
            <PromotionBannerPaginationBullet
                key={index}
                isActive={activeSlideIndex === index}
                onClick={onClick}
                index={index}
            />
        ))}
    </div>
);

PromotionBannerPagination.propTypes = {
    mediaItems: PropTypes.array,
    activeSlideIndex: PropTypes.number,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    promotionItemsLength: PropTypes.number,
};

PromotionBannerPagination.defaultProps = {
    mediaItems: null,
    activeSlideIndex: null,
    onClick: null,
    onMouseOver: null,
    onMouseOut: null,
    promotionItemsLength: null,
};

export default PromotionBannerPagination;
