import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemInfo.scss';

const MediaItemInfo = ({mediaItem, basketItem, offer, children}) => {
    const {
        title,
        contentAdvisoryRating,
        year,
        durationMinutes,
        mediaGenreId,
    } = mediaItem;
    const {resolution} = offer || {};
    const formatTag = basketItem && resolution === '1080p' ? (
        <sup className="vub-c-media-item-info__format-hd">HD</sup>
    ) : null;

    return (
        <div className="vub-c-media-item-info">
            <div className="vub-c-media-item-info__title">
                {title}&nbsp;{formatTag}
            </div>
            <div className="vub-c-media-item-info__basic-information">
                <div className="vub-c-media-item-info__certificate">
                    Certificate: {contentAdvisoryRating}
                </div>
                <div className="vub-c-media-item-info__year">
                    {year}
                </div>
                <div className="vub-c-media-item-info__duration">
                    {durationMinutes}
                </div>
                <div className="vub-c-media-item-info__genre">
                    {mediaGenreId}
                </div>
            </div>
            {children}
        </div>
    );
};

MediaItemInfo.propTypes = {
    mediaItem: PropTypes.object,
    basketItem: PropTypes.object,
    offer: PropTypes.object,
};

MediaItemInfo.defaultProps = {
    mediaItem: {},
    basketItem: null,
    offer: {},
};

export default MediaItemInfo;

