import {put, fork, take} from 'redux-saga/effects';
import * as actionTypes from '../authActionTypes';
import updateUserAccount from './updateUserAccount';
import userSessionFlow from './userSessionFlow';

/**
 * Update user account flow
 */
export const updateUserAccountFlow = function* updateUserAccountFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.UPDATE_USER_ACCOUNT);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: true},
        });

        const {
            name,
            email,
            oldPassword,
            newPassword,
            hasOptedInToReceiveUpdates,
        } = payload;
        yield fork(updateUserAccount, {
            name,
            email,
            oldPassword,
            newPassword,
            hasOptedInToReceiveUpdates,
        });
        const updateUserAccountResultAction = yield take([
            actionTypes.UPDATE_USER_ACCOUNT_SUCCESS,
            actionTypes.UPDATE_USER_ACCOUNT_ERROR,
        ]);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });

        if (updateUserAccountResultAction.type === actionTypes.UPDATE_USER_ACCOUNT_ERROR) continue;

        const {response} = updateUserAccountResultAction.payload;
        const {userAccount: userAccountDTO, authToken} = response;
        yield put({
            type: actionTypes.STORE_USER_ACCOUNT,
            payload: {userAccountDTO},
        });

        if (authToken) {
            yield* userSessionFlow(authToken);
        }
    }
};

export default updateUserAccountFlow;
