import {fork, put, take, select} from 'redux-saga/effects';
import * as authSelectors from '../auth/authSelectors';
import * as actionTypes from './mediaEntitlementActionTypes';
import * as mediaEntitlementSelectors from './mediaEntitlementSelectors';
import fetchCurrentEntitlements from './sagas/fetchCurrentEntitlements';

/**
 * Load user's current entitlements
 */
export const loadCurrentEntitlements = function* loadCurrentEntitlements() {
    const isUserSignedIn = yield select(authSelectors.getUserSignedInStatus);
    if (!isUserSignedIn) return null;

    const currentEntitlements = yield select(mediaEntitlementSelectors.getCurrentEntitlements);
    if (currentEntitlements) return null;

    yield fork(fetchCurrentEntitlements);
    const resultAction = yield take([
        actionTypes.FETCH_CURRENT_ENTITLEMENTS_SUCCESS,
        actionTypes.FETCH_CURRENT_ENTITLEMENTS_ERROR,
    ]);

    if (!resultAction.error) {
        const {response: entitlementDTOs} = resultAction.payload;
        yield put({
            type: actionTypes.STORE_CURRENT_ENTITLEMENTS,
            payload: {entitlementDTOs},
        });
    }
};
