import React from 'react';

export default React.createContext({
    isHeaderInUse: false,
    isHeaderTransparent: false,
    isHeaderReduced: false,
    setHeaderInUseState: () => {},
    setHeaderTransparency: () => {},
    setHeaderReducedState: () => {},
});
