import React, {PureComponent, Children} from 'react';
import PropTypes from 'prop-types';
import './DefaultBannerSlider.scss';

class DefaultBannerSlider extends PureComponent {
    static propTypes = {
        sliderSpeed: PropTypes.node,
        children: PropTypes.array,
        isSwipeOn: PropTypes.bool,
        touchSize: PropTypes.number,
    };

    static defaultProps = {
        sliderSpeed: null,
        children: null,
        isSwipeOn: true,
        touchSize: 100,
    };

    constructor(props) {
        super(props);
        const {children} = this.props;
        const {toArray} = Children;
        this.viewingFrameRef = React.createRef();
        const promotionItemsLength = toArray(children).filter(item => item && item.key.indexOf('pagination') === -1).length;
        this.state = {
            activeSlideIndex: 0,
            promotionItemsLength,
        };
    }

    state = {
        isPaused: false,
        sliderTransitionIntervalId: 0,
        firstTouchPosition: 0,
        differenceFirstLastTouchPosition: 0,
    };

    componentDidMount() {
        const {sliderSpeed, touchSize} = this.props;
        if (this.props.isSwipeOn) {
            this.viewingFrameRef.current.addEventListener('touchstart', this.handleTouchStart);
            this.viewingFrameRef.current.addEventListener('touchmove', this.handleTouchMove);
            this.viewingFrameRef.current.addEventListener('touchend', event => this.handleTouchEnd(event, touchSize));
        }
        this.setState({
            sliderTransitionIntervalId: this.activateInterval(this.goToNextSlide, sliderSpeed),
        });
    }

    componentWillUnmount() {
        const {sliderTransitionIntervalId, touchSize} = this.state;
        clearInterval(sliderTransitionIntervalId);
        if (this.props.isSwipeOn) {
            this.viewingFrameRef.current.removeEventListener('touchstart', this.handleTouchStart);
            this.viewingFrameRef.current.removeEventListener('touchmove', this.handleTouchMove);
            this.viewingFrameRef.current.removeEventListener('touchend', event => this.handleTouchEnd(event, touchSize));
        }
        this.setState(state => ({
            sliderTransitionIntervalId: 0,
            activeSlideIndex: 0,
        }));
    }

    /**
     * Activate interval
     *
     * @param {func} callback
     * @param {number} speed
     * @return {func} setInterval
     */
    activateInterval = (callback, speed = 1000) => (
        setInterval(callback, speed)
    );

    // TODO - move swipe implementaion in seperate file (includes state, 3 listeners, 3 functions)
    /**
     * Handle touch start
     *
     * @param {object} event
     */
    handleTouchStart = event => {
        this.setState({
            firstTouchPosition: event.changedTouches[0].clientX,
        });
        // pause slide trasition
        this.toggleSliderState();
    };

    /**
     * Handle touch move
     *
     * @param {object} event
     */
    handleTouchMove = event => {
        const {firstTouchPosition} = this.state;
        const lastTouchPosition = event.changedTouches[0].clientX;
        this.setState({
            differenceFirstLastTouchPosition: firstTouchPosition - lastTouchPosition,
        });
    };

    /**
     * Handle touch end
     *
     * @param {object} event
     * @param {number} touchSize
     *
     */
    handleTouchEnd = (event, touchSize = 100) => {
        const {activeSlideIndex, promotionItemsLength, differenceFirstLastTouchPosition} = this.state;
        const nextSlideIndex = activeSlideIndex === promotionItemsLength - 1 ? 0 : activeSlideIndex + 1;
        const prevSlideIndex = activeSlideIndex === 0 ? promotionItemsLength - 1 : activeSlideIndex - 1;
        if (differenceFirstLastTouchPosition > touchSize) {
            this.goToSlide(nextSlideIndex);
        }
        if (differenceFirstLastTouchPosition < -1 * touchSize) {
            this.goToSlide(prevSlideIndex);
        }
        this.setState({
            firstTouchPosition: 0,
            differenceFirstLastTouchPosition: 0,
        });
        // play slide trasition
        this.toggleSliderState();
    };

    /**
     * Go to specified slide
     *
     * @param {number} index
     */
    goToSlide = index => {
        this.setState(state => ({
            activeSlideIndex: index,
        }));
    };

    /**
     * Change slide to next one
     */
    goToNextSlide = () => {
        const {activeSlideIndex, isPaused, promotionItemsLength} = this.state;
        if (promotionItemsLength > 0 && !isPaused) {
            return activeSlideIndex === promotionItemsLength - 1
                ? this.goToSlide(0)
                : this.goToSlide(activeSlideIndex + 1);
        }
    };

    /**
     * Toggle slider state between isPaused and !isPaused
     */
    toggleSliderState = () => {
        const {sliderSpeed} = this.props;
        const isPaused = !this.state.isPaused;
        clearInterval(this.state.sliderTransitionIntervalId);
        const sliderTransitionIntervalId = !isPaused ? this.activateInterval(this.goToNextSlide, sliderSpeed) : null;
        this.setState(state => ({
            sliderTransitionIntervalId,
            isPaused,
        }));
    };

    render() {
        const {children} = this.props;
        const {activeSlideIndex, promotionItemsLength} = this.state;
        const updatedProps = Children.map(children, (child, index) => {
            if (!React.isValidElement(child)) {
                return;
            }
            if (child && child.key === 'pagination') {
                return React.cloneElement(child, {
                    onClick: this.goToSlide,
                    activeSlideIndex,
                    onMouseOver: this.toggleSliderState,
                    onMouseOut: this.toggleSliderState,
                    promotionItemsLength,
                });
            }
            return child;
        });
        return (
            <div className="vub-c-default-banner">
                <div className="vub-c-default-banner__slider" ref={this.viewingFrameRef}>
                    {updatedProps.map((child, index) => (
                        child.key.indexOf('pagination') === -1 && (
                            <div key={index} className={`vub-c-default-banner__slider-item ${index === activeSlideIndex ? 'vub-c-default-banner__slider-item--is-active' : ''}`}>
                                {child}
                            </div>
                        )
                    ))}
                </div>
                {updatedProps.map((child, index) => (
                    child.key.indexOf('pagination') >= 0 && child
                ))}
            </div>
        );
    }
}

export default DefaultBannerSlider;
