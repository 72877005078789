import React from 'react';

export default React.createContext({
    mediaBucket: null,
    mediaGenres: null,
    providers: null,
    isSearchRouteActive: false,
    searchQueryString: '',
    searchMedia: () => {},
    fetchBucketMedia: () => {},
    onGenreFilterSelect: () => {},
    onOrderBySelect: () => {},
    onMoreDetailsFilterSelect: () => {},
    setSearchQueryString: () => {},
    areSearchResultsValid: true,
    orderByValue: null,
    filterByValue: null,
});
