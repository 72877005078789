import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect';
import './HeroBannerBackgroundPlayer.scss';
import BackgroundPlayer from './BackgroundPlayer';
import BackgroundPlayerControls from './BackgroundPlayerControls';
import mobileBgImage from '../../assets/images/mwc_placeholder_new_year.png';

class HeroBannerBackgroundPlayer extends Component {
    static propTypes = {
        playbackSources: PropTypes.array,
        playbackDelay: PropTypes.number,
        setHeaderTransparency: PropTypes.func.isRequired,
        isWithImage: PropTypes.bool,
        isPlaybackEnabled: PropTypes.bool,
    };

    static defaultProps = {
        playbackSources: null,
        playbackDelay: null,
        isWithImage: false,
        isPlaybackEnabled: true,
    };

    constructor(props) {
        super(props);
        const isMobilePortrait = this.isMobileDevice() && (window.innerHeight > window.innerWidth);
        this.state = {
            isMuted: !this.isMobileDevice(),
            activePlaybackSourceIndex: 0,
            isMobilePlaybackCollectionStarted: false,
            isMobilePortrait,
        };
    }

    componentDidMount() {
        if ('onorientationchange' in window) {
            window.addEventListener('orientationchange', this.onOrientationChange);
        }
    }

    componentWillUnmount() {
        this.props.setHeaderTransparency(false);
        window.removeEventListener('orientationchange', this.onOrientationChange);
    }

    isMobileDevice = () => {
        const md = new MobileDetect(window.navigator.userAgent);
        return !!md.phone();
    };

    onOrientationChange = () => {
        const isMobilePortrait = this.isMobileDevice() && window.orientation === 0;
        this.setState({
            isMobilePortrait,
        });
    };

    onMediaPlaybackStart = () => {
        this.props.setHeaderTransparency(true);
    };

    onMediaPlaybackToggle = isPlaybackPaused => {
        this.props.setHeaderTransparency(!isPlaybackPaused);
    };

    onMediaMuteToggle = isMuted => {
        this.setState({
            isMuted,
        });
    };

    onMediaPlaybackStop = () => {
        this.props.setHeaderTransparency(false);
        this.setState(state => ({
            activePlaybackSourceIndex: state.activePlaybackSourceIndex === this.props.playbackSources.length - 1
                ? 0 : state.activePlaybackSourceIndex + 1,
            isMobilePlaybackCollectionStarted:
                state.activePlaybackSourceIndex === this.props.playbackSources.length - 1
                    ? false
                    : state.isMobilePlaybackCollectionStarted,
        }));
    };

    playPreview = () => {
        this.setState({
            isMobilePlaybackCollectionStarted: true,
        });
    };

    isMultiPlaybackReady = currentPlaybackSource => {
        const {
            isMobilePlaybackCollectionStarted,
            isMobilePortrait,
        } = this.state;
        if (isMobilePortrait) {
            if (isMobilePlaybackCollectionStarted) {
                return currentPlaybackSource && currentPlaybackSource.src;
            }
            return null;
        }
        return currentPlaybackSource && currentPlaybackSource.src;
    };

    render() {
        const {
            playbackDelay,
            isWithImage,
            playbackSources,
            isPlaybackEnabled,
        } = this.props;
        const {
            activePlaybackSourceIndex,
            isMuted,
            isMobilePlaybackCollectionStarted,
            isMobilePortrait,
        } = this.state;
        const currentPlaybackSource = playbackSources
            && playbackSources[activePlaybackSourceIndex].source;
        const currentBoxCoverImage = playbackSources && playbackSources[activePlaybackSourceIndex].imageBoxCover;
        const currentMediaPlaybackStartAt = playbackSources
            && playbackSources[activePlaybackSourceIndex].playbackStartAt;
        const mobilePortraitPreviewStyle = {
            backgroundImage: `url(${activePlaybackSourceIndex === 0 ? mobileBgImage : currentBoxCoverImage})`,
        };
        const previewStyle = isWithImage ? {backgroundImage: `url(${currentBoxCoverImage})`} : {};
        return (
            <div
                className="vub-c-hero-banner-background-player"
                style={isMobilePortrait ? mobilePortraitPreviewStyle : previewStyle}
            >
                {!isMobilePlaybackCollectionStarted && isMobilePortrait && currentPlaybackSource && (
                    <div
                        className="vub-c-hero-banner-background-player__button-view"
                        onClick={this.playPreview}
                    >
                        <span className="vub-c-hero-banner-background-player__button-view-logo" />
                        <span className="vub-c-hero-banner-background-player__button-view-descriptor">
                            trailer
                        </span>
                    </div>
                )}
                {this.isMultiPlaybackReady(currentPlaybackSource) && (
                    <BackgroundPlayer
                        onMediaPlaybackStart={this.onMediaPlaybackStart}
                        onMediaPlaybackToggle={this.onMediaPlaybackToggle}
                        onMediaPlaybackStop={this.onMediaPlaybackStop}
                        onMediaMuteToggle={this.onMediaMuteToggle}
                        playbackSource={currentPlaybackSource}
                        playbackStartAt={currentMediaPlaybackStartAt}
                        playbackDelay={playbackDelay}
                        isWithSpinner={!isWithImage}
                        isMuted={isMuted}
                        isPlaybackEnabled={isPlaybackEnabled}
                    >
                        <BackgroundPlayerControls
                            isMobileDevice={this.isMobileDevice()}
                        />
                    </BackgroundPlayer>
                )}
            </div>
        );
    }
}

export default HeroBannerBackgroundPlayer;
