import React from 'react';
import PropTypes from 'prop-types';
import './SpinnerApp.scss';

const SpinnerApp = ({className}) => (
    <div className="vub-c-app-spinner" />
);

SpinnerApp.propTypes = {
    className: PropTypes.string,
};

SpinnerApp.defaultProps = {
    className: null,
};

export default SpinnerApp;
