import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import MediaSearchContext from './MediaSearchContext';

export const withMediaSearch = BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'withMediaSearch'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
        };

        render() {
            return (
                <MediaSearchContext.Consumer>
                    {context => {
                        const {mediaBucket, mediaGenres, providers} = context;
                        const {areSearchResultsValid, isSearchRouteActive} = context;
                        const {searchQueryString, setSearchQueryString, fetchBucketMedia} = context;
                        const {
                            onGenreFilterSelect,
                            onOrderBySelect,
                            onMoreDetailsFilterSelect,
                            orderByValue,
                            filterByValue,
                            filterByMoreDetails,
                        } = context;

                        return (
                            <BaseComponent
                                {...this.props}
                                mediaBucket={mediaBucket}
                                mediaGenres={mediaGenres}
                                providers={providers}
                                isSearchRouteActive={isSearchRouteActive}
                                searchQueryString={searchQueryString}
                                setSearchQueryString={setSearchQueryString}
                                fetchBucketMedia={fetchBucketMedia}
                                onGenreFilterSelect={onGenreFilterSelect}
                                onOrderBySelect={onOrderBySelect}
                                onMoreDetailsFilterSelect={onMoreDetailsFilterSelect}
                                areSearchResultsValid={areSearchResultsValid}
                                orderByValue={orderByValue}
                                filterByValue={filterByValue}
                                filterByMoreDetails={filterByMoreDetails}
                            />
                        );
                    }}
                </MediaSearchContext.Consumer>
            );
        }
    }

    return ComposedComponent;
};

export const withMediaSearchPropTypes = {
    mediaBucket: PropTypes.object,
    mediaGenres: PropTypes.object,
    providers: PropTypes.object,
    isSearchRouteActive: PropTypes.bool,
    searchQueryString: PropTypes.string.isRequired,
    setSearchQueryString: PropTypes.func.isRequired,
    fetchBucketMedia: PropTypes.func.isRequired,
    onGenreFilterSelect: PropTypes.func.isRequired,
    onOrderBySelect: PropTypes.func.isRequired,
    onMoreDetailsFilterSelect: PropTypes.func.isRequired,
    areSearchResultsValid: PropTypes.bool,
    orderByValue: PropTypes.string,
    filterByValue: PropTypes.string,
    filterByMoreDetails: PropTypes.array,
};

export const withMediaSearchDefaultProps = {
    mediaBucket: null,
    mediaGenres: null,
    providers: null,
    isSearchRouteActive: false,
    searchQueryString: '',
    setSearchQueryString: () => {},
    searchMedia: () => {},
    fetchBucketMedia: () => {},
    onGenreFilterSelect: () => {},
    onOrderBySelect: () => {},
    onMoreDetailsFilterSelect: () => {},
    areSearchResultsValid: true,
    orderByValue: 'relevance',
    filterByValue: 'reset',
    filterByMoreDetails: [],
};
