import React, {Component} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import './BackgroundPlayer.scss';
import config from '../../config';
import SpinnerStandard from '../spinner/SpinnerStandard';

class BackgroundPlayer extends Component {
    static propTypes = {
        onMediaPlaybackStart: PropTypes.func,
        onMediaPlaybackToggle: PropTypes.func,
        onMediaPlaybackStop: PropTypes.func,
        onMediaMuteToggle: PropTypes.func,
        isAutoplayEnabled: PropTypes.bool,
        isControlsEnabled: PropTypes.bool,
        logo: PropTypes.object,
        poster: PropTypes.string,
        techOrder: PropTypes.array,
        playbackSource: PropTypes.object,
        playbackDelay: PropTypes.number,
        playbackStartAt: PropTypes.number,
        isWithSpinner: PropTypes.bool,
        isMuted: PropTypes.bool,
        isPlaybackEnabled: PropTypes.bool,
    };

    static defaultProps = {
        onMediaPlaybackStart: null,
        onMediaPlaybackToggle: null,
        onMediaPlaybackStop: null,
        onMediaMuteToggle: null,
        isAutoplayEnabled: true,
        isControlsEnabled: true,
        logo: {enabled: true},
        poster: null,
        techOrder: [
            'azureHtml5JS',
            'html5FairPlayHLS',
            'html5',
        ],
        playbackSource: null,
        playbackDelay: 0,
        playbackStartAt: 0,
        isWithSpinner: false,
        isMuted: true,
        isPlaybackEnabled: true,
    };

    constructor(props) {
        super(props);
        this.controlPlayback = throttle(this.controlPlayback, 50);
    }

    state = {
        isPlayerReady: null,
        isPlayerStarted: false,
        isPaused: false,
        errorMessage: null,
        isPausedViaScroll: false,
    };

    async componentDidMount() {
        const {playbackSource} = this.props;
        if (window.amp) {
            this.setPlayer(window.amp, playbackSource);
            return;
        }

        try {
            const amp = await this.loadMediaPlayer(config.AZURE_MEDIA_PLAYER_CDN_URL);
            // set player
            this.setPlayer(amp, playbackSource);
        } catch (e) {
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.playbackSource && prevProps.playbackSource.src !== this.props.playbackSource.src) {
            this.setAndStartPlayback(this.props.playbackSource, this.props.playbackDelay);
        }

        if (this.player && prevProps.isPlaybackEnabled && !this.props.isPlaybackEnabled) {
            this.player.pause();
        }
        return null;
    }

    componentWillUnmount() {
        this.player.ready(this.removePlayerEventListeners);
        document.removeEventListener('scroll', this.controlPlayback);
        this.player.dispose();
        this.player = null;
        this.setState({
            isPlayerReady: null,
            isPlayerStarted: null,
        });
    }

    addPlayerEventListeners = () => {
        this.player.addEventListener('play', this.onPlay);
        this.player.addEventListener('pause', this.onPause);
        this.player.addEventListener('ended', this.onEnded);
        this.player.addEventListener('error', this.onError);
    };

    removePlayerEventListeners = () => {
        this.player.removeEventListener('play', this.onPlay);
        this.player.removeEventListener('pause', this.onPause);
        this.player.removeEventListener('ended', this.onEnded);
        this.player.removeEventListener('error', this.onError);
    };

    // player instance
    player = null;

    // inject player in html head
    loadMediaPlayer = async playerCDN => (
        new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = playerCDN;
            scriptElement.addEventListener('load', () => resolve(window.amp));
            scriptElement.addEventListener('error', e => reject(e));
            document.head.appendChild(scriptElement);
        })
    );

    // set player options
    createVideoPlayer = amp => {
        const {
            isAutoplayEnabled,
            isControlsEnabled,
            logo,
            techOrder,
            poster,
        } = this.props;

        return amp(this.mediaPlayer, {
            autoplay: isAutoplayEnabled,
            controls: isControlsEnabled,
            logo,
            techOrder,
            ...(poster && {poster}),
        });
    };

    setPlayer = (amp, playbackSource) => {
        this.player = this.createVideoPlayer(amp);
        this.player.ready(this.addPlayerEventListeners);
        document.addEventListener('scroll', this.controlPlayback);
        this.player.ready(() => {
            this.setAndStartPlayback(playbackSource, this.props.playbackDelay);
        });
    };

    // set player video source
    setPlayerVideoSource = playbackSource => {
        this.player && (
            this.player.src([{
                src: playbackSource.src,
                type: playbackSource.type,
                protectionInfo: playbackSource.protectionInfo,
            }])
        );
    };

    // set video source and start playback
    setAndStartPlayback = (playbackSource, playbackDelay = 3000) => {
        if (!this.player) return;
        return setTimeout(() => {
            if (this.backgroundPlayer && this.backgroundPlayer.current) {
                this.setPlayerVideoSource(playbackSource);
                this.setState({
                    isPlayerReady: true,
                }, () => {
                    if (this.props.isPlaybackEnabled) this.player.play();
                    this.player.muted(this.props.isMuted);
                });
            }
        }, playbackDelay);
    };

    // play
    onPlay = () => {
        // temporary solution - it is better to create dynamic manifest
        // https://azure.microsoft.com/en-us/blog/dynamic-manifest/
        if (!this.state.isPlayerStarted) {
            const {onMediaPlaybackStart, playbackStartAt} = this.props;
            setTimeout(() => {
                this.setState({isPlayerStarted: true});
                if (typeof onMediaPlaybackStart === 'function') {
                    onMediaPlaybackStart();
                }
            }, playbackStartAt);
        }
    };

    // pause
    onPause = e => {
    };

    // error
    onError = () => {
        if (!this.player) return;
        this.setState({
            isPlayerStarted: false,
        }, () => this.props.onMediaPlaybackStop());
    };

    // ended
    onEnded = () => {
        if (!this.player) return;
        this.setState({
            isPlayerStarted: false,
        }, () => this.props.onMediaPlaybackStop());
    };

    // toggle playback
    togglePlayback = () => {
        this.setState(state => ({
            isPaused: !state.isPaused,
        }), () => {
            this.state.isPaused ? this.player.pause() : this.player.play();

            if (typeof this.props.onMediaPlaybackToggle === 'function') {
                this.props.onMediaPlaybackToggle(this.state.isPaused);
            }
        });
    };

    // TODO player should not know if it scrolled out or not, just what it's playback state is
    controlPlayback = () => {
        const {isPlaybackEnabled} = this.props;
        const {isPlayerReady, isPaused, isPausedViaScroll} = this.state;
        if (!isPlayerReady) return;

        const playerPositionFromTop = window.pageYOffset + this.backgroundPlayer.current.getBoundingClientRect().top;

        const playerBreakpoint = 200;
        if (!isPausedViaScroll && !isPaused && window.scrollY > playerBreakpoint + playerPositionFromTop) {
            this.setState({
                isPausedViaScroll: true,
                isPaused: true,
            }, () => {
                this.player.pause();

                if (typeof this.props.onMediaPlaybackToggle === 'function') {
                    this.props.onMediaPlaybackToggle(this.state.isPaused);
                }
            });

            return;
        }

        if (isPlaybackEnabled && isPausedViaScroll && window.scrollY <= playerBreakpoint + playerPositionFromTop) {
            this.setState({
                isPausedViaScroll: false,
                isPaused: false,
            }, () => {
                this.player.play();

                if (typeof this.props.onMediaPlaybackToggle === 'function') {
                    this.props.onMediaPlaybackToggle(this.state.isPaused);
                }
            });
        }
    };

    toggleMute = () => {
        this.player.muted(!this.props.isMuted);
        this.props.onMediaMuteToggle(!this.props.isMuted);
    };

    renderChildren = children => {
        const {
            isPaused,
        } = this.state;
        const {isMuted} = this.props;
        return children && React.Children.map(children, child => (
            React.cloneElement(child, {
                isPaused,
                isMuted,
                togglePlayback: this.togglePlayback,
                toggleMute: this.toggleMute,
            })
        ));
    };

    backgroundPlayer = React.createRef();

    render() {
        const {children, isWithSpinner} = this.props;
        const {
            isPlayerStarted,
            errorMessage,
        } = this.state;
        return (
            <div
                className={`vub-c-background-player-container ${isPlayerStarted ? 'vub-c-background-player-container--visible' : ''}`}
                ref={this.backgroundPlayer}
            >
                <video
                    className="azuremediaplayer"
                    ref={input => { this.mediaPlayer = input; }}
                />
                {isPlayerStarted ? (
                    this.renderChildren(children)
                ) : (!errorMessage && isWithSpinner) ? (
                    <div className="vub-c-background-player-container__spinner">
                        <SpinnerStandard className="vub-c-standard-spinner--small" />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default BackgroundPlayer;
