import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './NavigationSearchDropDown.scss';
import SpinnerStandard from '../../../ui-elements/spinner/SpinnerStandard';
import ShowMoreButton from '../ShowMoreButton';
import {markSearchQueryString} from '../../../media-search-view/mediaSearchUtils';

const NavigationSearchDropDown = ({mediaBucket, resolveMediaItemRoute, goToSearchPage, searchQueryString}) => (
    <div className="vub-c-navigation-search-drop-down">
        {mediaBucket && mediaBucket.isUpdatePending && (
            <div className="vub-c-navigation-search__spinner">
                <SpinnerStandard className="vub-c-standard-spinner--xsmall" />
            </div>
        )}

        {mediaBucket && !mediaBucket.isUpdatePending && mediaBucket.media.size === 0 && (
            <span className="vub-c-navigation-search__no-results">
                No media available for your search.
            </span>
        )}

        {mediaBucket && !mediaBucket.isUpdatePending && mediaBucket.media.size > 0 && (
        <ul className="vub-c-navigation-search-drop-down__menu">
            {mediaBucket.media
                .toArray()
                .slice(0, 5)
                .map(mediaItem => {
                    const {meta, node} = markSearchQueryString(
                        mediaItem,
                        searchQueryString,
                        60
                    );
                    return (
                        <li
                            key={mediaItem.id}
                            className="vub-c-navigation-search-drop-down__item"
                        >
                            <Link
                                className="vub-c-navigation-search-drop-down__link"
                                to={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                            >
                                <div className="vub-c-navigation-search-drop-down__tile">
                                    <div className="vub-c-navigation-search-drop-down__image-placeholder">
                                        <img
                                            className="vub-navigation-search-drop-down__image"
                                            src={`${mediaItem.imageBoxCover}&q=70&w=60`}
                                            alt="poster"
                                        />
                                    </div>
                                    <div className="vub-c-navigation-search-drop-down__item-content">
                                        <div className="vub-c-navigation-search-drop-down__category">{meta}</div>
                                        <div className="vub-c-navigation-search-drop-down__description">{node}</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    );
                })}
        </ul>
        )}
        {mediaBucket && !mediaBucket.isUpdatePending && mediaBucket.media.size > 0 && (
            <ShowMoreButton onClick={goToSearchPage}>Show all results</ShowMoreButton>
        )}
    </div>
);

NavigationSearchDropDown.propTypes = {
    mediaBucket: PropTypes.object,
    resolveMediaItemRoute: PropTypes.func,
    goToSearchPage: PropTypes.func,
    searchQueryString: PropTypes.string,
};

NavigationSearchDropDown.defaultProps = {
    mediaBucket: null,
    resolveMediaItemRoute: null,
    goToSearchPage: null,
    searchQueryString: null,
};

export default NavigationSearchDropDown;

