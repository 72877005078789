import React, {Component} from 'react';
import PopUpContext from './PopUpContext';
import PopUp from './PopUp';

export default class PopUpProvider extends Component {
    state = {
        content: null,
        isOpened: false,
        isWithOverlay: true,
        position: null,
    }

    addPopUpContent = (content = null) => {
        this.setState(state => ({content}), () => {
            this.state.content ? this.openPopUp() : this.closePopUp();
        });
    }

    setPopUp = (content, isWithOverlay = true, position) => {
        this.setState({
            isWithOverlay,
            position,
        });
        this.addPopUpContent(content);
    }

    openPopUp = () => {
        this.setState({
            isOpened: true,
        });
    }

    closePopUp = () => this.setState({
        isOpened: false,
        content: null,
    });

    render() {
        const {children} = this.props;
        const {isOpened, content, isWithOverlay, position} = this.state;
        const context = {
            ...this.state,
            addPopUpContent: this.addPopUpContent,
            closePopUp: this.closePopUp,
            openPopUp: this.openPopUp,
            setPopUp: this.setPopUp,
        };
        return (
            <PopUpContext.Provider value={context}>
                {children}
                {isOpened && (
                    <PopUp
                        isWithOverlay={isWithOverlay}
                        position={position}
                        content={content}
                        closePopUp={this.closePopUp}
                    />
                )}
            </PopUpContext.Provider>
        );
    }
}

