import React, {Component} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import LayoutControlsContext from './LayoutControlsContext';

class LayoutControlsProvider extends Component {
    static propTypes = {
        minPageYOffset: PropTypes.number,
    };

    static defaultProps = {
        minPageYOffset: 90,
    };

    constructor(props) {
        super(props);
        this.onScrollHandler = throttle(this.onScrollHandler, 50);
    }

    state = {
        isHeaderInUse: false,
        isHeaderTransparent: false,
        isHeaderReduced: false,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollHandler);
    }

    setHeaderInUseState = isHeaderInUse => {
        if (this.state.isHeaderInUse === isHeaderInUse) return;

        this.setState({
            isHeaderInUse: isHeaderInUse,
        });
    };

    setHeaderTransparency = isHeaderTransparent => {
        this.setState(state => ({
            isHeaderTransparent: !state.isHeaderInUse && isHeaderTransparent,
        }));
    };

    setHeaderReducedState = isHeaderReduced => {
        if (this.state.isHeaderReduced === isHeaderReduced) return;

        this.setState({
            isHeaderReduced: isHeaderReduced,
        });
    };

    onScrollHandler = () => {
        const {minPageYOffset} = this.props;
        this.setHeaderReducedState(window.pageYOffset > minPageYOffset);
    };

    render() {
        const {children} = this.props;
        const {isHeaderInUse, isHeaderTransparent, isHeaderReduced} = this.state;

        return (
            <LayoutControlsContext.Provider
                value={{
                    isHeaderInUse,
                    isHeaderTransparent,
                    isHeaderReduced,
                    setHeaderInUseState: this.setHeaderInUseState,
                    setHeaderTransparency: this.setHeaderTransparency,
                    setHeaderReducedState: this.setHeaderReducedState,
                }}
            >
                {children}
            </LayoutControlsContext.Provider>
        );
    }
}

export default LayoutControlsProvider;
