import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import './MediaGalleryListEntryInfo.scss';

const MediaGalleryListEntryInfo = ({mediaItem, meta, node}) => (
    mediaItem ? (
        <Fragment>
            <div className="vub-c-media-gallery-list-entry__title">
                {meta && meta.toLowerCase() === 'title' ? node : mediaItem.title}
            </div>
            <div className="vub-c-media-gallery-list-entry__basic-info">
                {mediaItem.contentAdvisoryRating && (
                    <span className="vub-c-media-gallery-list-entry__info">
                        Certificate: {mediaItem.contentAdvisoryRating}
                    </span>
                )}
                {mediaItem.year && (
                    <span className="vub-c-media-gallery-list-entry__info">
                        {mediaItem.year}
                    </span>
                )}
                {mediaItem.durationMinutes && (
                    <span className="vub-c-media-gallery-list-entry__info">
                        {mediaItem.durationMinutes}
                    </span>
                )}
            </div>
            <div className="vub-c-media-gallery-list-entry__synopsis">
                {meta && meta.toLowerCase() === 'synopsis' ? node : mediaItem.synopsis}
            </div>
        </Fragment>
    ) : null
);

MediaGalleryListEntryInfo.propTypes = {
    mediaItem: PropTypes.object.isRequired,
    meta: PropTypes.string,
    node: PropTypes.node,
};

MediaGalleryListEntryInfo.defaultProps = {
    meta: null,
    node: null,
};

export default MediaGalleryListEntryInfo;
