import React from 'react';
import PropTypes from 'prop-types';
import './SearchLayoutPicker.scss';

const SearchLayoutPicker = props => {
    return (
        <div className={'vub-c-search-layout-picker' + (props.isInTitle ? ' is-in-title' : '')}>
            {props.isInTitle
                ? null
                : <div className="vub-c-more-details-tag__label">Layout:</div>
            }
            <div className="vub-c-search-layout-picker__buttons">
                {props.searchViewLayout === 'list'
                    ? <button type="button" className="vub-c-search-layout-picker__list-button is-active" />
                    : <button type="button" onClick={() => props.updateLayoutSelectButtons('list')} className="vub-c-search-layout-picker__list-button" />
                }
                {props.searchViewLayout === 'grid'
                    ? <button type="button" className="vub-c-search-layout-picker__grid-button is-active" />
                    : <button type="button" onClick={() => props.updateLayoutSelectButtons('grid')} className="vub-c-search-layout-picker__grid-button" />
                }
            </div>
        </div>
    );
};

SearchLayoutPicker.propTypes = {
    searchViewLayout: PropTypes.string.isRequired,
    updateLayoutSelectButtons: PropTypes.func.isRequired,
    isInTitle: PropTypes.bool,
};
SearchLayoutPicker.defaultProps = {
    isInTitle: false,
};

export default SearchLayoutPicker;
