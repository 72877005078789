/**
 * Auth action types
 */
export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_UP_USER = 'SIGN_UP_USER';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const REGISTER_AND_AUTHENTICATE_USER = 'REGISTER_AND_AUTHENTICATE_USER';
export const SET_USER_CONTACT_PREFERENCES = 'SET_USER_CONTACT_PREFERENCES';
export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const CREATE_USER_ACCOUNT_REQUEST = 'CREATE_USER_ACCOUNT_REQUEST';
export const CREATE_USER_ACCOUNT_SUCCESS = 'CREATE_USER_ACCOUNT_SUCCESS';
export const CREATE_USER_ACCOUNT_ERROR = 'CREATE_USER_ACCOUNT_ERROR';

export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_ERROR = 'AUTHENTICATE_USER_ERROR';

export const UPDATE_USER_ACCOUNT_REQUEST = 'UPDATE_USER_ACCOUNT_REQUEST';
export const UPDATE_USER_ACCOUNT_SUCCESS = 'UPDATE_USER_ACCOUNT_SUCCESS';
export const UPDATE_USER_ACCOUNT_ERROR = 'UPDATE_USER_ACCOUNT_ERROR';

export const REQUEST_PASSWORD_RESET_REQUEST = 'REQUEST_PASSWORD_RESET_REQUEST';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_ERROR = 'REQUEST_PASSWORD_RESET_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const USER_SESSION_STARTED = 'USER_SESSION_STARTED';
export const USER_SESSION_ENDED = 'USER_SESSION_ENDED';

export const STORE_SESSION_CHANGE_STATUS = 'STORE_SESSION_CHANGE_STATUS';
export const STORE_USER_ACCOUNT = 'STORE_USER_ACCOUNT';
export const STORE_SERVER_ERRORS = 'STORE_SERVER_ERRORS';
