/**
 * Live streaming action types
 */
export const FETCH_EPG_LISTING = 'FETCH_EPG_LISTING';
export const START_LIVE_STREAM_PLAYBACK = 'START_LIVE_STREAM_PLAYBACK';
export const STOP_LIVE_STREAM_PLAYBACK = 'STOP_LIVE_STREAM_PLAYBACK';

export const FETCH_EPG_DATA_REQUEST = 'FETCH_EPG_DATA_REQUEST';
export const FETCH_EPG_DATA_SUCCESS = 'FETCH_EPG_DATA_SUCCESS';
export const FETCH_EPG_DATA_ERROR = 'FETCH_EPG_DATA_ERROR';

export const FETCH_LIVE_STREAM_PLAYBACK_SOURCE_REQUEST = 'FETCH_LIVE_STREAM_PLAYBACK_SOURCE_REQUEST';
export const FETCH_LIVE_STREAM_PLAYBACK_SOURCE_SUCCESS = 'FETCH_LIVE_STREAM_PLAYBACK_SOURCE_SUCCESS';
export const FETCH_LIVE_STREAM_PLAYBACK_SOURCE_ERROR = 'FETCH_LIVE_STREAM_PLAYBACK_SOURCE_ERROR';

export const STORE_EPG_DATA = 'STORE_EPG_DATA';
export const EPG_DATA_IN_STORE = 'EPG_DATA_IN_STORE';
export const STORE_LIVE_STREAM_PLAYBACK_SOURCE = 'STORE_LIVE_STREAM_PLAYBACK_SOURCE';
