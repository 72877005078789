import Immutable from 'immutable';
import * as actionTypes from './liveStreamingActionTypes';
import EPGChannel from './records/EPGChannel';
import EPGShow from './records/EPGShow';
import LiveStreamPlaybackSource from './records/LiveStreamPlaybackSource';

const initialState = {
    epgChannels: Immutable.OrderedMap(),
    epgShows: Immutable.Map(),
    availableEPGTimeFrame: null,
    loadedEPGTimeFrame: null,
    liveStreamPlaybackSource: null,
    playbackEPGChannelId: null,
};

/**
 * Live streaming reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */
const liveStreamingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_EPG_DATA:
            if (action.payload.epgShowDTOs) {
                const {epgChannelDTOs, epgShowDTOs} = action.payload;
                const {availableStartDate, availableEndDate, loadedStartDate, loadedEndDate} = action.payload;
                let epgChannels = state.epgChannels;
                let epgShows = state.epgShows;
                const loadedEPGTimeFrame = state.loadedEPGTimeFrame || {startDate: null, endDate: null};

                epgChannelDTOs.forEach(epgChannelDTO => {
                    const epgChannel = new EPGChannel({}).fromDTO(epgChannelDTO);
                    epgChannels = epgChannels.set(epgChannel.id, epgChannel);
                });

                epgShowDTOs.forEach(epgShowDTO => {
                    const epgShow = new EPGShow({}).fromDTO(epgShowDTO);
                    epgShows = epgShows.set(epgShow.id, epgShow);
                });

                state = {
                    ...state,
                    epgChannels: epgChannels.sort((epgChannelA, epgChannelB) => {
                        return epgChannelA.orderNumber - epgChannelB.orderNumber;
                    }),
                    epgShows: epgShows,
                    availableEPGTimeFrame: {
                        startDate: availableStartDate,
                        endDate: availableEndDate,
                    },
                    loadedEPGTimeFrame: {
                        startDate: (loadedEPGTimeFrame.startDate > loadedStartDate
                            || loadedEPGTimeFrame.startDate === null) ? loadedStartDate : loadedEPGTimeFrame.startDate,
                        endDate: (loadedEPGTimeFrame.endDate < loadedEndDate || loadedEPGTimeFrame.endDate === null)
                            ? loadedEndDate : loadedEPGTimeFrame.endDate,
                    },
                };
            }
            break;

        case actionTypes.STORE_LIVE_STREAM_PLAYBACK_SOURCE:
            state = action.payload.liveStreamPlaybackSourceDTO ? {
                ...state,
                liveStreamPlaybackSource: new LiveStreamPlaybackSource(action.payload.liveStreamPlaybackSourceDTO),
                playbackEPGChannelId: action.payload.playbackEPGChannelId,
            } : {
                ...state,
                liveStreamPlaybackSource: null,
                playbackEPGChannelId: null,
            };
            break;

        default:
        // no-op
    }
    return state;
};

export default liveStreamingReducer;
