import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ContentRow from '../layout/ContentRow';
import * as selectors from '../media-classification-view/mediaClassificationViewSelectors';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';
import MediaItemPopoverView from '../media-item-popover-view/MediaItemPopoverView';

const TestPopoverView = ({mediaBucket}) => (
    <div className="vub-c-test-popover-view">

        {mediaBucket && mediaBucket.media.size > 0 ? (
            <ContentRow>
                {mediaBucket.media
                    .toArray()
                    .map(mediaItem => (
                        <MediaItemPopoverView
                            key={mediaItem.id}
                            mediaItem={mediaItem}
                            hidePopover={() => {}}
                        />
                    ))}
            </ContentRow>
        ) : null}

        {mediaBucket && mediaBucket.isLoadingMedia ? (
            <ContentRow>
                <SpinnerStandard className="vub-c-standard-spinner--small" />
            </ContentRow>
        ) : null}

    </div>
);

TestPopoverView.propTypes = {
    mediaBucket: PropTypes.object,
};

TestPopoverView.defaultProps = {
    mediaBucket: null,
};

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaClassificationMoviesViewBucketSelector();
    // const mediaBucketSelector = selectors.createMediaClassificationTVShowsViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
    });
};

export default connect(
    createMapStateToProps,
)(TestPopoverView);
