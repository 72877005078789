export const getPrice = ({price, currency}) => {
    if (price) {
        return price.toLocaleString('en', {
            style: 'currency',
            currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
};

export const getOfferTypeString = type => {
    let typeString = '';
    switch (type) {
        case 'tvod': typeString = 'Rent';
            break;
        case 'est': typeString = 'Buy';
            break;
        case 'svod': typeString = 'Subscription';
            break;
        default: // no-op;
    }
    return typeString;
};

export const groupOffers = mediaItem => {
    const groupedOffers = {};
    mediaItem.offers.map(offer => {
        const item = offer.toJS();
        if (!groupedOffers[item.provider]) {
            groupedOffers[item.provider] = [item];
        } else {
            groupedOffers[item.provider].push(item);
        }
        return;
    });
    return groupedOffers;
};
