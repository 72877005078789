import React, {Component} from 'react';
import Overlay from './Overlay';
import OverlayContext from './OverlayContext';

export default class OverlayProvider extends Component {
    state = {
        isOpened: false,
    };

    // observer list
    observers = [];

    openOverlay = () => {
        !this.state.isOpened && this.setState({
            isOpened: true,
        });
    };

    closeOverlay = () => {
        this.setState({
            isOpened: false,
        });
    };

    subscribeToOverlayClick = observer => {
        this.observers = this.observers.every(item => item !== observer)
            ? [...this.observers, observer] : this.observers;
    };

    unsubscribeFromOverlayClick = observer => {
        this.observers = this.observers.filter(item => item !== observer);
    };

    onOverlayClick = () => this.observers.forEach(observer => observer());

    render() {
        const {children} = this.props;
        const {isOpened} = this.state;
        return (
            <OverlayContext.Provider value={{
                closeOverlay: this.closeOverlay,
                openOverlay: this.openOverlay,
                subscribeToOverlayClick: this.subscribeToOverlayClick,
                unsubscribeFromOverlayClick: this.unsubscribeFromOverlayClick,
                isOverlayOpened: isOpened,
            }}
            >
                {children}
                {isOpened && (
                    <Overlay
                        closeOverlay={this.closeOverlay}
                        onOverlayClick={this.onOverlayClick}
                    />
                )}
            </OverlayContext.Provider>
        );
    }
}

