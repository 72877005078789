import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import PurchaseOptions from '../../ui-elements/purchase-options/PurchaseOptions';
import PurchaseOption from '../../ui-elements/purchase-options/PurchaseOption';
import CTAButton from '../../ui-elements/cta-button/CTAButton';
import withPopUp from '../../ui-elements/pop-up/withPopUp';

@withPopUp
class PurchaseCTAButtonsWithPopup extends Component {
    static propTypes = {
        mediaItem: PropTypes.object,
        providers: PropTypes.object,
        fastCheckoutMediaItemOffer: PropTypes.func.isRequired,
        setPopUp: PropTypes.func,
        closePopUp: PropTypes.func,
    }

    static defaultProps = {
        mediaItem: null,
        providers: null,
        setPopUp: null,
        closePopUp: null,
    }

    getPrice = ({price, currency}) => {
        if (price) {
            return price.toLocaleString('en', {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    renderPurchaseOptions = (primaryOffers = [], secondaryOffers = [], tertiaryOffers = []) => {
        const {setPopUp, closePopUp, fastCheckoutMediaItemOffer, providers} = this.props;
        setPopUp(
            <PurchaseOptions closePopUp={closePopUp}>
                <Fragment>
                    {primaryOffers.length > 0
                    && (
                    <PurchaseOption
                        offers={primaryOffers}
                        handleOffer={fastCheckoutMediaItemOffer}
                        getPrice={this.getPrice}
                        providers={providers}
                    />
                    )
                    }
                    {secondaryOffers.length > 0
                    && (
                    <PurchaseOption
                        offers={secondaryOffers}
                        handleOffer={fastCheckoutMediaItemOffer}
                        getPrice={this.getPrice}
                        providers={providers}
                    />
                    )
                    }
                    {tertiaryOffers.length > 0
                    && (
                    <PurchaseOption
                        offers={tertiaryOffers}
                        handleOffer={fastCheckoutMediaItemOffer}
                        getPrice={this.getPrice}
                        providers={providers}
                    />
                    )
                    }
                </Fragment>
            </PurchaseOptions>
        );
    };

    render() {
        const {mediaItem} = this.props;
        const tvodOffers = mediaItem.offers.filter(offer => offer.offerType === 'tvod');
        const estOffers = mediaItem.offers.filter(offer => offer.offerType === 'est');
        const svodOffers = mediaItem.offers.filter(offer => offer.offerType === 'svod');
        const mediaItemWithTVOD = mediaItem.set('offers', tvodOffers);
        const mediaItemWithEST = mediaItem.set('offers', estOffers);
        const mediaItemWithSVOD = mediaItem.set('offers', svodOffers);

        return (
            <Fragment>
                {mediaItemWithTVOD.offers.length > 0
                && (
                <CTAButton
                    onClick={() => this.renderPurchaseOptions(
                        mediaItemWithTVOD.offers,
                        mediaItemWithEST.offers,
                        mediaItemWithSVOD.offers
                    )}
                >
                    <span>
                        <span className="vub-u-font-weight-bold">Rent </span>
                        <span className="vub-c-drop-down__label-text">from </span>
                        {this.getPrice(mediaItemWithTVOD.offers[0])}
                    </span>
                </CTAButton>
                )
                }
                {mediaItemWithEST.offers.length > 0
                && (
                <CTAButton
                    onClick={() => this.renderPurchaseOptions(
                        mediaItemWithEST.offers,
                        mediaItemWithTVOD.offers,
                        mediaItemWithSVOD.offers
                    )}
                >
                    <span>
                        <span className="vub-u-font-weight-bold">Buy </span>
                        <span className="vub-c-drop-down__label-text">from </span>
                        {this.getPrice(mediaItemWithEST.offers[0])}
                    </span>
                </CTAButton>
                )
                }
                {mediaItemWithSVOD.offers.length > 0
                && (
                <CTAButton
                    onClick={() => this.renderPurchaseOptions(
                        mediaItemWithSVOD.offers,
                        mediaItemWithEST.offers,
                        mediaItemWithTVOD.offers,
                    )}
                >
                    <span>
                        <span className="vub-u-font-weight-bold">Subscribe</span>
                    </span>
                </CTAButton>
                )
                }
            </Fragment>
        );
    }
}

export default PurchaseCTAButtonsWithPopup;
