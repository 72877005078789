import {put, select} from 'redux-saga/effects';
import moment from 'moment';
import * as actionTypes from '../live-streaming/liveStreamingActionTypes';
import * as selectors from '../live-streaming/liveStreamingSelectors';

/**
 * Load EPG view listing
 */
export const loadEPGListing = function* loadEPGListing() {
    const startDate = moment().startOf('hour').subtract(24, 'hours');
    const endDate = moment().startOf('hour').add(48, 'hours');

    const loadedEPGTimeFrame = yield select(selectors.getLoadedEPGTimeFrame);
    if (loadedEPGTimeFrame
            && loadedEPGTimeFrame.startDate <= startDate
            && loadedEPGTimeFrame.endDate >= endDate) {
        yield put({type: actionTypes.EPG_DATA_IN_STORE, payload: {}});
        return;
    }

    yield put({
        type: actionTypes.FETCH_EPG_LISTING,
        payload: {
            startDate: startDate,
            endDate: endDate,
        },
    });
};
