import {put, take, fork, cancel} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as actionTypes from '../authActionTypes';
import routePaths from '../../routePaths';
import authenticateUser from './authenticateUser';
import userSessionFlow from './userSessionFlow';
import {getOnceNewUserSessionRoute} from './userSessionRouteHandlers';

/**
 * User authentication flow (token based)
 */
const userAuthenticationFlow = function* userAuthenticationFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.AUTHENTICATE_USER);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: true},
        });

        const {email, password} = payload;
        const authenticateTask = yield fork(authenticateUser, {email, password});
        const authenticateResultAction = yield take([
            actionTypes.AUTHENTICATE_USER_SUCCESS,
            actionTypes.AUTHENTICATE_USER_ERROR,
            actionTypes.SIGN_OUT_USER,
        ]);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });

        if (authenticateResultAction.type === actionTypes.AUTHENTICATE_USER_ERROR) {
            // TODO error parser: const {response} = authenticateResultAction.payload;
            yield put({
                type: actionTypes.STORE_SERVER_ERRORS,
                payload: {serverErrors: ['Error Occurred. Incorrect username / password entered. Please try again.']},
            });
            continue;
        }

        if (authenticateResultAction.type === actionTypes.SIGN_OUT_USER) {
            yield cancel(authenticateTask);
            continue;
        }

        const {response} = authenticateResultAction.payload;
        const {userAccount: userAccountDTO, authToken} = response;
        yield put({
            type: actionTypes.STORE_USER_ACCOUNT,
            payload: {userAccountDTO},
        });
        yield put(push(getOnceNewUserSessionRoute() || routePaths.STOREFRONT));

        yield* userSessionFlow(authToken);
    }
};

export default userAuthenticationFlow;
