import {put, take, fork, cancel} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as actionTypes from '../authActionTypes';
import routePaths from '../../routePaths';
import authenticateUser from './authenticateUser';
import createUserAccount from './createUserAccount';
import userSessionFlow from './userSessionFlow';

/**
 * User registration flow (token based)
 */
const userRegistrationFlow = function* userRegistrationFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.REGISTER_AND_AUTHENTICATE_USER);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: true},
        });

        const {email, password, name, termsAndConditionsAccepted} = payload;
        yield fork(createUserAccount, {email, password, name, termsAndConditionsAccepted});
        const signUpResultAction = yield take([
            actionTypes.CREATE_USER_ACCOUNT_SUCCESS,
            actionTypes.CREATE_USER_ACCOUNT_ERROR,
        ]);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });

        if (signUpResultAction.type === actionTypes.CREATE_USER_ACCOUNT_ERROR) {
            // TODO error parser: const {response} = signUpResultAction.payload;
            yield put({
                type: actionTypes.STORE_SERVER_ERRORS,
                payload: {
                    serverErrors: ['Error Occurred. This email address is already in use. If you are a returning user go to Sign in page.'],
                },
            });

            continue;
        }

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: true},
        });

        const signInTask = yield fork(authenticateUser, {email, password});
        const signInResultAction = yield take([
            actionTypes.AUTHENTICATE_USER_SUCCESS,
            actionTypes.AUTHENTICATE_USER_ERROR,
            actionTypes.SIGN_OUT_USER,
        ]);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });

        if (signInResultAction.type === actionTypes.AUTHENTICATE_USER_ERROR) continue;

        if (signInResultAction.type === actionTypes.SIGN_OUT_USER) {
            yield cancel(signInTask);
            continue;
        }

        const {response} = signInResultAction.payload;
        const {userAccount: userAccountDTO, authToken} = response;
        yield put({
            type: actionTypes.STORE_USER_ACCOUNT,
            payload: {userAccountDTO},
        });
        yield put(push(routePaths.SET_CONTACT_PREFERENCES));

        yield* userSessionFlow(authToken);
    }
};

export default userRegistrationFlow;
