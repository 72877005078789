import {fork, put, take} from 'redux-saga/effects';
import moment from 'moment';
import * as actionTypes from '../liveStreamingActionTypes';
import fetchEPGData from './fetchEPGData';

/**
 * Fetch and store epg listing
 *
 * @param {FluxStandardAction} action
 */
const fetchEPGListing = function* fetchEPGListing({payload}) {
    const {startDate, endDate} = payload;
    const timeFrameId = `${startDate.toISOString()} ${endDate.toISOString()}`;

    yield fork(fetchEPGData, {startDate: startDate.unix(), endDate: endDate.unix()}, {timeFrameId});
    while (true) {
        const fetchEPGDataResultAction = yield take([
            actionTypes.FETCH_EPG_DATA_SUCCESS,
            actionTypes.FETCH_EPG_DATA_ERROR,
        ]);
        if (typeof fetchEPGDataResultAction.meta.timeFrameId !== 'undefined'
            && fetchEPGDataResultAction.meta.timeFrameId !== timeFrameId) continue;

        if (!fetchEPGDataResultAction.error) {
            const {response} = fetchEPGDataResultAction.payload;
            const {epgChannels: epgChannelDTOs, epgShows: epgShowDTOs} = response;
            const {availableStartDate, availableEndDate} = response;
            yield put({
                type: actionTypes.STORE_EPG_DATA,
                payload: {
                    epgChannelDTOs,
                    epgShowDTOs,
                    availableStartDate: moment(availableStartDate),
                    availableEndDate: moment(availableEndDate),
                    loadedStartDate: startDate,
                    loadedEndDate: endDate,
                },
            });
        }
        break;
    }
};

export default fetchEPGListing;
