import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push, goBack} from 'connected-react-router';
import * as liveStreamingActions from '../live-streaming/liveStreamingActions';
import './LiveStreamPlayerView.scss';
import {
    withOverlay,
    withOverlayPropTypes,
    withOverlayDefaultProps,
} from '../ui-elements/overlay/withOverlay';
import MediaPlayer from '../ui-elements/media-player-video-js/VideoJSPlayer';
import MediaPlayerControls from '../ui-elements/media-player-controls/MediaPlayerControls';

const HASH_VALUE = 'live-stream-view';

@withOverlay
class LiveStreamPlayerView extends Component {
    static propTypes = {
        ...withOverlayPropTypes,
        liveStreamPlaybackSource: PropTypes.object,
        epgChannel: PropTypes.object,
        stopLiveStreamPlayback: PropTypes.func.isRequired,
        openOverlay: PropTypes.func,
        closeOverlay: PropTypes.func,
        subscribeToOverlayClick: PropTypes.func,
        unsubscribeFromOverlayClick: PropTypes.func,
        location: PropTypes.object.isRequired,
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...withOverlayDefaultProps,
        liveStreamPlaybackSource: null,
        epgChannel: null,
        openOverlay: null,
        closeOverlay: null,
        subscribeToOverlayClick: null,
        unsubscribeFromOverlayClick: null,
    };

    componentDidUpdate(prevProps) {
        const {location} = this.props;
        // stop playback on back browser button click
        return location.hash !== prevProps.location.hash && prevProps.location.hash.includes(HASH_VALUE)
            ? this.onLiveStreamPlaybackStop()
            : null;
    }

    onPlayerReady = () => {
        const {location, push} = this.props;
        const updatedLocation = {
            ...location,
            hash: HASH_VALUE,
        };
        return !location.hash.includes(HASH_VALUE) && push(updatedLocation);
    };

    onLiveStreamPlaybackStop = () => {
        const {stopLiveStreamPlayback, closeOverlay, unsubscribeFromOverlayClick} = this.props;
        stopLiveStreamPlayback();
        closeOverlay();
        unsubscribeFromOverlayClick(this.onOverlayClick);
        this.goBackInHistory();
    };

    onOverlayClick = () => {
        const {stopLiveStreamPlayback, closeOverlay} = this.props;
        stopLiveStreamPlayback();
        closeOverlay();
        this.goBackInHistory();
    };

    goBackInHistory(location = this.props.location, hashValue = HASH_VALUE) {
        const {goBack} = this.props;
        return location.hash.includes(hashValue) && goBack();
    }

    render() {
        const {liveStreamPlaybackSource, epgChannel} = this.props;
        const {openOverlay, subscribeToOverlayClick} = this.props;

        if (liveStreamPlaybackSource && liveStreamPlaybackSource.src) {
            openOverlay();
            subscribeToOverlayClick(this.onOverlayClick);

            // TODO temp call
            this.onPlayerReady();

            return (
                <div className="vub-c-live-stream-player-view">
                    <MediaPlayer playbackSource={liveStreamPlaybackSource}>
                        <MediaPlayerControls
                            epgChannel={epgChannel}
                            closePlayer={this.onLiveStreamPlaybackStop}
                        />
                    </MediaPlayer>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = state => ({
    liveStreamPlaybackSource: state.liveStreaming.liveStreamPlaybackSource,
    epgChannel: state.liveStreaming.epgChannels.get(state.liveStreaming.playbackEPGChannelId),
    location: state.router.location,
});

const mapDispatchToProps = dispatch => ({
    stopLiveStreamPlayback: payload => dispatch(liveStreamingActions.stopLiveStreamPlayback(payload)),
    push: payload => dispatch(push(payload)),
    goBack: () => dispatch(goBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveStreamPlayerView);
