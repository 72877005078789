import React from 'react';
import PropTypes from 'prop-types';
import './Payment.scss';
import CustomSelect from '../../ui-elements/custom-select/CustomSelect';
import Option from '../../ui-elements/custom-select/Option';

const Payment = ({userAccount, paymentMethod, onPaymentMethodSelect, updateUser}) => (
    <div className="vub-c-payment">
        <div className="vub-c-payment__options">
            <div className="vub-c-payment__title">Payment</div>
            {userAccount && userAccount.paymentMethods.length === 0 && (
                <CustomSelect
                    onChange={null}
                    isTouchDeviceCustomDropDownDisabled={true}
                >
                    <Option
                        key="no-card"
                        value="no-card"
                    >
                        No cards available
                    </Option>
                </CustomSelect>
            )}
            {userAccount.paymentMethods.length > 0 && (
                <CustomSelect
                    value={paymentMethod}
                    onChange={onPaymentMethodSelect}
                    isTouchDeviceCustomDropDownDisabled={true}
                >
                    {userAccount.paymentMethods.map(paymentMethod => {
                        const paymentMethodLabel = paymentMethod.creditCard
                            ? `${paymentMethod.creditCard.name} *${paymentMethod.creditCard.account
                                .slice(paymentMethod.creditCard.account.length - 4)}`
                            : 'Unknown';

                        return (
                            <Option
                                key={paymentMethod.id}
                                value={paymentMethod.id}
                            >
                                {paymentMethodLabel}
                            </Option>
                        );
                    })}
                </CustomSelect>
            )}
        </div>
        <label
            className="vub-c-payment__link"
            onClick={updateUser}
        >
            Update my details
        </label>
    </div>
);

Payment.propTypes = {
    userAccount: PropTypes.object,
    paymentMethod: PropTypes.string,
    onPaymentMethodSelect: PropTypes.func,
    updateUser: PropTypes.func,
};

Payment.defaultProps = {
    userAccount: null,
    paymentMethod: null,
    onPaymentMethodSelect: null,
    updateUser: null,
};

export default Payment;
