import React from 'react';
import {Link} from 'react-router-dom';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import './NotFoundPage.scss';

const NotFoundPage = () => (
    <div className="vub-c-not-found-page">
        <div className="vub-c-not-found-page__icon" />
        <div className="vub-c-not-found-page__message">
            <h1 className="vub-c-not-found-page__title">404 Error</h1>
            <div className="vub-c-not-found-page__subtitle">
                Page not found.
            </div>
            <div className="vub-c-not-found-page__subtitle">
                The page you are looking for doesn&#39;t exists or another error has occurred.
            </div>
            <div className="vub-c-not-found-page__mini-menu">
                <Link
                    className="vub-c-not-found-page__link"
                    to={resolveRoute(routePaths.STOREFRONT)}
                >
                    Go Home
                </Link> |
                <Link
                    className="vub-c-not-found-page__link vub-u-margin-left-small"
                    to={resolveRoute(routePaths.PAGE, {pageId: 'contact-us'}, {state: {disableScrollRestoration: false}})}
                >
                    Contact Us
                </Link>
            </div>
        </div>
    </div>
);

export default NotFoundPage;
