import React, {Component} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import screenfull from 'screenfull';
import './AzurePlayer.scss';
import config from '../../config';
import SpinnerStandard from '../spinner/SpinnerStandard';

const MEDIA_PLAYER_CONTROLS_AUTO_HIDE = 5500;
const defaultErrorMessage = 'Something went wrong. Playback error occured. Please try to replay this item.';

class AzurePlayer extends Component {
    static propTypes = {
        playbackSource: PropTypes.object,
        playbackAds: PropTypes.object,
        onMediaPlaybackStop: PropTypes.func,
        isNativeControlsForTouchEnabled: PropTypes.bool,
        isAutoplayEnabled: PropTypes.bool,
        isControlsEnabled: PropTypes.bool,
        logo: PropTypes.object,
        poster: PropTypes.string,
        techOrder: PropTypes.array,
        onPlayerReady: PropTypes.func,
    };

    static defaultProps = {
        playbackSource: null,
        playbackAds: null,
        onMediaPlaybackStop: null,
        isNativeControlsForTouchEnabled: false,
        isAutoplayEnabled: true,
        isControlsEnabled: true,
        logo: {enabled: true},
        poster: null,
        techOrder: [
            'azureHtml5JS',
            'html5FairPlayHLS',
            'html5',
        ],
        onPlayerReady: null,
    };

    constructor(props) {
        super(props);
        this.timeControlsToggle = throttle(this.timeControlsToggle, MEDIA_PLAYER_CONTROLS_AUTO_HIDE - 500);
        this.onTimeUpdate = throttle(this.onTimeUpdate, 1000);
    }

    state = {
        isPlayerReady: null,
        currentTime: 0,
        duration: 0,
        paused: true,
        volume: 50,
        muted: false,
        isFullScreenActive: false,
        areControlsHidden: false,
        controlsToggleTimeoutId: 0,
        errorMessage: null,
        areControlsReady: false,
    };

    async componentDidMount() {
        if (window.amp) {
            this.setPlayer(window.amp);
            if (screenfull.enabled) screenfull.on('change', this.setFullScreenState);
            return;
        }
        try {
            const amp = await this.loadMediaPlayer(config.AZURE_MEDIA_PLAYER_CDN_URL);
            // set player
            this.setPlayer(amp);
            if (screenfull.enabled) screenfull.on('change', this.setFullScreenState);
        } catch (e) {}
    }

    // Current it doesn't call at all, bacause component is in dom all the time
    componentWillUnmount() {
        this.player.ready(this.removePlayerEventListeners);
        this.azurePlayer.current.removeEventListener('mousemove', this.timeControlsToggle);
        this.player.dispose();
        this.player = null;
        if (screenfull.enabled) {
            screenfull.off('change', this.setFullScreenState);
        }
        this.setState({
            isPlayerReady: null,
        });
    }

    addPlayerEventListeners = () => {
        this.player.addEventListener('play', this.onPlay);
        this.player.addEventListener('pause', this.onPause);
        this.player.addEventListener('timeupdate', this.onTimeUpdate);
        this.player.addEventListener('ended', this.onEnded);
        this.player.addEventListener('error', this.onError);
        this.player.addEventListener('durationchange', this.getDuration);
        this.player.addEventListener('loadeddata', this.setControlsReady);
    };

    removePlayerEventListeners = () => {
        this.player.removeEventListener('play', this.onPlay);
        this.player.removeEventListener('pause', this.onPause);
        this.player.removeEventListener('timeupdate', this.onTimeUpdate);
        this.player.removeEventListener('ended', this.onEnded);
        this.player.removeEventListener('error', this.onError);
        this.player.removeEventListener('durationchange', this.getDuration);
        this.player.removeEventListener('loadeddata', this.setControlsReady);
    };

    // player instance
    player = null;

    // inject player in html head
    loadMediaPlayer = async playerCDN => (
        new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = playerCDN;
            scriptElement.addEventListener('load', () => resolve(window.amp));
            scriptElement.addEventListener('error', e => reject(e));
            document.head.appendChild(scriptElement);
        })
    );

    // set player options
    createVideoPlayer = amp => {
        const {
            isAutoplayEnabled,
            isNativeControlsForTouchEnabled,
            isControlsEnabled,
            logo,
            techOrder,
            poster,
        } = this.props;

        return amp(this.mediaPlayer, {
            nativeControlsForTouch: isNativeControlsForTouchEnabled,
            autoplay: isAutoplayEnabled,
            controls: isControlsEnabled,
            logo,
            techOrder,
            ...(poster && {poster}),
        });
    };

    setPlayer = amp => {
        this.player = this.createVideoPlayer(amp);
        this.player.ready(this.addPlayerEventListeners);
        this.player.ready(() => {
            // set source
            this.setPlayerVideoSource();
            this.setState({
                isPlayerReady: true,
                // update hash to router location on player ready
            }, () => this.props.onPlayerReady());
        });
    };

    // set player video source
    setPlayerVideoSource = () => {
        if (!this.player) return;

        const {playbackSource, playbackAds} = this.props;
        if (!playbackAds) {
            this.player.src([{
                src: playbackSource.src,
                type: playbackSource.type,
                protectionInfo: playbackSource.protectionInfo,
            }]);
            return;
        }

        this.player.presentationLayout({
            ...playbackAds,
            mainProgram: {
                source: {
                    src: playbackSource.src,
                    type: playbackSource.type,
                    protectionInfo: playbackSource.protectionInfo,
                },
            },
        });
    };

    // play
    onPlay = () => {
        clearTimeout(this.state.controlsToggleTimeoutId);
        this.setState({
            controlsToggleTimeoutId: setTimeout(this.hideControls, MEDIA_PLAYER_CONTROLS_AUTO_HIDE),
        }, () => {
            this.azurePlayer.current.addEventListener('mousemove', this.timeControlsToggle);
        });
    };

    // pause
    onPause = () => {
        clearTimeout(this.state.controlsToggleTimeoutId);
        this.setState({
            areControlsHidden: false,
            controlsToggleTimeoutId: 0,
        });
    };

    // time update
    onTimeUpdate = () => {
        if (!this.player) return;

        this.setState({
            currentTime: this.player.currentTime(),
            paused: this.player.paused(),
        });
    };

    // error
    onError = () => {
        if (!this.player) return;
        const mediaError = this.player.error();
        this.setState({
            errorMessage: mediaError.message || defaultErrorMessage,
        });
    };

    // ended
    onEnded = () => {
        if (!this.player) return;

        if (typeof this.props.onMediaPlaybackStop === 'function') {
            this.props.onMediaPlaybackStop();
        }
    };

    // toggle playback
    togglePlayback = () => {
        this.setState(state => ({
            paused: !state.paused,
        }), () => {
            this.state.paused ? this.player.pause() : this.player.play();
        });
    };

    setVolume = volume => {
        this.setState(state => ({
            volume,
        }), () => {
            this.player.volume(volume * 0.01);
            this.toggleMute(volume === 0);
        });
    };

    setCurrentTime = time => {
        const currentTime = parseFloat(time);
        this.setState({
            currentTime,
        }, () => {
            this.player.currentTime(currentTime);
        });
    };

    toggleFullScreen = () => {
        this.state.isFullScreenActive ? screenfull.exit() : screenfull.request(this.azurePlayer.current);
        this.setState(state => ({
            isFullScreenActive: !state.isFullScreenActive,
        }));
    };

    setFullScreenState = () => {
        this.setState({
            isFullScreenActive: screenfull.isFullscreen,
        });
    };

    getDuration = () => {
        const duration = this.player.duration() ? Math.round(this.player.duration()) : 0;
        this.setState({
            duration,
        });
    };

    setMute = muted => this.setState({
        muted,
    }, () => this.player.muted(this.state.muted));

    toggleMute = isMuted => this.setState(state => ({
        muted: isMuted !== undefined ? isMuted : !state.muted,
    }), () => this.player.muted(this.state.muted));

    timeControlsToggle = () => {
        if (this.azurePlayer && this.azurePlayer.current) {
            clearTimeout(this.state.controlsToggleTimeoutId);
            this.setState({
                areControlsHidden: false,
                controlsToggleTimeoutId: setTimeout(this.hideControls, MEDIA_PLAYER_CONTROLS_AUTO_HIDE),
            });
        }
    };

    setControlsReady = () => {
        this.setState({
            areControlsReady: true,
        });
    };

    hideControls = () => {
        if (!this.player) return;

        this.setState({
            areControlsHidden: true,
        });
    };

    closePlayer = () => {
        if (typeof this.props.onMediaPlaybackStop === 'function') {
            this.props.onMediaPlaybackStop();
        }

        this.setState({
            currentTime: 0,
            isFullScreenActive: false,
        });
    };

    renderChildren = children => {
        const {
            isFullScreenActive,
            areControlsHidden,
            paused,
            volume,
            muted,
            duration,
            currentTime,
        } = this.state;
        return React.Children.map(children, child => (
            React.cloneElement(child, {
                isPaused: paused,
                volume,
                isMuted: muted,
                isFullScreenActive,
                currentTime,
                duration,
                areControlsHidden,
                setVolume: this.setVolume,
                toggleMute: this.toggleMute,
                togglePlayback: this.togglePlayback,
                toggleFullScreen: this.toggleFullScreen,
                setCurrentTime: this.setCurrentTime,
                closePlayer: this.closePlayer,
            })
        ));
    };

    azurePlayer = React.createRef();

    render() {
        const {children} = this.props;
        const {
            isPlayerReady,
            isFullScreenActive,
            areControlsReady,
            errorMessage,
        } = this.state;
        return (
            <div
                className={`vub-c-amp-container ${isFullScreenActive ? 'vub-c-amp-container--full-screen' : ''}`}
                ref={this.azurePlayer}
            >
                <video
                    className="azuremediaplayer"
                    ref={input => { this.mediaPlayer = input; }}
                />
                {isPlayerReady && areControlsReady ? (
                    this.renderChildren(children)
                ) : !errorMessage ? (
                    <div className="vub-c-amp-container__spinner">
                        <SpinnerStandard className="vub-c-standard-spinner--small" />
                    </div>
                ) : null}
                {errorMessage && (
                    <div className="vub-c-amp-container__errors">
                        <div className="vub-c-amp-container__errors-header">
                            <div className="vub-c-amp-container__errors-title">Error occured</div>
                            <div className="vub-c-amp-container__errors-close" onClick={this.closePlayer} />
                        </div>
                        <div className="vub-c-amp-container__errors-content">{errorMessage}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default AzurePlayer;
