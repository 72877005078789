import Immutable from 'immutable';
import moment from 'moment';
import * as actionTypes from './mediaActionTypes';
import MediaGenre from './records/MediaGenre';
import MediaBucket from './records/MediaBucket';
import MediaItem from './records/MediaItem';

const initialState = {
    media: Immutable.Map(),
    mediaGenres: Immutable.Map(),
    mediaCollections: Immutable.Map(),
    mediaBuckets: Immutable.Map(),
};

// TODO refactor
initialState.mediaBuckets = initialState.mediaBuckets
    .set('MEDIA_SEARCH_VIEW', new MediaBucket({key: 'MEDIA_SEARCH_VIEW', isUpdatePending: true}));

/**
 * Media reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */
const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_MEDIA_GENRES:
            if (action.payload.mediaGenreDTOs) {
                const {mediaGenreDTOs} = action.payload;
                let mediaGenres = state.mediaGenres;

                mediaGenreDTOs.forEach(mediaGenreDTO => {
                    const mediaGenre = new MediaGenre(mediaGenreDTO);
                    mediaGenres = mediaGenres.set(mediaGenre.id, mediaGenre);
                });

                state = {
                    ...state,
                    mediaGenres: mediaGenres,
                };
            }
            break;

        case actionTypes.SET_MEDIA_BUCKET:
            if (action.payload.mediaBucketKey) {
                const {mediaBucketKey, invalidateCurrentSet, isLoadingMedia} = action.payload;
                let mediaBucket = state.mediaBuckets.get(mediaBucketKey)
                    || new MediaBucket({
                        key: mediaBucketKey,
                        isUpdatePending: true,
                    });

                mediaBucket = mediaBucket.merge({
                    ...(invalidateCurrentSet && {
                        mediaTotalCount: null,
                        mediaIds: Immutable.List(),
                        media: Immutable.OrderedMap(),
                        primaryMediaIds: Immutable.List(),
                    }),
                    ...(typeof isLoadingMedia !== 'undefined' && {
                        isUpdatePending: true,
                        isLoadingMedia,
                    }),
                });

                state = {
                    ...state,
                    mediaBuckets: state.mediaBuckets.set(mediaBucketKey, mediaBucket),
                };
            }
            break;

        case actionTypes.STORE_MEDIA_BUCKET_MEDIA:
            if (action.payload.mediaBucketKey) {
                const {mediaBucketKey, mediaTotalCount, mediaDTOs, primaryMediaIds, facets} = action.payload;
                let mediaBucket = state.mediaBuckets.get(mediaBucketKey);
                let media = state.media;

                const mediaIds = [...mediaBucket.mediaIds];
                mediaDTOs.forEach(mediaItemDTO => {
                    const mediaItem = new MediaItem({}).fromDTO(mediaItemDTO);
                    media = media.set(mediaItem.id, mediaItem);
                    mediaIds.push(mediaItem.id);
                });

                mediaBucket = mediaBucket.merge({
                    mediaTotalCount: mediaTotalCount,
                    mediaIds: [...new Set(mediaIds)],
                    primaryMediaIds: primaryMediaIds,
                    ...(facets && {facets}),
                    isUpdatePending: false,
                    isLoadingMedia: false,
                    updateTime: moment(),
                });

                state = {
                    ...state,
                    mediaBuckets: state.mediaBuckets.set(mediaBucket.key, mediaBucket),
                    media: media,
                };
            }
            break;

        default:
        // no-op
    }
    return state;
};

export default mediaReducer;
