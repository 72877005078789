import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {getPrice, getOfferTypeString} from './helpers';
import './MediaGalleryListEntryOffers.scss';

const MediaGalleryListEntryOffers = ({groupedOffers, providers}) => (
    <Fragment>
        <div className="vub-c-media-gallery-list-entry__offers-title">
                Available on:
        </div>
        <div className="vub-c-media-gallery-list-entry__offers">
            {groupedOffers && Object.entries(groupedOffers).map(([provider, offers]) => {
                const getProvider = providers.get(provider);
                const availableResolutions = [];
                return (
                    <div key={getProvider} className="vub-c-media-gallery-list-entry__offer">
                        <div className="vub-c-media-gallery-list-entry__provider-img">
                            {getProvider && (
                            <img
                                src={getProvider.serviceLogo}
                                alt={getProvider.provider}
                            />
                            )}
                        </div>
                        <div className="vub-c-media-gallery-list-entry__offer-resolutions">
                            {offers.map((offer, index) => {
                                if (availableResolutions.includes(offer.resolution)) return;
                                availableResolutions.push(offer.resolution);
                                return (
                                    <span
                                        key={`${offer.resolution}-${index}`}
                                        className="vub-c-media-gallery-list-entry__offer-resolution"
                                    >{offer.resolution}
                                    </span>
                                );
                            })}
                        </div>
                        <div className="vub-c-media-gallery-list-entry__offer-types">
                            {offers.map((offer, index) => {
                                const label = getOfferTypeString(offer.offerType);
                                return (
                                    <div
                                        key={`${offer.offerType}-${index}`}
                                        className={`vub-c-media-gallery-list-entry__${label.toLowerCase()}`}
                                    >
                                        {label !== 'Subscription'
                                            ? (
                                                <div>
                                                    <span className="vub-c-media-gallery-list-entry__weighted">
                                                        {label}
                                                    </span>
                                                        from {getPrice(offer)}
                                                </div>
                                            )
                                            : (
                                                <div>
                                                    <span className="vub-c-media-gallery-list-entry__weighted">
                                                        {label}
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    </Fragment>
);

MediaGalleryListEntryOffers.propTypes = {
    groupedOffers: PropTypes.object,
    providers: PropTypes.object,
};

MediaGalleryListEntryOffers.defaultProps = {
    groupedOffers: null,
    providers: null,
};

export default MediaGalleryListEntryOffers;
