import React from 'react';
import PropTypes from 'prop-types';
import './ProductInfo.scss';

const ProductInfo = ({title, description}) => {
    return (
        <div className="vub-c-product-info">
            <div className="vub-c-product-info__title">
                {title}
            </div>
            <div className="vub-c-product-info__description">
                {description}
            </div>
        </div>
    );
};

ProductInfo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

ProductInfo.defaultProps = {
    title: null,
    description: null,
};

export default ProductInfo;
