/**
 * Entitlement action types
 */
export const CHECKOUT_SUBSCRIPTION = 'CHECKOUT_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_CHECKOUT = 'CANCEL_SUBSCRIPTION_CHECKOUT';
export const FAST_CHECKOUT_MEDIA_ITEM_OFFER = 'FAST_CHECKOUT_MEDIA_ITEM_OFFER';
export const CANCEL_MEDIA_ITEM_OFFER_FAST_CHECKOUT = 'CANCEL_MEDIA_ITEM_OFFER_FAST_CHECKOUT';
export const CHARGE_BASKET = 'CHARGE_BASKET';

export const CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_REQUEST = 'CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_REQUEST';
export const CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_SUCCESS = 'CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_SUCCESS';
export const CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR = 'CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR';

export const CHARGE_BASKET_REQUEST = 'CHARGE_BASKET_REQUEST';
export const CHARGE_BASKET_SUCCESS = 'CHARGE_BASKET_SUCCESS';
export const CHARGE_BASKET_ERROR = 'CHARGE_BASKET_ERROR';

export const FETCH_CURRENT_ENTITLEMENTS_REQUEST = 'FETCH_CURRENT_ENTITLEMENTS_REQUEST';
export const FETCH_CURRENT_ENTITLEMENTS_SUCCESS = 'FETCH_CURRENT_ENTITLEMENTS_SUCCESS';
export const FETCH_CURRENT_ENTITLEMENTS_ERROR = 'FETCH_CURRENT_ENTITLEMENTS_ERROR';

export const START_MEDIA_ITEM_OFFER_PLAYBACK = 'START_MEDIA_ITEM_OFFER_PLAYBACK';
export const START_MEDIA_ITEM_SVOD_OFFER_PLAYBACK = 'START_MEDIA_ITEM_SVOD_OFFER_PLAYBACK';
export const STOP_MEDIA_ITEM_PLAYBACK = 'STOP_MEDIA_ITEM_PLAYBACK';

export const FETCH_MEDIA_ITEM_PLAYBACK_INFO_REQUEST = 'FETCH_MEDIA_ITEM_PLAYBACK_INFO_REQUEST';
export const FETCH_MEDIA_ITEM_PLAYBACK_INFO_SUCCESS = 'FETCH_MEDIA_ITEM_PLAYBACK_INFO_SUCCESS';
export const FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR = 'FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR';

export const FETCH_MEDIA_ITEM_OFFER_ADS_REQUEST = 'FETCH_MEDIA_ITEM_OFFER_ADS_REQUEST';
export const FETCH_MEDIA_ITEM_OFFER_ADS_SUCCESS = 'FETCH_MEDIA_ITEM_OFFER_ADS_SUCCESS';
export const FETCH_MEDIA_ITEM_OFFER_ADS_ERROR = 'FETCH_MEDIA_ITEM_OFFER_ADS_ERROR';

export const CREATE_PURCHASE_ORDER_ITEM_SVOD_REQUEST = 'CREATE_PURCHASE_ORDER_ITEM_SVOD_REQUEST';
export const CREATE_PURCHASE_ORDER_ITEM_SVOD_SUCCESS = 'CREATE_PURCHASE_ORDER_ITEM_SVOD_SUCCESS';
export const CREATE_PURCHASE_ORDER_ITEM_SVOD_ERROR = 'CREATE_PURCHASE_ORDER_ITEM_SVOD_ERROR';

export const STORE_CURRENT_ENTITLEMENTS = 'STORE_CURRENT_ENTITLEMENTS';
export const STORE_SUBSCRIPTION_CHECKOUT_STATUS = 'STORE_SUBSCRIPTION_CHECKOUT_STATUS';
export const STORE_SUBSCRIPTION_BASKET_ITEM = 'STORE_SUBSCRIPTION_BASKET_ITEM';
export const STORE_FAST_CHECKOUT_STATUS = 'STORE_FAST_CHECKOUT_STATUS';
export const STORE_BASKET_ITEM = 'STORE_BASKET_ITEM';
export const STORE_BASKET_CHARGING_STATUS = 'STORE_BASKET_CHARGING_STATUS';
export const STORE_MEDIA_ITEM_PLAYBACK_SOURCE = 'STORE_MEDIA_ITEM_PLAYBACK_SOURCE';

export const REMOVE_BASKET_ITEM = 'REMOVE_BASKET_ITEM';
export const RESET_CURRENT_ENTITLEMENTS = 'RESET_CURRENT_ENTITLEMENTS';
export const RESET_BASKET = 'RESET_BASKET';
