import React, {PureComponent} from 'react';
import './PaginationButton.scss';
import Button from '../../ui-kit/Button';

/**
 * PaginationButton Component
 */
class PaginationButton extends PureComponent {
    /**
     * React: propTypes
     */
    static propTypes = {
        ...Button.propTypes,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        ...Button.defaultProps,
    };

    /**
     * React: render
     */
    render() {
        return (
            <Button {...this.props} blockName={`vub-c-pagination-button ${this.props.isActive ? 'vub-c-pagination-button--is-active' : ''}`} />
        );
    }
}

export default PaginationButton;
