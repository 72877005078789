import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification, mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../media-entitlement/withMediaActions';
import './CTAButtonBarView.scss';
import PurchaseCTAButtonsTVOD from './elements/PurchaseCTAButtonsTVOD';
import PurchaseCTAButtonsEST from './elements/PurchaseCTAButtonsEST';
import PurchaseCTAButtonsSVOD from './elements/PurchaseCTAButtonsSVOD';
import PurchaseCTAButtonsAVOD from './elements/PurchaseCTAButtonsAVOD';
import PurchaseCTAButtonsWithPopup from './elements/PurchaseCTAButtonsWithPopup';
import WatchCTAButtons from './elements/WatchCTAButtons';
// TODO To be removed
import {avengersEndGame} from './fakeOffers';

@withMediaActions
class CTAButtonBarView extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {isUserSignedIn, userAccount, providers, fastCheckoutMediaItemOffer} = this.props;
        let {mediaItem, currentEntitlements} = this.props;

        // display CTA button bar only for movies and episodes
        if (mediaItem.classification !== mediaItemClassification.MOVIE
            && mediaItem.classification !== mediaItemClassification.EPISODE) return null;

        // TODO
        // if purchased & wide: "watch" bar with more actions in pop-up
        // if not purchased & narrow: "offers" bar with actions in pop-up
        // if purchased & narrow: "watch" bar with actions in pop-up

        // TODO Remove mock offers for Avengers - Endgame after api becomes operational
        let newMediaItem;
        if (mediaItem.id === 'VUBI0000000001452610') {
            newMediaItem = mediaItem.set('offers', avengersEndGame);
            mediaItem = mediaItem.merge(newMediaItem);
        }

        const isAVODOfferAvailable = !isUserSignedIn
            && !!mediaItem.offers.find(offer => offer.offerType === mediaItemOfferType.AVOD);

        const entitlementOfferIds = currentEntitlements
            ? currentEntitlements.map(entitlement => entitlement.offerId) : [];

        const signedInUserHasEntitlements = isUserSignedIn
            && !!mediaItem.offers.find(offer => {
                if (offer.offerType === mediaItemOfferType.AVOD) {
                    return true;
                }

                if (offer.offerType === mediaItemOfferType.SVOD) {
                    return !!userAccount.subscriptions.find(subscription => subscription.provider === offer.provider);
                }

                if (offer.offerType === mediaItemOfferType.TVOD || offer.offerType === mediaItemOfferType.EST) {
                    return entitlementOfferIds.includes(offer.id);
                }

                return false;
            });

        return (
            <div className="vub-c-cta-button-bar-view">
                {isAVODOfferAvailable && (
                    <PurchaseCTAButtonsAVOD mediaItem={mediaItem} />
                )}
                {signedInUserHasEntitlements && (
                    <WatchCTAButtons mediaItem={mediaItem} />
                )}
                {!isAVODOfferAvailable && !signedInUserHasEntitlements && (
                    <Fragment>
                        <div className="vub-c-cta-button-bar-view__mobile">
                            <PurchaseCTAButtonsWithPopup
                                mediaItem={mediaItem}
                                providers={providers}
                                fastCheckoutMediaItemOffer={fastCheckoutMediaItemOffer}
                            />
                        </div>
                        <div className="vub-c-cta-button-bar-view__standard">
                            <PurchaseCTAButtonsTVOD mediaItem={mediaItem} />
                            <PurchaseCTAButtonsEST mediaItem={mediaItem} />
                            <PurchaseCTAButtonsSVOD mediaItem={mediaItem} />
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default CTAButtonBarView;
