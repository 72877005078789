import {all, fork, takeEvery} from 'redux-saga/effects';
import * as authActionTypes from '../auth/authActionTypes';
import checkoutSubscriptionFlow from './sagas/checkoutSubscriptionFlow';
import fastCheckoutMediaItemOfferFlow from './sagas/fastCheckoutMediaItemOfferFlow';
import startMediaItemOfferPlaybackFlow from './sagas/startMediaItemOfferPlaybackFlow';
import setEntitlementServiceAuthToken from './sagas/setEntitlementServiceAuthToken';
import invalidateUserSessionData from './sagas/invalidateUserSessionData';

/**
 * Media entitlement watcher saga
 */
export const mediaEntitlementWatcher = function* mediaEntitlementWatcher() {
    yield all([
        fork(checkoutSubscriptionFlow),
        fork(fastCheckoutMediaItemOfferFlow),
        fork(startMediaItemOfferPlaybackFlow),
        takeEvery(authActionTypes.USER_SESSION_STARTED, setEntitlementServiceAuthToken),
        takeEvery(authActionTypes.USER_SESSION_ENDED, invalidateUserSessionData),
    ]);
};
