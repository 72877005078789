import {call, put, race, take} from 'redux-saga/effects';
import {providerAuthenticationType} from '@comrock/vub-wls-auth-service';
import * as actionTypes from '../authActionTypes';
import {authService} from '../../services';
import routePaths from '../../routePaths';
import config from '../../config';

const userSessionFlow = function* userSessionFlow(authToken) {
    yield put({
        type: actionTypes.USER_SESSION_STARTED,
        payload: {authToken},
    });

    const {userSessionExpired, signOutAction} = yield race({
        userSessionExpired: call(authService.hasUserSessionExpired),
        signOutAction: take(actionTypes.SIGN_OUT_USER),
        userSessionStartedAction: take(actionTypes.USER_SESSION_STARTED),
    });

    if (signOutAction) {
        yield call(authService.endUserSession);
    }

    if (userSessionExpired || signOutAction) {
        yield put({
            type: actionTypes.USER_SESSION_ENDED,
            payload: {},
        });
    }

    if (signOutAction && authService.authenticationType === providerAuthenticationType.SINGLE_SIGN_ON) {
        const oauth2SignOutUri = authService.getOAuth2SignOutUri({
            redirectUri: `${config.BASE_URL}${routePaths.STOREFRONT}`,
        });
        yield call(() => window.location.assign(oauth2SignOutUri));
    }
};

export default userSessionFlow;
