import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './ContentRowHeader.scss';

const ContentRowHeader = ({title, subtitle, linkTo}) => (
    <div className="vub-c-content-row-header">
        <div className="vub-c-content-row-header__title">
            {linkTo ? (
                <Link
                    className="vub-c-content-row-header__title-link"
                    to={linkTo}
                >
                    {title}
                </Link>
            ) : title}
        </div>
        <div className="vub-c-content-row-header__subtitle">
            {linkTo ? (
                <Link
                    className="vub-c-content-row-header__title-link"
                    to={linkTo}
                >
                    {subtitle}
                </Link>
            ) : subtitle}
        </div>
    </div>
);

ContentRowHeader.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    linkTo: PropTypes.object,
};

ContentRowHeader.defaultProps = {
    title: null,
    subtitle: null,
    linkTo: null,
};

export default ContentRowHeader;
