import * as actionTypes from './liveStreamingActionTypes';

/**
 * Fetch EPG listing
 */
export const fetchEPGListing = (payload = {}) => ({type: actionTypes.FETCH_EPG_LISTING, payload});

/**
 * Start live stream playback
 */
export const startLiveStreamPlayback = (payload = {}) => ({
    type: actionTypes.START_LIVE_STREAM_PLAYBACK,
    payload,
});

/**
 * Stop live stream playback
 */
export const stopLiveStreamPlayback = (payload = {}) => ({
    type: actionTypes.STOP_LIVE_STREAM_PLAYBACK,
    payload,
});
