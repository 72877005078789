import React, {Component} from 'react';
import {compose, setDisplayName, wrapDisplayName} from 'recompose';
import BaseElement from '../BaseElement';
import DisabledRole from '../roles/DisabledRole';
import SwitchRole from '../roles/SwitchRole';

const SwitchBehavior = BaseComponent => {
    const PreComposedComponent = compose(
        SwitchRole,
        DisabledRole,
    )(BaseComponent);

    @setDisplayName(wrapDisplayName(PreComposedComponent, 'SwitchBehavior'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...PreComposedComponent.propTypes,
        };

        static defaultProps = {
            ...PreComposedComponent.defaultProps,
        };

        render() {
            return (
                <PreComposedComponent {...this.props} />
            );
        }
    }

    return ComposedComponent;
};

const {SwitchBehaviorPropTypes, SwitchBehaviorDefaultProps} = SwitchBehavior(BaseElement);

export {
    SwitchBehavior as default,
    SwitchBehavior,
    SwitchBehaviorPropTypes,
    SwitchBehaviorDefaultProps,
};
