import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './FormContainer.scss';
import {Validator} from '../form-validation/validations';

export default class FormContainer extends Component {
    static propTypes = {
        initialState: PropTypes.object,
        getFormValues: PropTypes.func,
        render: PropTypes.func,
    };

    static defaultProps = {
        initialState: {},
        getFormValues: null,
        render: null,
    };

    constructor(props) {
        super(props);
        this.state = {...props.initialState, validationList: []};
    }

    handleFormValues = (name, value, validators, isTouched) => {
        this.setState(state => ({
            [name]: value,
        }));
        // validation
        validators && this.handleValidation(name, value, validators, isTouched);
    };

    callFunctionType = item => {
        if (typeof item === 'function') {
            return item();
        }
        return item;
    };

    validationObjectList = [];

    // TODO - make this better
    handleValidation = (name, value, validators, isTouched) => {
        const validations = Array.isArray(validators)
            ? validators.map(validator => this.callFunctionType(validator))
            : this.callFunctionType(validators);
        const validationObject = {
            name,
            value,
            validations,
            isTouched: isTouched || (this.state.errors[name] && this.state.errors[name].isTouched),
        };
        this.validationObjectList = this.validationObjectList.find(error => error.name === name)
            ? [...this.validationObjectList.filter(error => error.name !== name), validationObject]
            : [...this.validationObjectList, validationObject];
        this.setValidationErrors(this.validationObjectList);
    }

    // validation errors
    setValidationErrors = list => {
        let errors = {};
        list.forEach(validation => {
            const errorInstance = Array.isArray(validation.validations)
                ? new Validator(...validation.validations)
                : new Validator(validation.validations);
            errors = {
                ...errors,
                [validation.name]: errorInstance.validate(validation.isTouched, validation.value, this.state),
            };
        });
        this.setState(state => {
            return {
                errors: this.filterErrors(state, errors),
            };
        });
    }

    // TODO - workaround for validaiton for account settings form (mixed update profile and change password form)
    filterErrors = (state, errors) => {
        const fields = ['oldPassword', 'newPassword', 'confirmedPassword'];
        if (fields.every(field => state.hasOwnProperty(field) && !state[field])) {
            // remove validation errors for specified fields
            // when those fields are empty
            return Object.keys(errors)
                .filter(el => !fields.includes(el))
                .reduce((prev, curr) => {
                    prev[curr] = errors[curr];
                    return prev;
                }, {});
        }
        return errors;
    }

    handleSubmit = (e, state) => {
        e.preventDefault();
        this.props.getFormValues(state);
    };

    handleReset = e => {
        e.preventDefault();
        this.setState(() => this.props.initialState);
    };

    render() {
        return (
            <form
                className="vub-c-auth-form-container"
                onSubmit={event => this.handleSubmit(event, this.state)}
            >
                {this.props.render(this.state, this.handleFormValues, this.handleReset)}
            </form>
        );
    }
}
