/**
 * Navigation action types
 */
export const FETCH_NAVIGATION = 'FETCH_NAVIGATION';
export const FETCH_NAVIGATION_REQUEST = 'FETCH_NAVIGATION_REQUEST';
export const FETCH_NAVIGATION_SUCCESS = 'FETCH_NAVIGATION_SUCCESS';
export const FETCH_NAVIGATION_ERROR = 'FETCH_NAVIGATION_ERROR';

export const FETCH_STOREFRONT_ROWS_REQUEST = 'FETCH_STOREFRONT_ROWS_REQUEST';
export const FETCH_STOREFRONT_ROWS_SUCCESS = 'FETCH_STOREFRONT_ROWS_SUCCESS';
export const FETCH_STOREFRONT_ROWS_ERROR = 'FETCH_STOREFRONT_ROWS_ERROR';
export const STORE_STOREFRONT_ROWS = 'STORE_STOREFRONT_ROWS';
