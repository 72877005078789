import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetailsContentInfo.scss';

const MediaItemDetailsContentInfo = ({mediaItem}) => (
    <div className="vub-c-media-item-details-content-info">
        <p className="vub-c-media-item-details-content-info__row">
            <span className="vub-c-media-item-details-content-info__title">
                Parental Advisory:
            </span>
            <span className="vub-c-media-item-details-content-info__text">
                {mediaItem.parentalAdvisory}
            </span>
        </p>
        <p className="vub-c-media-item-details-content-info__row">
            <span className="vub-c-media-item-details-content-info__title">
                Genre:
            </span>
            <span className="vub-c-media-item-details-content-info__text">
                {mediaItem.mediaGenreId}
            </span>
        </p>
    </div>
);

MediaItemDetailsContentInfo.propTypes = {
    mediaItem: PropTypes.object.isRequired,
};

export default MediaItemDetailsContentInfo;
