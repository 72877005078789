import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../../media-entitlement/withMediaActions';
import CTAButton from '../../ui-elements/cta-button/CTAButton';

@withMediaActions
class PurchaseCTAButtonsAVOD extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {mediaItem, providers} = this.props;
        const {isFastCheckoutInProgress, checkoutSubscription} = this.props;
        const offers = mediaItem.offers.filter(offer => offer.offerType === mediaItemOfferType.AVOD);

        if (offers.length === 1) {
            const offer = offers[0];
            const fastCheckoutSubscription = () => checkoutSubscription(offer);
            const provider = providers.get(offer.provider);

            return (
                <CTAButton
                    key={offer.id}
                    onClick={fastCheckoutSubscription}
                    isDisabled={isFastCheckoutInProgress}
                >
                    <span>
                        Play
                    </span>
                    {provider && (
                        <img
                            className="vub-c-cta-button__icon vub-u-margin-left-small"
                            src={provider.serviceCTALogo}
                            alt="cta-icon"
                        />
                    )}
                </CTAButton>
            );
        }

        // return null if no render condition is matched
        return null;
    }
}

export default PurchaseCTAButtonsAVOD;
