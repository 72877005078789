import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';

const globalClassNames = {};

export const withStyles = styles => BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'withStyles'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
            classNameResolver: PropTypes.func,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            classNameResolver: null,
        };

        createClassNameResolver = blockName => {
            return className => {
                // skip if classname is global
                if (className.includes('global!') || typeof globalClassNames[className] !== 'undefined') {
                    return className;
                }

                const elementModifierIndex = className.indexOf('__') !== -1
                    ? className.indexOf('__')
                    : (className.indexOf('--') !== -1 ? className.indexOf('--') : -1);

                const bemClassName = blockName + (elementModifierIndex !== -1
                    ? className.substr(elementModifierIndex, className.length) : '');

                return (styles && styles[bemClassName])
                    || (styles && styles[className])
                    || className;
            };
        };

        classNames = (...classArguments) => {
            const {classNameResolver} = this.props;
            const classes = [];

            // go trough classname arguments and parse them into classes depending on type
            classArguments.forEach(classArgument => {
                if (typeof classArgument === 'string' && classArgument.includes(' ')) {
                    classes.push(this.classNames(...classArgument.split(' ')));
                }

                if ((typeof classArgument === 'string' && !classArgument.includes(' '))
                    || typeof classArgument === 'number') {
                    classes.push((classNameResolver && classNameResolver(classArgument))
                        || (styles && styles[classArgument])
                        || classArgument);
                }

                if (Array.isArray(classArgument)) {
                    classes.push(this.classNames(...classArgument));
                }

                if (!Array.isArray(classArgument) && typeof classArgument === 'object') {
                    Object.keys(classArgument).forEach(classKey => {
                        if (classArgument[classKey]) {
                            classes.push((classNameResolver && classNameResolver(classKey))
                                || (styles && styles[classKey])
                                || classKey);
                        }
                    });
                }
            });

            classes.forEach(className => {
                if (className.includes('global!')) globalClassNames[className.replace('global!', '')] = className;
            });

            return classes.map(className => className.replace('global!', '')).join(' ');
        };

        render() {
            const {classNameResolver, ...restProps} = this.props;
            return (
                <BaseComponent
                    {...restProps}
                    classNames={this.classNames}
                    cx={this.classNames}
                    createClassNameResolver={this.createClassNameResolver}
                />
            );
        }
    }

    return ComposedComponent;
};

export const withStylesPropTypes = {
    className: PropTypes.string,
    classNames: PropTypes.func,
    cx: PropTypes.func,
    createClassNameResolver: PropTypes.func,
};

export const withStylesDefaultProps = {
    className: '',
    classNames: () => {},
    cx: () => {},
    createClassNameResolver: () => {},
};
