import React from 'react';
import PropTypes from 'prop-types';
import './PurchaseOptions.scss';
import LayoutBox from '../layout-box/LayoutBox';
import LayoutBoxRow from '../layout-box/LayoutBoxRow';

const PurchaseOptions = ({children, closePopUp, title}) => {
    return (
        <LayoutBox>
            <div className="vub-c-purchase-options">
                <LayoutBoxRow
                    title={title}
                />
                {children}
                <LayoutBoxRow>
                    <div className="vub-c-purchase-options__buttons">
                        <button
                            type="button"
                            className="vub-c-purchase-options__button"
                            onClick={closePopUp}
                        >
                            Cancel
                        </button>
                    </div>
                </LayoutBoxRow>
            </div>
        </LayoutBox>
    );
};

PurchaseOptions.propTypes = {
    closePopUp: PropTypes.func,
    title: PropTypes.string,
};

PurchaseOptions.defaultProps = {
    closePopUp: null,
    title: 'Purchase options',
};

export default PurchaseOptions;
