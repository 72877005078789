import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import * as selectors from './mediaItemViewSelectors';
import {getMediaItem} from '../media/mediaBucketHelpers';
import ContentRow from '../layout/ContentRow';
import MediaItemDetails from '../ui-elements/media-item-details/MediaItemDetails';

@withRouter
class MediaItemView extends Component {
    static propTypes = {
        mediaBucket: PropTypes.object,
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        mediaBucket: null,
    };

    render() {
        const {mediaBucket, match} = this.props;
        const {mediaItemId} = match.params;
        const mediaItem = mediaBucket && mediaItemId ? getMediaItem(mediaBucket, mediaItemId) : null;

        return (
            mediaItem ? (
                <ContentRow>
                    <MediaItemDetails mediaItem={mediaItem} />
                </ContentRow>
            ) : null
        );
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaItemViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
    });
};

export default connect(
    createMapStateToProps,
)(MediaItemView);
