import React from 'react';
import PropTypes from 'prop-types';
import './InputValidationError.scss';

const InputValidationError = ({error}) => (
    <div className="vub-c-input-validation-error">
        {error
            && error.messages
            && error.messages.map((message, index) => (
                <div key={index} className="vub-c-input-validation-error__item">
                    {message}
                </div>
            ))
        }
    </div>
);

InputValidationError.propTypes = {
    error: PropTypes.object,
};

InputValidationError.defaultProps = {
    error: {},
};

export default InputValidationError;

