import React from 'react';

export default React.createContext({
    selectedTime: null,
    currentTime: null,
    epgChannels: null,
    epgChannelShows: {},
    epgShowPositions: {},
    timelineScaleFractions: [],
    isScrollToPreviousAvailable: false,
    isScrollToNextAvailable: false,
    slideTrayWidth: 0,
    slideTrayOffset: 0,
    markerOffset: 0,
    isMarkerVisible: false,
    startLiveStreamPlayback: () => {},
    scrollToTime: () => {},
});
