import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect';
import './CustomSelect.scss';
import CustomDropDownTouchDevice from './CustomDropDownTouchDevice';
import {
    withOverlay,
    withOverlayPropTypes,
    withOverlayDefaultProps,
} from '../overlay/withOverlay';
import {
    withOutsideClick,
    withOutsideClickPropTypes,
    withOutsideClickDefaultProps,
} from '../outside-click/withOutsideClick';

@withOverlay
@withOutsideClick
class CustomSelect extends Component {
    static propTypes = {
        ...withOutsideClickPropTypes,
        ...withOverlayPropTypes,
        label: PropTypes.string,
        value: PropTypes.string,
        children: PropTypes.node,
        onChange: PropTypes.func,
        isTouchDeviceCustomDropDownDisabled: PropTypes.bool,
    };

    static defaultProps = {
        ...withOutsideClickDefaultProps,
        ...withOverlayDefaultProps,
        label: null,
        value: null,
        children: null,
        onChange: null,
        isTouchDeviceCustomDropDownDisabled: false,
    };

    state = {
        isActive: false,
    };

    componentDidMount() {
        this.props.subscribeToClickOutside(this.clickOutsideRef, this.onClickOutside);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromClickOutside(this.clickOutsideRef, this.onClickOutside);
    }

    onClickOutside = () => {
        if (!this.props.isOverlayOpened) {
            this.setState({
                isActive: false,
            }, () => this.clearOptionsRefs());
        }
        return false;
    };

    arrayOfOptionsRefs = [];

    setOptionRef = element => {
        if (element !== null) {
            this.arrayOfOptionsRefs.push(element);
        }
    };

    clearOptionsRefs = () => {
        this.arrayOfOptionsRefs = [];
    };

    currentOptionIndex = 0;

    handleOptionsOnKeyDown = (e, index, value, parentValue) => {
        e.preventDefault();
        const disabledClass = 'vub-c-custom-select-item--is-disabled';
        const len = this.arrayOfOptionsRefs.length - 1;
        switch (e.key) {
            case 'Enter':
            case ' ':
                this.toggleDropDown();
                this.props.onChange(value, parentValue);
                break;
            case 'Escape':
                this.toggleDropDown();
                this.currentOptionIndex = 0;
                break;
            case 'ArrowUp':
                if (index > 0) {
                    let nextIndex = index - 1;
                    if (this.arrayOfOptionsRefs[index - 1].classList.contains(disabledClass) && index > 1) {
                        nextIndex = index - 2;
                    }
                    this.arrayOfOptionsRefs[nextIndex].focus();
                    this.currentOptionIndex = nextIndex;
                    this.arrayOfOptionsRefs[index].blur();
                }
                break;
            case 'ArrowDown':
                if (len > index) {
                    let previousIndex = index + 1;
                    if (this.arrayOfOptionsRefs[index + 1].classList.contains(disabledClass) && index < len - 1) {
                        previousIndex = index + 2;
                    }
                    this.arrayOfOptionsRefs[previousIndex].focus();
                    this.arrayOfOptionsRefs[this.currentOptionIndex].blur();
                    this.currentOptionIndex = previousIndex;
                }
                break;
            default:
                return;
        }
    }

    handleOptionsOnMouseOver = (e, index) => {
        this.arrayOfOptionsRefs[index].focus();
        if (index !== this.currentOptionIndex) {
            this.arrayOfOptionsRefs[this.currentOptionIndex].blur();
            this.currentOptionIndex = index;
        }
    }

    toggleDropDown = e => {
        this.setState(state => ({
            isActive: !state.isActive,
        }), () => {
            if (this.state.isActive) {
                this.arrayOfOptionsRefs[this.currentOptionIndex].focus();
                return;
            }
            this.clearOptionsRefs();
            this.clickOutsideRef.current.focus();
        });
    };

    getSelectedOption = (value, options = []) => {
        if (options.length > 0) {
            // selected option
            const selectedOption = options.find(option => option.props && option.props.value === value);
            if (selectedOption) {
                return selectedOption;
            }
            // default option
            const defaultOption = options.find(option => option.props && option.props.isDefault);
            if (defaultOption) {
                return defaultOption;
            }
            // first option
            return options[0];
        }
    }

    clickOutsideRef = React.createRef();

    render() {
        const {
            label,
            children,
            value,
            onChange,
            className,
            closeOverlay,
            openOverlay,
            isTouchDeviceCustomDropDownDisabled,
        } = this.props;
        const options = React.Children.toArray(children);
        const selectedOption = this.getSelectedOption(value, options);
        const selectedValue = selectedOption && selectedOption.props && selectedOption.props.value;
        const updatedChildren = React.Children.map(
            children,
            (child, index) => React.cloneElement(child, {
                onChange,
                selectedValue,
                setOptionRef: this.setOptionRef,
                handleOptionsOnKeyDown: this.handleOptionsOnKeyDown,
                handleOptionsOnMouseOver: this.handleOptionsOnMouseOver,
                index,
            })
        );

        // show pop up with native app download option if user on mobile
        const md = new MobileDetect(window.navigator.userAgent);
        const isTouchDevice = md.os() === 'AndroidOS' || md.os() === 'iOS';
        return (
            <div
                onClick={this.toggleDropDown}
                className={`vub-c-custom-select ${className || ''}`}
                ref={this.clickOutsideRef}
                tabIndex="0"
            >
                {label && <label className="vub-c-custom-select__label">{label}</label>}
                <div className="vub-c-custom-select__field">
                    <span className="vub-c-custom-select__field-value">
                        {selectedOption && selectedOption.props && selectedOption.props.children}
                    </span>
                    <div className="vub-c-custom-select__icon" />
                </div>
                {(this.state.isActive) && (
                    isTouchDevice && !isTouchDeviceCustomDropDownDisabled ? (
                        <CustomDropDownTouchDevice
                            openOverlay={openOverlay}
                            closeOverlay={closeOverlay}
                        >
                            <li className="vub-c-custom-select-item vub-c-custom-select-item--is-disabled">{label}</li>
                            {updatedChildren}
                        </CustomDropDownTouchDevice>
                    ) : (
                        <ul className="vub-c-custom-select__drop-down" tabIndex="0">
                            {updatedChildren}
                        </ul>
                    ))}
            </div>
        );
    }
}

export default CustomSelect;

