import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as liveStreamingActions from '../live-streaming/liveStreamingActions';
import {EPG, EPGTimeline, EPGGrid, EPGMarker} from '../ui-elements/epg';
import {ContentTopStripe, ContentRowInner} from '../ui-elements/app-layout';
import ContentRow from '../layout/ContentRow';

const LiveTVView = props => {
    const {epgChannels, epgShows, availableEPGTimeFrame, loadedEPGTimeFrame} = props;
    const {fetchEPGListing, startLiveStreamPlayback} = props;

    return (
        <Fragment>
            <EPG
                epgChannels={epgChannels}
                epgShows={epgShows}
                availableEPGTimeFrame={availableEPGTimeFrame}
                loadedEPGTimeFrame={loadedEPGTimeFrame}
                fetchEPGListing={fetchEPGListing}
                startLiveStreamPlayback={startLiveStreamPlayback}
                timelineLength="PT24H"
            >
                <ContentTopStripe>
                    <EPGTimeline />
                    <ContentRowInner>
                        <EPGMarker />
                    </ContentRowInner>
                </ContentTopStripe>
                <ContentRow>
                    <EPGGrid />
                </ContentRow>
            </EPG>
        </Fragment>
    );
};

LiveTVView.propTypes = {
    epgChannels: PropTypes.object.isRequired,
    epgShows: PropTypes.object.isRequired,
    availableEPGTimeFrame: PropTypes.object,
    loadedEPGTimeFrame: PropTypes.object,
    fetchEPGListing: PropTypes.func.isRequired,
    startLiveStreamPlayback: PropTypes.func.isRequired,
};

LiveTVView.defaultProps = {
    availableEPGTimeFrame: null,
    loadedEPGTimeFrame: null,
};

const mapStateToProps = state => ({
    epgChannels: state.liveStreaming.epgChannels,
    epgShows: state.liveStreaming.epgShows,
    availableEPGTimeFrame: state.liveStreaming.availableEPGTimeFrame,
    loadedEPGTimeFrame: state.liveStreaming.loadedEPGTimeFrame,
});

const mapDispatchToProps = dispatch => ({
    fetchEPGListing: payload => dispatch(liveStreamingActions.fetchEPGListing(payload)),
    startLiveStreamPlayback: payload => dispatch(liveStreamingActions.startLiveStreamPlayback(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveTVView);
