import {put, take} from 'redux-saga/effects';
import * as actionTypes from '../mediaEntitlementActionTypes';

/**
 * Checkout subscription flow
 */
const checkoutSubscriptionFlow = function* checkoutSubscriptionFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.CHECKOUT_SUBSCRIPTION);
        yield put({
            type: actionTypes.STORE_SUBSCRIPTION_CHECKOUT_STATUS,
            payload: {isSubscriptionCheckoutInProgress: true},
        });

        const {offer} = payload;
        yield put({
            type: actionTypes.STORE_SUBSCRIPTION_BASKET_ITEM,
            payload: {offer},
        });

        const fastCheckoutResultAction = yield take([
            actionTypes.CANCEL_SUBSCRIPTION_CHECKOUT,
        ]);

        if (fastCheckoutResultAction.type === actionTypes.CANCEL_SUBSCRIPTION_CHECKOUT) {
            yield put({
                type: actionTypes.STORE_SUBSCRIPTION_CHECKOUT_STATUS,
                payload: {isSubscriptionCheckoutInProgress: false},
            });
        }
    }
};

export default checkoutSubscriptionFlow;
