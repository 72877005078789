import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../mediaEntitlementActionTypes';
import {mediaEntitlementService} from '../../services';

/**
 * Fetch mediaItemPlaybackSource request
 */
const fetchMediaItemPlaybackSourceRequest = function* fetchMediaItemPlaybackSourceRequest(params) {
    try {
        yield put({
            type: actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_REQUEST,
            payload: params,
        });

        const response = yield call(mediaEntitlementService.getAssetPlaybackSource, params);
        yield put({
            type: actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR, error: true, payload: error});
    }
};

export default fetchMediaItemPlaybackSourceRequest;
