import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './NavigationFooter.scss';
import * as menuTypes from '../../navigation/menuTypes';

const NavigationFooter = ({footerMenu}) => (footerMenu ? (
    <div className="vub-c-navigation-footer">
        {footerMenu.map((menuItem, index) => (
            (menuItem.display) && (
                <Fragment key={`footer-menu-item-${menuItem.id}`}>
                    {(menuItem.url) ? (
                        <Fragment>
                            {(index !== 0) ? '|' : ''}
                            <Link
                                className="vub-c-navigation-footer__link"
                                to={menuItem.url}
                            >
                                {menuItem.title}
                            </Link>
                        </Fragment>
                    ) : (
                        <span>{menuItem.title}</span>
                    )}
                </Fragment>
            )
        ))}
    </div>
) : null);

NavigationFooter.propTypes = {
    footerMenu: PropTypes.array,
};

NavigationFooter.defaultProps = {
    footerMenu: null,
};

export {NavigationFooter};

// connect component to redux store and actions
const mapStateToProps = state => ({
    footerMenu: state.navigation.menus[menuTypes.FOOTER_MENU],
});

export default connect(
    mapStateToProps,
)(NavigationFooter);
