import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaItemViewSelectors';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../media-entitlement/withMediaActions';
import {getMediaItem, getMediaItemChildren} from '../media/mediaBucketHelpers';
import './MediaItemSeasonViewCastor.scss';
import ContentRow from '../layout/ContentRow';
import ContentRowHeader from '../layout/ContentRowHeader';
import MediaItemHeroImage from '../ui-elements/media-item-hero-image/MediaItemHeroImageForTransparentHeader';
import MediaItemDetailsSeasonCastor from '../ui-elements/media-item-details/MediaItemDetailsSeasonCastor';
import MediaItemFormatTagHD from '../ui-elements/media-item-format-tag/MediaItemFormatTagHD';
import MediaCarousel from '../ui-elements/media-carousel/MediaCarousel';
import MediaCarouselSlideCTADetailed from '../ui-elements/media-carousel-slide/MediaCarouselSlideCTADetailed';
import MediaAccordion from '../ui-elements/media-accordion/MediaAccordion';
import MediaAccordionItem from '../ui-elements/media-accordion/MediaAccordionItem';
import ProviderCornerRibbon from '../ui-elements/provider-corner-ribbon/ProviderCornerRibbon';
import PreviewButtonViewCastor from './PreviewButtonViewCastor';
import CTAButtonBarView from './CTAButtonBarView';

@withRouter
@withMediaActions
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemSeasonViewCastor extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaActionsPropTypes,
        mediaBucket: PropTypes.object,
        providers: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaActionsDefaultProps,
        mediaBucket: null,
        providers: null,
    };

    onSeasonSelect = value => {
        const {history, match, resolveMediaItemRoute} = this.props;
        const {mediaItemId, seasonMediaItemId} = match.params;
        if (value === seasonMediaItemId) return;

        if (value === 'all') {
            history.push(resolveMediaItemRoute({mediaItemId}));
            return;
        }

        history.push(resolveMediaItemRoute({mediaItemId: value}));
    };

    render() {
        const {mediaBucket, providers, startMediaItemOfferPlayback, isUserSignedIn} = this.props;
        const {match, history, resolveMediaItemRoute} = this.props;
        const {mediaItemId, seasonMediaItemId} = match.params;
        const tvShowMediaItem = mediaBucket && mediaItemId ? getMediaItem(mediaBucket, mediaItemId) : null;
        const seasonMediaItem = mediaBucket && seasonMediaItemId ? getMediaItem(mediaBucket, seasonMediaItemId) : null;
        const tvShowMediaItemSeasons = tvShowMediaItem ? getMediaItemChildren(mediaBucket, tvShowMediaItem) : null;
        const seasonMediaItemEpisodes = seasonMediaItem ? getMediaItemChildren(mediaBucket, seasonMediaItem) : null;
        const mediaItem = seasonMediaItem;
        if (!mediaItem) return null;

        // title
        const allSeasonOffers = seasonMediaItemEpisodes
            .reduce((allSeasonOffers, mediaItem) => [...allSeasonOffers, ...mediaItem.offers], []);
        const hasHDOffers = !!allSeasonOffers.find(offer => offer.resolution === '1080p');
        const title = (
            <Fragment>
                {tvShowMediaItem.title} {mediaItem.title}&nbsp;{hasHDOffers && (
                    <MediaItemFormatTagHD />
                )}
            </Fragment>
        );

        // provider
        const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
            ? providers.get(mediaItem.offers[0].provider) : null;

        // season select options
        const seasonSelectOptions = [
            {id: 'all', value: 'all', name: 'All Seasons'},
            ...tvShowMediaItemSeasons.toArray().map(seasonMediaItem => ({
                id: seasonMediaItem.id,
                value: seasonMediaItem.id,
                name: seasonMediaItem.title,
            })),
        ];

        return (
            <div className="vub-c-media-item-season-view">
                <MediaItemHeroImage mediaItem={mediaItem}>
                    <ContentRow>
                        <MediaItemDetailsSeasonCastor
                            mediaItem={mediaItem}
                            title={title}
                            provider={provider}
                            ctaButtonBar={(
                                <CTAButtonBarView mediaItem={mediaItem} />
                            )}
                            previewButton={(
                                <PreviewButtonViewCastor mediaItem={mediaItem} />
                            )}
                            onSeasonSelect={this.onSeasonSelect}
                            seasonSelectOptions={seasonSelectOptions}
                        />
                    </ContentRow>
                </MediaItemHeroImage>

                {/* Carousel display for tablet and desktop view */}
                <div className="vub-c-media-item-season-view__episodes-carousel">
                    <ContentRow>
                        <ContentRowHeader
                            title={seasonMediaItem.title}
                            subtitle={`(${seasonMediaItemEpisodes.size} episodes)`}
                        />
                        <MediaCarousel hasPagination>
                            {seasonMediaItemEpisodes.toArray().map(mediaItem => {
                                const offer = mediaItem.offers.length && mediaItem.offers[0];
                                const startOfferPlayback = () => startMediaItemOfferPlayback(offer);
                                const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
                                    ? providers.get(mediaItem.offers[0].provider) : null;

                                return (
                                    <MediaCarouselSlideCTADetailed
                                        key={mediaItem.id}
                                        mediaItem={mediaItem}
                                        linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                        history={history}
                                        onPlayIconClick={startOfferPlayback}
                                        isUserSignedIn={isUserSignedIn}
                                    >
                                        <ProviderCornerRibbon provider={provider} />
                                    </MediaCarouselSlideCTADetailed>
                                );
                            })}
                        </MediaCarousel>
                    </ContentRow>
                </div>

                {/* Accordion display for mobile view */}
                <div className="vub-c-media-item-season-view__episodes-accordion">
                    <MediaAccordion
                        title={seasonMediaItem.title}
                        subtitle={`(${seasonMediaItemEpisodes.size} episodes)`}
                    >
                        {seasonMediaItemEpisodes.toArray().map(mediaItem => {
                            const startOfferPlayback = () => startMediaItemOfferPlayback(mediaItem);

                            return (
                                <MediaAccordionItem
                                    key={mediaItem.id}
                                    mediaItem={mediaItem}
                                    linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                    history={history}
                                    onPlayIconClick={startOfferPlayback}
                                    isUserSignedIn={isUserSignedIn}
                                />
                            );
                        })}
                    </MediaAccordion>
                </div>
            </div>
        );
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaItemViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
        providers: state.storeConfiguration.providers,
    });
};

export default connect(
    createMapStateToProps,
)(MediaItemSeasonViewCastor);
