import Immutable from 'immutable';

/**
 * LiveStreamPlaybackSource definition
 *
 * @typedef {Immutable.Record} LiveStreamPlaybackSource
 * @property {?string} src
 * @property {?string} type
 * @property {?Object} protectionInfo
 */

/**
 * LiveStreamPlaybackSource record
 *
 * @type {LiveStreamPlaybackSource}
 */
class LiveStreamPlaybackSource extends Immutable.Record({
    src: null,
    type: null,
    protectionInfo: null,
}) {}

export default LiveStreamPlaybackSource;
