import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../authActionTypes';
import {authService} from '../../services';

/**
 * Update user account
 */
const updateUserAccount = function* updateUserAccount(params) {
    try {
        yield put({type: actionTypes.UPDATE_USER_ACCOUNT_REQUEST, payload: params});

        const response = yield call(authService.updateUserAccount, params);
        yield put({
            type: actionTypes.UPDATE_USER_ACCOUNT_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.UPDATE_USER_ACCOUNT_ERROR, error: true, payload: error});
    }
};

export default updateUserAccount;
