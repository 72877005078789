import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import config from '../config';
import * as authActions from '../auth/authActions';
import './AccountSignUpView.scss';
import CustomCheckbox from '../ui-elements/custom-checkbox/CustomCheckbox';
import Input from '../ui-elements/input/Input';
import FormContainer from '../ui-elements/form/FormContainer';
import {
    required,
    isEmailValid,
    isPasswordValid,
    isMatchWith,
    maxLength,
} from '../ui-elements/form-validation/validations';
import Recaptcha from '../ui-elements/recaptcha/Recaptcha';
import FormButton from '../ui-elements/form/FormButton';
import ServerValidationErrors from '../ui-elements/form-validation/ServerValidationErrors';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';

const AccountSignUpView = ({signInUser, registerAndAuthenticateUser, isSessionChangeInProgress, serverErrors}) => {
    const getFormValues = ({email, password, name, isTermsAndConditions}) => {
        registerAndAuthenticateUser({email, password, name, termsAndConditionsAccepted: isTermsAndConditions});
    };

    // disable paste
    const preventDefaultOnPaste = event => event.preventDefault(event);

    return (
        <div className="vub-c-account-sign-up-view">
            <div className="vub-c-account-sign-up-view__title">
                sign up now
            </div>
            <div className="vub-c-account-sign-up-view__description">
                Sign up to watch blockbusters and binge-worthy TV Box Sets.
                Break out the popcorn to watch them at home, or on the go.
            </div>
            <div className="vub-c-account-sign-up-view__form">
                <FormContainer
                    initialState={{
                        name: '',
                        email: '',
                        confirmedEmail: '',
                        password: '',
                        confirmedPassword: '',
                        isTermsAndConditions: false,
                        recaptcha: !config.RECAPTCHA_SITE_KEY,
                        errors: {},
                    }}
                    getFormValues={getFormValues}
                    render={
                        (
                            {
                                name,
                                email,
                                confirmedEmail,
                                password,
                                confirmedPassword,
                                isTermsAndConditions,
                                errors,
                                recaptcha,
                            },
                            handleValues
                        ) => (
                            <Fragment>
                                <Input
                                    type="text"
                                    name="name"
                                    className="vub-c-input-container--auth"
                                    placeholder="Display name"
                                    value={name}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    maxLength={40}
                                    validators={[
                                        required,
                                        maxLength(40),
                                    ]}
                                    error={errors['name']}
                                />
                                <Input
                                    type="email"
                                    name="email"
                                    className="vub-c-input-container--auth"
                                    placeholder="Email"
                                    value={email}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={[required, isEmailValid]}
                                    error={errors['email']}
                                />
                                <Input
                                    type="email"
                                    name="confirmedEmail"
                                    className="vub-c-input-container--auth"
                                    placeholder="Confirm email"
                                    value={confirmedEmail}
                                    handleChange={handleValues}
                                    handlePaste={preventDefaultOnPaste}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={[
                                        required,
                                        isEmailValid,
                                        isMatchWith('email'),
                                    ]}
                                    error={errors['confirmedEmail']}
                                />
                                <Input
                                    type="password"
                                    name="password"
                                    className="vub-c-input-container--signed-up-password"
                                    placeholder="Password"
                                    value={password}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={[required, isPasswordValid]}
                                    error={errors['password']}
                                />
                                <Input
                                    type="password"
                                    name="confirmedPassword"
                                    className="vub-c-input-container--auth"
                                    placeholder="Confirm password"
                                    value={confirmedPassword}
                                    handleChange={handleValues}
                                    isDisabled={isSessionChangeInProgress}
                                    validators={[
                                        required,
                                        isPasswordValid,
                                        isMatchWith('password'),
                                    ]}
                                    error={errors['confirmedPassword']}
                                />
                                <CustomCheckbox
                                    name="isTermsAndConditions"
                                    className="vub-c-custom-checkbox--auth"
                                    label="I agree to the Terms & Conditions"
                                    isDisabled={isSessionChangeInProgress}
                                    isChecked={isTermsAndConditions}
                                    handleChange={handleValues}
                                />
                                {config.RECAPTCHA_SITE_KEY && (
                                    <Recaptcha
                                        name="recaptcha"
                                        siteKey={config.RECAPTCHA_SITE_KEY}
                                        handleRecaptcha={handleValues}
                                    />
                                )}
                                {serverErrors
                                && serverErrors.length > 0
                                && <ServerValidationErrors errors={serverErrors} />
                                }
                                <div className="vub-c-account-sign-up-view__buttons">
                                    <FormButton
                                        type="submit"
                                        isDisabled={
                                            isSessionChangeInProgress
                                            || !name
                                            || !password
                                            || !confirmedPassword
                                            || !isTermsAndConditions
                                            || !recaptcha
                                            || Object.values(errors).some(el => el.isInvalid)
                                        }
                                    >
                                        {isSessionChangeInProgress ? <SpinnerStandard /> : 'Continue'}
                                    </FormButton>
                                </div>
                            </Fragment>
                        )
                    }
                />
            </div>
            <div className="vub-c-account-sign-up-view__link-container">
                <label
                    className="vub-c-account-sign-up-view__link"
                    onClick={signInUser}
                >
                    Already have an account? Sign in.
                </label>
            </div>
        </div>
    );
};

AccountSignUpView.propTypes = {
    signInUser: PropTypes.func.isRequired,
    registerAndAuthenticateUser: PropTypes.func.isRequired,
    isSessionChangeInProgress: PropTypes.bool,
    serverErrors: PropTypes.array,
};

AccountSignUpView.defaultProps = {
    isSessionChangeInProgress: false,
    serverErrors: [],
};

export {AccountSignUpView};

const mapStateToProps = state => ({
    isSessionChangeInProgress: state.auth.isSessionChangeInProgress,
    serverErrors: state.auth.serverErrors,
});

const mapDispatchToProps = dispatch => ({
    signInUser: payload => dispatch(authActions.signInUser(payload)),
    registerAndAuthenticateUser: payload => dispatch(authActions.registerAndAuthenticateUser(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountSignUpView);
