import Immutable from 'immutable';
import moment from 'moment';
import * as actionTypes from './mediaEntitlementActionTypes';
import MediaItemPlaybackSource from './records/MediaItemPlaybackSource';
import BasketItem from './records/BasketItem';
import Entitlement from './records/Entitlement';

const initialState = {
    isFastCheckoutInProgress: false,
    basket: Immutable.Map(),
    isBasketChargingInProgress: false,
    currentEntitlements: null,
    currentEntitlementsByMediaItem: null,
    currentEntitlementsUpdateTime: null,
    mediaItemPlaybackSource: null,
    playbackMediaItemId: null,
    playbackAds: null,
    isPlaybackInProgress: false,
    isSubscriptionCheckoutInProgress: false,
    subscriptionBasket: null,
};

/**
 * Media entitlement reducer
 * TODO implement media bucket invalidation
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */
const mediaEntitlementReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_SUBSCRIPTION_CHECKOUT_STATUS:
            state = {
                ...state,
                isSubscriptionCheckoutInProgress: action.payload.isSubscriptionCheckoutInProgress,
                subscriptionBasket: null,
            };
            break;

        case actionTypes.STORE_SUBSCRIPTION_BASKET_ITEM:
            if (action.payload.offer) {
                state = {
                    ...state,
                    subscriptionBasket: action.payload.offer,
                };
            }
            break;

        case actionTypes.STORE_FAST_CHECKOUT_STATUS:
            state = {
                ...state,
                isFastCheckoutInProgress: action.payload.isFastCheckoutInProgress,
                basket: action.payload.isFastCheckoutInProgress ? Immutable.Map() : state.basket,
            };
            break;

        case actionTypes.STORE_BASKET_ITEM:
            if (action.payload.basketItemDTO) {
                const basketItem = new BasketItem(action.payload.basketItemDTO);

                state = {
                    ...state,
                    basket: state.basket.set(basketItem.offerId, basketItem),
                };
            }
            break;

        case actionTypes.REMOVE_BASKET_ITEM:
            if (action.payload.offerId) {
                state = {
                    ...state,
                    basket: state.basket.delete(action.payload.offerId),
                };
            }
            break;

        case actionTypes.RESET_BASKET:
            state = {
                ...state,
                basket: Immutable.Map(),
            };
            break;

        case actionTypes.STORE_BASKET_CHARGING_STATUS:
            state = {
                ...state,
                isBasketChargingInProgress: action.payload.isBasketChargingInProgress,
            };
            break;

        case actionTypes.STORE_CURRENT_ENTITLEMENTS:
            if (action.payload.entitlementDTOs) {
                const entitlementDTOs = action.payload.entitlementDTOs;
                let currentEntitlements = state.currentEntitlements || Immutable.Map();
                let currentEntitlementsByMediaItem = state.currentEntitlementsByMediaItem || Immutable.Map();

                entitlementDTOs.forEach(entitlementDTO => {
                    const entitlement = new Entitlement({}).fromDTO(entitlementDTO);
                    if (entitlement.id) {
                        currentEntitlements = currentEntitlements.set(entitlement.id, entitlement);
                        if (entitlement.mediaItemId) {
                            currentEntitlementsByMediaItem = currentEntitlementsByMediaItem
                                .set(entitlement.mediaItemId, entitlement);
                        }
                    }
                });

                state = {
                    ...state,
                    currentEntitlements,
                    currentEntitlementsByMediaItem,
                    currentEntitlementsUpdateTime: moment(),
                };
            }
            break;

        case actionTypes.RESET_CURRENT_ENTITLEMENTS:
            state = {
                ...state,
                currentEntitlements: null,
                currentEntitlementsByMediaItem: null,
                currentEntitlementsUpdateTime: null,
            };
            break;

        case actionTypes.STORE_MEDIA_ITEM_PLAYBACK_SOURCE:
            state = action.payload.mediaItemPlaybackSourceDTO ? {
                ...state,
                mediaItemPlaybackSource: new MediaItemPlaybackSource(action.payload.mediaItemPlaybackSourceDTO),
                playbackMediaItemId: action.payload.playbackMediaItemId,
                playbackAds: action.payload.playbackAds,
            } : {
                ...state,
                mediaItemPlaybackSource: null,
                playbackMediaItemId: null,
                playbackAds: null,
            };
            break;

        default:
        // no-op
    }
    return state;
};

export default mediaEntitlementReducer;
