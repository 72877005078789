import Immutable from 'immutable';

/**
 * MediaGenre definition
 *
 * @typedef {Immutable.Record} MediaGenre
 * @property {?string} id
 * @property {?string} name
 * @property {?string} slug
 * @property {?string} searchQuery
 * @property {?boolean} hasMovies
 * @property {?boolean} hasTVShows
 */

/**
 * MediaGenre record
 *
 * @type {MediaGenre}
 */
class MediaGenre extends Immutable.Record({
    id: null,
    name: null,
    slug: null,
    searchQuery: null,
    hasMovies: false,
    hasTVShows: false,
}) {}

export default MediaGenre;
