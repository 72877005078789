import React from 'react';
import PropTypes from 'prop-types';
import './Option.scss';

const Option = ({
    children,
    value,
    onChange,
    selectedValue,
    parentValue,
    isDisabled,
    label,
    className,
    setOptionRef,
    index,
    handleOptionsOnKeyDown,
    handleOptionsOnMouseOver,
    isDefault,
    isSelected,
}) => {
    const optionValue = value || label;
    const isOptionSelected = isSelected || selectedValue === optionValue;
    const handleClick = () => {
        if (typeof onChange === 'function' && !isDisabled) {
            onChange(optionValue, parentValue);
        }
    };
    const handleKeyDown = e => {
        if (typeof handleOptionsOnKeyDown === 'function') {
            handleOptionsOnKeyDown(e, index, optionValue, parentValue);
        }
    };
    const handleMouseOver = e => {
        if (typeof handleOptionsOnMouseOver === 'function' && !isDisabled) {
            handleOptionsOnMouseOver(e, index);
        }
    };

    return (
        <li
            tabIndex="0"
            role="option"
            className={`vub-c-custom-select-item ${isOptionSelected ? 'vub-c-custom-select-item--is-active' : ''} ${isDisabled ? 'vub-c-custom-select-item--is-disabled' : ''} ${className || ''}`}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseOver={handleMouseOver}
            aria-selected={isOptionSelected}
            ref={setOptionRef}
        >
            {children || label}
        </li>
    );
};

Option.propTypes = {
    value: PropTypes.node,
    onChange: PropTypes.func,
    parentValue: PropTypes.string,
    isDisabled: PropTypes.bool,
    selectedValue: PropTypes.node,
    label: PropTypes.node,
    className: PropTypes.string,
    setOptionRef: PropTypes.func,
    index: PropTypes.number,
    handleOptionsOnKeyDown: PropTypes.func,
    handleOptionsOnMouseOver: PropTypes.func,
    isDefault: PropTypes.bool,
    isSelected: PropTypes.bool,
};

Option.defaultProps = {
    value: null,
    onChange: null,
    parentValue: null,
    isDisabled: false,
    selectedValue: null,
    label: null,
    className: null,
    setOptionRef: null,
    index: null,
    handleOptionsOnKeyDown: null,
    handleOptionsOnMouseOver: null,
    isDefault: null,
    isSelected: null,
};

export default Option;
