import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import MediaItemPopoverContext from './MediaItemPopoverContext';

export const withMediaItemPopover = BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'withMediaItemPopover'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
        };

        render() {
            const popoverCallerRef = React.createRef();
            return (
                <MediaItemPopoverContext.Consumer>
                    {({toggleMediaItemPopover}) => {
                        const toggleMediaItemPopoverWithNode = (enter, mediaItem) => {
                            if (enter) {
                                const mediaItemPopoverNode = popoverCallerRef.current;
                                toggleMediaItemPopover(enter, mediaItem, mediaItemPopoverNode);
                            } else {
                                toggleMediaItemPopover(enter);
                            }
                        };

                        return (
                            <BaseComponent
                                {...this.props}
                                toggleMediaItemPopover={toggleMediaItemPopoverWithNode}
                                popoverCallerRef={popoverCallerRef}
                            />
                        );
                    }}
                </MediaItemPopoverContext.Consumer>
            );
        }
    }

    return ComposedComponent;
};

export const withMediaItemPopoverPropTypes = {
    toggleMediaItemPopover: PropTypes.func.isRequired,
    popoverCallerRef: PropTypes.object.isRequired,
};

export const withMediaItemPopoverDefaultProps = {
    toggleMediaItemPopover: () => {},
    popoverCallerRef: {},
};
