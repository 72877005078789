import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import smoothscroll from 'smoothscroll-polyfill';
import './BackToTop.scss';
import BackToTopIcon from './BackToTopIcon';
import {iconBackToTop} from '../icons';

class BackToTop extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        screenMultiplicator: PropTypes.number,
        scrollToElement: PropTypes.object,
    };

    static defaultProps = {
        title: null,
        screenMultiplicator: null,
        scrollToElement: null,
    };

    constructor(props) {
        super(props);
        this.setVisibility = throttle(this.setVisibility, 200);
    }

    state = {
        isVisible: false,
    };

    componentDidMount() {
        smoothscroll.polyfill();
        this.setVisibility();

        window.addEventListener('resize', this.setVisibility);
        window.addEventListener('scroll', this.setVisibility);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setVisibility);
        window.removeEventListener('scroll', this.setVisibility);
    }

    setVisibility = () => {
        const {screenMultiplicator} = this.props;
        const pageYOffset = window.pageYOffset;
        const screenHeight = document.documentElement.clientHeight;

        const isVisible = pageYOffset >= screenHeight * (screenMultiplicator || 1);
        if (this.state.isVisible !== isVisible) {
            this.setState({
                isVisible: isVisible,
            });
        }
    };

    /**
     * Scroll to
     * TODO scrolled correctly but doesn't takes into account header height
     */
    scrollTo = () => {
        const {scrollToElement} = this.props;
        const position = scrollToElement ? scrollToElement.offsetTop : document.body.offsetTop;

        window.scrollTo({
            top: position,
            behavior: 'smooth',
        });
    };

    render() {
        const {title} = this.props;
        const {isVisible} = this.state;

        return (
            <div className={`vub-c-back-to-top ${isVisible ? '' : 'vub-c-back-to-top--hidden'}`}>
                <div className="vub-c-back-to-top__wrapper">
                    <div className="vub-c-back-to-top__content" onClick={this.scrollTo}>
                        <span className="vub-c-back-to-top__title">{title}</span>
                        <BackToTopIcon
                            icon={iconBackToTop}
                            className="vub-c-back-to-top-icon--large"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default BackToTop;
