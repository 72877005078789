import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../../media-entitlement/withMediaActions';
import CTAButton from '../../ui-elements/cta-button/CTAButton';
import CTADropDown from '../../ui-elements/cta-drop-down/CTADropDown';
import CTADropDownOption from '../../ui-elements/cta-drop-down/CTADropDownOption';

@withMediaActions
class PurchaseCTAButtonsTVOD extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
        mediaItem: null,
    };

    render() {
        const {mediaItem, providers} = this.props;
        const {isFastCheckoutInProgress, fastCheckoutMediaItemOffer} = this.props;
        const offers = mediaItem.offers.filter(offer => offer.offerType === mediaItemOfferType.TVOD);

        if (offers.length === 1) {
            const offer = offers[0];
            const fastCheckoutOffer = () => fastCheckoutMediaItemOffer(offer);
            const provider = providers.get(offer.provider);
            const price = offer.price.toLocaleString('en', {
                style: 'currency',
                currency: offer.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            return (
                <CTAButton
                    key={offer.id}
                    onClick={fastCheckoutOffer}
                    isDisabled={isFastCheckoutInProgress}
                >
                    <span>
                        Rent {offer.resolution}
                        &nbsp;<span className="vub-c-cta-button__price">{price}</span>
                    </span>
                    {provider && (
                        <img
                            className="vub-c-cta-button__icon vub-u-margin-left-small"
                            src={provider.serviceCTALogo}
                            alt="cta-icon"
                        />
                    )}
                </CTAButton>
            );
        }

        if (offers.length > 1) {
            const fastCheckoutOffer = () => fastCheckoutMediaItemOffer(offer);
            const offer = offers[0];
            const price = offer.price.toLocaleString('en', {
                style: 'currency',
                currency: offer.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            return (
                <CTADropDown
                    label={(
                        <span>
                            <span className="vub-u-font-weight-bold">Rent</span>
                            &nbsp;<span className="vub-c-drop-down__label-text">from</span>
                            &nbsp;{price}
                        </span>
                    )}
                    isDisabled={false}
                    onChange={fastCheckoutOffer}
                >
                    {offers.map(offer => {
                        const provider = providers.get(offer.provider);
                        const price = offer.price.toLocaleString('en', {
                            style: 'currency',
                            currency: offer.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });

                        return (
                            <CTADropDownOption
                                key={offer.id}
                                value={offer}
                            >
                                <span>
                                    Rent {offer.resolution}
                                    &nbsp;<span className="vub-c-cta-drop-down-option__price">{price}</span>
                                </span>
                                {provider && (
                                    <img
                                        className="vub-c-cta-drop-down-option__icon"
                                        src={provider.serviceCTALogo}
                                        alt="drop-down-option-icon"
                                    />
                                )}
                            </CTADropDownOption>
                        );
                    })}
                </CTADropDown>
            );
        }

        // return null if no render condition is matched
        return null;
    }
}

export default PurchaseCTAButtonsTVOD;
