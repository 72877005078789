import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../mediaEntitlementActionTypes';
import {mediaEntitlementService} from '../../services';

/**
 * Fetch mediaItem offer ads
 */
const fetchMediaItemOfferAds = function* fetchMediaItemOfferAds(params) {
    try {
        yield put({
            type: actionTypes.FETCH_MEDIA_ITEM_OFFER_ADS_REQUEST,
            payload: params,
        });

        const response = yield call(mediaEntitlementService.getMediaItemOfferAds, params);
        yield put({
            type: actionTypes.FETCH_MEDIA_ITEM_OFFER_ADS_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_MEDIA_ITEM_OFFER_ADS_ERROR, error: true, payload: error});
    }
};

export default fetchMediaItemOfferAds;
