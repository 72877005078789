import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import routePaths from '../routePaths';
import resolveRoute from '../navigation/resolveRoute';
import * as authActions from '../auth/authActions';
import './AccountContactPreferencesView.scss';
import CustomCheckbox from '../ui-elements/custom-checkbox/CustomCheckbox';
import FormContainer from '../ui-elements/form/FormContainer';
import FormButton from '../ui-elements/form/FormButton';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';

const AccountContactPreferencesView = ({userAccount, setContactPreferences, isSessionChangeInProgress}) => {
    const getFormValues = ({hasOptedInToReceiveUpdates}) => {
        setContactPreferences({hasOptedInToReceiveUpdates});
    };

    return userAccount ? (
        <div className="vub-c-account-contact-preferences-view">
            <div className="vub-c-account-contact-preferences-view__title">
                Contact preferences
            </div>
            <div className="vub-c-account-contact-preferences-view__description">
                If the box is ticked bellow, it means you agree to share your contact details so that
                PlayVU Store can send you updates about brilliant offers, rewards and product news.
            </div>
            <div className="vub-c-account-contact-preferences-view__form">
                <FormContainer
                    initialState={{
                        hasOptedInToReceiveUpdates: userAccount.hasOptedInToReceiveUpdates,
                    }}
                    getFormValues={getFormValues}
                    render={
                        ({hasOptedInToReceiveUpdates}, handleValues) => (
                            <Fragment>
                                <CustomCheckbox
                                    name="hasOptedInToReceiveUpdates"
                                    label="Receive updates by email, post, sms or phone"
                                    isChecked={hasOptedInToReceiveUpdates}
                                    isDisabled={isSessionChangeInProgress}
                                    handleChange={handleValues}
                                />
                                <div className="vub-c-account-contact-preferences-view__description">
                                    Obviously we want to stay in your good books,
                                    so we keep your personal details private and threat them
                                    with the utmost care (find out more&nbsp;
                                    <Link
                                        className="vub-c-account-contact-preferences-view__link"
                                        to={resolveRoute(routePaths.PAGE, {pageId: 'privacy'})}
                                        target="_blank"
                                    >
                                        here
                                    </Link>),
                                    and fully comply with yur data subject right under the&nbsp;
                                    <Link
                                        className="vub-c-account-contact-preferences-view__link"
                                        to={resolveRoute(routePaths.PAGE, {pageId: 'gdpr-info'})}
                                        target="_blank"
                                    >
                                        General Data Protection Regulations
                                    </Link>)
                                </div>
                                <div className="vub-c-account-contact-preferences-view__buttons">
                                    <FormButton
                                        type="submit"
                                        isDisabled={false}
                                    >
                                        {isSessionChangeInProgress ? <SpinnerStandard /> : 'Submit'}
                                    </FormButton>
                                </div>
                            </Fragment>
                        )}
                />
            </div>
        </div>
    ) : null;
};

AccountContactPreferencesView.propTypes = {
    userAccount: PropTypes.object,
    setContactPreferences: PropTypes.func.isRequired,
    isSessionChangeInProgress: PropTypes.bool,
};

AccountContactPreferencesView.defaultProps = {
    userAccount: null,
    isSessionChangeInProgress: false,
};

export {AccountContactPreferencesView};

const mapStateToProps = state => ({
    userAccount: state.auth.userAccount,
    isSessionChangeInProgress: state.auth.isSessionChangeInProgress,
});

const mapDispatchToProps = dispatch => ({
    setContactPreferences: payload => dispatch(authActions.setContactPreferences(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountContactPreferencesView);
