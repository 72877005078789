import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import pluralize from 'pluralize';
import throttle from 'lodash.throttle';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaClassificationViewSelectors';
import * as mediaActions from '../media/mediaActions';
import './MediaClassificationTVShowsView.scss';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortrait from '../ui-elements/media-gallery-tile/MediaGalleryTilePortrait';
import BackToTop from '../ui-elements/back-to-top/BackToTop';
import ContentRow from '../layout/ContentRow';
import PullDownContent from '../ui-elements/pull-down-content/PullDownContent';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';
import orderByOptions from '../static-data/orderByOptions';
import CustomSelect from '../ui-elements/custom-select/CustomSelect';
import Option from '../ui-elements/custom-select/Option';
import MoreDetailsFilter from '../ui-elements/more-details-filter/MoreDetailsFilter';
import ProviderCornerRibbon from '../ui-elements/provider-corner-ribbon/ProviderCornerRibbon';

@withMediaRouteHelpers({mediaRouteResolver})
class MediaClassificationTVShowsView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        mediaBucket: PropTypes.object,
        mediaGenres: PropTypes.object.isRequired,
        fetchMediaCollectionMedia: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        mediaBucket: null,
    };

    constructor(props) {
        super(props);
        this.fetchNextMediaPageOnScrollToBottom = throttle(this.fetchNextMediaPageOnScrollToBottom, 200);
    }

    state = {
        filterByValue: null,
        orderByValue: 'a-z',
        prevPropLocation: null, // eslint-disable-line react/no-unused-state
        filterByMoreDetails: [],
    };

    componentDidMount() {
        window.addEventListener('scroll', this.fetchNextMediaPageOnScrollToBottom, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.fetchNextMediaPageOnScrollToBottom, false);
    }

    componentRef = React.createRef();

    getWhereQuery = () => {
        return [
            `classification:in:${mediaItemClassification.TV_SHOW}`,
            ...this.getMoreDetailsQuery(this.state.filterByMoreDetails),
        ];
    };

    getMoreDetailsQuery = (moreDetails = []) => {
        const groupedMoreDetailsByKey = moreDetails
            .reduce((acc, curr, index, arr) => {
                acc[curr.key] = arr.filter(el => el.key === curr.key)
                    .map(el => el.value);
                return acc;
            }, {});
        const query = Object.keys(groupedMoreDetailsByKey)
            .reduce((acc, curr) => {
                if (groupedMoreDetailsByKey[curr].length > 0) {
                    acc = [...acc, `${curr}:in:${groupedMoreDetailsByKey[curr].join(',')}`];
                }
                return acc;
            }, []);
        return query.filter(Boolean);
    };

    fetchBucketMedia = (fetchNextPage = false) => {
        const {mediaBucket, fetchMediaCollectionMedia} = this.props;
        const {orderByValue} = this.state;
        const orderByOption = orderByOptions.find(option => option.value === orderByValue);

        fetchMediaCollectionMedia({
            requestParams: {
                mediaCollectionId: null,
                where: this.getWhereQuery(),
                sort: orderByOption ? orderByOption.query : undefined,
                start: fetchNextPage ? mediaBucket.mediaIds.size : 0,
                limit: 24, // TODO move somewhere else
            },
            mediaBucketKey: mediaBucket.key,
            invalidateCurrentSet: !fetchNextPage,
        });
    };

    onGenreNavigationSelect = value => {
        if (value !== 'reset') {
            const {history, resolveMediaGenreRoute} = this.props;
            history.push(resolveMediaGenreRoute({
                mediaGenreId: value,
                classification: 'tv-shows',
                filterByMoreDetails: [],
            }));
        }
    };

    onOrderBySelect = value => {
        this.setState({
            orderByValue: value,
        }, () => this.fetchBucketMedia());
    };

    onMoreDetailsFilterSelect = filterByMoreDetails => {
        this.setState({
            filterByMoreDetails,
        }, () => this.fetchBucketMedia());
    };

    fetchNextMediaPageOnScrollToBottom = () => {
        const componentNode = this.componentRef.current;
        if (!componentNode) return;

        const {mediaBucket} = this.props;
        const componentRect = componentNode.getBoundingClientRect();
        if (mediaBucket
            && !mediaBucket.isLoadingMedia
            && !mediaBucket.isUpdatePending
            && mediaBucket.media.size < mediaBucket.mediaTotalCount
            && window.innerHeight + window.pageYOffset >= componentRect.height
        ) {
            this.fetchBucketMedia(true);
        }
    };

    render() {
        const {mediaBucket, mediaGenres, resolveMediaItemRoute, providers} = this.props;
        const title = 'all series';
        const {filterByValue, orderByValue} = this.state;

        const facetProviders = mediaBucket && typeof mediaBucket.toJS().facets['offers/provider'] !== 'undefined'
            ? mediaBucket.toJS().facets['offers/provider'].map(providerFacet => providerFacet.value)
            : [];
        const facetMediaGenreIds = mediaBucket && typeof mediaBucket.toJS().facets['mediaGenreId'] !== 'undefined'
            ? mediaBucket.toJS().facets['mediaGenreId'].map(mediaGenreIdFacet => mediaGenreIdFacet.value)
            : [];
        const filterOptions = [
            {
                id: 'reset',
                name: 'All Genres',
            },
            ...(facetMediaGenreIds.length > 0
                ? mediaGenres
                    .toArray()
                    .filter(mediaGenre => facetMediaGenreIds.includes(mediaGenre.name))
                : mediaGenres.toArray()),
        ];

        return (
            <div className="vub-c-media-classification-tv-shows-view" ref={this.componentRef}>
                <ContentRow>
                    <PullDownContent>
                        <div className="vub-c-media-classification-tv-shows-view__filters">
                            <CustomSelect
                                label="Genres"
                                value={filterByValue}
                                onChange={this.onGenreNavigationSelect}
                            >
                                {filterOptions.map(option => (
                                    <Option
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </Option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label="Sort by"
                                value={orderByValue}
                                onChange={this.onOrderBySelect}
                            >
                                {orderByOptions.map(option => (
                                    <Option
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </Option>
                                ))}
                            </CustomSelect>
                            <MoreDetailsFilter
                                onMoreDetailsFilterSelect={this.onMoreDetailsFilterSelect}
                                filterByMoreDetails={this.state.filterByMoreDetails}
                                providers={providers}
                                facetProviders={facetProviders}
                            />
                        </div>
                    </PullDownContent>
                </ContentRow>

                {mediaBucket && mediaBucket.media.size > 0 ? (
                    <MediaGallery
                        title={title}
                        subtitle={`(${mediaBucket.mediaTotalCount} ${pluralize('title', mediaBucket.mediaTotalCount)})`}
                    >
                        {mediaBucket.media
                            .toArray()
                            .map(mediaItem => {
                                const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
                                    ? providers.get(mediaItem.offers[0].provider) : null;

                                return (
                                    <MediaGalleryTilePortrait
                                        key={mediaItem.id}
                                        mediaItem={mediaItem}
                                        linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                    >
                                        <ProviderCornerRibbon provider={provider} />
                                    </MediaGalleryTilePortrait>
                                );
                            })}
                    </MediaGallery>
                ) : null}

                {mediaBucket && mediaBucket.isLoadingMedia ? (
                    <div className="vub-c-media-classification-tv-shows-view__spinner">
                        <SpinnerStandard className="vub-c-standard-spinner--small" />
                    </div>
                ) : null}

                <BackToTop
                    title="Back to top"
                    screenMultiplicator={2}
                />
            </div>
        );
    }
}

export {MediaClassificationTVShowsView};

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaClassificationTVShowsViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
        mediaGenres: state.media.mediaGenres,
        providers: state.storeConfiguration.providers,
    });
};

const mapDispatchToProps = dispatch => ({
    fetchMediaCollectionMedia: payload => dispatch(mediaActions.fetchMediaCollectionMedia(payload)),
});

export default connect(
    createMapStateToProps,
    mapDispatchToProps,
)(MediaClassificationTVShowsView);
