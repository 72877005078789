import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './LinkButton.scss';

const LinkButton = ({target, uri, children}) => (
    <Link
        className="vub-c-link-button"
        target={target}
        to={uri}
    >
        {children}
    </Link>
);

LinkButton.propTypes = {
    target: PropTypes.string,
    uri: PropTypes.string,
    children: PropTypes.string,
};

LinkButton.defaultProps = {
    uri: null,
    target: null,
    children: null,
};

export default LinkButton;
