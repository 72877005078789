import {createSelector} from 'reselect';
import Immutable from 'immutable';
import * as mediaSelectors from '../media/mediaSelectors';

export const getClassificationMoviesMediaBucketKey = () => `MEDIA_CLASSIFICATION_VIEW['movies']`;

export const getClassificationTVShowsMediaBucketKey = () => `MEDIA_CLASSIFICATION_VIEW['tv-shows']`;

export const createMediaClassificationMoviesViewBucketSelector = () => createSelector(
    [
        mediaSelectors.getMediaItems,
        mediaSelectors.getMediaBuckets,
        getClassificationMoviesMediaBucketKey,
    ],
    (mediaItems, mediaBuckets, mediaBucketKey) => {
        const mediaBucket = mediaBuckets.get(mediaBucketKey);
        if (!mediaBucket) return mediaBucket;

        let media = Immutable.OrderedMap();
        mediaBucket.mediaIds.forEach(mediaItemId => {
            media = media.set(mediaItemId, mediaItems.get(mediaItemId));
        });

        return mediaBucket.merge({media});
    },
);

export const createMediaClassificationTVShowsViewBucketSelector = () => createSelector(
    [
        mediaSelectors.getMediaItems,
        mediaSelectors.getMediaBuckets,
        getClassificationTVShowsMediaBucketKey,
    ],
    (mediaItems, mediaBuckets, mediaBucketKey) => {
        const mediaBucket = mediaBuckets.get(mediaBucketKey);
        if (!mediaBucket) return mediaBucket;

        let media = Immutable.OrderedMap();
        mediaBucket.mediaIds.forEach(mediaItemId => {
            media = media.set(mediaItemId, mediaItems.get(mediaItemId));
        });

        return mediaBucket.merge({media});
    },
);
