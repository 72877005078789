import {all, fork, takeEvery} from 'redux-saga/effects';
import * as actionTypes from './liveStreamingActionTypes';
import fetchEPGListing from './sagas/fetchEPGListing';
import liveStreamPlaybackFlow from './sagas/liveStreamPlaybackFlow';

/**
 * Live streaming watcher saga
 */
export const liveStreamingWatcher = function* liveStreamingWatcher() {
    yield all([
        fork(liveStreamPlaybackFlow),
        takeEvery(actionTypes.FETCH_EPG_LISTING, fetchEPGListing),
    ]);
};
