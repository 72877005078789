import React, {Component, createRef} from 'react';
import throttle from 'lodash.throttle';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import {
    withMediaSearch,
    withMediaSearchPropTypes,
    withMediaSearchDefaultProps,
} from '../media/withMediaSearch';
import './MediaSearchView.scss';
import BackToTop from '../ui-elements/back-to-top/BackToTop';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';
import ContentRow from '../layout/ContentRow';
import PullDownContent from '../ui-elements/pull-down-content/PullDownContent';
import defaultOrderByOptions from '../static-data/orderByOptions';
import CustomSelect from '../ui-elements/custom-select/CustomSelect';
import Option from '../ui-elements/custom-select/Option';
import MoreDetailsFilter from '../ui-elements/more-details-filter/MoreDetailsFilter';
import SearchLayoutPicker from '../ui-elements/search-layout-picker/SearchLayoutPicker';
import MediaSearchGroupedGallery from './MediaSearchGroupedGallery';

const minimumSearchQueryLength = 3;

@withMediaSearch
@withMediaRouteHelpers({mediaRouteResolver})
class MediaSearchViewCastor extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaSearchPropTypes,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaSearchDefaultProps,
    };

    constructor(props) {
        super(props);
        this.fetchNextMediaPageOnScrollToBottom = throttle(this.fetchNextMediaPageOnScrollToBottom, 200);
    }

    state = {
        searchViewLayout: 'grid',
    };

    componentDidMount() {
        window.addEventListener('scroll', this.fetchNextMediaPageOnScrollToBottom, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.fetchNextMediaPageOnScrollToBottom, false);
    }

    componentRef = createRef();

    fetchBucketMedia = (fetchNextPage = false) => this.props.fetchBucketMedia(fetchNextPage);

    onGenreFilterSelect = value => this.props.onGenreFilterSelect(value);

    onOrderBySelect = value => this.props.onOrderBySelect(value);

    onMoreDetailsFilterSelect = values => this.props.onMoreDetailsFilterSelect(values);

    fetchNextMediaPageOnScrollToBottom = () => {
        const componentNode = this.componentRef.current;
        if (!componentNode) return;

        const {mediaBucket} = this.props;
        const componentRect = componentNode.getBoundingClientRect();
        if (mediaBucket
            && !mediaBucket.isLoadingMedia
            && !mediaBucket.isUpdatePending
            && mediaBucket.media.size < mediaBucket.mediaTotalCount
            && window.innerHeight + window.pageYOffset >= componentRect.height
        ) {
            this.fetchBucketMedia(true);
        }
    };

    updateLayoutSelectButtons = selectedButton => {
        this.setState({searchViewLayout: selectedButton});
    };

    render() {
        const {mediaBucket, mediaGenres, providers} = this.props;

        const {searchQueryString, areSearchResultsValid, resolveMediaItemRoute} = this.props;
        const {orderByValue, filterByValue, filterByMoreDetails} = this.props;

        const facetProviders = mediaBucket && typeof mediaBucket.toJS().facets['offers/provider'] !== 'undefined'
            ? mediaBucket.toJS().facets['offers/provider'].map(providerFacet => providerFacet.value)
            : [];
        const facetMediaGenreIds = mediaBucket && typeof mediaBucket.toJS().facets['mediaGenreId'] !== 'undefined'
            ? mediaBucket.toJS().facets['mediaGenreId'].map(mediaGenreIdFacet => mediaGenreIdFacet.value)
            : [];
        const filterOptions = [
            {
                id: 'reset',
                name: 'All Genres',
            },
            ...(facetMediaGenreIds.length > 0
                ? mediaGenres
                    .toArray()
                    .filter(mediaGenre => facetMediaGenreIds.includes(mediaGenre.name))
                : mediaGenres.toArray()),
        ];

        const orderByOptions = [
            {
                id: 3,
                value: 'relevance',
                name: 'Relevance',
                query: null,
            },
            ...defaultOrderByOptions,
        ];

        return (
            <div className="vub-c-media-search-view" ref={this.componentRef}>
                {searchQueryString.length >= minimumSearchQueryLength
                && areSearchResultsValid
                && mediaBucket
                && !mediaBucket.isUpdatePending
                && (
                    <ContentRow>
                        <PullDownContent>
                            <div className="vub-c-media-search-view__filters">
                                <CustomSelect
                                    label="Genres"
                                    value={filterByValue}
                                    onChange={this.onGenreFilterSelect}
                                >
                                    {filterOptions.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </CustomSelect>
                                <CustomSelect
                                    label="Sort by"
                                    value={orderByValue}
                                    onChange={this.onOrderBySelect}
                                >
                                    {orderByOptions.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option.value}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </CustomSelect>
                                <MoreDetailsFilter
                                    providers={providers}
                                    onMoreDetailsFilterSelect={this.onMoreDetailsFilterSelect}
                                    filterByMoreDetails={filterByMoreDetails}
                                    facetProviders={facetProviders}
                                />
                                <SearchLayoutPicker
                                    searchViewLayout={this.state.searchViewLayout}
                                    updateLayoutSelectButtons={this.updateLayoutSelectButtons}
                                />
                            </div>
                        </PullDownContent>
                    </ContentRow>
                )}

                {searchQueryString.length < minimumSearchQueryLength && (
                    <ContentRow>
                        <div className="vub-c-media-search-view__search-term-length">
                            Search term must be at least 3 characters in length.
                        </div>
                    </ContentRow>
                )}

                {searchQueryString.length >= minimumSearchQueryLength
                && areSearchResultsValid
                && mediaBucket
                && !mediaBucket.isUpdatePending
                && mediaBucket.media.size === 0
                && (
                    <ContentRow>
                        <div className="vub-c-media-search-view__without-media">
                            No media available for your search parameters.
                        </div>
                    </ContentRow>
                )}

                {searchQueryString.length >= minimumSearchQueryLength
                && areSearchResultsValid
                && mediaBucket
                && mediaBucket.media.size > 0
                && (
                    <ContentRow>
                        <MediaSearchGroupedGallery
                            mediaBucket={mediaBucket}
                            searchQueryString={searchQueryString}
                            resolveMediaItemRoute={resolveMediaItemRoute}
                            providers={providers}
                            searchViewLayout={this.state.searchViewLayout}
                            updateLayoutSelectButtons={this.updateLayoutSelectButtons}
                        />
                    </ContentRow>
                )}

                {mediaBucket && mediaBucket.isLoadingMedia ? (
                    <div className="vub-c-media-search-view__spinner">
                        <SpinnerStandard className="vub-c-standard-spinner--small" />
                    </div>
                ) : null}

                <BackToTop
                    title="Back to top"
                    screenMultiplicator={2}
                />
            </div>
        );
    }
}

export default MediaSearchViewCastor;
