import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import './Recaptcha.scss';

const Recaptcha = ({handleRecaptcha, name, siteKey}) => {
    const handleChange = value => handleRecaptcha(name, true);
    const handleExpired = value => handleRecaptcha(name, false);
    return (
        <div className="vub-c-google-recaptcha">
            <ReCAPTCHA
                sitekey={siteKey}
                onChange={handleChange}
                onExpired={handleExpired}
            />
        </div>
    );
};

Recaptcha.propTypes = {
    handleRecaptcha: PropTypes.func,
    name: PropTypes.string,
    siteKey: PropTypes.string,
};

Recaptcha.defaultProps = {
    handleRecaptcha: null,
    name: null,
    siteKey: null,
};

export default Recaptcha;
