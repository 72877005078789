export const avengersEndGame = [{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==1',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'tvod',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'vubiquity.co.uk',
    'currency': 'GBP',
    'price': 4.99,
    'resolution': 'SD',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
},
{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==2',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'tvod',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'netflix.com',
    'currency': 'GBP',
    'price': 5.99,
    'resolution': 'HD',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
},
{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==3',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'tvod',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'playhbonow.com',
    'currency': 'GBP',
    'price': 6.99,
    'resolution': '4K',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
},
{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==4',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'est',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'vubiquity.co.uk',
    'currency': 'GBP',
    'price': 4.99,
    'resolution': 'SD',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
},
{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==5',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'est',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'playhbonow.com',
    'currency': 'GBP',
    'price': 5.99,
    'resolution': 'HD',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
},
{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==6',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'est',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'primevideo.com',
    'currency': 'GBP',
    'price': 7.99,
    'resolution': '4K',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
},
{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==7',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'svod',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'vubiquity.co.uk',
    'currency': 'GBP',
    'price': 17.99,
    'resolution': 'SD',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
},
{
    'id': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==8',
    'mediaItemId': 'VUBI0000000001452610',
    'productId': 'TWVkaWFIdWIgUHVyY2hhc2UgZm9yIDcuOTk',
    'assetId': null,
    'assetOfferId': 'dnViaXF1aXR5LmNvLnVrL21vdmllcy9WVUJJMDAwMDAwMDAwMTQ1MjYxMA==',
    'assetPreviewId': 'https://computerrockmedia-euwe.streaming.media.azure.net/734fe58a-3970-4db0-b59c-95a44f9196c0/Marvel_Studios_Avengers_-_Offici.ism/manifest',
    'link': '',
    'offerType': 'svod',
    'startDate': '2017-03-03T06:00:00.000Z',
    'endDate': '2020-02-28T05:59:59.000Z',
    'billingId': '0000D',
    'rentalPeriod': 'P7D',
    'expirationAfterFirstPlay': 'P2D',
    'provider': 'playhbonow.com',
    'currency': 'GBP',
    'price': 16.99,
    'resolution': 'SD',
    'audioType': [
        'stereo',
    ],
    'isAudioDescribed': false,
    'hasSubtitles': false,
}];
