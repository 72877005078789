import React from 'react';
import PropTypes from 'prop-types';
import './PurchaseOption.scss';
import LayoutBoxRow from '../layout-box/LayoutBoxRow';
import CTAButton from '../cta-button/CTAButton';

const PurchaseOption = ({offers, handleOffer, getPrice, providers}) => {
    let displayTitle = null;

    switch (offers[0].offerType) {
        case 'tvod': displayTitle = 'Rent';
            break;
        case 'est': displayTitle = 'Buy';
            break;
        case 'svod': displayTitle = 'Subscriptions';
            break;
        default:
            // no-op
    }
    return (
        <div className="vub-c-purchase-option">
            <LayoutBoxRow border={['bottom']}>
                {displayTitle}
            </LayoutBoxRow>
            <LayoutBoxRow>
                <div className="vub-c-purchase-option__offers">
                    {offers.map(offer => {
                        const provider = providers.get(offer.provider);
                        return (
                            <CTAButton
                                key={offer.id}
                                onClick={() => handleOffer(offer)}
                            >
                                <div className="vub-c-cta-button__content-with-space-between">
                                    <span>{`${displayTitle === 'Subscriptions' ? 'Subscribe' : displayTitle}
                                        ${offer.offerType !== 'svod' ? offer.resolution : ''} `}
                                        {typeof getPrice === 'function'
                                            ? <span className="vub-c-purchase-option__price">{getPrice(offer)} </span>
                                            : null}
                                        {offer.offerType === 'svod' ? 'p/m' : null}
                                    </span>
                                    {provider && (
                                    <img
                                        className="vub-c-purchase-option__icon"
                                        src={provider.serviceCTALogo}
                                        alt="purchase-option-icon"
                                    />
                                    )}
                                </div>
                            </CTAButton>
                        );
                    })}
                </div>
            </LayoutBoxRow>
        </div>
    );
};

PurchaseOption.propTypes = {
    offers: PropTypes.array,
    handleOffer: PropTypes.func,
    getPrice: PropTypes.func,
    providers: PropTypes.object,
};

PurchaseOption.defaultProps = {
    offers: null,
    handleOffer: null,
    getPrice: null,
    providers: null,
};

export default PurchaseOption;
