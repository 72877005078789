import React, {Component} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import moment from 'moment';
import EPGContext from './EPGContext';
import * as epgTimeSteps from './epgTimeSteps';

const calculateChannelShows = (props, state) => {
    const {epgChannels, epgShows} = props;
    const {referenceTime} = state;
    const timelineLength = moment.duration(props.timelineLength);
    const epgChannelShows = {};
    const epgShowPositions = {};

    if (!epgChannels) return {epgChannelShows, epgShowPositions};

    const timelineStartDate = moment(referenceTime).subtract(timelineLength);
    const timelineEndDate = moment(referenceTime)
        .add(timelineLength)
        .add(timelineLength);
    const timelineLengthMinutes = timelineLength.asMinutes();

    epgChannels.forEach(epgChannel => {
        epgChannelShows[epgChannel.id] = epgShows ? epgShows
            .toArray()
            .filter(epgShow => {
                return epgShow.channelId === epgChannel.id
                    && (epgShow.endDate >= timelineStartDate
                        || epgShow.startDate <= timelineEndDate);
            })
            .sort((epgShowA, epgShowB) => {
                if (epgShowA.startDate > epgShowB.startDate) return 1;
                if (epgShowA.startDate < epgShowB.startDate) return -1;

                return 0;
            }) : [];

        epgChannelShows[epgChannel.id].forEach((epgShow, index) => {
            // calculate show width
            const epgShowDuration = moment.duration(moment(epgShow.endDate).diff(epgShow.startDate));
            const showWidth = epgShowDuration.asMinutes() * 100 / (3 * timelineLengthMinutes);

            // calculate offset from previous show or timeline beginning
            let showOffset = 0;
            const previousEPGShow = index !== 0 ? epgChannelShows[epgChannel.id][index - 1] : null;
            if (!previousEPGShow) {
                const showOffsetPeriod = moment.duration(epgShow.startDate.diff(timelineStartDate));
                showOffset = showOffsetPeriod.asMinutes() * 100 / (3 * timelineLengthMinutes);
            }
            if (previousEPGShow && epgShow.startDate.isSame(previousEPGShow.endDate)) {
                showOffset = 0;
            }
            if (previousEPGShow && !epgShow.startDate.isSame(previousEPGShow.endDate)) {
                const showOffsetPeriod = moment.duration(epgShow.startDate.diff(previousEPGShow.endDate));
                showOffset = showOffsetPeriod.asMinutes() * 100 / (3 * timelineLengthMinutes);
            }

            epgShowPositions[epgShow.id] = {showWidth, showOffset};
        });
    });

    return {epgChannelShows, epgShowPositions};
};

const calculateTimelineScaleFractions = (props, state) => {
    const {referenceTime} = state;
    const timelineLength = moment.duration(props.timelineLength);
    const timelineScaleFraction = moment.duration(props.timelineScaleFraction);

    let timelineStart = moment(referenceTime).subtract(timelineLength);
    const timelineEnd = moment(referenceTime).add(timelineLength).add(timelineLength);
    const timelineScaleFractions = [];
    const timelineScaleFractionWidth = timelineScaleFraction.asMinutes() * 100 / (3 * timelineLength.asMinutes());

    while (timelineStart <= timelineEnd) {
        timelineScaleFractions.push({
            date: moment(timelineStart),
            width: timelineScaleFractionWidth,
        });
        timelineStart = timelineStart.add(timelineScaleFraction);
    }

    return timelineScaleFractions;
};

class EPG extends Component {
    static propTypes = {
        epgChannels: PropTypes.object.isRequired,
        epgShows: PropTypes.object.isRequired,
        availableEPGTimeFrame: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
        loadedEPGTimeFrame: PropTypes.object,
        fetchEPGListing: PropTypes.func,
        startLiveStreamPlayback: PropTypes.func,
        timelineLength: PropTypes.string,
        timelineFrameLength: PropTypes.string,
        timelineScrollStep: PropTypes.string,
        timelineScaleSize: PropTypes.string,
        timelineScaleFraction: PropTypes.string,
    };

    static defaultProps = {
        availableEPGTimeFrame: null,
        loadedEPGTimeFrame: null,
        fetchEPGListing: null,
        startLiveStreamPlayback: null,
        timelineLength: 'PT12H',
        timelineFrameLength: 'PT2H30M',
        timelineScrollStep: 'PT2H',
        timelineScaleSize: 'PT2H30M',
        timelineScaleFraction: 'PT30M',
    };

    constructor(props) {
        super(props);
        this.setSelectedTime = debounce(this.setSelectedTime, 300);
    }

    state = {
        referenceTime: moment().startOf('hour'),
        selectedTime: moment().startOf('hour'),
        currentTime: moment(),
        epgChannelShows: {},
        epgShowPositions: {},
        prevEPGShows: null, // eslint-disable-line react/no-unused-state
    };

    static getDerivedStateFromProps(props, state) {
        const {epgShows} = props;
        if (props.loadedEPGTimeFrame && epgShows !== state.prevEPGShows) {
            const {epgChannelShows, epgShowPositions} = calculateChannelShows(props, state);
            const timelineScaleFractions = calculateTimelineScaleFractions(props, state);

            return {
                epgChannelShows: epgChannelShows,
                epgShowPositions: epgShowPositions,
                timelineScaleFractions: timelineScaleFractions,
                prevEPGShows: epgShows,
            };
        }
        return null;
    }

    // update state exactly every min after start of next min
    componentDidMount() {
        const diff = moment().diff(moment().startOf('minute'));
        setTimeout(() => {
            this.setState({currentTime: moment()});
            this.updateStateTimer = setInterval(() => {
                this.setState({currentTime: moment()});
            }, 60000);
        }, 60000 - diff);
    }

    componentWillUnmount() {
        clearInterval(this.updateStateTimer);
    }

    updateStateTimer = null;

    getDurations = () => {
        const {timelineLength, timelineFrameLength, timelineScrollStep} = this.props;
        const {timelineScaleSize, timelineScaleFraction} = this.props;

        return {
            timelineLength: moment.duration(timelineLength),
            timelineFrameLength: moment.duration(timelineFrameLength),
            timelineScrollStep: moment.duration(timelineScrollStep),
            timelineScaleSize: moment.duration(timelineScaleSize),
            timelineScaleFraction: moment.duration(timelineScaleFraction),
        };
    };

    setSelectedTime = (newSelectedTime = moment().startOf('hour')) => {
        const {fetchEPGListing} = this.props;
        const {timelineFrameLength, timelineLength} = this.getDurations();

        this.setState(state => {
            let newReferenceTime = null;

            if (newSelectedTime > state.selectedTime
                && moment(state.referenceTime).add(timelineFrameLength) <= newSelectedTime) {
                // newReferenceTime = moment(state.referenceTime).add(timelineLength);
                newReferenceTime = moment(newSelectedTime);
            }

            if (newSelectedTime < state.selectedTime
                && state.referenceTime >= moment(newSelectedTime).add(timelineFrameLength)) {
                // newReferenceTime = moment(state.referenceTime).subtract(timelineLength);
                newReferenceTime = moment(newSelectedTime);
            }

            if (newSelectedTime.isSame(state.selectedTime)
                && !state.referenceTime.isSame(newSelectedTime)) {
                newReferenceTime = moment(newSelectedTime);
            }

            // TODO disabled as better pagination needed
            newReferenceTime = null;

            if (newReferenceTime) {
                fetchEPGListing({
                    startDate: moment(newReferenceTime).subtract(timelineLength),
                    endDate: moment(newReferenceTime).add(timelineLength),
                });
            }

            return {
                referenceTime: newReferenceTime || state.referenceTime,
                selectedTime: newSelectedTime,
                ...(newReferenceTime && calculateChannelShows(this.props, state)),
                ...(newReferenceTime && calculateTimelineScaleFractions(this.props, state)),
            };
        });
    };

    scrollToTime = epgTimeStep => {
        const {loadedEPGTimeFrame: availableEPGTimeFrame} = this.props; // TODO overridden as better pagination needed
        const {selectedTime} = this.state;
        const {timelineFrameLength, timelineScrollStep} = this.getDurations();
        let newSelectedTime = moment().startOf('hour');

        switch (epgTimeStep) {
            case epgTimeSteps.PREVIOUS_TIME_SLOT:
                newSelectedTime = moment(selectedTime).subtract(timelineScrollStep);
                break;

            case epgTimeSteps.NEXT_TIME_SLOT:
                newSelectedTime = moment(selectedTime).add(timelineScrollStep);
                break;

            case epgTimeSteps.PREVIOUS_DAY:
                newSelectedTime = moment(selectedTime)
                    .subtract(24, 'hours') > availableEPGTimeFrame.startDate
                    ? moment(selectedTime).subtract(24, 'hours')
                    : moment(availableEPGTimeFrame.startDate);
                break;

            case epgTimeSteps.NEXT_DAY:
                newSelectedTime = moment(selectedTime)
                    .add(24, 'hours').add(timelineFrameLength) < availableEPGTimeFrame.endDate
                    ? moment(selectedTime).add(24, 'hours')
                    : moment(availableEPGTimeFrame.endDate).subtract(timelineFrameLength);
                break;

            case epgTimeSteps.CURRENT_TIME_SLOT:
            default:
            // no-op
        }

        this.setSelectedTime(newSelectedTime);
    };

    render() {
        const {children, epgChannels, startLiveStreamPlayback} = this.props;
        const {loadedEPGTimeFrame: availableEPGTimeFrame} = this.props; // TODO overridden as better pagination needed
        const {referenceTime, selectedTime, currentTime} = this.state;
        const {epgChannelShows, epgShowPositions, timelineScaleFractions} = this.state;
        const {
            timelineLength,
            timelineFrameLength,
            timelineScrollStep,
        } = this.getDurations();

        // calculate slide tray width
        const timelineLengthMinutes = timelineLength.asMinutes();
        const slideTrayWidth = (3 * timelineLengthMinutes) / timelineFrameLength.asMinutes() * 100;
        const timelineLengthClone = timelineLength.clone();
        const selectedTimeOffsetPeriod = timelineLengthClone.add(moment.duration(selectedTime.diff(referenceTime)));
        const slideTrayOffset = selectedTimeOffsetPeriod.asMinutes() * -100 / (3 * timelineLengthMinutes);

        // calculate marker offset
        const markerTime = currentTime;
        const markerOffsetPeriod = moment.duration(markerTime.diff(selectedTime));
        const markerOffset = markerOffsetPeriod.asMinutes() * 100 / timelineFrameLength.asMinutes();
        const isMarkerVisible = epgChannels.toArray().length > 0
            && (markerTime > selectedTime
                && markerTime < moment(selectedTime).add(timelineFrameLength).subtract(10, 'minutes'));

        // calculate button states
        const isScrollToPreviousAvailable = availableEPGTimeFrame && availableEPGTimeFrame.startDate
            <= moment(selectedTime).subtract(timelineScrollStep);
        const isScrollToNextAvailable = availableEPGTimeFrame && availableEPGTimeFrame.endDate
            > moment(selectedTime).add(timelineScrollStep).add(timelineFrameLength);

        return (
            <EPGContext.Provider
                value={{
                    selectedTime,
                    currentTime,
                    epgChannels,
                    epgChannelShows,
                    epgShowPositions,
                    timelineScaleFractions,
                    isScrollToPreviousAvailable,
                    isScrollToNextAvailable,
                    slideTrayWidth,
                    slideTrayOffset,
                    markerOffset,
                    isMarkerVisible,
                    startLiveStreamPlayback,
                    scrollToTime: this.scrollToTime,
                }}
            >
                {children}
            </EPGContext.Provider>
        );
    }
}

export default EPG;
