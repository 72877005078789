import React from 'react';
import './Header.scss';
import NavigationSide from './NavigationSide';
import NavigationTop from './NavigationTop';
import LogoHeader from './LogoHeader';
import NavigationSearch from './NavigationSearch';
import NavigationAccount from './NavigationAccount';

const Header = () => (
    <div className="vub-c-header">
        <NavigationSide isSwitchable />
        <NavigationTop />
        <LogoHeader />
        <div className="vub-c-header__navigation-top-right">
            <NavigationSearch />
            <NavigationAccount />
        </div>
    </div>
);

export default Header;
