import {fork, put, take, select} from 'redux-saga/effects';
import * as actionTypes from './storeConfigurationActionTypes';
import * as storeConfigurationSelectors from './storeConfigurationSelectors';
import fetchProviders from './sagas/fetchProviders';

/**
 * Load providers
 */
export const loadProviders = function* loadProviders() {
    const providers = yield select(storeConfigurationSelectors.getProviders);
    if (!providers) return null;

    yield fork(fetchProviders);
    const resultAction = yield take([
        actionTypes.FETCH_PROVIDERS_SUCCESS,
        actionTypes.FETCH_PROVIDERS_ERROR,
    ]);

    if (!resultAction.error) {
        const {response: providerDTOs} = resultAction.payload;
        yield put({
            type: actionTypes.STORE_PROVIDERS,
            payload: {providerDTOs},
        });
    }
};
