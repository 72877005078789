import Immutable from 'immutable';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import resolveRoute from './resolveRoute';

/**
 * Media route resolver
 * TODO implement default routePaths
 */
class MediaRouteResolver {
    media = Immutable.Map();
    mediaGenres = Immutable.Map();
    mediaCollections = Immutable.Map();

    constructor(options = {}) {
        this.resolveRoute = options.resolveRoute || resolveRoute;
    }

    setMedia(media = Immutable.Map()) {
        this.media = media;
    }

    setMediaGenres(mediaGenres = Immutable.Map()) {
        this.mediaGenres = mediaGenres;
    }

    setMediaCollections(mediaCollections = Immutable.Map()) {
        this.mediaCollections = mediaCollections;
    }

    /**
     * Resolves route to mediaItem
     *
     * @param {Object} params
     * @param params.mediaItemId
     * @param params.toParams
     */
    resolveMediaItemRoute({mediaItemId, toParams = {}}) {
        const mediaItem = this.media.get(mediaItemId);
        if (!mediaItem) return {pathname: ''};

        let resolvedRoute = this.resolveRoute(
            '/media-items/:mediaItemId',
            {
                mediaItemId: mediaItem.id,
            },
            toParams,
        );

        switch (mediaItem.classification) {
            case mediaItemClassification.MOVIE:
                resolvedRoute = this.resolveRoute(
                    '/movies/:mediaItemId',
                    {
                        mediaItemId: mediaItem.id,
                    },
                    toParams,
                );
                break;

            case mediaItemClassification.TV_SHOW:
                resolvedRoute = this.resolveRoute(
                    '/tv-shows/:mediaItemId',
                    {
                        mediaItemId: mediaItem.id,
                    },
                    toParams,
                );
                break;

            case mediaItemClassification.SEASON:
                resolvedRoute = this.resolveRoute(
                    '/tv-shows/:mediaItemId/:seasonMediaItemId',
                    {
                        mediaItemId: mediaItem.parentId,
                        seasonMediaItemId: mediaItem.id,
                    },
                    toParams
                );
                break;

            case mediaItemClassification.EPISODE:
                if (mediaItem.parentId) {
                    const seasonMediaItem = this.media.get(mediaItem.parentId);
                    resolvedRoute = this.resolveRoute(
                        '/tv-shows/:mediaItemId/:seasonMediaItemId/:episodeMediaItemId',
                        {
                            mediaItemId: seasonMediaItem.parentId,
                            seasonMediaItemId: mediaItem.parentId,
                            episodeMediaItemId: mediaItem.id,
                        },
                        toParams,
                    );
                }
                break;
        }

        return resolvedRoute;
    }

    /**
     * Resolves route to mediaGenre
     *
     * @param {Object} params
     * @param params.mediaGenreId
     * @param params.classification
     * @param params.toParams
     */
    resolveMediaGenreRoute({mediaGenreId, classification, toParams = {}}) {
        const mediaGenre = this.mediaGenres.get(mediaGenreId);
        if (!mediaGenre) return {pathname: ''};

        return this.resolveRoute(
            '/genres/:mediaGenreId/:classification?',
            {
                mediaGenreId: mediaGenre.id,
                ...(classification ? {classification} : {}),
            },
            toParams,
        );
    }

    /**
     * Resolves route to mediaCollection
     *
     * @param {Object} params
     * @param params.mediaCollectionId
     * @param params.classification
     * @param params.toParams
     */
    resolveMediaCollectionRoute({mediaCollectionId, classification, toParams = {}}) {
        const mediaCollection = this.mediaCollections.get(mediaCollectionId);
        if (!mediaCollection) return {pathname: ''};

        return this.resolveRoute(
            '/collections/:mediaCollectionId/:classification?',
            {
                mediaCollectionId: mediaCollection.id,
                ...(classification ? {classification} : {}),
            },
            toParams,
        );
    }
}

export default MediaRouteResolver;
