import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as authActions from '../auth/authActions';
import './AccountPasswordResetView.scss';
import Input from '../ui-elements/input/Input';
import FormContainer from '../ui-elements/form/FormContainer';
import {required, isPasswordValid, isMatchWith} from '../ui-elements/form-validation/validations';
import ServerValidationErrors from '../ui-elements/form-validation/ServerValidationErrors';
import FormButton from '../ui-elements/form/FormButton';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';

@withRouter
class AccountPasswordResetView extends Component {
    static propTypes = {
        signInUser: PropTypes.func.isRequired,
        signUpUser: PropTypes.func.isRequired,
        resetPassword: PropTypes.func.isRequired,
        isSessionChangeInProgress: PropTypes.bool,
        location: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isSessionChangeInProgress: false,
    };

    // TODO - get server errors via redux
    // ['404 - not found', '500 - Internal server error'];
    serverErrors = [];

    getFormValues = ({newPassword}) => {
        const {resetPassword, location} = this.props;
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        resetPassword({token, newPassword});
    };

    render() {
        const {signInUser, signUpUser} = this.props;
        const {isSessionChangeInProgress} = this.props;

        return (
            <div className="vub-c-account-request-password-reset-view">
                <div className="vub-c-account-request-password-reset-view__title">
                    reset password
                </div>
                <div className="vub-c-account-request-password-reset-view__form">
                    <FormContainer
                        initialState={{
                            newPassword: '',
                            confirmedPassword: '',
                            errors: {},
                        }}
                        getFormValues={this.getFormValues}
                        render={
                            (
                                {newPassword, confirmedPassword, errors},
                                handleValues
                            ) => (
                                <Fragment>
                                    <Input
                                        type="password"
                                        name="newPassword"
                                        className="vub-c-input-container--auth"
                                        placeholder="New Password"
                                        value={newPassword}
                                        handleChange={handleValues}
                                        isDisabled={isSessionChangeInProgress}
                                        validators={[required, isPasswordValid]}
                                        error={errors['newPassword']}
                                    />
                                    <Input
                                        type="password"
                                        name="confirmedPassword"
                                        className="vub-c-input-container--auth"
                                        placeholder="Confirm Password"
                                        value={confirmedPassword}
                                        handleChange={handleValues}
                                        isDisabled={isSessionChangeInProgress}
                                        validators={[required, isPasswordValid, isMatchWith('newPassword')]}
                                        validationFieldToMatch="newPassword"
                                        error={errors['confirmedPassword']}
                                    />
                                    {this.serverErrors
                                    && this.serverErrors.length > 0
                                    && <ServerValidationErrors errors={this.serverErrors} />
                                    }
                                    <div className="vub-c-account-request-password-reset-view__buttons">
                                        <FormButton
                                            type="submit"
                                            isDisabled={
                                                isSessionChangeInProgress
                                                || !newPassword
                                                || !confirmedPassword
                                                || Object.values(errors).some(el => el.isInvalid)
                                            }
                                        >
                                            {isSessionChangeInProgress ? <SpinnerStandard /> : 'Submit'}
                                        </FormButton>
                                    </div>
                                </Fragment>
                            )
                        }
                    />
                </div>
                <div className="vub-c-account-request-password-reset-view__link-container">
                    <label
                        className="vub-c-account-request-password-reset-view__link"
                        onClick={signInUser}
                    >
                        Go back to Sign In.
                    </label>
                    <label
                        className="vub-c-account-request-password-reset-view__link"
                        onClick={signUpUser}
                    >
                        {`Don't have an account yet? Sign up now!`}
                    </label>
                </div>
            </div>
        );
    }
}

export {AccountPasswordResetView};

const mapStateToProps = state => ({
    isSessionChangeInProgress: state.auth.isSessionChangeInProgress,
});

const mapDispatchToProps = dispatch => ({
    signInUser: payload => dispatch(authActions.signInUser(payload)),
    signUpUser: payload => dispatch(authActions.signUpUser(payload)),
    resetPassword: payload => dispatch(authActions.resetPassword(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountPasswordResetView);
