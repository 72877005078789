import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import * as authActions from '../../auth/authActions';
import './NavigationAccount.scss';
import {
    iconMenuLibrary,
    iconMenuSignOut,
    iconNavUser,
} from '../../ui-elements/icons';
import NavigationIcon from './NavigationIcon';
import SubscriptionLabel from '../../ui-elements/subscription-label/SubscriptionLabel';

const NavigationAccount = ({isUserSignedIn, userAccount, signInUser, signOutUser, updateUser}) => (
    <div className="vub-c-navigation-account">
        {isUserSignedIn ? (
            <Fragment>
                <NavigationIcon icon={iconNavUser} className="vub-c-navigation-icon--large" />
                {userAccount && userAccount.name && (
                    <div className="vub-c-navigation-account__name">
                        Hello {userAccount.name.slice(0, 10)}
                    </div>
                )}
            </Fragment>
        ) : (
            <label
                className="vub-c-navigation-account__sign-in"
                onClick={signInUser}
            >
                Sign in
            </label>
        )}
        <div
            className={`vub-c-navigation-account__content ${isUserSignedIn
                ? 'vub-c-navigation-account__content--is-active' : ''}`}
        >
            <ul className="vub-c-navigation-account__menu">
                {isUserSignedIn ? (
                    <li className="vub-c-navigation-account__menu-item">
                        <label
                            className="vub-c-navigation-account__menu-label"
                            onClick={updateUser}
                        >
                            Account
                            {userAccount.primarySubscription && (
                                <SubscriptionLabel>
                                    {userAccount.primarySubscription.type}
                                </SubscriptionLabel>
                            )}
                            <NavigationIcon icon={iconNavUser} />
                        </label>
                    </li>
                ) : null}
                {isUserSignedIn ? (
                    <li className="vub-c-navigation-account__menu-item">
                        <Link
                            className="vub-c-navigation-account__menu-link"
                            to={resolveRoute(routePaths.ACCOUNT_LIBRARY)}
                        >
                            Library
                            <NavigationIcon icon={iconMenuLibrary} />
                        </Link>
                    </li>
                ) : null}
                {isUserSignedIn ? (
                    <li className="vub-c-navigation-account__menu-item">
                        <Link
                            className="vub-c-navigation-account__menu-link"
                            to={resolveRoute(routePaths.STOREFRONT)}
                            onClick={signOutUser}
                        >
                            Sign Out
                            <NavigationIcon icon={iconMenuSignOut} />
                        </Link>
                    </li>
                ) : null}
            </ul>
        </div>
    </div>
);

NavigationAccount.propTypes = {
    isUserSignedIn: PropTypes.bool,
    userAccount: PropTypes.object,
    signInUser: PropTypes.func.isRequired,
    signOutUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
};

NavigationAccount.defaultProps = {
    isUserSignedIn: false,
    userAccount: null,
};

export {NavigationAccount};

// connect component to redux store and actions
const mapStateToProps = state => ({
    isUserSignedIn: state.auth.isUserSignedIn,
    userAccount: state.auth.userAccount,
});

const mapDispatchToProps = dispatch => ({
    signInUser: payload => dispatch(authActions.signInUser(payload)),
    signOutUser: payload => dispatch(authActions.signOutUser(payload)),
    updateUser: payload => dispatch(authActions.updateUser(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavigationAccount);
