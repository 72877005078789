import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ButtonBehavior, ButtonBehaviorPropTypes, ButtonBehaviorDefaultProps} from './behaviors/ButtonBehavior';
import {BEMClassNameResolver, BEMClassNameResolverPropTypes, BEMClassNameResolverDefaultProps} from './BEMClassNameResolver';
import './Button.scss';

@ButtonBehavior
@BEMClassNameResolver('cr-c-button')
class Button extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        ...ButtonBehaviorPropTypes,
        children: PropTypes.node,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        ...ButtonBehaviorDefaultProps,
        children: null,
    };

    render() {
        const {type, onClick, isDisabled, children} = this.props;
        const {resolveClassName} = this.props;
        return (
            // https://github.com/yannickcr/eslint-plugin-react/issues/1555
            // eslint-disable-next-line react/button-has-type
            <button type={type} className={resolveClassName()} onClick={onClick} disabled={isDisabled}>
                {children}
            </button>
        );
    }
}

export default Button;
