import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect';
import './NestedCustomSelect.scss';
import CustomNestedDropDownTouchDevice from './CustomNestedDropDowmTouchDevice';
import {
    withOverlay,
    withOverlayPropTypes,
    withOverlayDefaultProps,
} from '../overlay/withOverlay';
import {
    withOutsideClick,
    withOutsideClickPropTypes,
    withOutsideClickDefaultProps,
} from '../outside-click/withOutsideClick';

@withOverlay
@withOutsideClick
class NestedCustomSelect extends Component {
    static propTypes = {
        ...withOverlayPropTypes,
        ...withOutsideClickPropTypes,
        label: PropTypes.string,
        value: PropTypes.string,
        children: PropTypes.node,
        onChange: PropTypes.func,
        isTouchDeviceCustomDropDownDisabled: PropTypes.bool,
        isChild: PropTypes.bool,
    };

    static defaultProps = {
        ...withOverlayDefaultProps,
        ...withOutsideClickDefaultProps,
        label: null,
        value: null,
        children: null,
        onChange: null,
        isTouchDeviceCustomDropDownDisabled: false,
        isChild: false,
    };

    state = {
        isActive: false,
    }

    componentDidMount() {
        this.props.subscribeToClickOutside(this.clickOutsideRef, this.onClickOutside);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromClickOutside(this.clickOutsideRef, this.onClickOutside);
    }

    onClickOutside = () => {
        if (!this.props.isChild) {
            this.setState({
                isActive: false,
            });
        }
    }

    toggleDropDown = () => {
        this.setState(state => ({
            isActive: !state.isActive,
        }));
    }

    closeDropDown = () => {
        this.setState({
            isActive: false,
        });
    }

    arrayOfOptionsRefs = [];

    setOptionRef = element => {
        if (element !== null) {
            this.arrayOfOptionsRefs.push(element);
        }
    };

    clearOptionsRefs = () => {
        this.arrayOfOptionsRefs = [];
    };

    currentOptionIndex = 0;

    handleOptionsOnKeyDown = (e, index, value, parentValue) => {
        e.preventDefault();
        const disabledClass = 'vub-c-nested-custom-select-item--is-disabled';
        const len = this.arrayOfOptionsRefs.length - 1;
        switch (e.key) {
            case 'Enter':
            case ' ':
                this.toggleDropDown();
                this.props.onChange(value, parentValue);
                break;
            case 'Escape':
                this.toggleDropDown();
                this.currentOptionIndex = 0;
                break;
            case 'ArrowUp':
                if (index > 0) {
                    let nextIndex = index - 1;
                    if (this.arrayOfOptionsRefs[index - 1].classList.contains(disabledClass) && index > 1) {
                        nextIndex = index - 2;
                    }
                    this.arrayOfOptionsRefs[nextIndex].focus();
                    this.currentOptionIndex = nextIndex;
                    this.arrayOfOptionsRefs[index].blur();
                }
                break;
            case 'ArrowDown':
                if (len > index) {
                    let previousIndex = index + 1;
                    if (this.arrayOfOptionsRefs[index + 1].classList.contains(disabledClass) && index < len - 1) {
                        previousIndex = index + 2;
                    }
                    this.arrayOfOptionsRefs[previousIndex].focus();
                    this.arrayOfOptionsRefs[this.currentOptionIndex].blur();
                    this.currentOptionIndex = previousIndex;
                }
                break;
            default:
                return;
        }
    }

    handleOptionsOnMouseOver = (e, index) => {
        this.arrayOfOptionsRefs[index].focus();
        if (index !== this.currentOptionIndex) {
            this.arrayOfOptionsRefs[this.currentOptionIndex].blur();
            this.currentOptionIndex = index;
        }
    }

    renderSelectedContent = (options = [], selectedValue, prop = 'value') => {
        const selectedOption = options.find(el => el.props[prop] === selectedValue);
        return selectedOption ? selectedOption.props.children : selectedValue;
    };

    setSelectedValue = (value, options = [], prop = 'value') => value || (options.length && options[0].props[prop]);

    clickOutsideRef = React.createRef();

    render() {
        const {
            label,
            children,
            value,
            onChange,
            className,
            closeOverlay,
            openOverlay,
            isTouchDeviceCustomDropDownDisabled,
        } = this.props;
        const options = React.Children.toArray(children);
        // val equal to value or to the first item value from the options
        const selectedValue = this.setSelectedValue(value, options);
        const onChangeItem = (val, prop) => {
            this.closeDropDown();
            // close overlay when it is open
            closeOverlay();
            onChange(val, prop);
        };
        const closeDropDownItem = () => {
            if (typeof this.props.closeDropDown === 'function') {
                this.props.closeDropDown();
            }
            this.closeDropDown();
        };
        const updatedChildren = React.Children.map(
            children,
            (child, index) => React.cloneElement(child, {
                onChange: onChangeItem,
                closeDropDown: closeDropDownItem,
                selectedValue,
                setOptionRef: this.setOptionRef,
                handleOptionsOnKeyDown: this.handleOptionsOnKeyDown,
                handleOptionsOnMouseOver: this.handleOptionsOnMouseOver,
                index,
            })
        );
        // show pop up with native app download option if user on mobile
        const md = new MobileDetect(window.navigator.userAgent);
        const isTouchDevice = md.os() === 'AndroidOS' || md.os() === 'iOS';

        return (
            <div
                className={`vub-c-nested-custom-select ${className || ''}`}
                ref={this.clickOutsideRef}
                tabIndex="0"
            >
                {label && <label className="vub-c-nested-custom-select__label">{label}</label>}
                <div className="vub-c-nested-custom-select__field" onClick={this.toggleDropDown}>
                    <span className="vub-c-nested-custom-select__field-value">
                        {this.renderSelectedContent(options, selectedValue)}
                    </span>
                    <div className="vub-c-nested-custom-select__icon" />
                </div>
                {(this.state.isActive) && (
                    isTouchDevice && !isTouchDeviceCustomDropDownDisabled ? (
                        <div className="vub-c-nested-custom-select__drop-down-touch-device-wrapper">
                            <CustomNestedDropDownTouchDevice
                                className={className}
                                openOverlay={openOverlay}
                                closeOverlay={closeOverlay}
                                closeDropDown={closeDropDownItem}
                                closeCurrentDropDown={this.closeDropDown}
                                label={label}
                            >
                                {updatedChildren}
                            </CustomNestedDropDownTouchDevice>
                        </div>
                    ) : (
                        <ul className="vub-c-nested-custom-select__drop-down">
                            {updatedChildren}
                        </ul>
                    ))}
            </div>
        );
    }
}

export default NestedCustomSelect;

