/**
 * Menu types
 */
export default {
    GROUP: 'GROUP',
    CONTAINER: 'CONTAINER',
    CATEGORY: 'CATEGORY',
    GENRE: 'GENRE',
    ALL_MOVIES: 'ALL_MOVIES',
    ALL_TV_SHOWS: 'ALL_TV_SHOWS',
    EXTERNAL_URL: 'EXTERNAL_URL',
    PAGE: 'PAGE',
    ROUTE: 'ROUTE',
};
