import {all, call, takeEvery, select} from 'redux-saga/effects';
// import {gaService} from '../services';
// import config from '../config';

/**
 * Track view
 */
export const trackView = function trackView({pathname}) {
    // TODO ga temporary disabled (Edge/IE issue)
    // gaService.pageview({
    //     dp: pathname,
    //     dt: pathname,
    //     dh: config.BASE_URL,
    //     dr: document.referrer,
    // }).send();
};

/**
 * On location change
 */
const onLocationChange = function* onLocationChange() {
    const routerState = yield select(state => state.router);
    yield call(trackView, {
        pathname: routerState.location.pathname,
    });
};

/**
 * Analytics watcher saga
 */
export const analyticsWatcher = function* analyticsWatcher() {
    yield all([
        takeEvery('@@router/LOCATION_CHANGE', onLocationChange),
    ]);
};
