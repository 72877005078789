import {fork, put, take, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as mediaSelectors from '../../media/mediaSelectors';
import * as actionTypes from '../mediaEntitlementActionTypes';
import {mediaRouteResolver} from '../../services';
import checkoutSingleMediaItemOffer from './checkoutSingleMediaItemOffer';
import chargeBasket from './chargeBasket';

/**
 * Fast checkout mediaItem offer flow
 */
const fastCheckoutMediaItemOfferFlow = function* fastCheckoutMediaItemOfferFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.FAST_CHECKOUT_MEDIA_ITEM_OFFER);
        yield put({type: actionTypes.STORE_FAST_CHECKOUT_STATUS, payload: {isFastCheckoutInProgress: true}});

        const {offer} = payload;
        yield put({
            type: actionTypes.STORE_BASKET_ITEM,
            payload: {offerId: offer.id, mediaItemId: offer.mediaItemId, updatePending: true},
        });
        yield fork(checkoutSingleMediaItemOffer, {offerId: offer.id});
        const checkoutSingleMediaItemOfferResultAction = yield take([
            actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_SUCCESS,
            actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR,
        ]);

        if (checkoutSingleMediaItemOfferResultAction.type === actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR) {
            yield put({
                type: actionTypes.STORE_FAST_CHECKOUT_STATUS,
                payload: {isFastCheckoutInProgress: false},
            });
            continue;
        }

        const {response: basketItemDTO} = checkoutSingleMediaItemOfferResultAction.payload;
        yield put({
            type: actionTypes.STORE_BASKET_ITEM,
            payload: {basketItemDTO},
        });

        const fastCheckoutResultAction = yield take([
            actionTypes.CANCEL_MEDIA_ITEM_OFFER_FAST_CHECKOUT,
            actionTypes.CHARGE_BASKET,
        ]);

        if (fastCheckoutResultAction.type === actionTypes.CANCEL_MEDIA_ITEM_OFFER_FAST_CHECKOUT) {
            yield put({type: actionTypes.STORE_FAST_CHECKOUT_STATUS, payload: {isFastCheckoutInProgress: false}});
            continue;
        }

        yield fork(chargeBasket, {amountDue: offer.price, currencyCode: offer.currency});
        yield put({type: actionTypes.STORE_BASKET_CHARGING_STATUS, payload: {isBasketChargingInProgress: true}});

        const chargeBasketResultAction = yield take([
            actionTypes.CHARGE_BASKET_SUCCESS,
            actionTypes.CHARGE_BASKET_ERROR,
        ]);

        yield put({type: actionTypes.STORE_BASKET_CHARGING_STATUS, payload: {isBasketChargingInProgress: false}});
        yield put({type: actionTypes.STORE_FAST_CHECKOUT_STATUS, payload: {isFastCheckoutInProgress: false}});

        if (chargeBasketResultAction.type === actionTypes.CHARGE_BASKET_ERROR) continue;

        const {response: entitlementDTOs} = chargeBasketResultAction.payload;
        yield put({
            type: actionTypes.STORE_CURRENT_ENTITLEMENTS,
            payload: {entitlementDTOs},
        });
        yield put({type: actionTypes.REMOVE_BASKET_ITEM, payload: {offerId: offer.id}});

        const mediaItems = yield select(mediaSelectors.getMediaItems);
        const mediaItem = mediaItems.get(offer.mediaItemId);
        if (mediaItem) {
            const media = yield select(mediaSelectors.getMediaItems);
            mediaRouteResolver.setMedia(media);
            yield put(push(mediaRouteResolver.resolveMediaItemRoute({mediaItemId: mediaItem.id})));
        }
    }
};

export default fastCheckoutMediaItemOfferFlow;
