import {createSelector} from 'reselect';
import Immutable from 'immutable';
import {matchPath} from 'react-router';
import * as mediaSelectors from './mediaSelectors';
import routePaths from '../routePaths';

export const createMediaSearchViewBucketSelector = () => createSelector(
    [
        mediaSelectors.getMediaItems,
        mediaSelectors.getMediaBuckets,
    ],
    (mediaItems, mediaBuckets) => {
        const mediaBucketKey = 'MEDIA_SEARCH_VIEW';
        const mediaBucket = mediaBuckets.get(mediaBucketKey);
        if (!mediaBucket) return mediaBucket;

        let media = Immutable.OrderedMap();
        mediaBucket.mediaIds.forEach(mediaItemId => {
            media = media.set(mediaItemId, mediaItems.get(mediaItemId));
        });

        return mediaBucket.merge({media: media});
    },
);

export const isSearchRouteActive = state => {
    const location = state.router.location;
    const match = matchPath(location.pathname, {
        path: routePaths.MEDIA_SEARCH,
        exact: true,
        strict: false,
    });

    return !!match && match.isExact;
};

export const getSearchQueryString = state => {
    const location = state.router.location;
    const match = matchPath(location.pathname, {
        path: routePaths.MEDIA_SEARCH,
        exact: true,
        strict: false,
    });
    if (!match) return '';

    const {searchQueryString} = match.params;
    return searchQueryString || '';
};
