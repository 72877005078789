import React from 'react';
import PropTypes from 'prop-types';
import {mediaItemStaffMemberType} from '@comrock/vub-wls-media-service';
import './MediaItemDetailsCredits.scss';

const MediaItemDetailsCredits = ({mediaItem}) => {
    return mediaItem.staffMembers.length > 0 ? (
        <div className="vub-c-media-item-details-credits">
            <p className="vub-c-media-item-details-credits__row">
                <span className="vub-c-media-item-details-credits__title">Director:</span>
                <span className="vub-c-media-item-details-credits__text">
                    {mediaItem.staffMembers
                        .filter(staffMember => staffMember.type === mediaItemStaffMemberType.DIRECTOR)
                        .map(staffMember => staffMember.name)
                        .join(', ')}
                </span>
            </p>
            <p className="vub-c-media-item-details-credits__row">
                <span className="vub-c-media-item-details-credits__title">Cast:</span>
                <span className="vub-c-media-item-details-credits__text">
                    {mediaItem.staffMembers
                        .filter(staffMember => staffMember.type === mediaItemStaffMemberType.ACTOR)
                        .map(staffMembers => staffMembers.name)
                        .join(', ')}
                </span>
            </p>
        </div>
    ) : null;
};

MediaItemDetailsCredits.propTypes = {
    mediaItem: PropTypes.object.isRequired,
};

export default MediaItemDetailsCredits;
