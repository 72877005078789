import React, {Component} from 'react';
import {compose, setDisplayName, wrapDisplayName} from 'recompose';
import BaseElement from '../BaseElement';
import DisabledRole from '../roles/DisabledRole';
import SwitchRole from '../roles/SwitchRole';
import ButtonRole from '../roles/ButtonRole';

const ButtonBehavior = BaseComponent => {
    const PreComposedComponent = compose(
        ButtonRole,
        SwitchRole,
        DisabledRole,
    )(BaseComponent);

    @setDisplayName(wrapDisplayName(PreComposedComponent, 'ButtonBehavior'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...PreComposedComponent.propTypes,
        };

        static defaultProps = {
            ...PreComposedComponent.defaultProps,
        };

        render() {
            return (
                <PreComposedComponent {...this.props} />
            );
        }
    }

    return ComposedComponent;
};

const {ButtonBehaviorPropTypes, ButtonBehaviorDefaultProps} = ButtonBehavior(BaseElement);

export {
    ButtonBehavior as default,
    ButtonBehavior,
    ButtonBehaviorPropTypes,
    ButtonBehaviorDefaultProps,
};
