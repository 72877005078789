import React from 'react';
import PropTypes from 'prop-types';
import './SubscriptionLabel.scss';

const SubscriptionLabel = ({children, className}) => (
    <span className={`vub-c-subscription-label ${className}`}>
        {children}
    </span>
);

SubscriptionLabel.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

SubscriptionLabel.defaultProps = {
    className: '',
};

export default SubscriptionLabel;
