import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../mediaEntitlementActionTypes';
import {mediaEntitlementService} from '../../services';

/**
 * Create purchase order item SVOD
 */
const createPurchaseOrderItemSVOD = function* createPurchaseOrderItemSVOD(params) {
    try {
        yield put({type: actionTypes.CREATE_PURCHASE_ORDER_ITEM_SVOD_REQUEST, payload: params});

        const response = yield call(mediaEntitlementService.createPurchaseOrderItemSVOD, params);
        yield put({
            type: actionTypes.CREATE_PURCHASE_ORDER_ITEM_SVOD_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.CREATE_PURCHASE_ORDER_ITEM_SVOD_ERROR, error: true, payload: error});
    }
};

export default createPurchaseOrderItemSVOD;
