import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import {combineClassNames} from '../utils';

const DisabledRole = BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'DisabledRole'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
            isDisabled: PropTypes.bool,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            isDisabled: false,
        };

        render() {
            const props = {
                ...this.props,
                className: combineClassNames(
                    this.props.className,
                    this.props.isDisabled && 'cr-is-disabled',
                ),
            };

            return (
                <BaseComponent {...props} />
            );
        }
    }

    return ComposedComponent;
};

export default DisabledRole;
