import React from 'react';
import PropTypes from 'prop-types';
import './SpinnerContainer.scss';
import SpinnerApp from '../../ui-elements/spinner/SpinnerApp';
import SpinnerStandard from '../../ui-elements/spinner/SpinnerStandard';

const SpinnerContainer = ({title}) => (
    <div className="vub-c-spinner-container">
        <div className="vub-c-spinner-container__title">
            {title}
        </div>
        <div className="vub-c-spinner-container__content">
            <div className="vub-c-spinner-container__content-item">
                <SpinnerApp />
            </div>
            <div className="vub-c-spinner-container__content-item">
                <SpinnerStandard className="vub-c-standard-spinner--small" />
            </div>
        </div>
    </div>
);

SpinnerContainer.propTypes = {
    title: PropTypes.string,
};

SpinnerContainer.defaultProps = {
    title: 'loader',
};

export default SpinnerContainer;
