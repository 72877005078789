import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {renderRoutes} from 'react-router-config';
import './AppLayout.scss';
import config from '../config';
import Header from './header/Header';
import Footer from './footer/Footer';
import LayoutControlsContext from './LayoutControlsContext';
import LayoutControlsProvider from './LayoutControlsProvider';
import {combineClassNames} from '../ui-kit/utils';
import MediaSearchProvider from '../media/MediaSearchProvider';
import MediaItemPopoverProvider from '../ui-elements/media-item-popover/MediaItemPopoverProvider';
import MediaItemPopoverView from '../media-item-popover-view/MediaItemPopoverView';
import MediaPlayerView from '../media-player-view/MediaPlayerView';
import LiveStreamPlayerView from '../live-stream-player-view/LiveStreamPlayerView';
import FastCheckoutView from '../fast-checkout-view/FastCheckoutView';
import OverlayProvider from '../ui-elements/overlay/OverlayProvider';
import PopUpProvider from '../ui-elements/pop-up/PopUpProvider';
import OutsideClickProvider from '../ui-elements/outside-click/OutsideClickProvider';
import SubscriptionCheckoutView from '../subscription-checkout-view/SubsriptionCheckoutView';

class AppLayout extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location
            && (this.props.location.state ? !this.props.location.state.disableScrollRestoration : true)) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const {route} = this.props;

        return (
            <OutsideClickProvider>
                <OverlayProvider>
                    <PopUpProvider>
                        <LayoutControlsProvider>
                            <MediaSearchProvider>
                                <MediaItemPopoverProvider
                                    paddingTop={90}
                                    renderPopoverContent={(mediaItem, hidePopover) => (
                                        <MediaItemPopoverView mediaItem={mediaItem} hidePopover={hidePopover} />
                                    )}
                                    isPopoverDisabled={config.TEMP_DISABLE_MEDIA_ITEM_POPOVER}
                                >
                                    <LayoutControlsContext.Consumer>
                                        {context => {
                                            const {isHeaderReduced, isHeaderInUse, isHeaderTransparent} = context;

                                            return (
                                                <div className="vub-c-app-layout">
                                                    <header
                                                        className={combineClassNames(
                                                            'vub-c-app-layout__header',
                                                            isHeaderReduced && 'vub-c-app-layout__header--reduced',
                                                            isHeaderTransparent
                                                            && !isHeaderReduced
                                                            && !isHeaderInUse
                                                            && 'vub-c-app-layout__header--transparent',
                                                        )}
                                                    >
                                                        <Header />
                                                    </header>
                                                    <div className="vub-c-app-layout__main">
                                                        {renderRoutes(route.routes)}
                                                    </div>
                                                    <footer className="vub-c-app-layout__footer">
                                                        <Footer />
                                                    </footer>
                                                </div>
                                            );
                                        }}
                                    </LayoutControlsContext.Consumer>
                                    <MediaPlayerView />
                                    <LiveStreamPlayerView />
                                    <FastCheckoutView />
                                    <SubscriptionCheckoutView />
                                </MediaItemPopoverProvider>
                            </MediaSearchProvider>
                        </LayoutControlsProvider>
                    </PopUpProvider>
                </OverlayProvider>
            </OutsideClickProvider>
        );
    }
}

export default AppLayout;
