import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    withMediaItemPopover,
    withMediaItemPopoverPropTypes,
    withMediaItemPopoverDefaultProps,
} from '../media-item-popover/withMediaItemPopover';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../ui-kit/withStyles';
import styles from './MediaCarouselSlidePortrait.module.scss';
import {CarouselSlide} from '../../ui-kit';

@withMediaItemPopover
@withStyles(styles)
class MediaCarouselSlidePortrait extends Component {
    static propTypes = {
        ...withStylesPropTypes,
        ...withMediaItemPopoverPropTypes,
        mediaItem: PropTypes.object,
        linkTo: PropTypes.object,
    };

    static defaultProps = {
        ...withStylesDefaultProps,
        ...withMediaItemPopoverDefaultProps,
        mediaItem: null,
        linkTo: {pathname: ''},
    };

    componentWillUnmount() {
        this.props.toggleMediaItemPopover(false);
    }

    onMouseEnter = () => this.props.toggleMediaItemPopover(true, this.props.mediaItem);

    onMouseOut = () => this.props.toggleMediaItemPopover(false);

    render() {
        const {className, cx, createClassNameResolver, ...props} = this.props;
        const {mediaItem, popoverCallerRef, linkTo, children, ...restProps} = props;
        const imageUrl = mediaItem.imageBoxCover ? `${mediaItem.imageBoxCover}&q=70&w=200` : null;

        return (
            <CarouselSlide
                {...restProps}
                className={cx(className)}
                classNameResolver={createClassNameResolver('vub-c-media-carousel-slide-portrait')}
            >

                <div
                    className={cx('vub-c-media-carousel-slide-portrait__content')}
                    onMouseEnter={this.onMouseEnter}
                    onMouseOut={this.onMouseOut}
                    ref={popoverCallerRef}
                >
                    <div className={cx('vub-c-media-carousel-slide-portrait__image-placeholder')}>
                        {mediaItem ? (
                            <Link
                                className={cx('vub-c-media-carousel-slide-portrait__link')}
                                to={linkTo}
                            >
                                <img
                                    className={cx('vub-c-media-carousel-slide-portrait__image')}
                                    src={imageUrl}
                                    alt="box cover"
                                />
                                {children}
                            </Link>
                        ) : null}
                    </div>
                </div>

            </CarouselSlide>
        );
    }
}

export default MediaCarouselSlidePortrait;
