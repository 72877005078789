import {createSelector} from 'reselect';
import Immutable from 'immutable';
import * as mediaSelectors from '../media/mediaSelectors';

export const getMediaBucketKey = (state, props) => {
    const {match} = props;
    const {mediaCollectionId, classification} = match.params;
    return !classification
        ? `MEDIA_COLLECTION_VIEW['${mediaCollectionId}']`
        : `MEDIA_COLLECTION_VIEW['${mediaCollectionId}']['${classification}']`;
};

export const createMediaCollectionViewBucketSelector = () => createSelector(
    [
        mediaSelectors.getMediaItems,
        mediaSelectors.getMediaBuckets,
        getMediaBucketKey,
    ],
    (mediaItems, mediaBuckets, mediaBucketKey) => {
        const mediaBucket = mediaBuckets.get(mediaBucketKey);
        if (!mediaBucket) return mediaBucket;

        let media = Immutable.OrderedMap();
        mediaBucket.mediaIds.forEach(mediaItemId => {
            media = media.set(mediaItemId, mediaItems.get(mediaItemId));
        });

        return mediaBucket.merge({media: media});
    },
);
