import React from 'react';
import './ContentRow.scss';

const ContentRow = ({children}) => (
    <div className="vub-c-content-row">
        {children}
    </div>
);

export default ContentRow;
