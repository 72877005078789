import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../../media-entitlement/withMediaActions';
import CTAButton from '../../ui-elements/cta-button/CTAButton';

@withMediaActions
class PurchaseCTAButtonsAVODPopover extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object.isRequired,
        hidePopover: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
    };

    render() {
        const {mediaItem, hidePopover} = this.props;
        const {isFastCheckoutInProgress, checkoutSubscription} = this.props;
        const offers = mediaItem.offers.filter(offer => offer.offerType === mediaItemOfferType.AVOD);

        if (offers.length === 1) {
            const offer = offers[0];
            const fastCheckoutSubscription = () => {
                hidePopover();
                checkoutSubscription(offer);
            };

            return (
                <CTAButton
                    key={offer.id}
                    onClick={fastCheckoutSubscription}
                    isDisabled={isFastCheckoutInProgress}
                >
                    <span>
                        Play
                    </span>
                </CTAButton>
            );
        }

        // return null if no render condition is matched
        return null;
    }
}

export default PurchaseCTAButtonsAVODPopover;
