import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
    withMediaActions,
    withMediaActionsPropTypes,
    withMediaActionsDefaultProps,
} from '../../media-entitlement/withMediaActions';
import CTAButton from '../../ui-elements/cta-button/CTAButton';
import CTAOfferInfo from '../../ui-elements/cta-offer-info/CTAOfferInfo';

@withMediaActions
class EpisodicCTAButtonsPopover extends Component {
    static propTypes = {
        ...withMediaActionsPropTypes,
        mediaItem: PropTypes.object.isRequired,
        hidePopover: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...withMediaActionsDefaultProps,
    };

    render() {
        const {mediaItem, hidePopover} = this.props;
        const {isUserSignedIn, openMediaItemRoute} = this.props;

        const goToMediaItem = () => {
            hidePopover();
            openMediaItemRoute(mediaItem);
        };

        return isUserSignedIn ? (
            <Fragment>
                <CTAButton
                    onClick={goToMediaItem}
                    className="vub-c-cta-button--secondary"
                >
                    Season Details
                </CTAButton>
                <CTAOfferInfo className="vub-c-cta-offer-info--secondary">
                    Find available episodes
                </CTAOfferInfo>
            </Fragment>
        ) : null;
    }
}

export default EpisodicCTAButtonsPopover;
