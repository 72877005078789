import React from 'react';
import PropTypes from 'prop-types';
import './CTADropDownOption.scss';

const CTADropDownOption = ({children, value, onChange, className}) => {
    const handleClick = value => onChange(value);

    return (
        <div className={`vub-c-cta-drop-down-option ${className || ''}`} onClick={handleClick}>
            {children}
        </div>
    );
};

CTADropDownOption.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

CTADropDownOption.defaultProps = {
    value: null,
    onChange: null,
    className: null,
};

export default CTADropDownOption;
