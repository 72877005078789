import React, {Component} from 'react';
import './PullDownContent.scss';
import Icon from '../../ui-kit/Icon';
import {iconDownload} from '../icons';

class PullDownContent extends Component {
    state = {
        isOpened: false,
        ajarDistance: 0,
    };

    componentDidMount() {
        const pullDownContainerNode = this.pullDownContainerRef.current;
        this.contentNode = pullDownContainerNode.querySelector('.js-pull-down-content');

        pullDownContainerNode.addEventListener('touchstart', this.handleTouchStart, false);
        pullDownContainerNode.addEventListener('touchmove', this.handleTouchMove, false);
        pullDownContainerNode.addEventListener('touchend', this.handleTouchEnd, false);
    }

    componentWillUnmount() {
        const pullDownContainerNode = this.pullDownContainerRef.current;
        this.contentNode = null;

        pullDownContainerNode.removeEventListener('touchstart', this.handleTouchStart, false);
        pullDownContainerNode.removeEventListener('touchmove', this.handleTouchMove, false);
        pullDownContainerNode.removeEventListener('touchend', this.handleTouchEnd, false);
    }

    pullDownContainerRef = React.createRef();

    contentNode = null;

    previousTouch = null;

    handleTouchStart = event => {
        if (this.state.isOpened) return;

        this.previousTouch = event.changedTouches[0] || null;
    };

    handleTouchMove = event => {
        const currentTouch = event.changedTouches[0] || null;
        if (!this.contentNode || !this.previousTouch || !currentTouch || this.state.isOpened) return;

        const contentRect = this.contentNode.getBoundingClientRect();
        const pullDownDelta = contentRect.height * 0.95;
        if (this.previousTouch.clientY < currentTouch.clientY
            && this.previousTouch.clientY + pullDownDelta > currentTouch.clientY) {
            this.setState({
                ajarDistance: currentTouch.clientY - this.previousTouch.clientY,
            });
            return;
        }

        if (this.previousTouch.clientY < currentTouch.clientY
            && this.previousTouch.clientY + pullDownDelta < currentTouch.clientY) {
            this.setState({
                isOpened: true,
                ajarDistance: 0,
            }, () => {
                this.previousTouch = null;
            });
        }
    };

    handleTouchEnd = () => {
        if (this.state.isOpened) return;

        this.setState({
            ajarDistance: 0,
        });
    };

    calculateDrawerDepth = () => {
        if (this.state.isOpened) return 0;

        const contentRect = this.contentNode ? this.contentNode.getBoundingClientRect() : null;
        return !contentRect ? 0 : -1 * contentRect.height + this.state.ajarDistance;
    };

    render() {
        const {children} = this.props;
        const {isOpened, ajarDistance} = this.state;
        const collapseLineStyle = {marginTop: this.calculateDrawerDepth()};

        return (
            <div
                className={`vub-c-pull-down-content ${isOpened
                    ? 'vub-c-pull-down-content--is-opened' : ''} ${!isOpened && ajarDistance > 0
                    ? 'vub-c-pull-down-content--is-ajar' : ''}`}
                ref={this.pullDownContainerRef}
            >
                <div className="vub-c-pull-down-content__collapse-line" style={collapseLineStyle} />
                <div className="vub-c-pull-down-content__content js-pull-down-content">
                    {children}
                </div>
                <div className="vub-c-pull-down-content__handle">
                    <Icon icon={iconDownload} className="vub-c-icon--medium" />
                </div>
            </div>
        );
    }
}

export default PullDownContent;
