import React, {Component, Children} from 'react';
import throttle from 'lodash.throttle';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../withStyles';
import styles from './Carousel.module.scss';

@withStyles(styles)
class Carousel extends Component {
    static propTypes = {
        ...withStylesPropTypes,
    };

    static defaultProps = {
        ...withStylesDefaultProps,
    };

    constructor(props) {
        super(props);
        this.setCarousel = throttle(this.setCarousel, 650);
    }

    state = {
        carouselPages: [],
        slideTrayOffset: 0,
        currentPage: 0,
    };

    componentDidMount() {
        window.addEventListener('resize', this.setCarousel);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setCarousel);
        this.setState({
            carouselPages: [],
            slideTrayOffset: 0,
            currentPage: 0,
        });
    }

    viewingFrameRef = null;

    /**
     * Sets ref to viewingFrame DOM node
     *
     * @param element
     */
    setViewingFrameRef = element => {
        if (element) {
            this.viewingFrameRef = element;
            this.viewingFrameRef.addEventListener('touchstart', this.handleTouchStart);
            this.viewingFrameRef.addEventListener('touchmove', this.handleTouchMove);
            this.viewingFrameRef.addEventListener('touchend', this.handleTouchEnd);
            return;
        }

        if (this.viewingFrameRef && !element) {
            this.viewingFrameRef.removeEventListener('touchstart', this.handleTouchStart);
            this.viewingFrameRef.removeEventListener('touchmove', this.handleTouchMove);
            this.viewingFrameRef.removeEventListener('touchend', this.handleTouchEnd);
        }
        this.viewingFrameRef = element;
    };

    setCarousel = () => {
        const carouselPages = this.getCarouselPages();
        if (carouselPages.length === 0) {
            this.setState({
                carouselPages: carouselPages,
                slideTrayOffset: 0,
                currentPage: 0,
            });
            return;
        }

        this.setState(state => ({
            carouselPages: carouselPages,
            slideTrayOffset: carouselPages[state.currentPage].x * -1,
            ...(state.currentPage > carouselPages.length - 1 && {
                currentPage: carouselPages.length - 1,
                slideTrayOffset: carouselPages[carouselPages.length - 1].x * -1,
            }),
        }));
    };

    /**
     * Calculates carousel pages for given viewing frame and set of slides and their widths
     */
    getCarouselPages = () => {
        if (!this.viewingFrameRef) return [];

        const slideNodeList = this.viewingFrameRef.querySelectorAll('.js-carousel-slide-tray .js-carousel-slide');
        const viewingFrameRect = this.viewingFrameRef.getBoundingClientRect();
        const slideNodeRectList = [];
        Array.prototype.forEach.call(slideNodeList, slideNode => {
            return slideNodeRectList.push(slideNode.getBoundingClientRect());
        });
        const slideTrayWidth = slideNodeRectList
            .reduce((slideTrayWidth, slideNodeRect) => slideTrayWidth + slideNodeRect.width, 0);

        return slideNodeRectList.reduce((carouselPages, slideNodeRect, index) => {
            if (carouselPages.length === 0) carouselPages.push({id: 0, x: 0, slidesWidth: 0});

            const page = carouselPages[carouselPages.length - 1];

            if (page.slidesWidth + slideNodeRect.width <= viewingFrameRect.width) {
                page.slidesWidth += slideNodeRect.width;
            } else {
                const newPage = {
                    id: page.id + 1,
                    x: page.x + page.slidesWidth,
                    slidesWidth: slideNodeRect.width,
                };
                carouselPages.push(newPage);
            }

            if (index === slideNodeRectList.length - 1 && carouselPages.length > 1) {
                const lastPage = carouselPages[carouselPages.length - 1];
                carouselPages[carouselPages.length - 1] = {
                    id: lastPage.id,
                    x: slideTrayWidth - viewingFrameRect.width,
                    slidesWidth: lastPage.slidesWidth,
                };
            }

            return carouselPages;
        }, []);
    };

    /**
     * Rotates carousel one page left
     */
    rotateCarouselLeft = () => {
        const {carouselPages} = this.state;
        if (carouselPages.length === 0 || this.state.currentPage === 0) return;

        this.setState(state => ({
            currentPage: state.currentPage - 1,
            slideTrayOffset: carouselPages[state.currentPage - 1].x * -1,
        }));
    };

    /**
     * Rotates carousel one page right
     */
    rotateCarouselRight = () => {
        const {carouselPages} = this.state;
        if (carouselPages.length === 0 || this.state.currentPage === carouselPages.length - 1) return;

        this.setState(state => ({
            currentPage: state.currentPage + 1,
            slideTrayOffset: carouselPages[state.currentPage + 1].x * -1,
        }));
    };

    /**
     * Rotates carousel to given page
     *
     * @param {Object} page
     */
    goToPage = page => {
        const {carouselPages} = this.state;
        const index = page.id;
        if (carouselPages.length === 0 || this.state.currentPage === index || !carouselPages[index]) return;

        this.setState({
            currentPage: index,
            slideTrayOffset: carouselPages[index].x * -1,
        });
    };

    previousTouchEvent = {};

    handleTouchStart = event => {
        this.previousTouchEvent = event;
    };

    handleTouchMove = event => {
        if (this.previousTouchEvent.changedTouches[0].clientX - event.changedTouches[0].clientX > 100) {
            this.rotateCarouselRight();
            this.previousTouchEvent = event;
        }
        if (event.changedTouches[0].clientX - this.previousTouchEvent.changedTouches[0].clientX > 100) {
            this.rotateCarouselLeft();
            this.previousTouchEvent = event;
        }
    };

    handleTouchEnd = () => {
        this.previousTouchEvent = {};
    };

    render() {
        const {className, cx} = this.props;
        const children = Children.map(this.props.children, child => (typeof child === 'string' ? child
            : React.cloneElement(child, {
                carouselPages: this.state.carouselPages,
                slideTrayOffset: this.state.slideTrayOffset,
                currentPage: this.state.currentPage,
                setViewingFrameRef: this.setViewingFrameRef,
                setCarousel: this.setCarousel,
                rotateCarouselLeft: this.rotateCarouselLeft,
                rotateCarouselRight: this.rotateCarouselRight,
                goToPage: this.goToPage,
            })));

        return (
            <div className={cx('cr-c-carousel', className)}>
                {children}
            </div>
        );
    }
}

export default Carousel;
