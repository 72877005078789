import {call} from 'redux-saga/effects';
import {mediaEntitlementService} from '../../services';

/**
 * Set entitlement-service auth token
 */
export const setEntitlementServiceAuthToken = function* setEntitlementServiceAuthToken({payload}) {
    const {authToken} = payload;
    yield call(mediaEntitlementService.setAuthToken, authToken);
};

export default setEntitlementServiceAuthToken;
