import React from 'react';
import PropTypes from 'prop-types';
import './PromotionBannerPaginationBullet.scss';
import PaginationButton from './PaginationButton';

/**
 * PromotionBannerPaginationBullet Component
 */
const PromotionBannerPaginationBullet = ({isActive, onClick, index}) => (
    <div className="vub-c-promotion-banner-pagination-bullet">
        <PaginationButton isActive={isActive} onClick={() => onClick(index)} />
    </div>
);

PromotionBannerPaginationBullet.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    index: PropTypes.number,
};

PromotionBannerPaginationBullet.defaultProps = {
    isActive: null,
    onClick: null,
    index: null,
};

export default PromotionBannerPaginationBullet;
