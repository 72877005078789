/**
 * Parses and combines class names into nicely spaced string
 */
export const combineClassNames = (first = [], ...restArgs) => {
    const params = Array.isArray(first) ? first.concat(...restArgs) : [first, ...restArgs];
    const classNames = [
        ...params
            .filter(param => typeof param === 'string')
            .reduce((classNames, param) => classNames.concat(param.split(' ')), []),
    ];

    // return de-duped classNames as one string
    return [...new Set(classNames)].filter(className => !!className).join(' ');
};
