import React, {Component, Children} from 'react';
import PropTypes from 'prop-types';
import './MediaAccordion.scss';

export default class MediaAccordion extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
    };

    static defaultProps = {
        title: null,
        subtitle: null,
    };

    state = {
        activeItemId: null,
        showMore: false,
    };

    handleItemClick = activeItemId => this.setState({activeItemId});

    toggleShowMore = () => {
        this.setState(state => ({
            showMore: !state.showMore,
        }));
    };

    render() {
        const {activeItemId, showMore} = this.state;
        const {title, subtitle} = this.props;
        const updatedChildren = Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                handleItemClick: this.handleItemClick,
                activeItemId,
            });
        });

        const displayChildren = showMore ? updatedChildren : updatedChildren.slice(0, 4);
        return (
            <div className="vub-c-media-accordion">
                <div className="vub-c-media-accordion__header">
                    <div className="vub-c-media-accordion__title">
                        {title}
                    </div>
                    <div className="vub-c-media-accordion__subtitle">
                        {subtitle}
                    </div>
                </div>
                {displayChildren}
                <button type="button" onClick={this.toggleShowMore} className="vub-c-media-accordion__show-more-button">
                    {`${showMore ? 'Show less' : 'Show more'}`}
                </button>
            </div>
        );
    }
}
