import React from 'react';
import PropTypes from 'prop-types';
import './CTADropDown.scss';

const CTADropDown = ({label, onChange, onClick, isDisabled, children}) => {
    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
    };
    const updatedChildren = React.Children.map(children, child => (
        React.cloneElement(child, {onChange})
    ));

    return (
        <div className="vub-c-cta-drop-down">
            <div
                className={`vub-c-cta-drop-down__label ${isDisabled ? 'vub-c-cta-drop-down__label--is-disabled' : ''}`}
                onClick={handleClick}
            >
                <div className="vub-c-cta-drop-down__label-content">
                    {label}
                </div>
                <div className="vub-c-cta-drop-down__label-field">
                    <div className="vub-c-cta-drop-down__icon" />
                </div>
            </div>
            {!isDisabled ? (
                <div className="vub-c-cta-drop-down__menu-wrapper">
                    <div className="vub-c-cta-drop-down__menu">
                        <div className="vub-c-cta-drop-down__menu-arrow" />
                        {updatedChildren}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

CTADropDown.propTypes = {
    label: PropTypes.node,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

CTADropDown.defaultProps = {
    label: null,
    onChange: null,
    onClick: null,
    isDisabled: false,
};

export default CTADropDown;
