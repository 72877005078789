import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SwitchBehavior, SwitchBehaviorPropTypes, SwitchBehaviorDefaultProps} from './behaviors/SwitchBehavior';
import {BEMClassNameResolver, BEMClassNameResolverPropTypes, BEMClassNameResolverDefaultProps} from './BEMClassNameResolver';
import './IconButton.scss';
import Icon from './Icon';

@SwitchBehavior
@BEMClassNameResolver('cr-c-icon-button')
class IconButton extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        ...SwitchBehaviorPropTypes,
        icon: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        ...SwitchBehaviorDefaultProps,
    };

    render() {
        const {icon, onClick} = this.props;
        const {resolveClassName} = this.props;
        return (
            <div className={resolveClassName()} onClick={onClick}>
                <Icon icon={icon} className={resolveClassName('__icon')} />
            </div>
        );
    }
}

export default IconButton;
