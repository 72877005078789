import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import LayoutControlsContext from './LayoutControlsContext';

export const withLayoutControls = BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'withLayoutControls'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
        };

        render() {
            return (
                <LayoutControlsContext.Consumer>
                    {context => {
                        const {isHeaderInUse, isHeaderTransparent, isHeaderReduced} = context;
                        const {setHeaderInUseState, setHeaderTransparency, setHeaderReducedState} = context;

                        return (
                            <BaseComponent
                                {...this.props}
                                isHeaderInUse={isHeaderInUse}
                                isHeaderTransparent={isHeaderTransparent}
                                isHeaderReduced={isHeaderReduced}
                                setHeaderInUseState={setHeaderInUseState}
                                setHeaderTransparency={setHeaderTransparency}
                                setHeaderReducedState={setHeaderReducedState}
                            />
                        );
                    }}
                </LayoutControlsContext.Consumer>
            );
        }
    }

    return ComposedComponent;
};

export const withLayoutControlsPropTypes = {
    isHeaderInUse: PropTypes.bool.isRequired,
    isHeaderTransparent: PropTypes.bool.isRequired,
    isHeaderReduced: PropTypes.bool.isRequired,
    setHeaderInUseState: PropTypes.func.isRequired,
    setHeaderTransparency: PropTypes.func.isRequired,
    setHeaderReducedState: PropTypes.func.isRequired,
};

export const withLayoutControlsDefaultProps = {
    isHeaderInUse: false,
    isHeaderTransparent: false,
    isHeaderReduced: false,
    setHeaderInUseState: () => {},
    setHeaderTransparency: () => {},
    setHeaderReducedState: () => {},
};
