import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import routePaths from '../routePaths';
import MediaRouteResolver from './MediaRouteResolver';

/**
 * Custom media route resolver
 * TODO move to better location
 */
class CustomMediaRouteResolver extends MediaRouteResolver {
    /**
     * Resolves route to mediaItem
     *
     * @param {Object} params
     * @param params.mediaItemId
     * @param params.toParams
     */
    resolveMediaItemRoute({mediaItemId, toParams = {}}) {
        const mediaItem = this.media.get(mediaItemId);
        if (!mediaItem) return {pathname: ''};

        let resolvedRoute = this.resolveRoute(
            routePaths.MEDIA_ITEM,
            {
                mediaItemId: mediaItem.id,
            },
            toParams,
        );

        switch (mediaItem.classification) {
            case mediaItemClassification.MOVIE:
                resolvedRoute = this.resolveRoute(
                    routePaths.MEDIA_ITEM_MOVIE,
                    {
                        mediaItemId: mediaItem.id,
                    },
                    toParams,
                );
                break;

            case mediaItemClassification.TV_SHOW:
                resolvedRoute = this.resolveRoute(
                    routePaths.MEDIA_ITEM_TV_SHOW,
                    {
                        mediaItemId: mediaItem.id,
                    },
                    toParams,
                );
                break;

            case mediaItemClassification.SEASON:
                resolvedRoute = this.resolveRoute(
                    routePaths.MEDIA_ITEM_SEASON,
                    {
                        mediaItemId: mediaItem.parentId,
                        seasonMediaItemId: mediaItem.id,
                    },
                    toParams,
                );
                break;

            case mediaItemClassification.EPISODE:
                if (mediaItem.parentId) {
                    const seasonMediaItem = this.media.get(mediaItem.parentId);
                    resolvedRoute = this.resolveRoute(
                        routePaths.MEDIA_ITEM_EPISODE,
                        {
                            mediaItemId: seasonMediaItem.parentId,
                            seasonMediaItemId: mediaItem.parentId,
                            episodeMediaItemId: mediaItem.id,
                        },
                        toParams,
                    );
                }
                break;
        }

        return resolvedRoute;
    }

    /**
     * Resolves route to mediaGenre
     *
     * @param {Object} params
     * @param params.mediaGenreId
     * @param params.classification
     * @param params.toParams
     */
    resolveMediaGenreRoute({mediaGenreId, classification, toParams = {}}) {
        const mediaGenre = this.mediaGenres.get(mediaGenreId);
        if (!mediaGenre) return {pathname: ''};

        return this.resolveRoute(
            routePaths.MEDIA_GENRE,
            {
                mediaGenreId: mediaGenre.name,
                ...(classification ? {classification} : {}),
            },
            toParams,
        );
    }

    /**
     * Resolves route to mediaCollection
     *
     * @param {Object} params
     * @param params.mediaCollectionId
     * @param params.classification
     * @param params.toParams
     */
    resolveMediaCollectionRoute({mediaCollectionId, classification, toParams = {}}) {
        const mediaCollection = this.mediaCollections.get(mediaCollectionId);
        if (!mediaCollection) return {pathname: ''};

        return this.resolveRoute(
            routePaths.MEDIA_COLLECTION,
            {
                mediaCollectionId: mediaCollection.id,
                ...(classification ? {classification} : {}),
            },
            toParams,
        );
    }
}

export default CustomMediaRouteResolver;
