import React, {Component, Children} from 'react';
import PropTypes from 'prop-types';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../withStyles';
import styles from './Carousel.module.scss';

@withStyles(styles)
class CarouselViewingFrame extends Component {
    static propTypes = {
        ...withStylesPropTypes,
        slideTrayOffset: PropTypes.number,
        setViewingFrameRef: PropTypes.func,
        setCarousel: PropTypes.func,
    };

    static defaultProps = {
        ...withStylesDefaultProps,
        slideTrayOffset: 0,
        setViewingFrameRef: null,
        setCarousel: null,
    };

    render() {
        const {slideTrayOffset, setViewingFrameRef, setCarousel} = this.props;
        const {cx} = this.props;
        const slideTrayStyle = {
            transform: `translateX(${slideTrayOffset}px)`,
        };

        const children = Children.map(this.props.children, child => (typeof child === 'string' ? child
            : React.cloneElement(child, {
                setCarousel: setCarousel,
            })));

        return (
            <div className={cx('cr-c-carousel__viewing-frame')} ref={setViewingFrameRef}>
                <div
                    className={cx('cr-c-carousel__slide-tray', 'global!js-carousel-slide-tray')}
                    style={slideTrayStyle}
                >
                    {children}
                </div>
            </div>
        );
    }
}

export default CarouselViewingFrame;
