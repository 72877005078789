import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './NavigationSideItem.scss';

const NavigationSideItem = ({
    children,
    linkTo,
    isSelected,
    onClick,
}) => (
    <li className={`vub-c-navigation-side-item ${isSelected
        ? 'vub-c-navigation-side-item--is-selected' : ''} ${!onClick ? 'vub-c-navigation-side-item--unclickable' : ''}`}
    >
        {linkTo ? (
            <Link
                className="vub-c-navigation-side-item__link"
                to={linkTo}
                onClick={onClick}
            >
                {children}
            </Link>
        ) : (
            <label
                className="vub-c-navigation-side-item__label"
                onClick={onClick}
            >
                {children}
            </label>
        )}
    </li>
);

NavigationSideItem.propTypes = {
    linkTo: PropTypes.object,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
};

NavigationSideItem.defaultProps = {
    linkTo: null,
    isSelected: null,
    onClick: null,
};

export default NavigationSideItem;
