import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import {connect} from 'react-redux';
import MediaRouteResolver from '../navigation/MediaRouteResolver';

export const withMediaRouteHelpers = ({
    mediaRouteResolver = new MediaRouteResolver(),
}) => BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'withMediaRouteHelpers'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
        };

        render() {
            const {media, mediaGenres, mediaCollections} = this.props;
            mediaRouteResolver.setMedia(media);
            mediaRouteResolver.setMediaGenres(mediaGenres);
            mediaRouteResolver.setMediaCollections(mediaCollections);

            const resolveMediaItemRoute = params => mediaRouteResolver.resolveMediaItemRoute(params);
            const resolveMediaGenreRoute = params => mediaRouteResolver.resolveMediaGenreRoute(params);
            const resolveMediaCollectionRoute = params => mediaRouteResolver.resolveMediaCollectionRoute(params);

            return (
                <BaseComponent
                    {...this.props}
                    resolveMediaItemRoute={resolveMediaItemRoute}
                    resolveMediaGenreRoute={resolveMediaGenreRoute}
                    resolveMediaCollectionRoute={resolveMediaCollectionRoute}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        media: state.media.media,
        mediaGenres: state.media.mediaGenres,
        mediaCollections: state.media.mediaCollections,
    });

    return connect(
        mapStateToProps,
    )(ComposedComponent);
};

export const withMediaRouteHelpersPropTypes = {
    resolveMediaItemRoute: PropTypes.func.isRequired,
    resolveMediaGenreRoute: PropTypes.func.isRequired,
    resolveMediaCollectionRoute: PropTypes.func.isRequired,
};

export const withMediaRouteHelpersDefaultProps = {
    resolveMediaItemRoute: () => {},
    resolveMediaGenreRoute: () => {},
    resolveMediaCollectionRoute: () => {},
};
