import React from 'react';
// import './StaticPageView.scss';

const FaqPage = () => (
    <div>
        <h2>FAQ</h2>
        <h3>First question here</h3>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur, ipsum non semper
            venenatis, turpis tortor pharetra est, ultrices venenatis purus nisi in nunc. Nulla maximus purus et lacus
            placerat condimentum. Maecenas pretium sapien lacus, et suscipit est efficitur eu. Aenean sit amet rutrum
            massa. Sed nec nisi sollicitudin, feugiat eros eget, vulputate nunc. Proin maximus arcu sed nisi cursus
            iaculis. Morbi vel velit vitae orci vehicula facilisis. In sit amet suscipit libero. Nam ultrices dolor
            quis porttitor bibendum. Proin nec mi sed magna interdum scelerisque. Aenean ut tempus nibh, ut
            ullamcorper ex. Cras laoreet nunc diam.
        </p>

        <h3>Secong question here</h3>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur, ipsum non semper
            venenatis, turpis tortor pharetra est, ultrices venenatis purus nisi in nunc. Nulla maximus purus et lacus
            placerat condimentum. Maecenas pretium sapien lacus, et suscipit est efficitur eu. Aenean sit amet rutrum
            massa. Sed nec nisi sollicitudin, feugiat eros eget, vulputate nunc. Proin maximus arcu sed nisi cursus
            iaculis. Morbi vel velit vitae orci vehicula facilisis. In sit amet suscipit libero. Nam ultrices dolor
            quis porttitor bibendum. Proin nec mi sed magna interdum scelerisque. Aenean ut tempus nibh, ut ullamcorper
            ex. Cras laoreet nunc diam.
        </p>

        <h3>Third question here</h3>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur, ipsum non semper
            venenatis, turpis tortor pharetra est, ultrices venenatis purus nisi in nunc. Nulla maximus purus et lacus
            placerat condimentum. Maecenas pretium sapien lacus, et suscipit est efficitur eu. Aenean sit amet rutrum
            massa. Sed nec nisi sollicitudin, feugiat eros eget, vulputate nunc. Proin maximus arcu sed nisi cursus
            iaculis. Morbi vel velit vitae orci vehicula facilisis. In sit amet suscipit libero. Nam ultrices dolor
            quis porttitor bibendum. Proin nec mi sed magna interdum scelerisque. Aenean ut tempus nibh, ut ullamcorper
            ex. Cras laoreet nunc diam.
        </p>

        <h3>Another question here</h3>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur, ipsum non semper
            venenatis, turpis tortor pharetra est, ultrices venenatis purus nisi in nunc. Nulla maximus purus et lacus
            placerat condimentum. Maecenas pretium sapien lacus, et suscipit est efficitur eu. Aenean sit amet rutrum
            massa. Sed nec nisi sollicitudin, feugiat eros eget, vulputate nunc. Proin maximus arcu sed nisi cursus
            iaculis. Morbi vel velit vitae orci vehicula facilisis. In sit amet suscipit libero. Nam ultrices dolor
            quis porttitor bibendum. Proin nec mi sed magna interdum scelerisque. Aenean ut tempus nibh, ut ullamcorper
            ex. Cras laoreet nunc diam.
        </p>
    </div>
);

export default FaqPage;
