import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './PopUp.scss';
import withPopUp from './withPopUp';
import {
    withOverlay,
    withOverlayPropTypes,
    withOverlayDefaultProps,
} from '../overlay/withOverlay';

@withOverlay
@withPopUp
class PopUp extends Component {
    static propTypes = {
        ...withOverlayPropTypes,
        isWithOverlay: PropTypes.bool,
        content: PropTypes.node,
        openOverlay: PropTypes.func,
        closeOverlay: PropTypes.func,
    };

    static defaultProps = {
        ...withOverlayDefaultProps,
        isWithOverlay: false,
        content: null,
        openOverlay: null,
        closeOverlay: null,
    };

    componentDidMount() {
        this.props.isWithOverlay && this.props.openOverlay();
    }

    componentWillUnmount() {
        this.props.isWithOverlay && this.props.closeOverlay();
    }

    render() {
        const {content} = this.props;
        return (
            <div className="vub-c-pop-up">
                {content}
            </div>
        );
    }
}

export default PopUp;
