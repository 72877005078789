import React from 'react';
import './StyleGuideView.scss';
import SpinnerContainer from './elements/SpinnerContainer';
import StyleGuideIcons from './elements/StyleGuideIcons';
import HoverDialogueContainer from './elements/HoverDialogueContainer';
import CTAButtonsLayout from './elements/CTAButtonsLayout';
import SliderContainer from './elements/SliderContainer';

const StyleGuideView = () => (
    <div className="vub-c-style-guide-view">
        <div className="vub-c-style-guide-view__row">
            <SpinnerContainer title="loader" />
        </div>
        <div className="vub-c-style-guide-view__row vub-c-style-guide-view__row--inverted">
            <HoverDialogueContainer />
        </div>
        <div className="vub-c-style-guide-view__row">
            <StyleGuideIcons title="icons" />
        </div>
        <div className="vub-c-style-guide-view__row">
            <CTAButtonsLayout title="buttons" />
        </div>
        <div className="vub-c-style-guide-view__element">
            <SliderContainer />
        </div>
    </div>
);

export default StyleGuideView;
