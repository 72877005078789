import {put, select} from 'redux-saga/effects';
import moment from 'moment';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import * as actionTypes from '../media/mediaActionTypes';
import * as selectors from '../media/mediaSelectors';

/**
 * Load mediaGenre view media
 *
 * @param {FluxStandardAction} action
 */
export const loadMediaForMediaGenreView = function* loadMediaForMediaGenreView({payload}) {
    const {match} = payload;
    const {mediaGenreId, classification} = match.params;
    const mediaBucketKey = !classification
        ? `MEDIA_GENRE_VIEW['${mediaGenreId}']`
        : `MEDIA_GENRE_VIEW['${mediaGenreId}']['${classification}']`;

    const mediaBuckets = yield select(selectors.getMediaBuckets);
    const mediaBucket = mediaBuckets.get(mediaBucketKey);
    if (mediaBucket && mediaBucket.updateTime.add(5, 'minutes') > moment()) {
        yield put({type: actionTypes.MEDIA_BUCKET_IN_STORE, payload: {mediaBucketKey}});
        return;
    }

    const classificationQuery = [
        !classification && `${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`,
        classification === 'movies' && mediaItemClassification.MOVIE,
        classification === 'tv-shows' && mediaItemClassification.TV_SHOW,
    ].filter(Boolean);

    yield put({
        type: actionTypes.FETCH_MEDIA_GENRE_MEDIA,
        payload: {
            requestParams: {
                mediaGenreId,
                where: classificationQuery.length > 0
                    ? [`classification:in:${classificationQuery.join(',')}`] : [],
                sort: [
                    'titleSort:ASC',
                ],
                limit: 24,
                facets: ['offers/provider'],
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};
