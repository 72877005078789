import React from 'react';
import PropTypes from 'prop-types';
import './CustomCheckbox.scss';

const CustomCheckbox = ({
    type,
    name,
    label,
    className,
    isDisabled,
    isChecked,
    handleChange,
}) => {
    const handleInputChange = event => {
        const {checked} = event.target;
        handleChange(name, checked);
    };
    return (
        <label className={`vub-c-custom-checkbox ${className}`}>
            {label}
            <input
                type={type}
                name={name}
                className="vub-c-custom-checkbox__input"
                disabled={isDisabled}
                checked={!!isChecked}
                onChange={handleInputChange}
            />
            <span className={`vub-c-custom-checkbox__field ${isDisabled ? 'vub-c-custom-checkbox__field--disabled' : ''}`} />
        </label>
    );
};

CustomCheckbox.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    handleChange: PropTypes.func,
};

CustomCheckbox.defaultProps = {
    type: 'checkbox',
    name: null,
    className: '',
    label: null,
    isChecked: false,
    isDisabled: false,
    handleChange: null,
};
export default CustomCheckbox;
