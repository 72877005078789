import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemDetailsTitle.scss';

const MediaItemDetailsTitle = ({title, subtitle}) => (
    <div className="vub-c-media-item-details-title">
        {title}
        {subtitle && (
            <p className="vub-c-media-item-details-title__subtitle">{subtitle}</p>
        )}
    </div>
);

MediaItemDetailsTitle.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
};

MediaItemDetailsTitle.defaultProps = {
    title: null,
    subtitle: null,
};

export default MediaItemDetailsTitle;
