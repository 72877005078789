import Immutable from 'immutable';
import * as actionTypes from './navigationActionTypes';
import MenuItem from './records/MenuItem';
import StorefrontRow from './records/StorefrontRow';

const initialState = {
    menus: [],
    isFetchPending: false,
    storefrontRows: Immutable.OrderedMap(),
};

/**
 * Navigation reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NAVIGATION_REQUEST:
            state = {
                ...state,
                isFetchPending: true,
            };
            break;

        case actionTypes.FETCH_NAVIGATION_SUCCESS:
            if (action.payload.menus) {
                const navigation = {};
                action.payload.menus.forEach(menuDTO => {
                    menuDTO.children[0].children.forEach(menuItemDTO => {
                        const menuItem = new MenuItem({}).fromDTO(menuItemDTO);
                        if (!navigation.hasOwnProperty(menuDTO.type)) {
                            navigation[menuDTO.type] = [];
                        }
                        navigation[menuDTO.type].push(menuItem);
                    });
                });
                state = {
                    ...state,
                    menus: navigation,
                    isFetchPending: false,
                };
            }
            break;

        case actionTypes.STORE_STOREFRONT_ROWS:
            if (action.payload.storefrontRowDTOs) {
                let storefrontRows = Immutable.OrderedMap();
                action.payload.storefrontRowDTOs
                    .sort((storefrontRowDTO1, storefrontRowDTO2) => storefrontRowDTO1.position
                        - storefrontRowDTO2.position)
                    .forEach(storefrontRowDTO => {
                        const storefrontRow = new StorefrontRow(storefrontRowDTO);
                        if (storefrontRow.id) {
                            storefrontRows = storefrontRows.set(storefrontRow.id, storefrontRow);
                        }
                    });

                state = {
                    ...state,
                    storefrontRows,
                };
            }
            break;

        default:
        // no-op
    }
    return state;
};

export default navigationReducer;
