import React from 'react';
import PropTypes from 'prop-types';
import './ServerValidationErrors.scss';

const ServerValidationErrors = ({errors}) => (
    <div className="vub-c-server-validation-errors">
        {errors.map(error => (
            <div key={error} className="vub-c-sever-validation-errors__error">
                {error}
            </div>
        ))}
    </div>
);

ServerValidationErrors.propTypes = {
    errors: PropTypes.array,
};

ServerValidationErrors.defaultProps = {
    errors: [],
};

export default ServerValidationErrors;
