import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../authActionTypes';
import {authService} from '../../services';

/**
 * Reset password flow
 * TODO refactor into flow
 */
const resetPasswordFlow = function* resetPasswordFlow({payload}) {
    try {
        yield put({type: actionTypes.RESET_PASSWORD_REQUEST, payload});

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: true},
        });

        const {token, newPassword} = payload;
        const response = yield call(authService.resetPassword, {token, newPassword});
        yield put({
            type: actionTypes.RESET_PASSWORD_SUCCESS,
            payload: {response},
        });

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });
    } catch (error) {
        yield put({type: actionTypes.RESET_PASSWORD_ERROR, error: true, payload: error});

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });
    }
};

export default resetPasswordFlow;
