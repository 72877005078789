import Immutable from 'immutable';

/**
 * Provider definition
 *
 * @typedef {Immutable.Record} Provider
 * @property {?string} id
 * @property {?string} provider
 * @property {?string} service
 * @property {?string} description
 * @property {?string} serviceLogo
 * @property {?string} serviceOverlayLogo
 * @property {?string} serviceCTALogo
 */

/**
 * Provider record
 *
 * @type {Provider}
 */
class Provider extends Immutable.Record({
    id: null,
    provider: null,
    service: null,
    description: null,
    serviceLogo: null,
    serviceOverlayLogo: null,
    serviceCTALogo: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {Provider}
     */
    fromDTO(dataTransferObject) {
        return new Provider({
            ...this.toObject(),
            ...dataTransferObject,
            id: dataTransferObject.provider || this.id,
            serviceCTALogo: dataTransferObject.serviceCtaLogo || this.serviceCTALogo,
        });
    }
}

export default Provider;
