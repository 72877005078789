import React from 'react';
import {mediaItemStaffMemberType} from '@comrock/vub-wls-media-service';

export const findSearchQueryStringInMediaItem = (mediaItem, searchQueryString, maxLength) => {
    searchQueryString = searchQueryString.toLowerCase();

    const isInTitle = typeof mediaItem.title !== 'undefined'
        && mediaItem.title.toLowerCase().includes(searchQueryString);
    if (isInTitle) return {key: 'title', value: mediaItem.title};

    const isInCast = mediaItem.staffMembers
        .filter(staffMember => staffMember.type === mediaItemStaffMemberType.ACTOR)
        .reduce((isInCast, staffMember) => (staffMember.name.toLowerCase().includes(searchQueryString)
            ? staffMember.name : isInCast), null);
    if (isInCast) return {key: 'cast', value: isInCast};

    const isInAuthor = mediaItem.staffMembers
        .filter(staffMember => staffMember.type === mediaItemStaffMemberType.DIRECTOR
            || staffMember.type === mediaItemStaffMemberType.WRITER)
        .reduce((isInAuthor, staffMember) => (staffMember.name.toLowerCase().includes(searchQueryString)
            ? staffMember.name : isInAuthor), null);
    if (isInAuthor) return {key: 'director', value: isInAuthor};

    const isInSynopsis = mediaItem.synopsisShort.toLowerCase().includes(searchQueryString);
    if (isInSynopsis) {
        return {
            key: 'synopsis',
            value:
                maxLength && mediaItem.synopsisShort.length > maxLength
                    ? `${mediaItem.synopsisShort.slice(0, maxLength)} ...`
                    : mediaItem.synopsisShort,
        };
    }

    return null;
};

const createElementNode = (content, searchQueryString) => {
    const regex = new RegExp(searchQueryString, 'gi');
    const nodeElement = [];
    let previous = 0;
    let match;

    while ((match = regex.exec(content)) !== null) {
        nodeElement.push((
            <span key={match.index}>
                {content.slice(previous, match.index)}
                <em>{match[0]}</em>
            </span>
        ));
        previous = regex.lastIndex;
    }
    nodeElement.push((
        <span key="rest">
            {content.slice(previous, content.length)}
        </span>
    ));

    return nodeElement.map(el => el);
};

export const markSearchQueryString = (mediaItem, searchQueryString, maxLength) => {
    const result = findSearchQueryStringInMediaItem(mediaItem, searchQueryString, maxLength);

    if (result) {
        return {
            node: createElementNode(result.value, searchQueryString),
            meta: result.key,
        };
    }

    return {};
};

export const searchResultsGroupingOrder = {
    'title': 1,
    'cast': 2,
    'director': 3,
    'synopsis': 4,
};
