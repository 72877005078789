import * as actionTypes from './authActionTypes';

/**
 * Sign up user
 */
export const signUpUser = (payload = {}) => ({type: actionTypes.SIGN_UP_USER, payload});

/**
 * Sign in user
 */
export const signInUser = (payload = {}) => ({type: actionTypes.SIGN_IN_USER, payload});

/**
 * Sign out user
 */
export const signOutUser = (payload = {}) => ({type: actionTypes.SIGN_OUT_USER, payload});

/**
 * Update user
 */
export const updateUser = (payload = {}) => ({type: actionTypes.UPDATE_USER, payload});

/**
 * Authenticate user
 */
export const authenticateUser = (payload = {}) => ({type: actionTypes.AUTHENTICATE_USER, payload});

/**
 * Register and authenticate user
 */
export const registerAndAuthenticateUser = (payload = {}) => ({
    type: actionTypes.REGISTER_AND_AUTHENTICATE_USER,
    payload,
});

/**
 * Set user contact preferences
 */
export const setContactPreferences = (payload = {}) => ({type: actionTypes.SET_USER_CONTACT_PREFERENCES, payload});


/**
 * Update user account
 */
export const updateUserAccount = (payload = {}) => ({type: actionTypes.UPDATE_USER_ACCOUNT, payload});

/**
 * Request password reset
 */
export const requestPasswordReset = (payload = {}) => ({type: actionTypes.REQUEST_PASSWORD_RESET, payload});

/**
 * Reset password
 */
export const resetPassword = (payload = {}) => ({type: actionTypes.RESET_PASSWORD, payload});
