import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../ui-kit/withStyles';
import styles from './MediaCarousel.module.scss';
import {Carousel, CarouselViewingFrame, CarouselControls, CarouselPagination} from '../../ui-kit';

const MediaCarousel = ({children, hasPagination, cx, createClassNameResolver, ...restProps}) => {
    const classNameResolver = createClassNameResolver('vub-c-media-carousel');
    return (
        <Carousel
            {...restProps}
            className={cx({
                'vub-c-media-carousel--with-pagination': hasPagination,
            })}
            classNameResolver={classNameResolver}
        >
            <CarouselPagination classNameResolver={classNameResolver} />
            <CarouselViewingFrame classNameResolver={classNameResolver}>
                {children}
            </CarouselViewingFrame>
            <CarouselControls classNameResolver={classNameResolver} />
        </Carousel>
    );
};

MediaCarousel.propTypes = {
    ...withStylesPropTypes,
    hasPagination: PropTypes.bool,
};

MediaCarousel.defaultProps = {
    ...withStylesDefaultProps,
    hasPagination: false,
};

export default withStyles(styles)(MediaCarousel);
