import {put, select} from 'redux-saga/effects';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import * as actionTypes from '../media/mediaActionTypes';
import * as mediaSelectors from '../media/mediaSelectors';
import * as authSelectors from '../auth/authSelectors';
import * as selectors from './accountLibraryViewSelectors';

/**
 * Load accountLibrary view media
 *
 * @param {FluxStandardAction} action
 */
export const loadMediaForAccountLibraryView = function* loadMediaForAccountLibraryView({payload}) {
    const userAccount = yield select(authSelectors.getUserAccount);
    const currentEntitlements = yield select(selectors.getCurrentEntitlements);
    const currentEntitlementsUpdateTime = yield select(selectors.getCurrentEntitlementsUpdateTime);
    const mediaBucketKey = `ACCOUNT_LIBRARY_VIEW['${userAccount
        ? userAccount.id : 'null'}_${currentEntitlementsUpdateTime
        ? currentEntitlementsUpdateTime.unix() : 'null'}']`;
    const mediaIds = currentEntitlements.toArray().map(entitlement => entitlement.mediaItemId);

    const mediaBuckets = yield select(mediaSelectors.getMediaBuckets);
    if (mediaBuckets.get(mediaBucketKey)) {
        yield put({type: actionTypes.MEDIA_BUCKET_IN_STORE, payload: {mediaBucketKey}});
        return;
    }

    yield put({
        type: actionTypes.FETCH_MEDIA,
        payload: {
            requestParams: {
                mediaIds,
                where: [`classification:in:${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`],
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};
