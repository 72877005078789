import React from 'react';
import PropTypes from 'prop-types';
import './CTAOfferInfo.scss';

const CTAOfferInfo = ({className, children}) => (
    <div className={`vub-c-cta-offer-info ${className}`}>
        {children}
    </div>
);

CTAOfferInfo.propTypes = {
    className: PropTypes.string,
};

CTAOfferInfo.defaultProps = {
    className: '',
};

export default CTAOfferInfo;
