import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SVGSpriteSymbol from './SVGSpriteSymbol';
import {BEMClassNameResolver, BEMClassNameResolverPropTypes, BEMClassNameResolverDefaultProps} from './BEMClassNameResolver';
import './Icon.scss';

@BEMClassNameResolver('cr-c-icon')
class Icon extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        icon: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
    };

    render() {
        const {
            icon,
            resolveClassName,
            ...restProps
        } = this.props;
        return (
            <SVGSpriteSymbol {...restProps} spriteSymbol={icon} className={resolveClassName()} />
        );
    }
}

export default Icon;
