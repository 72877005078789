import {Record} from 'immutable';

/**
 * MenuItem definition
 *
 * @typedef {Immutable.Record} MenuItem
 * @property {?string} id
 * @property {?boolean} display
 * @property {?string} type
 * @property {?string} title
 * @property {?Array<string>} roles
 * @property {?string} value
 * @property {?children<MenuItem>} children
 */

/**
 * MediaItem record
 *
 * @type {MenuItem}
 */
class MenuItem extends Record({
    id: null,
    display: null,
    type: null,
    title: null,
    roles: null,
    value: null,
    children: null,
    url: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @param {MenuItemDTO} dataTransferObject
     * @return {MenuItem}
     */
    fromDTO(dataTransferObject) {
        return new MenuItem({
            ...this.toObject(),
            id: typeof dataTransferObject.id !== 'undefined'
                ? dataTransferObject.id : this.id,
            display: typeof dataTransferObject.display !== 'undefined'
                ? dataTransferObject.display : this.display,
            type: typeof dataTransferObject.type !== 'undefined'
                ? dataTransferObject.type : this.type,
            title: typeof dataTransferObject.title !== 'undefined'
                ? dataTransferObject.title : this.title,
            roles: typeof dataTransferObject.roles !== 'undefined'
                ? dataTransferObject.roles : this.roles,
            value: typeof dataTransferObject.value !== 'undefined'
                ? dataTransferObject.value : this.value,
            children: typeof dataTransferObject.children !== 'undefined'
                ? dataTransferObject.children : this.children,
            url: typeof dataTransferObject.url !== 'undefined'
                ? dataTransferObject.url : this.url,
        });
    }
}

export default MenuItem;
