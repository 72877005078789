import React from 'react';
import PropTypes from 'prop-types';
import './FormButton.scss';

const FormButton = ({
    type,
    className,
    onClick,
    isDisabled,
    children,
    ...restProps
}) => (
    // eslint-disable-next-line react/button-has-type
    <button
        type={type}
        className={`vub-c-form-button ${className} ${isDisabled ? 'vub-c-form-button--disabled' : ''}`}
        onClick={onClick}
        disabled={isDisabled}
        {...restProps}
    >
        {children || 'Submit'}
    </button>
);

FormButton.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    children: PropTypes.node,

};

FormButton.defaultProps = {
    type: 'button',
    className: '',
    onClick: null,
    isDisabled: false,
    children: null,
};

export default FormButton;

