import React from 'react';
import PropTypes from 'prop-types';
import './MediaPlayerControls.scss';

const MediaPlayerControls = ({epgChannel, closePlayer, areControlsHidden}) => {
    return (
        <div className={`vub-c-media-player-controls ${areControlsHidden ? 'vub-c-media-player-controls--hidden' : ''}`}>
            <div className="vub-c-media-player-controls__top">
                <div className="vub-c-media-player-controls__title" onClick={closePlayer}>
                    <span className="vub-c-media-player-controls__back-icon" />
                    <div className="vub-c-media-player-controls__image-container">
                        <img
                            src={epgChannel.logo}
                            alt={epgChannel.name}
                            className="vub-c-media-player-controls__channel-logo"
                        />
                    </div>
                    {epgChannel && epgChannel.name}
                </div>
            </div>
            <div className="vub-c-azure-player-controls__bottom" />
        </div>
    );
};

MediaPlayerControls.propTypes = {
    epgChannel: PropTypes.object,
    closePlayer: PropTypes.func,
    areControlsHidden: PropTypes.bool,
};

MediaPlayerControls.defaultProps = {
    epgChannel: null,
    closePlayer: null,
    areControlsHidden: false,
};

export default MediaPlayerControls;
