import {put, take, fork} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as actionTypes from '../authActionTypes';
import routePaths from '../../routePaths';
import updateUserAccount from './updateUserAccount';

/**
 * Set user contact preferences flow
 */
export const setUserContactPreferencesFlow = function* setUserContactPreferencesFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.SET_USER_CONTACT_PREFERENCES);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: true},
        });

        const {hasOptedInToReceiveUpdates} = payload;
        yield fork(updateUserAccount, {hasOptedInToReceiveUpdates});
        const updateUserAccountResultAction = yield take([
            actionTypes.UPDATE_USER_ACCOUNT_SUCCESS,
            actionTypes.UPDATE_USER_ACCOUNT_ERROR,
        ]);

        yield put({
            type: actionTypes.STORE_SESSION_CHANGE_STATUS,
            payload: {isSessionChangeInProgress: false},
        });

        if (updateUserAccountResultAction.type === actionTypes.UPDATE_USER_ACCOUNT_ERROR) continue;

        const {response} = updateUserAccountResultAction.payload;
        const {userAccount: userAccountDTO} = response;
        yield put({
            type: actionTypes.STORE_USER_ACCOUNT,
            payload: {userAccountDTO},
        });
        yield put(push(routePaths.STOREFRONT));
    }
};

export default setUserContactPreferencesFlow;
