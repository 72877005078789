import React from 'react';
import './LayoutBox.scss';

const LayoutBox = ({children}) => (
    <div className="vub-c-layout-box">
        {children}
    </div>
);

export default LayoutBox;
