import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../liveStreamingActionTypes';
import {liveStreamingService} from '../../services';

/**
 * Fetch live stream playback source request
 */
const fetchLiveStreamPlaybackSource = function* fetchLiveStreamPlaybackSource(params) {
    try {
        yield put({
            type: actionTypes.FETCH_LIVE_STREAM_PLAYBACK_SOURCE_REQUEST,
            payload: params,
        });

        const response = yield call(liveStreamingService.getAssetPlaybackSource, params);
        yield put({
            type: actionTypes.FETCH_LIVE_STREAM_PLAYBACK_SOURCE_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_LIVE_STREAM_PLAYBACK_SOURCE_ERROR, error: true, payload: error});
    }
};

export default fetchLiveStreamPlaybackSource;
