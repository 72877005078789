import React from 'react';
import PropTypes from 'prop-types';
import './StyleGuideIcons.scss';
import StandardIcon from '../../ui-elements/standard-icon/StandardIcon';
import {
    iconNavUser,
    iconNavSearch,
    iconMenuContact,
    iconNavClear,
    iconHome,
    iconBackToTop,
    iconInputSearch,
    iconWishlist,
    iconMenuDevices,
    iconMenuLibrary,
    iconMenuSignOut,
    iconPlaySolid,
    iconSideLeft,
    iconSideRight,
    iconTick,
    iconRemove,
    iconMore,
    iconDelete,
    iconDownload,
    iconRent,
    iconEdit,
    iconWarning,
    iconArrowDown,
    iconBuy,
    iconRentTimer,
    iconRate,
    iconLock,
    iconTV,
    iconTablet,
    iconPhone,
    iconDVD,
    iconHD,
    iconUHD,
    iconUnmute,
    iconMute,
    iconPlay,
    iconPause,
    iconExpand,
    iconShrink,
    iconCast,
    iconSubtitle,
    iconFilter,
    iconFacebook,
    iconTwitter,
} from '../../ui-elements/icons';

const standardIcons = [
    {
        name: 'search',
        svg: iconNavSearch,
    },
    {
        name: 'profile',
        svg: iconNavUser,
    },
    {
        name: 'watch',
        svg: iconPlaySolid,
    },
    {
        name: 'tick 1',
        svg: iconTick,
    },
    {
        name: 'add',
        svg: iconWishlist,
    },
    {
        name: 'remove',
        svg: iconRemove,
    },
    {
        name: 'more',
        svg: iconMore,
    },
    {
        name: 'delete',
        svg: iconDelete,
    },
    {
        name: 'download',
        svg: iconDownload,
    },
    {
        name: 'rent',
        svg: iconRent,
    },
    {
        name: 'edit',
        svg: iconEdit,
    },
    {
        name: 'warning',
        svg: iconWarning,
    },
    {
        name: 'close',
        svg: iconNavClear,
    },
    {
        name: 'down',
        svg: iconArrowDown,
    },
    {
        name: 'buy',
        svg: iconBuy,
    },
    {
        name: 'home',
        svg: iconHome,
    },
    {
        name: 'library',
        svg: iconMenuLibrary,
    },
    {
        name: 'devices',
        svg: iconMenuDevices,
    },
    {
        name: 'contact',
        svg: iconMenuContact,
    },
    {
        name: 'signout',
        svg: iconMenuSignOut,
    },
    {
        name: 'slide left',
        svg: iconSideLeft,
    },
    {
        name: 'slide right',
        svg: iconSideRight,
    },
    {
        name: 'timer',
        svg: iconRentTimer,
    },
    {
        name: 'rate',
        svg: iconRate,
    },
    {
        name: 'lock',
        svg: iconLock,
    },
    {
        name: 'top',
        svg: iconBackToTop,
    },
    {
        name: 'search2',
        svg: iconInputSearch,
    },
];

const otherIcons = [
    {
        name: 'TV',
        svg: iconTV,
    },
    {
        name: 'tablet',
        svg: iconTablet,
    },
    {
        name: 'Phone',
        svg: iconPhone,
    },
    {
        name: 'DVD',
        svg: iconDVD,
    },
    {
        name: 'HD',
        svg: iconHD,
    },
    {
        name: 'UHD',
        svg: iconUHD,
    },
];

const playerIcons = [
    {
        name: 'Unmute',
        svg: iconUnmute,
    },
    {
        name: 'Mute',
        svg: iconMute,
    },
    {
        name: 'Play',
        svg: iconPlay,
    },
    {
        name: 'Pause',
        svg: iconPause,
    },
    {
        name: 'Expand',
        svg: iconExpand,
    },
    {
        name: 'Shrink',
        svg: iconShrink,
    },
    {
        name: 'Cast',
        svg: iconCast,
    },
    {
        name: 'Subtitle',
        svg: iconSubtitle,
    },
    {
        name: 'Filter',
        svg: iconFilter,
    },
];

const socialIcons = [
    {
        name: 'Facebook',
        svg: iconFacebook,
    },
    {
        name: 'Twitter',
        svg: iconTwitter,
    },
];

const StyleGuideIcons = ({title}) => (
    <div className="vub-c-style-guide-icons">
        <div className="vub-c-style-guide-icons__title">
            {title}
        </div>
        <div className="vub-c-style-guide-icons__content">
            <div className="vub-c-style-guide-icons__icon-group">
                <div className="vub-c-style-guide-icons__icon-group-name">standard</div>
                <div className="vub-c-style-guide-icons__list">
                    {standardIcons.map(icon => (
                        <div key={icon.name} className="vub-c-style-guide-icons__icon">
                            <div className="vub-c-style-guide-icons__icon-name">
                                {icon.name}
                            </div>
                            <StandardIcon
                                icon={icon.svg}
                                className="vub-c-icon--large"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="vub-c-style-guide-icons__icon-group">
                <div className="vub-c-style-guide-icons__icon-group-name">Social</div>
                <div className="vub-c-style-guide-icons__list">
                    {socialIcons.map(icon => (
                        <div key={icon.name} className="vub-c-style-guide-icons__icon">
                            <div className="vub-c-style-guide-icons__icon-name">
                                {icon.name}
                            </div>
                            <StandardIcon
                                icon={icon.svg}
                                className="vub-c-icon--large"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="vub-c-style-guide-icons__icon-group">
                <div className="vub-c-style-guide-icons__icon-group-name">other</div>
                <div className="vub-c-style-guide-icons__list">
                    {otherIcons.map(icon => (
                        <div key={icon.name} className="vub-c-style-guide-icons__icon">
                            <div className="vub-c-style-guide-icons__icon-name">
                                {icon.name}
                            </div>
                            <StandardIcon
                                icon={icon.svg}
                                className="vub-c-icon--large"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="vub-c-style-guide-icons__icon-group">
                <div className="vub-c-style-guide-icons__icon-group-name">Player</div>
                <div className="vub-c-style-guide-icons__list">
                    {playerIcons.map(icon => (
                        <div key={icon.name} className="vub-c-style-guide-icons__icon">
                            <div className="vub-c-style-guide-icons__icon-name">
                                {icon.name}
                            </div>
                            <StandardIcon
                                icon={icon.svg}
                                className="vub-c-icon--large"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

StyleGuideIcons.propTypes = {
    title: PropTypes.string,
};

StyleGuideIcons.defaultProps = {
    title: 'icons',
};

export default StyleGuideIcons;
