import {Record} from 'immutable';
import moment from 'moment';

/**
 * EPGShow definition
 *
 * @typedef {Immutable.Record} EPGShow
 * @property {?string} id
 * @property {?string} channelId
 * @property {?Object} startDate
 * @property {?Object} endDate
 * @property {?string} title
 * @property {?string} description
 */

/**
 * EPGShow record
 *
 * @type {EPGShow}
 */
class EPGShow extends Record({
    id: null,
    channelId: null,
    startDate: null,
    endDate: null,
    title: null,
    description: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {EPGShow}
     */
    fromDTO(dataTransferObject) {
        return new EPGShow({
            ...this.toObject(),
            ...dataTransferObject,
            startDate: dataTransferObject.startDate ? moment(dataTransferObject.startDate) : this.startDate,
            endDate: dataTransferObject.endDate ? moment(dataTransferObject.endDate) : this.endDate,
        });
    }
}

export default EPGShow;
