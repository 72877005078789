import {mediaItemOfferType} from '@comrock/vub-wls-media-service';
import seed from 'seed-random';
import config from '../../config';

/**
 * Returns set of fake offers for testing
 *
 * @return {Array}
 */
const createFakeOffers = (mediaItemId, baseOffers) => {
    const fakeOffers = [];
    const providers = ['playhbonow.com', 'disney.com', 'primevideo.com', 'hulu.com'];

    if (!config.CREATE_FAKE_OFFERS) return fakeOffers;

    // create fake offers just for some mediaItems
    const initiativeThrow = seed(mediaItemId);
    if (initiativeThrow() < 0.5) return fakeOffers;

    // create new offers
    baseOffers.forEach(offer => {
        // add high-res TVOD offer and additional TVOD offers
        if (offer.offerType === mediaItemOfferType.TVOD) {
            fakeOffers.push({
                ...offer,
                id: offer.id + '-0',
                price: (parseFloat(offer.price) + 2).toString(),
                resolution: 'HD',
            });

            const seedRandomProvider1 = seed(offer.id + '-1');
            fakeOffers.push({
                ...offer,
                id: offer.id + '-1',
                provider: providers[Math.floor(seedRandomProvider1() * providers.length)],
                price: (parseFloat(offer.price) - 2).toString(),
            });

            const seedRandomProvider2 = seed(offer.id + '-2');
            fakeOffers.push({
                ...offer,
                id: offer.id + '-2',
                provider: providers[Math.floor(seedRandomProvider2() * providers.length)],
                price: (parseFloat(offer.price) + 3.5).toString(),
                resolution: 'HD',
            });
        }

        // add EST offers
        if (offer.offerType === mediaItemOfferType.TVOD) {
            const seedRandomProvider3 = seed(offer.id + '-3');
            fakeOffers.push({
                ...offer,
                id: offer.id + '-3',
                provider: providers[Math.floor(seedRandomProvider3() * providers.length)],
                offerType: mediaItemOfferType.EST,
                price: (parseFloat(offer.price) + 5).toString(),
            });

            const seedRandomProvider4 = seed(offer.id + '-4');
            fakeOffers.push({
                ...offer,
                id: offer.id + '-4',
                provider: providers[Math.floor(seedRandomProvider4() * providers.length)],
                offerType: mediaItemOfferType.EST,
                price: (parseFloat(offer.price) + 8.5).toString(),
                resolution: 'HD',
            });
        }

        // add SVOD offers
        if (offer.offerType === mediaItemOfferType.SVOD) {
            fakeOffers.push({
                ...offer,
                id: offer.id + '-5',
                price: (parseFloat(offer.price) + 2).toString(),
                resolution: 'HD',
            });

            const seedRandomProvider6 = seed(offer.id + '-6');
            fakeOffers.push({
                ...offer,
                id: offer.id + '-6',
                provider: providers[Math.floor(seedRandomProvider6() * providers.length)],
                price: (parseFloat(offer.price) + 5.5).toString(),
                resolution: 'HD',
            });
        }
    });

    return fakeOffers;
};

export default createFakeOffers;
