import {put} from 'redux-saga/effects';
import * as actionTypes from '../mediaEntitlementActionTypes';

/**
 * Invalidate user session data
 */
const invalidateUserSessionData = function* invalidateUserSessionData() {
    yield put({type: actionTypes.RESET_CURRENT_ENTITLEMENTS, payload: {}});
    yield put({type: actionTypes.RESET_BASKET, payload: {}});
};

export default invalidateUserSessionData;
