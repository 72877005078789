const regExNameRule = /^[^-\s][a-zA-Z0-9_\s-]+$/;

const regExEmailRule = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

// regex rule for name checks if string start with spaces but allows space between words
const regExPasswordRule = /^(?=.*?[A-Z+])(?=.*?[a-z])(?=.*?[0-9])(?!.*:)(?!.*[ ]).{8,}$/;

// validation rules
const required = {
    message: 'The field is required!',
    check: value => value,
};

const isNameValid = {
    message: 'Name format is not correct!',
    check: name => regExNameRule.test(name),
};

const isEmailValid = {
    message: 'Email format is not correct!',
    check: email => regExEmailRule.test(email),
};

const isPasswordValid = {
    message: 'Password format is not correct!',
    check: password => regExPasswordRule.test(password),
};

const isMatchWith = matchName => ({
    message: `Repeated ${matchName} does not match with ${matchName}!`,
    check: (value, allValues) => allValues[matchName] === value,
});

const maxLength = (allowedLength = 20) => ({
    message: `Maximum length of ${allowedLength} is reached!`,
    check: text => text.length <= allowedLength,
});

const minLength = (allowedLength = 20) => ({
    message: `Manimum length is ${allowedLength}!`,
    check: text => text.length >= allowedLength,
});

class Validator {
    constructor(...validations) {
        // an array of validation rules
        this.validations = validations;
    }

    validate(isTouched, value, allValues) {
        const validationObject = new ValidationObject();
        this.validations.forEach(validation => {
            const {check, message} = validation;

            if (!check(value, allValues)) {
                validationObject.invalidStatus = true;
                validationObject.addMessage(message);
            }
        });
        validationObject.touchState = isTouched;
        return validationObject;
    }
}

class ValidationObject {
    constructor(isInvalid = false, isTouched = false, messages = []) {
        this.isInvalid = isInvalid;
        this.isTouched = isTouched;
        this.messages = messages;
    }

    addMessage(message) {
        this.messages = [...this.messages, message];
    }

    clear() {
        this.messages = [];
        this.isTouched = false;
        this.isInvalid = false;
        return this;
    }

    set invalidStatus(flag) {
        this.isInvalid = flag;
    }

    set touchState(flag) {
        this.isTouched = flag;
    }
}

export {
    required,
    isNameValid,
    isEmailValid,
    isPasswordValid,
    isMatchWith,
    maxLength,
    minLength,
    Validator,
    ValidationObject,
};
