import Immutable from 'immutable';

/**
 * Returns mediaItem from bucket media
 *
 * @param {MediaBucket} mediaBucket
 * @param {string} mediaItemId
 * @returns {?MediaItem}
 */
export const getMediaItem = function getMediaItem(mediaBucket, mediaItemId) {
    return mediaBucket.media.get(mediaItemId);
};

/**
 * Returns mediaItem from bucket media
 * TODO memoize
 *
 * @param {MediaBucket} mediaBucket
 * @param {MediaItem} mediaItem
 * @param {?Immutable.OrderedMap<MediaItem>} mediaItemDescendants
 * @returns {Immutable.OrderedMap<MediaItem>}
 */
export const getMediaItemDescendants = function getMediaItemDescendants(
    mediaBucket,
    mediaItem,
    mediaItemDescendants = Immutable.OrderedMap()
) {
    mediaItem.childrenIds.map(mediaItemId => {
        return getMediaItem(mediaBucket, mediaItemId);
    }).forEach(childMediaItem => {
        if (childMediaItem) {
            mediaItemDescendants = mediaItemDescendants.set(childMediaItem.id, childMediaItem);
            mediaItemDescendants = getMediaItemDescendants(mediaBucket, childMediaItem, mediaItemDescendants);
        }
    });

    return mediaItemDescendants;
};

/**
 * Returns mediaItem from bucket media sorted by index
 * TODO memoize
 *
 * @param {MediaBucket} mediaBucket
 * @param {MediaItem} mediaItem
 * @returns {Immutable.OrderedMap<MediaItem>}
 */
export const getMediaItemChildren = function getMediaItemChildren(mediaBucket, mediaItem) {
    let mediaItemChildren = Immutable.OrderedMap();

    mediaItem.childrenIds.map(mediaItemId => {
        return getMediaItem(mediaBucket, mediaItemId);
    }).forEach(childMediaItem => {
        if (childMediaItem) {
            mediaItemChildren = mediaItemChildren.set(childMediaItem.id, childMediaItem);
        }
    });

    mediaItemChildren = mediaItemChildren.sort((mediaItemA, mediaItemB) => mediaItemA.siblingIndex
        - mediaItemB.siblingIndex);

    return mediaItemChildren;
};
