import {Record} from 'immutable';
import moment from 'moment';

/**
 * Offer definition
 *
 * @typedef {Immutable.Record} Offer
 * @property {?string} id
 * @property {?number} mediaItemId
 * @property {?string} productId
 * @property {?string} assetId
 * @property {?string} assetOfferId
 * @property {?string} assetPreviewId
 * @property {?string} link
 * @property {?string} offerType
 * @property {?Object} startDate
 * @property {?Object} endDate
 * @property {?string} billingId
 * @property {?string} rentalPeriod
 * @property {?string} expirationAfterFirstPlay
 * @property {?string} provider
 * @property {?string} currency
 * @property {?number} price
 * @property {?string} resolution
 * @property {?string} audioType
 * @property {?boolean} isAudioDescribed
 * @property {?boolean} hasSubtitles
 */

/**
 * Offer record
 *
 * @type {Offer}
 */
class Offer extends Record({
    id: null,
    mediaItemId: null,
    productId: null,
    assetId: null,
    assetOfferId: null,
    assetPreviewId: null,
    link: null,
    offerType: null,
    startDate: null,
    endDate: null,
    billingId: null,
    rentalPeriod: null,
    expirationAfterFirstPlay: null,
    provider: null,
    currency: null,
    price: 0,
    resolution: null,
    audioType: null,
    isAudioDescribed: null,
    hasSubtitles: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {Offer}
     */
    fromDTO(dataTransferObject) {
        return new Offer({
            ...this.toObject(),
            ...dataTransferObject,
            startDate: dataTransferObject.startDate ? moment(dataTransferObject.startDate) : this.startDate,
            endDate: dataTransferObject.endDate ? moment(dataTransferObject.endDate) : this.endDate,
            price: dataTransferObject.price
                ? parseFloat(dataTransferObject.price) : this.price,
        });
    }
}

export default Offer;
