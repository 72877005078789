import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {BEMClassNameResolver, BEMClassNameResolverPropTypes, BEMClassNameResolverDefaultProps} from './BEMClassNameResolver';

@BEMClassNameResolver('cr-c-sprite-symbol')
class SVGSpriteSymbol extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        spriteSymbol: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
    };

    render() {
        const {
            spriteSymbol,
            blockName, // eslint-disable-line
            resolveClassName,
            ...restProps
        } = this.props;

        return (
            <svg {...restProps} viewBox={spriteSymbol.viewBox} className={resolveClassName()}>
                <use xlinkHref={spriteSymbol.url} />
            </svg>
        );
    }
}

export default SVGSpriteSymbol;
