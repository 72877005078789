import React, {PureComponent} from 'react';
import './NavigationIconButton.scss';
import IconButton from '../../ui-kit/IconButton';

class NavigationIconButton extends PureComponent {
    static propTypes = {
        ...IconButton.propTypes,
    };

    static defaultProps = {
        ...IconButton.defaultProps,
    };

    render() {
        return (
            <IconButton {...this.props} blockName="vub-c-navigation-icon-button" />
        );
    }
}

export default NavigationIconButton;
