import {Record} from 'immutable';
import moment from 'moment';
import Subscription, {SubscriptionType} from './Subscription';
import PaymentMethod from './PaymentMethod';

/**
 * Returns array of subscriptions
 */
const parseSubscriptionsFromDTO = (userAccountDTOSubscriptions = []) => {
    const subscriptions = [];
    userAccountDTOSubscriptions.forEach(subscriptionDTO => {
        const subscription = new Subscription({}).fromDTO(subscriptionDTO);
        if (subscription.id && subscription.active) {
            subscriptions.push(subscription);
        }
    });

    const subscriptionPriority = {
        [SubscriptionType.PREMIUM]: 0,
        [SubscriptionType.STANDARD]: 1,
        [SubscriptionType.ULTRA]: 2,
        [null]: 3,
    };

    return subscriptions.sort((subscriptionA, subscriptionB) => {
        return subscriptionPriority[subscriptionA.type] - subscriptionPriority[subscriptionB.type];
    });
};

/**
 * Returns array of payment methods
 */
const parsePaymentMethodsFromDTO = (userAccountDTOPaymentMethods = []) => {
    const paymentMethods = [];
    userAccountDTOPaymentMethods.forEach(paymentMethodDTO => {
        const paymentMethod = new PaymentMethod({}).fromDTO(paymentMethodDTO);
        if (paymentMethod.id) {
            paymentMethods.push(paymentMethod);
        }
    });

    return paymentMethods;
};

/**
 * UserAccount definition
 *
 * @typedef {Immutable.Record} UserAccount
 * @property {?string} id
 * @property {?string} email
 * @property {?boolean} termsAndConditionsAccepted
 * @property {?string} name
 * @property {?boolean} hasOptedInToReceiveUpdates
 * @property {?array<PaymentMethod>} paymentMethods
 * @property {?array<Subscription>} subscriptions
 * @property {?Subscription} primarySubscription
 * @property {?Object} preferenceUpdateDate
 */

/**
 * UserAccount record
 *
 * @type {UserAccount}
 */
class UserAccount extends Record({
    id: null,
    email: null,
    termsAndConditionsAccepted: false,
    name: null,
    hasOptedInToReceiveUpdates: false,
    paymentMethods: [],
    subscriptions: [],
    primarySubscription: null,
    preferenceUpdateDate: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {UserAccount}
     */
    fromDTO(dataTransferObject) {
        const paymentMethods = dataTransferObject.paymentMethods
            ? parsePaymentMethodsFromDTO(dataTransferObject.paymentMethods) : this.paymentMethods;
        const subscriptions = dataTransferObject.subscriptions
            ? parseSubscriptionsFromDTO(dataTransferObject.subscriptions) : this.subscriptions;
        const primarySubscription = typeof subscriptions[0] !== 'undefined' && subscriptions[0].type !== null
            ? subscriptions[0] : null;
        const preferenceUpdateDate = dataTransferObject.preferenceUpdateDate
            ? moment(dataTransferObject.preferenceUpdateDate)
            : this.preferenceUpdateDate;

        return new UserAccount({
            ...this.toObject(),
            ...dataTransferObject,
            paymentMethods,
            subscriptions,
            primarySubscription,
            preferenceUpdateDate,
        });
    }
}

export default UserAccount;
