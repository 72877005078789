import React from 'react';

const ContactUsPage = () => (
    <div>
        <h2>Contact us</h2>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque efficitur, ipsum non semper
            venenatis, turpis tortor pharetra est, ultrices venenatis purus nisi in nunc. Nulla maximus purus et lacus
            placerat condimentum. Maecenas pretium sapien lacus, et suscipit est efficitur eu. Aenean sit amet rutrum
            massa. Sed nec nisi sollicitudin, feugiat eros eget, vulputate nunc. Proin maximus arcu sed nisi cursus
            iaculis. Morbi vel velit vitae orci vehicula facilisis. In sit amet suscipit libero. Nam ultrices dolor
            quis porttitor bibendum. Proin nec mi sed magna interdum scelerisque. Aenean ut tempus nibh, ut ullamcorper
            ex. Cras laoreet nunc diam.
        </p>
        <p>
            Pellentesque posuere augue nec purus rutrum, tristique facilisis lectus tincidunt. Vestibulum accumsan diam
            a lorem condimentum, nec maximus risus consequat. Curabitur nec quam a tellus accumsan egestas in quis
            nisi. Vivamus sodales efficitur purus vel pretium. Mauris nec neque metus. Etiam vulputate, erat vitae
            convallis scelerisque, nunc velit mattis erat, id tempor massa sapien volutpat ligula. Donec pretium augue
            condimentum, faucibus est nec, facilisis libero. Fusce et nisi justo. Phasellus ultrices euismod porttitor.
            Quisque quis felis faucibus, aliquet arcu sit amet, aliquam arcu. Nam vulputate egestas scelerisque.
            Integer finibus, odio vitae tempus interdum, turpis ipsum tempor diam, vestibulum posuere ligula neque
            sed justo. Nulla neque dolor, dapibus vitae tincidunt vitae, semper fermentum nisl.
        </p>
        <p>
            Integer fermentum tortor dui, at gravida dui rutrum non. Phasellus est ipsum, bibendum sit amet dui et,
            mollis sodales felis. Quisque id scelerisque sapien. Ut id luctus nulla. Nulla nec semper arcu. Proin ac
            augue vitae enim elementum finibus. Nunc nunc massa, dignissim rutrum suscipit ut, fringilla nec purus.
            Donec suscipit blandit fringilla. Duis sit amet ornare lorem. Mauris hendrerit velit et lorem accumsan,
            ut dapibus ex vehicula. Sed iaculis, nulla nec vulputate elementum, risus magna sodales erat, dignissim
            porta libero urna vitae urna. Sed at commodo ante. Vestibulum sagittis odio vitae mauris accumsan, nec
            mattis felis malesuada. Donec varius urna non ligula placerat convallis. In hac habitasse platea dictumst.
            Cras vulputate varius lorem, ac bibendum massa eleifend id.
        </p>
    </div>
);

export default ContactUsPage;
