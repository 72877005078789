import {all, call, fork, put, take, takeEvery, takeLatest} from 'redux-saga/effects';
import * as actionTypes from './mediaActionTypes';
import {mediaService} from '../services';

/**
 * Fetch mediaGenres request
 */
export const fetchMediaGenresRequest = function* fetchMediaGenresRequest() {
    try {
        yield put({
            type: actionTypes.FETCH_MEDIA_GENRES_REQUEST,
            payload: {},
        });

        const response = yield call(mediaService.getMediaGenres);
        yield put({
            type: actionTypes.FETCH_MEDIA_GENRES_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_MEDIA_GENRES_ERROR, error: true, payload: error});
    }
};

/**
 * Load mediaGenres into the store
 */
export const loadMediaGenres = function* loadMediaGenres() {
    yield fork(fetchMediaGenresRequest);
    const resultAction = yield take([
        actionTypes.FETCH_MEDIA_GENRES_SUCCESS,
        actionTypes.FETCH_MEDIA_GENRES_ERROR,
    ]);

    if (!resultAction.error) {
        const {response: mediaGenreDTOs} = resultAction.payload;
        yield put({
            type: actionTypes.STORE_MEDIA_GENRES,
            payload: {
                mediaGenreDTOs,
            },
        });
    }
};

/**
 * Fetch media request
 *
 * @param {function} requestMethod
 * @param {Object} requestParams
 * @param {Object} meta
 */
export const fetchMediaRequest = function* fetchMediaRequest(requestMethod, requestParams, meta) {
    try {
        yield put({
            type: actionTypes.FETCH_MEDIA_REQUEST,
            payload: {requestParams},
            meta,
        });

        const response = yield call(requestMethod, requestParams);
        yield put({
            type: actionTypes.FETCH_MEDIA_SUCCESS,
            payload: {response},
            meta,
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_MEDIA_ERROR, error: true, payload: error, meta});
    }
};

/**
 * Fetch and store media
 *
 * @param {function} requestMethod
 * @param {FluxStandardAction} action
 */
export const fetchAndStoreMedia = function* fetchAndStoreMedia(requestMethod, {payload}) {
    const {mediaBucketKey, requestParams, invalidateCurrentSet} = payload;

    yield put({
        type: actionTypes.SET_MEDIA_BUCKET,
        payload: {
            mediaBucketKey,
            invalidateCurrentSet,
            isLoadingMedia: true,
        },
    });

    yield fork(fetchMediaRequest, requestMethod, requestParams, {mediaBucketKey});
    while (true) {
        const fetchMediaResultAction = yield take([
            actionTypes.FETCH_MEDIA_SUCCESS,
            actionTypes.FETCH_MEDIA_ERROR,
        ]);
        if (typeof fetchMediaResultAction.meta.mediaBucketKey !== 'undefined'
            && fetchMediaResultAction.meta.mediaBucketKey !== mediaBucketKey) continue;

        if (!fetchMediaResultAction.error) {
            const {response} = fetchMediaResultAction.payload;
            const {mediaDTOs, primaryMediaIds, count: mediaTotalCount, facets} = response;
            yield put({
                type: actionTypes.STORE_MEDIA_BUCKET_MEDIA,
                payload: {
                    mediaBucketKey,
                    mediaTotalCount,
                    mediaDTOs,
                    primaryMediaIds,
                    facets,
                },
            });
            yield put({
                type: actionTypes.MEDIA_BUCKET_MEDIA_STORED,
                payload: {
                    mediaBucketKey,
                },
            });
        }
        break;
    }
};

/**
 * Media watcher saga
 */
export const mediaWatcher = function* mediaWatcher() {
    yield all([
        takeEvery(actionTypes.FETCH_MEDIA_GENRE_MEDIA, fetchAndStoreMedia, mediaService.getMediaGenreMedia),
        takeEvery(actionTypes.FETCH_MEDIA_COLLECTION_MEDIA, fetchAndStoreMedia, mediaService.getMediaCollectionMedia),
        takeEvery(actionTypes.FETCH_MEDIA_ITEM, fetchAndStoreMedia, mediaService.getMediaItem),
        takeEvery(actionTypes.FETCH_MEDIA, fetchAndStoreMedia, mediaService.getMedia),
        takeEvery(
            actionTypes.FETCH_RECOMMENDATIONS_MEDIA,
            fetchAndStoreMedia,
            mediaService.getMediaItemRecommendationsMedia,
        ),
        takeLatest(actionTypes.SEARCH_MEDIA, fetchAndStoreMedia, mediaService.searchMedia),
    ]);
};
