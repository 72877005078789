import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {mediaRouteResolver} from '../services';
import {
    withMediaRouteHelpers,
    withMediaRouteHelpersPropTypes,
    withMediaRouteHelpersDefaultProps,
} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaItemViewSelectors';
import {getMediaItem, getMediaItemChildren} from '../media/mediaBucketHelpers';
import ContentRow from '../layout/ContentRow';
import MediaItemHeroImage from '../ui-elements/media-item-hero-image/MediaItemHeroImageForTransparentHeader';
import MediaItemDetailsTVShowCastor from '../ui-elements/media-item-details/MediaItemDetailsTVShowCastor';
import MediaItemFormatTagHD from '../ui-elements/media-item-format-tag/MediaItemFormatTagHD';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortrait from '../ui-elements/media-gallery-tile/MediaGalleryTilePortrait';
import ProviderCornerRibbon from '../ui-elements/provider-corner-ribbon/ProviderCornerRibbon';
import CTAButtonBarView from './CTAButtonBarView';

@withRouter
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemTVShowViewCastor extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        mediaBucket: PropTypes.object,
        recommendationsMediaBucket: PropTypes.object,
        providers: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        mediaBucket: null,
        providers: null,
        recommendationsMediaBucket: null,
    };

    onSeasonSelect = value => {
        if (value === 'all') return;
        const {history, resolveMediaItemRoute} = this.props;

        history.push(resolveMediaItemRoute({mediaItemId: value}));
    };

    render() {
        const {mediaBucket, providers, recommendationsMediaBucket} = this.props;
        const {match, resolveMediaItemRoute} = this.props;
        const {mediaItemId} = match.params;
        const tvShowMediaItem = mediaBucket && mediaItemId ? getMediaItem(mediaBucket, mediaItemId) : null;
        const tvShowMediaItemSeasons = tvShowMediaItem ? getMediaItemChildren(mediaBucket, tvShowMediaItem) : null;
        const mediaItem = tvShowMediaItem;
        if (!mediaItem) return null;

        // title
        const allTVSHowOffers = tvShowMediaItemSeasons
            .reduce((allTVSHowOffers, tvShowMediaItemSeason) => {
                const seasonMediaItemEpisodes = getMediaItemChildren(mediaBucket, tvShowMediaItemSeason);
                return [
                    ...allTVSHowOffers,
                    ...(seasonMediaItemEpisodes
                        .reduce((allSeasonOffers, mediaItem) => [...allSeasonOffers, ...mediaItem.offers], [])),
                ];
            }, []);
        const hasHDOffers = !!allTVSHowOffers.find(offer => offer.resolution === '1080p');
        const title = (
            <Fragment>
                {mediaItem.title}&nbsp;{hasHDOffers && (
                    <MediaItemFormatTagHD />
                )}
            </Fragment>
        );

        // provider
        const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
            ? providers.get(mediaItem.offers[0].provider) : null;

        // subtitle
        const subtitle = tvShowMediaItemSeasons.size > 1
            ? `Season ${tvShowMediaItemSeasons.first().siblingIndex} - ${tvShowMediaItemSeasons.last().siblingIndex}`
            : (tvShowMediaItemSeasons.size === 1 ? `Season ${tvShowMediaItemSeasons.first().siblingIndex}` : null);

        // season select options
        const seasonSelectOptions = [
            {id: 'all', value: 'all', name: 'All Seasons'},
            ...tvShowMediaItemSeasons.toArray().map(seasonMediaItem => ({
                id: seasonMediaItem.id,
                value: seasonMediaItem.id,
                name: seasonMediaItem.title,
            })),
        ];

        return (
            <Fragment>
                <MediaItemHeroImage mediaItem={mediaItem}>
                    <ContentRow>
                        <MediaItemDetailsTVShowCastor
                            mediaItem={mediaItem}
                            title={title}
                            subtitle={subtitle}
                            provider={provider}
                            ctaButtonBar={(
                                <CTAButtonBarView mediaItem={mediaItem} />
                            )}
                            onSeasonSelect={this.onSeasonSelect}
                            seasonSelectOptions={seasonSelectOptions}
                        />
                    </ContentRow>
                </MediaItemHeroImage>

                {recommendationsMediaBucket && recommendationsMediaBucket.media.size > 0 ? (
                    <MediaGallery
                        title="You might also like"
                    >
                        {recommendationsMediaBucket.media
                            .toArray()
                            .map(mediaItem => {
                                const provider = typeof mediaItem.offers[0] !== 'undefined' && providers
                                    ? providers.get(mediaItem.offers[0].provider) : null;

                                return (
                                    <MediaGalleryTilePortrait
                                        key={mediaItem.id}
                                        mediaItem={mediaItem}
                                        linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                    >
                                        <ProviderCornerRibbon provider={provider} />
                                    </MediaGalleryTilePortrait>
                                );
                            })}
                    </MediaGallery>
                ) : null}
            </Fragment>
        );
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaItemViewBucketSelector();
    const recommendationsMediaBucketSelector = selectors.createMediaItemRecommendationsViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
        recommendationsMediaBucket: recommendationsMediaBucketSelector(state, props),
        providers: state.storeConfiguration.providers,
    });
};

export default connect(
    createMapStateToProps,
)(MediaItemTVShowViewCastor);
