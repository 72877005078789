import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles, withStylesPropTypes, withStylesDefaultProps} from '../../withStyles';
import styles from './CarouselSlide.module.scss';

@withStyles(styles)
class CarouselSlide extends Component {
    static propTypes = {
        ...withStylesPropTypes,
        setCarousel: PropTypes.func,
    };

    static defaultProps = {
        ...withStylesDefaultProps,
        setCarousel: null,
    };

    componentDidMount() {
        this.props.setCarousel();
    }

    componentDidUpdate(prevProps) {
        // TODO causes rendering loop, research other approach for refreshing carousel page count
        // if (this.props.children !== prevProps.children) {
        //     this.props.setCarousel();
        // }
    }

    render() {
        const {children, className, cx} = this.props;

        return (
            <div className={cx('cr-c-carousel-slide', 'global!js-carousel-slide', className)}>
                {children}
            </div>
        );
    }
}

export default CarouselSlide;
