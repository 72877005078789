import {fork, put, take} from 'redux-saga/effects';
import * as actionTypes from '../liveStreamingActionTypes';
import fetchLiveStreamPlaybackSource from './fetchLiveStreamPlaybackSource';

/**
 * Live stream playback flow
 */
export const liveStreamPlaybackFlow = function* liveStreamPlaybackFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.START_LIVE_STREAM_PLAYBACK);
        const {epgChannel} = payload;

        yield fork(fetchLiveStreamPlaybackSource, {
            epgChannelId: epgChannel.id,
        });
        const fetchLiveStreamPlaybackSourceResult = yield take([
            actionTypes.FETCH_LIVE_STREAM_PLAYBACK_SOURCE_SUCCESS,
            actionTypes.FETCH_LIVE_STREAM_PLAYBACK_SOURCE_ERROR,
        ]);

        if (fetchLiveStreamPlaybackSourceResult.type === actionTypes.FETCH_LIVE_STREAM_PLAYBACK_SOURCE_ERROR) continue;

        const {response: liveStreamPlaybackSourceDTO} = fetchLiveStreamPlaybackSourceResult.payload;
        yield put({
            type: actionTypes.STORE_LIVE_STREAM_PLAYBACK_SOURCE,
            payload: {liveStreamPlaybackSourceDTO, playbackEPGChannelId: epgChannel.id},
        });

        yield take(actionTypes.STOP_LIVE_STREAM_PLAYBACK);
        yield put({type: actionTypes.STORE_LIVE_STREAM_PLAYBACK_SOURCE, payload: {liveStreamPlaybackSourceDTO: null}});
    }
};

export default liveStreamPlaybackFlow;
