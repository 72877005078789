import store from 'store';

export const setNewUserSessionRoute = function setNewUserSessionRoute(newUserSessionRoute = null) {
    store.set('wls_new_user_session_route', JSON.stringify(newUserSessionRoute));
};

export const getOnceNewUserSessionRoute = function getOnceNewUserSessionRoute() {
    const storedNewUserSessionRoute = store.get('wls_new_user_session_route');
    if (!storedNewUserSessionRoute) return null;

    try {
        store.remove('wls_new_user_session_route');
        return JSON.parse(storedNewUserSessionRoute);
    } catch (e) {
        return null;
    }
};
