import React, {useContext} from 'react';
import moment from 'moment';
import './EPGTimeline.scss';
import EPGContext from './EPGContext';
import CTAButton from '../cta-button/CTAButton';
import * as epgTimeSteps from './epgTimeSteps';

const EPGTimeline = () => {
    const epgContext = useContext(EPGContext);
    const {scrollToTime} = epgContext;
    const {selectedTime, isScrollToPreviousAvailable, isScrollToNextAvailable} = epgContext;
    const {timelineScaleFractions, slideTrayWidth, slideTrayOffset} = epgContext;

    const scrollToPreviousTimeSlot = () => scrollToTime(epgTimeSteps.PREVIOUS_TIME_SLOT);
    const scrollToCurrentTimeSlot = () => scrollToTime(epgTimeSteps.CURRENT_TIME_SLOT);
    const scrollToNextTimeSlot = () => scrollToTime(epgTimeSteps.NEXT_TIME_SLOT);
    const scrollToPreviousDay = () => scrollToTime(epgTimeSteps.PREVIOUS_DAY);
    const scrollToNextDay = () => scrollToTime(epgTimeSteps.NEXT_DAY);

    const slideTrayStyle = {
        width: `${slideTrayWidth}%`,
        transform: `translateX(${slideTrayOffset}%)`,
    };

    return (
        <div className="vub-c-epg__timeline">
            <CTAButton
                className="vub-c-epg-timeline__button-now"
                onClick={scrollToCurrentTimeSlot}
            >
                Now
            </CTAButton>
            <div className="vub-c-epg-timeline__date-selector">
                {isScrollToPreviousAvailable && (
                    <button
                        type="button"
                        className="vub-c-epg-timeline__button-previous-day"
                        onClick={scrollToPreviousDay}
                    />
                )}
                <p className="vub-c-epg-timeline__date">
                    {moment(selectedTime).format('dddd, Do MMM')}
                </p>
                {isScrollToNextAvailable && (
                    <button
                        type="button"
                        className="vub-c-epg-timeline__button-next-day"
                        onClick={scrollToNextDay}
                    />
                )}
            </div>
            <div className="vub-c-epg-timeline__time-selector">
                <div className="vub-c-epg-timeline__viewing-frame">
                    <div className="vub-c-epg-timeline__slide-tray" style={slideTrayStyle}>
                        {timelineScaleFractions && timelineScaleFractions.map(timelineScaleFraction => {
                            const timelineScaleFractionStyle = {
                                width: `${timelineScaleFraction.width}%`,
                            };

                            return (
                                <div
                                    className="vub-c-epg-timeline__scale-fraction"
                                    style={timelineScaleFractionStyle}
                                    key={timelineScaleFraction.date.format('DD-HH:mm')}
                                >
                                    {timelineScaleFraction.date.format('HH:mm')}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="vub-c-epg-timeline__button-block-previous">
                    {isScrollToPreviousAvailable && (
                        <button
                            type="button"
                            className="vub-c-epg-timeline__button-previous-time-slot"
                            onClick={scrollToPreviousTimeSlot}
                        />
                    )}
                </div>
                <div className="vub-c-epg-timeline__button-block-next">
                    {isScrollToNextAvailable && (
                        <button
                            type="button"
                            className="vub-c-epg-timeline__button-next-time-slot"
                            onClick={scrollToNextTimeSlot}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EPGTimeline;
