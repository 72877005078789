import {call, put, all, select, takeEvery} from 'redux-saga/effects';
import * as actionTypes from './navigationActionTypes';
import * as selectors from './navigationSelectors';
import {wlsConfigurationService} from '../services';
import {resolveMenuItemUrls} from './menuUtils';
import {getStorefrontRows} from './navigationUtils';

/**
 * Fetch navigation
 *
 * @param {FluxStandardAction} action
 */
export const fetchNavigation = function* fetchNavigation({payload}) {
    const menus = yield select(selectors.getMenus);
    if (menus.length > 0) return;

    try {
        yield put({type: actionTypes.FETCH_NAVIGATION_REQUEST, payload});
        const menus = yield call(wlsConfigurationService.getNavigationMenus, payload);
        // TODO form menu items url here
        yield put({type: actionTypes.FETCH_NAVIGATION_SUCCESS, payload: {menus: resolveMenuItemUrls(menus)}});
    } catch (error) {
        yield put({type: actionTypes.FETCH_NAVIGATION_ERROR, error: true, payload: error});
    }
};

/**
 * Fetch storefront rows
 */
export const fetchStorefrontRows = function* fetchStorefrontRows() {
    try {
        yield put({type: actionTypes.FETCH_STOREFRONT_ROWS_REQUEST, payload: {}});

        const response = yield call(wlsConfigurationService.getContentPages);
        yield put({
            type: actionTypes.FETCH_STOREFRONT_ROWS_SUCCESS,
            payload: {response},
        });

        yield put({
            type: actionTypes.STORE_STOREFRONT_ROWS,
            payload: {
                storefrontRowDTOs: getStorefrontRows(response),
            },
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_STOREFRONT_ROWS_ERROR, error: true, payload: error});
    }
};

/**
 * Storefront watcher saga
 */
export const navigationWatcher = function* navigationWatcher() {
    yield all([
        takeEvery(actionTypes.FETCH_NAVIGATION, fetchNavigation),
    ]);
};
