import {call, put} from 'redux-saga/effects';
import * as actionTypes from '../mediaEntitlementActionTypes';
import {mediaEntitlementService} from '../../services';

/**
 * Checkout single mediaItem offer
 */
const checkoutSingleMediaItemOffer = function* checkoutSingleMediaItemOffer(params) {
    try {
        yield put({type: actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_REQUEST, payload: params});

        yield call(mediaEntitlementService.emptyBasket);
        const response = yield call(mediaEntitlementService.addOfferToBasket, params);
        yield put({
            type: actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR, error: true, payload: error});
    }
};

export default checkoutSingleMediaItemOffer;
